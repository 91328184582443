import { ElementRef } from '@angular/core';
import { CursorUtils } from '../../common/cursor-utils';
import { MentionableProcessor } from '../mentionable.processor';
import { MentionableNodeUtils } from '../mentionable.node.utils';
import { PlatformUtils } from '../../common/platform-utils';

export class KeyboardEventProcessor {
  protected el: ElementRef;
  protected mentionableProcessor: MentionableProcessor;

  protected participantNameTyped = ''; // the string which used typing in search area

  constructor(el: ElementRef, mentionableProcessor: MentionableProcessor) {
    this.el = el;
    this.mentionableProcessor = mentionableProcessor;

    el.nativeElement.addEventListener('input', this.onChange.bind(this));
    el.nativeElement.addEventListener('keydown', this.onKeydown.bind(this));
  }

  onChange(event: any): void {
    if (event.data) {
      if (this.mentionableProcessor.isActivated) {
        if (event.data === '@') {
          // should stop previous mention and start a new one
          this.mentionableProcessor.stopMention(undefined);
          this.mentionableProcessor
            .closeMentionDialogIfOpened()
            .subscribe((res: any) => {
              this.startMention();
            });
        } else if (event.data === ' ') {
          // stop previous mention because space is entered
          this.mentionableProcessor.closeMentionDialogIfOpened();
          this.mentionableProcessor.stopMention(undefined);
        } else if (!event.isComposing && event.data.length === 1) {
          this.onTypeNameChar(event.data);
        }
      } else {
        // not activated yet
        if (event.data === '@') {
          this.startMention();
        }
      }
    }
  }

  onKeydown(event: any): void {
    if ('Escape' === event.key && this.mentionableProcessor.isActivated) {
      this.mentionableProcessor.stopMention(undefined);
    }

    const element: any = event.target;

    if (!this.mentionableProcessor.isActivated) {
      if ('Backspace' === event.key || 'Delete' === event.key) {
        this.onRemoveText(event);
      } else if ('Enter' === event.key) {
        const selection: Selection | null = window.getSelection();
        if (selection) {
          const newLineContent = PlatformUtils.isClientFirefox()
            ? '\n\0'
            : '\n\n';
          MentionableNodeUtils.insertContentIntoSelectedNode(
            selection,
            newLineContent
          );
        }
        event.preventDefault();
      } else if (this.isCharacterKeyPressed(event)) {
        this.onReplaceText(event);
      }

      return;
    } else {
      // activated mention mode
      if ('Backspace' === event.key) {
        const cursorPosition = CursorUtils.getCurrentCursorPosition(element);
        if (cursorPosition > 0) {
          const elementText: string = element.innerText;
          const removingChar = elementText.charAt(cursorPosition - 1);
          if ('@' === removingChar) {
            this.mentionableProcessor.closeMentionDialogIfOpened();
            return;
          }
        }

        this.participantNameTyped = this.participantNameTyped.slice(0, -1);
        this.mentionableProcessor.filterParticipantsList(
          this.participantNameTyped
        );
      } else if ('Enter' === event.key) {
        event.preventDefault();
        return;
      }
    }
  }

  protected startMention(): void {
    this.participantNameTyped = '';
    const result = this.mentionableProcessor.startMention();

    result.afterOpened.subscribe(() => {
      this.el.nativeElement.focus();
    });

    result.afterClosed.subscribe(() => {
      setTimeout(() => {
        this.el.nativeElement.blur();
        this.el.nativeElement.focus();
      }, 10);
    });
  }

  protected onTypeNameChar(char: string): void {
    this.participantNameTyped += char;
    this.mentionableProcessor.filterParticipantsList(this.participantNameTyped);
  }

  private onRemoveText(event: any): void {
    const selection = window.getSelection();
    if ('Range' === selection?.type) {
      this.onRemoveTextRange(event, selection);
    } else {
      this.onRemoveTextSymbol(event);
    }
  }

  private onRemoveTextRange(event: any, selection: Selection): void {
    let focusOffset = 0;
    let focusNode: any = selection.focusNode;

    if (focusNode?.nodeName === '#text') {
      focusOffset = selection.focusOffset;
    }

    for (let i = 0; i < selection.rangeCount; i++) {
      const removingMentionsNodes: Node[] = [];

      const range = selection.getRangeAt(i);
      let startNode: any = range.startContainer;
      let endNode: any = range.endContainer;

      if (
        startNode.parentNode?.classList?.contains('mention-participant-name')
      ) {
        // start node is inside mention span. should use parent (mention) element
        startNode = startNode.parentNode;
      }
      if (endNode.parentNode?.classList?.contains('mention-participant-name')) {
        // start node is inside mention span. should use parent (mention) element
        endNode = endNode.parentNode;
      }

      if (startNode.nodeName === '#text') {
        let data = startNode.data.slice(0, range.startOffset);

        if (startNode === endNode) {
          data += startNode.data.slice(range.endOffset);
        }

        startNode.data = data;
      }

      if (endNode.nodeName === '#text' && startNode !== endNode) {
        focusOffset -= range.endOffset;
        endNode.data = endNode.data.slice(range.endOffset);
        if (focusOffset < 0) {
          focusOffset = 0;
        }
      }

      let nextNode: any = startNode;

      while (nextNode != null) {
        if (nextNode.nodeName === 'SPAN') {
          removingMentionsNodes.push(nextNode);
        } else if (nextNode !== startNode && nextNode !== endNode) {
          // removing #text nodes in the middle
          removingMentionsNodes.push(nextNode);
        }
        if (nextNode === endNode) {
          break;
        }
        nextNode = nextNode.nextSibling;
      }

      // update cursor position depending on removing nodes
      let cursorNode;

      if (
        focusNode.parentNode?.classList?.contains('mention-participant-name')
      ) {
        cursorNode = focusNode.parentNode;
      } else {
        cursorNode = focusNode;
      }

      while (cursorNode && removingMentionsNodes.includes(cursorNode)) {
        cursorNode = cursorNode.previousSibling;
      }

      if (focusNode !== cursorNode) {
        focusOffset = cursorNode?.length || 0;
        focusNode = cursorNode;
      }

      for (const removingNode of removingMentionsNodes) {
        const removedMentionId: number | null =
          MentionableNodeUtils.removeMentionAndShy(removingNode);
        if (removedMentionId) {
          this.mentionableProcessor.notifyMentionRemoved(removedMentionId);
        }
      }
    }

    if (
      MentionableNodeUtils.isNodeContainsNode(this.el.nativeElement, focusNode)
    ) {
      selection.setPosition(focusNode, focusOffset);
    } else {
      //// selected node has been removed. moving cursor to the end
      if (!focusNode) {
        MentionableNodeUtils.moveCursorToEnd(this.el.nativeElement);
      }
    }

    if (!this.isCharacterKeyPressed(event)) {
      event.preventDefault();
    }
  }

  private onRemoveTextSymbol(event: any): void {
    const selection: Selection | null = window.getSelection();

    if ('Backspace' === event.key) {
      if (
        selection?.focusOffset === 1 &&
        selection.focusNode?.previousSibling?.nodeName === 'SPAN'
      ) {
        // removing mentioned participant
        const removingNode = selection.focusNode.previousSibling;
        this.checkAndRemoveMentionNode(removingNode, event);
      } else if (selection?.focusNode?.parentNode?.nodeName === 'SPAN') {
        const spanNode: any = selection?.focusNode.parentNode;
        if (MentionableNodeUtils.isMentionedNode(spanNode)) {
          // backspace entered inside the mention node
          this.checkAndRemoveMentionNode(spanNode, event);
        } else {
          // backspace entered not in the mention node
          setTimeout(() => {
            if (this.el.nativeElement.innerText === '\n') {
              this.el.nativeElement.innerText = '';
            }
          }, 0);
        }
      }
    } else if ('Delete' === event.key) {
      if (selection?.focusNode?.nodeValue?.length === selection?.focusOffset) {
        // delete entered at the end of focused node
        if (selection?.focusNode?.nextSibling?.nodeName === 'SPAN') {
          // and the next node in mention node - so we should remove that next mention node
          const nextMentionNode = selection?.focusNode.nextSibling;
          this.checkAndRemoveMentionNode(nextMentionNode, event);
        } else if (selection?.focusNode?.parentNode?.nodeName === 'SPAN') {
          // and delete entered inside the mention node
          const spanNode: any = selection?.focusNode.parentNode;
          this.checkAndRemoveMentionNode(spanNode, event);
        }
      } else if (selection?.focusNode?.parentNode?.nodeName === 'SPAN') {
        // delete entered inside the mention mode
        const spanNode: any = selection?.focusNode.parentNode;
        this.checkAndRemoveMentionNode(spanNode, event);
      }
    }
  }

  private onReplaceText(event: any): void {
    const selection = window.getSelection();
    if ('Range' === selection?.type) {
      this.onReplaceTextRange(event, selection);
    } else {
      this.onReplaceTextSymbol(event);
    }
  }

  private onReplaceTextRange(event: any, selection: Selection): void {
    this.onRemoveTextRange(event, selection);
  }

  protected onReplaceTextSymbol(event: any): void {
    const selection: Selection | null = window.getSelection();
    if (selection?.focusNode?.parentNode) {
      const parentNode: any = selection.focusNode.parentNode;
      if (MentionableNodeUtils.isMentionedNode(parentNode)) {
        // user tried to enter symbol inside mention tag - just ignore/lock input
        event.preventDefault();
      }
    }
  }

  private checkAndRemoveMentionNode(mentionNode: any, event: any): void {
    if (mentionNode.classList?.contains('mention-participant-name')) {
      const removedMentionId: number | null =
        MentionableNodeUtils.removeMentionAndShy(mentionNode);
      if (removedMentionId) {
        this.mentionableProcessor.notifyMentionRemoved(removedMentionId);
      }
      event.preventDefault();
    }
  }

  private isCharacterKeyPressed(event: any): boolean {
    return (
      !event.ctrlKey &&
      !event.metaKey &&
      !event.altKey &&
      'Shift' !== event.key &&
      'Backspace' !== event.key &&
      'Delete' !== event.key
    );
  }
}
