<ng-container>
  <div class="apg-title apg-long-title" *ngIf="title">{{ title }}</div>

  <div mat-dialog-content>
    <div class="dialog-message">{{ message }}</div>
  </div>

  <div mat-dialog-actions class="buttons-block">
    <ng-container *ngIf="no">
      <button class="apg-button second-button" (click)="onDismiss()">
        {{ no }}
      </button>
    </ng-container>
    <ng-container *ngIf="yes">
      <button class="apg-button destructive-button" (click)="onConfirm()">
        {{ yes }}
      </button>
    </ng-container>
  </div>
</ng-container>
