import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Post, PostComment, PostReply, PostTopic } from '../../models/post';
import { Workspace } from '../../models/workspace';
import { ProfileService } from '../user/profile/profile.service';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../component/dialog/dialog-confirm/dialog-confirm.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { User } from '../../models/user';
import { Participant } from '../../models/participant';
import { Message } from '@twilio/conversations';
import { UserReportingService } from 'src/app/services/user/user-reporting/user-reporting.service';
import { UserService } from 'src/app/services/user/user/user.service';
import { UiHelperService } from '../utils/ui-helper.service';
import { SnackbarService, SnackbarType } from '../snackbar/snackbar.service';
import { catchError, lastValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  constructor(
    private httpClient: HttpClient,
    public profileService: ProfileService,
    public dialog: MatDialog,
    private reportingService: UserReportingService,
    private userService: UserService,
    private snackBar: SnackbarService,
    private uiHelperService: UiHelperService
  ) {}

  private sorting:
    | 'LIKE_ASC'
    | 'LIKE_DESC'
    | 'DATE_POSTED_ASC'
    | 'DATE_POSTED_DESC' = 'DATE_POSTED_DESC';
  private feedType: 'POST' | 'QUESTION' | 'WORKSPACE_COMMENT' = 'POST';
  private limit = 10;
  private page = 0;
  private pageCount = 0;
  public posts: PostTopic[] = [];
  private workspace: Workspace | undefined;
  private urlPostSave: string = environment.url + 'posts'; // post
  private urlPostEdit: string = environment.url + 'posts/'; // put /posts/{id} Update the post
  private urlOnePost: string = environment.url + 'posts/'; // get /posts/{id} Get list of post
  private urlPostDelete: string = environment.url + 'posts/'; // delete post
  private urlPostWithChildren: string = environment.url + 'posts/with/children'; // get
  private likedPost: string[] = [];
  private dislikedPost: string[] = [];
  private postId = 0;
  public loadInProgress = false;

  /**
   * GET /workspaces/{workspaceId}/comments/with/children'
   * url = urlCommentsPart1 + id + urlCommentsPart2
   */
  private urlCommentsPart1: string = environment.url + 'workspaces/';
  private urlCommentsPart2 = '/comments/with/children';

  /**
   *
   * /workspaces/{workspaceId}/comments/{id}/pin
   * Pin workspaces comment
   *
   * @param post - model Post
   */
  static getUrlPin(post: Post): string {
    return (
      environment.url +
      'workspaces/' +
      post.workspaceId +
      '/comments/' +
      post.postId +
      '/pin'
    );
  }

  /**
   *
   * /workspaces/comments/{id}/unpin
   * Unpin workspaces comment
   *
   * @param post - model Post
   */
  static getUrlUnpin(post: Post): string {
    return (
      environment.url +
      'workspaces/' +
      post.workspaceId +
      '/comments/' +
      post.postId +
      '/unpin'
    );
  }

  private urlLikeDislike(post: Post): string {
    return environment.url + 'posts/' + post.postId + '/likes';
  }

  /**
   *
   * /likes/posts/liked-disliked/{userId}
   * Get posts liked/disliked by user
   *
   * @param user - model User
   */
  private urlLikedDislikedByUser(user: User): string {
    return environment.url + 'users/' + user.userId + '/posts/likes';
  }

  /**
   *
   * /likes/{postId}
   * Delete the like
   *
   * @param post - model Post
   */
  private urlReactionRemove(post: Post): string {
    return environment.url + 'posts/' + post.postId + '/likes';
  }

  getLikedPost(): string[] {
    return this.likedPost;
  }

  getPage(): number {
    return this.page;
  }

  setPage(value: number): void {
    this.page = value;
  }

  getPageCount(): number {
    return this.pageCount;
  }

  setPageCount(value: string): void {
    this.pageCount = parseInt(value, 10);
  }

  removeAddLikePost(id: number, add: boolean = true): void {
    if (add) {
      // @ts-ignore
      this.likedPost.push(id);
    } else {
      this.likedPost.map((item, i) => {
        // @ts-ignore
        if (item === id) {
          // @ts-ignore
          this.likedPost[i] = undefined;
        }
      });
    }
  }

  updateInfoAboutPost(post: Post): void {
    this.httpClient
      .get(this.urlOnePost + post.postId)
      .subscribe((data: any) => {
        post.likeCounter = data.likeCounter;
        post.dislikeCounter = data.dislikeCounter;
        this.getSelfLiked();
      });
  }

  removeAddDislikePost(id: number, add: boolean = true): void {
    if (add) {
      // @ts-ignore
      this.dislikedPost.push(id);
    } else {
      this.dislikedPost.map((item, i) => {
        // @ts-ignore
        if (item === id) {
          // @ts-ignore
          this.dislikedPost[i] = undefined;
        }
      });
    }
  }

  getDislikedPost(): string[] {
    return this.dislikedPost;
  }

  setLikedPost(data: string[]): string[] {
    return (this.likedPost = data);
  }

  setDislikedPost(data: string[]): string[] {
    return (this.dislikedPost = data);
  }

  getWorkspace(workspace: Workspace): void {
    this.workspace = workspace;
    this.loadInProgress = true;
    this.getCommentsWithChildren().then(
      () => {
        this.loadInProgress = false;
      },
      () => {
        this.loadInProgress = false;
      }
    );
  }

  clearPost(): void {
    this.posts = [];
    this.page = 0;
  }

  saveNewPost(
    workspace: Workspace,
    text: string,
    mentionedParticipantsIds: Array<number>
  ): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(
        this.urlPostSave,
        new PostTopic(
          workspace.workspaceId !== undefined ? workspace.workspaceId : 0,
          text,
          mentionedParticipantsIds
        )
      )
    ).then(this.getAllCommentsWithChildren.bind(this));
  }

  saveNewPostComment(
    postTopic: PostTopic,
    text: string,
    mentionedParticipantsIds: Array<number>
  ): Promise<PostComment> {
    return new Promise<PostComment>((resolve, reject) => {
      lastValueFrom(
        this.httpClient.post(
          this.urlPostSave,
          new PostComment(
            postTopic.workspaceId !== undefined ? postTopic.workspaceId : 0,
            text,
            postTopic.postId,
            mentionedParticipantsIds
          )
        )
      ).then((item: any) => {
        const comment = new PostComment(
          0,
          '',
          undefined,
          undefined
        ).deserialize(item);
        postTopic.children?.push(comment);
        resolve(comment);
      });
    });
  }

  editPost(post: Post, text: string): Promise<any> {
    post.description = text;
    return lastValueFrom(
      this.httpClient.put(this.urlPostEdit + post.postId, post)
    ).then((item) => {
      const p = new Post().deserialize(item);
      post.description = p.description;
    });
  }

  saveNewPostReplay(postComment: PostComment, text: string): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(
        this.urlPostSave,
        new PostReply(
          postComment.workspaceId !== undefined ? postComment.workspaceId : 0,
          text,
          postComment.postId,
          postComment.topicId
        )
      )
    ).then(this.getAllCommentsWithChildren.bind(this));
  }

  getCommentsWithChildren(): Promise<any> {
    const params = new HttpParams()
      .set('sorting', this.sorting)
      .set('limit', this.limit.toString())
      .set('page', this.page.toString())
      .set('feedType', this.feedType);
    return lastValueFrom(
      this.httpClient.get(
        this.urlCommentsPart1 +
          this.workspace?.workspaceId +
          this.urlCommentsPart2,
        { params }
      )
    ).then(this.appendNotifications.bind(this));
  }

  getAllCommentsWithChildren(): Promise<any> {
    const params = new HttpParams()
      .set('sorting', this.sorting)
      .set('limit', this.limit * (this.page + 1))
      .set('page', '0')
      .set('feedType', this.feedType);
    return lastValueFrom(
      this.httpClient.get(
        this.urlCommentsPart1 +
          this.workspace?.workspaceId +
          this.urlCommentsPart2,
        { params }
      )
    ).then((items: any) => {
      this.cachePosts(items);
    });
  }

  getPostWithChildren(): Promise<any> {
    const params = new HttpParams()
      .set('sorting', this.sorting)
      .set('limit', this.limit.toString())
      .set('page', this.page.toString())
      .set('feedType', this.feedType);
    return lastValueFrom(
      this.httpClient.get(this.urlPostWithChildren, { params })
    );
  }

  confirmDeleteDialog(callback: (post: Post) => void, post: Post): void {
    const message = `Do you really want to delete this comment?`;
    const dialogData = new ConfirmDialogModel(message, 'Delete', 'Cancel');
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: dialogData,
      panelClass: 'confirm-dialog-container'
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        callback(post);
      }
    });
  }

  reportPost(post: Post): void {
    this.uiHelperService
      .showFreeFormDialog('Report user', 'Please specify report reason')
      .then((result) => {
        if (result) {
          this.reportingService
            .reportWorkspacePost(post.postId!, post.userId!, result.description)
            .subscribe({
              next: () => this.snackBar.open('User has been reported.'),
              error: () =>
                this.snackBar.open(
                  'Failed to send complaint! Please try again.',
                  SnackbarType.Error
                )
            });
        }
      });
  }

  deletePostWrapper(post: Post): void {
    if (post) {
      this.confirmDeleteDialog(this.deletePost.bind(this), post);
    }
  }

  deletePost(post: Post): void {
    if (post && this.permissionDeletingPost(post)) {
      lastValueFrom(
        this.httpClient.delete(this.urlPostDelete + post.postId)
      ).then(() => {
        this.getAllCommentsWithChildren().then();
        /*if (post instanceof PostTopic) {
                        const index = this.posts.findIndex((postTopic: PostTopic) => {
                            return postTopic.postId === post.postId;
                        });
                        this.posts.splice(index, 1);
                    } else if (post.postType === 'COMMENT') {
                        const i = this.posts.findIndex((postTopic: PostTopic) => {
                            return postTopic.postId === post.topicId;
                        });
                        if (i !== undefined) {
                            const ii = this.posts[i].children?.findIndex((postComment: PostComment) => {
                                return postComment.postId === post.postId;
                            });
                            if (ii !== undefined) {
                                this.posts[i].children?.splice(ii, 1);
                            }
                        }
                    }*/
      });
    }
  }

  reportChatMessage(message: Message): void {
    const showError = () => {
      this.snackBar.open(
        'Failed to send complaint! Please try again.',
        SnackbarType.Error
      );
    };

    this.uiHelperService
      .showFreeFormDialog('Report user', 'Please specify report reason')
      .then((result) => {
        if (result) {
          const reason = result.description;
          message
            .getParticipant()
            .then((participant) => {
              if (participant.identity) {
                this.userService
                  .getUserById(participant.identity)
                  .then((user) => {
                    this.reportingService
                      .reportChatMessage(
                        message.conversation.sid,
                        message.sid,
                        user.userId,
                        reason
                      )
                      .pipe(
                        catchError(() => {
                          showError();
                          return of(null);
                        })
                      )
                      .subscribe((response) => {
                        this.snackBar.open('User has been reported.');
                      });
                  })
                  .catch((error) => {
                    showError();
                  });
              } else {
                showError();
              }
            })
            .catch((error) => {
              showError();
            });
        }
      });
  }

  permissionEditingPost(post: Post): boolean {
    return !!post && post.email === this.profileService.user?.email;
  }

  permissionDeletingPost(post: Post): boolean {
    return (
      (!!post && post.email === this.profileService.user?.email) ||
      this.profileService.isAdminOrManager(this.workspace?.participants || [])
    );
  }

  permissionReportPost(post?: Post): boolean {
    return !!post && post.email !== this.profileService.user?.email;
  }

  permissionPinUnpin(participants: Participant[]): boolean {
    return true;
  }

  pin(post: Post): void {
    lastValueFrom(this.httpClient.post(PostService.getUrlPin(post), {}))
      .then(this.getAllCommentsWithChildren.bind(this))
      .then(() => {
        document
          .querySelector('.pin-active')
          ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
  }

  unpin(post: Post): void {
    lastValueFrom(this.httpClient.post(PostService.getUrlUnpin(post), {})).then(
      this.getAllCommentsWithChildren.bind(this)
    );
  }

  like(post: Post, user: User): void {
    lastValueFrom(
      this.httpClient.post(this.urlLikeDislike(post), {
        status: 'LIKE',
        userId: user.userId
      } as LikeDislike)
    ).then((data: any) => {
      post.likeCounter = data.likeCounter;
      post.dislikeCounter = data.dislikeCounter;
      this.getSelfLiked();
    });
  }

  dislike(post: Post, user: User): void {
    lastValueFrom(
      this.httpClient.post(this.urlLikeDislike(post), {
        status: 'DISLIKE',
        userId: user.userId
      } as LikeDislike)
    ).then((data: any) => {
      post.likeCounter = data.likeCounter;
      post.dislikeCounter = data.dislikeCounter;
      this.getSelfLiked();
    });
  }

  removeReaction(post: Post): void {
    lastValueFrom(this.httpClient.delete(this.urlReactionRemove(post))).then(
      () => {
        this.updateInfoAboutPost(post);
      }
    );
  }

  getSelfDisliked(): void {
    const user = this.profileService?.user;
    if (user?.userId) {
      const params = new HttpParams().set('likeStatus', 'DISLIKE');
      this.httpClient
        .get(this.urlLikedDislikedByUser(user), { params })
        .subscribe((data) => {
          this.setDislikedPost(data as []);
        });
    }
  }

  getSelfLiked(): void {
    const user = this.profileService?.user;
    if (user?.userId) {
      const params = new HttpParams()
        .set('userId', user.userId.toString())
        .set('likeStatus', 'LIKE');
      this.httpClient
        .get(this.urlLikedDislikedByUser(user), { params })
        .subscribe((data) => {
          this.setLikedPost(data as []);
        });
    }
  }

  isIncludesLike(post: Post, hardcode?: boolean): boolean {
    if (hardcode !== undefined && post.postId !== undefined) {
      this.removeAddLikePost(post.postId, hardcode);
      if (hardcode) {
        this.removeAddDislikePost(post.postId, !hardcode);
      }
      return hardcode;
    }

    if (post.postId !== undefined) {
      // @ts-ignore
      return this.getLikedPost().includes(post.postId);
    }
    return false;
  }

  isIncludesDislike(post: Post, hardcode?: boolean): boolean {
    if (hardcode !== undefined && post.postId !== undefined) {
      this.removeAddDislikePost(post.postId, hardcode);
      if (hardcode) {
        this.removeAddLikePost(post.postId, !hardcode);
      }
      return hardcode;
    }

    if (post.postId !== undefined) {
      // @ts-ignore
      return this.getDislikedPost().includes(post.postId);
    }
    return false;
  }

  async getCommentsWithChildrenPageCount(): Promise<number> {
    const params = new HttpParams().set('limit', this.limit.toString());
    const count = await lastValueFrom(
      this.httpClient.get(
        environment.url +
          '/workspaces/' +
          this.workspace?.workspaceId +
          '/comments/pages',
        { params, responseType: 'text' }
      )
    );
    this.setPageCount(count);
    return parseInt(count, 10);
  }

  private parsedPosts(data: any): Post[] {
    this.getSelfDisliked();
    this.getSelfLiked();

    const posts = [];
    for (const item of data) {
      posts.push(new Post().deserialize(item));
    }
    return posts;
  }

  private cachePosts(data: any): void {
    this.posts = this.parsedPosts(data);
  }

  private appendNotifications(data: any): void {
    this.posts = this.posts.concat(this.parsedPosts(data));
  }

  updatePostId(id: number): void {
    this.postId = id;
  }

  getPostId(): number {
    return this.postId;
  }
}

export class LikeDislike {
  postId: 0 | undefined; // can be applied to posts, comments, replies
  status: 'LIKE' | 'DISLIKE' = 'LIKE';
  userId: 0 | undefined; // user who likes or dislikes
}
