import { Injectable } from '@angular/core';
import { ArchiveFile } from '../../models/archiveFile';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor() {}

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 KB';
    }

    const kb = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(kb));
    return parseFloat((bytes / Math.pow(kb, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  colorForDocument(file: ArchiveFile): string {
    switch (file.extension) {
      case 'doc':
      case 'docx':
      case 'gdoc':
      case 'gdocx':
        return '#42AED0';
      case 'zip':
      case 'rar':
      case '7z':
        return '#8072B6';
      case 'pdf':
        return '#F7B84E';
      case 'txt':
      case 'rtf':
        return '#3F6B8C';
      case 'pages':
        return '#EF604E';
      case 'xls':
      case 'xlsx':
      case 'gxls':
      case 'gxlsx':
      case 'gsheet':
        return '#84BD5A';
      case 'm4v':
      case 'mov':
      case 'mp4':
      case 'avi':
        return '#C64D8E';
      default:
        return '#808080';
    }
  }
}
