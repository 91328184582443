import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'appDateSimplePipe'
})
export class AppDateSimplePipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  transform(value: string, format: string = 'MMM, D h:mm A'): string | null {
    return moment.utc(value).local().format(format);
  }
}
