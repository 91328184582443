import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EventReport } from '../../models/EventReport';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventReportService {
  constructor(private http: HttpClient) {}

  getReport(workspaceId: number, eventId: number): Promise<any> {
    return lastValueFrom(
      this.http.get(
        environment.url +
          'workspaces/' +
          workspaceId +
          '/events/' +
          eventId +
          '/report'
      )
    );
  }

  saveReport(
    workspaceId: number,
    eventId: number,
    report: EventReport
  ): Promise<any> {
    if (report.workspaceEventReportId === null) {
      return lastValueFrom(
        this.http.post(
          environment.url +
            'workspaces/' +
            workspaceId +
            '/events/' +
            eventId +
            '/report',
          report
        )
      );
    }
    return lastValueFrom(
      this.http.put(
        environment.url +
          'workspaces/' +
          workspaceId +
          '/events/' +
          eventId +
          '/report',
        report
      )
    );
  }

  deleteReport(workspaceId: number, eventId: number): Promise<any> {
    return lastValueFrom(
      this.http.delete(
        environment.url +
          'workspaces/' +
          workspaceId +
          '/events/' +
          eventId +
          '/report'
      )
    );
  }

  downloadPdf(workspaceId: number, eventId: number): Promise<any> {
    const options = {
      headers: new HttpHeaders({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      })
    };

    return lastValueFrom(
      this.http.get(
        environment.url +
          'workspaces/' +
          workspaceId +
          '/events/' +
          eventId +
          '/report/attachment',
        options
      )
    );
  }
}
