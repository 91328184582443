<ng-container *ngIf="form">
  <form [formGroup]="form" class="add-update-form">
    <div class="closeElement" mat-dialog-close>
      <span class="material-icons">close</span>
    </div>

    <div class="titleBlock link-dialog">
      <h1 class="apg-title">Add Link</h1>
    </div>
    <mat-dialog-content>
      <div class="apg-form-group">
        <label
          >Link
          <input
            type="text"
            formControlName="link"
            maxlength="2048"
            (blur)="linkFieldDidLoseFocus()"
            [class.errorInput]="shouldDisplayErrorBlock(linkInput)"
          />
        </label>

        <ng-container *ngIf="shouldDisplayErrorBlock(linkInput)">
          <div class="error" *ngIf="linkInput.errors?.required">
            Event title is required
          </div>
          <div class="error" *ngIf="linkInput.errors?.maxLength">
            Max length is {{linkInput.errors?.maxLength?.requiredLength}}
            symbols
          </div>
        </ng-container>
      </div>

      <div class="apg-form-group">
        <label
          >Title
          <input
            type="text"
            formControlName="title"
            maxlength="255"
            [class.errorInput]="shouldDisplayErrorBlock(titleInput)"
          />
        </label>

        <ng-container *ngIf="shouldDisplayErrorBlock(titleInput)">
          <div class="error" *ngIf="titleInput.errors?.maxLength">
            Max length is {{titleInput.errors?.maxLength?.requiredLength}}
            symbols
          </div>
        </ng-container>
      </div>

      <div class="apg-form-group apg-inline">
        <button
          class="apg-button red-button"
          [class.spinner]="isSavingInProgress"
          [disabled]="isSavingInProgress"
          (click)="save()"
        >
          Save
        </button>
      </div>
    </mat-dialog-content>
  </form>
</ng-container>
