<ngx-spinner
  size="medium"
  color="#000"
  type="ball-clip-rotate"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p></ngx-spinner
>
<ng-container *ngIf="existsOnListOfRoutes(); else otherIf">
  <router-outlet></router-outlet>
</ng-container>

<ng-template #otherIf>
  <app-auth-layout
    *ngIf="authService.checkAuth(); else signin"
  ></app-auth-layout>

  <ng-template #signin>
    <router-outlet (activate)="onShowSignin()"></router-outlet>
  </ng-template>
</ng-template>

<p-toast
  styleClass="announcement-toast"
  [breakpoints]="{ '450px': { width: '90%', margin: 'auto' } }"
  position="top-right"
  key="main"
  (onClose)="announcements.onMessageClose($event.message)"
>
  <ng-template let-message pTemplate="message">
    <div class="announcement">
      <mat-icon class="announcement-icon">{{ message.data.icon }}</mat-icon>

      <div class="announcement-text">
        <p class="summary">{{ message.summary }}</p>
        <p class="details" innerHtml="{{ message.detail }}"></p>
      </div>
    </div>
  </ng-template>
</p-toast>
