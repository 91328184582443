import { Component, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialogModule as MatDialogModule
} from '@angular/material/legacy-dialog';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { GlobalConstants } from '../../../../../common/global-constants';
import { NgFor, NgIf } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AvatarModule } from 'src/app/component/avatar/avatar.module';
import { LoadingComponent } from 'src/app/component/loading/loading.component';
import { NotificationCardComponent } from '../notification-card/notification-card.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    AvatarModule,
    MatMenuModule,
    LoadingComponent,
    NotificationCardComponent,
    InfiniteScrollModule
  ]
})
export class NotificationDialogComponent implements OnInit {
  page = 0;
  modalScrollDistance = 4;
  modalScrollThrottle = 50;

  userProfileRoute = GlobalConstants.routes.profile;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    public notificationService: NotificationsService,
    public profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.notificationService.setNotification();
    this.notificationService.getNotifications().then();
  }

  onConfirm(): void {
    this.dialogRef.close();
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  markAllAsRead(): void {
    this.notificationService.readAllNotification().then();
  }

  deleteAll(): void {
    this.notificationService.deleteAllNotification().then();
  }

  close(): void {
    this.dialogRef.close();
  }

  isHeaderVisible(): boolean {
    return window.innerWidth < 500;
  }

  onScrollDown(): void {
    this.notificationService.getPageCount().then((count) => {
      if (this.page >= count) {
        return;
      }
      this.notificationService.setPage(++this.page);
      this.notificationService.getNotifications().then();
    });
  }
}
