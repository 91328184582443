import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Notification, NotificationTypes } from 'src/app/models/notification';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ProfileService } from '../../../../../services/user/profile/profile.service';
import { PostService } from '../../../../../services/post/post.service';
import { GlobalConstants } from '../../../../../common/global-constants';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { AvatarModule } from 'src/app/component/avatar/avatar.module';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    AvatarModule,
    NgIf
  ]
})
export class NotificationCardComponent implements OnInit {
  @Input() notification: Notification;

  @Output() clicked = new EventEmitter();

  show = true;
  htmlToAdd: any;
  isMouseHere = false;
  isTabletOrMobile = false;
  src: string | undefined = '';
  title: string | undefined = '';
  notificationRoute = '';

  constructor(
    public notificationService: NotificationsService,
    public profileService: ProfileService,
    public postService: PostService,
    public router: Router
  ) {
    this.notification = new Notification();
  }

  ngOnInit(): void {
    this.src = this.notification.whoTriggeredProfileImageUrl;
    this.title = this.notification.userNameWhoTriggered;
    this.parse();
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        navigator.userAgent
      )
    ) {
      this.isTabletOrMobile = true;
    }
  }

  parse(): void {
    switch (this.notification.type) {
      case NotificationTypes.inviteToWorkspace: {
        this.htmlToAdd =
          'Invited you to join the ' +
          GlobalConstants.i18n.showspace +
          ' ' +
          this.changeColor(this.notification.workspaceTitle);
        this.notificationRoute = this.getWorkspaceRoute();
        break;
      }
      case NotificationTypes.created: {
        this.htmlToAdd =
          'Added ' +
          this.changeColor(this.notification.eventTitle) +
          ' to ' +
          this.changeColor(this.notification.workspaceTitle);
        this.notificationRoute = this.getEventRoute();
        break;
      }
      case NotificationTypes.pinnedComment: {
        this.htmlToAdd =
          'Pinned a comment in ' +
          this.changeColor(this.notification.workspaceTitle);
        this.notificationRoute = this.getWorkspaceRoute();
        break;
      }
      case NotificationTypes.startsTomorrow: {
        this.src = 'assets/avatar/event.svg';
        this.title = this.notification.eventTitle;
        this.htmlToAdd = 'Starts tomorrow';
        this.notificationRoute = this.getEventRoute();
        break;
      }
      case NotificationTypes.comment: {
        this.htmlToAdd =
          'Left a comment in ' +
          this.changeColor(this.notification.workspaceTitle);
        this.notificationRoute = this.getWorkspaceRoute();
        break;
      }
      case NotificationTypes.fileAttached: {
        this.htmlToAdd =
          'Added ' +
          this.changeColor(this.notification.attachmentType) +
          ' to the Library';
        this.notificationRoute = this.getWorkspaceRoute() + '/library';
        break;
      }
      case NotificationTypes.notGoingAttendanceAttendee: {
        this.htmlToAdd =
          'Mark why are you skipping ' +
          this.changeColor(this.notification.eventTitle);
        this.notificationRoute = this.getEventRoute();
        break;
      }
      case NotificationTypes.notGoingAttendanceMentor: {
        this.htmlToAdd =
          'Is not attending ' + this.changeColor(this.notification.eventTitle);
        this.notificationRoute = this.getEventRoute();
        break;
      }
      case NotificationTypes.updated: {
        if (this.notification.section === 'WORKSPACE') {
          this.htmlToAdd =
            'Updated ' + this.changeColor(this.notification.workspaceTitle);
          this.notificationRoute = this.getWorkspaceRoute();
        } else if (this.notification.section === 'EVENT') {
          this.htmlToAdd =
            'Updated ' + this.changeColor(this.notification.eventTitle);
          this.notificationRoute = this.getEventRoute();
        } else {
          this.show = false;
        }
        break;
      }
      case NotificationTypes.like: {
        if (this.notification.section === 'WORKSPACE') {
          this.htmlToAdd = 'Liked your comment';
          this.notificationRoute = this.getWorkspaceRoute();
        } else {
          this.show = false;
        }
        break;
      }
      case NotificationTypes.dislike: {
        if (this.notification.section === 'WORKSPACE') {
          this.htmlToAdd = 'Disliked your comment';
          this.notificationRoute = this.getWorkspaceRoute();
        } else {
          this.show = false;
        }
        break;
      }
      case NotificationTypes.reply: {
        if (this.notification.section === 'WORKSPACE') {
          this.htmlToAdd = 'Replied to your comment';
          this.notificationRoute = this.getWorkspaceRoute();
        } else {
          this.show = false;
        }
        break;
      }
      case NotificationTypes.mention: {
        this.htmlToAdd = 'Mentioned you in comment';
        this.notificationRoute = this.getWorkspaceRoute() + '/messages';
        break;
      }
      case NotificationTypes.eventMention: {
        this.htmlToAdd =
          'Mentioned you in event ' +
          this.changeColor(this.notification.eventTitle);
        this.notificationRoute = this.getEventRoute();
        break;
      }
      case NotificationTypes.sampleExpiresSoon: {
        this.htmlToAdd =
          'Your ' +
          this.changeColor(this.notification.workspaceTitle) +
          ' ' +
          GlobalConstants.i18n.showspace +
          ' will expire soon';
        this.notificationRoute = this.getWorkspaceRoute();
        break;
      }
      case NotificationTypes.eventReportPublished: {
        this.htmlToAdd =
          'Has published the ' +
          this.changeColor(this.notification.eventTitle) +
          ' report';
        this.notificationRoute = this.getEventRoute();
        break;
      }
      case NotificationTypes.eventParticipantAdded: {
        this.htmlToAdd =
          'Invited you to join the ' +
          this.changeColor(this.notification.eventTitle);
        this.notificationRoute = this.getEventRoute();
        break;
      }
      case NotificationTypes.assigneeCompletedTask: {
        this.htmlToAdd =
          'Has just marked ' +
          this.changeColor(this.notification.taskTitle) +
          ' as completed in ' +
          this.changeColor(this.notification.workspaceTitle);
        this.notificationRoute = this.getTaskRoute();
        break;
      }
      case NotificationTypes.taskClosed: {
        this.htmlToAdd =
          'Has just closed ' +
          this.changeColor(this.notification.taskTitle) +
          ' in ' +
          this.changeColor(this.notification.workspaceTitle);
        this.notificationRoute = this.getTaskRoute();
        break;
      }
      case NotificationTypes.taskDueDateTomorrow:
      case NotificationTypes.taskDueDateIn3Days:
      case NotificationTypes.taskDueDateIn7Days: {
        this.htmlToAdd =
          'Task for ' +
          this.changeColor(this.notification.workspaceTitle) +
          ' is due in ' +
          this.taskDueTime() +
          ': ' +
          this.changeColor(this.notification.taskTitle);
        this.notificationRoute = this.getTaskRoute();
        break;
      }
      default: {
        this.show = false;
        break;
      }
    }
  }

  taskDueTime(): string {
    switch (this.notification.type) {
      case NotificationTypes.taskDueDateTomorrow:
        return '24 hours';
      case NotificationTypes.taskDueDateIn3Days:
        return '3 days';
      case NotificationTypes.taskDueDateIn7Days:
        return '7 days';
      default:
        return '';
    }
  }

  getTaskRoute(): string {
    return (
      '/' +
      GlobalConstants.routes.taskDetails +
      String(this.notification.taskId)
    );
  }

  getEventRoute(): string {
    return (
      '/' +
      GlobalConstants.routes.workspaceEvent
        .replace(':workspacesId', String(this.notification.workspaceId))
        .replace(':id', String(this.notification.eventId))
    );
  }

  getWorkspaceRoute(): string {
    return (
      '/' +
      GlobalConstants.routes.workspace.replace(
        ':id',
        String(this.notification.workspaceId)
      )
    );
  }

  changeColor(text: string): string {
    return '<span class="accent-notification-text">' + text + '</span>';
  }

  time(): string {
    const result = moment.utc(this.notification.createTime).fromNow();
    if (
      result.includes('day') ||
      result.includes('month') ||
      result.includes('year')
    ) {
      const date = new Date(this.notification.createTime);
      return date.getMonth() + 1 + '/' + date.getDate();
    }
    return result;
  }

  readNotification(): void {
    this.notificationService
      .readNotification(this.notification.notificationId)
      .then();
    this.notification.seen = true;
  }

  deleteNotification(): void {
    this.notificationService.deleteNotification(this.notification).then();
  }

  openNotification(): void {
    this.clicked.emit();
    this.readNotification();
    this.postService.clearPost(); // AD-246

    // Fixed a bug with opening a notification on the same page.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([this.notificationRoute]).then();
    });
  }
}
