<mat-icon
  matBadge="{{
    service.countUnseen !== undefined && service.countUnseen > 99
      ? '99+'
      : service.countUnseen
  }}"
  matBadgeHidden="{{ service.countUnseen === 0 }}"
  (click)="isInteractionEnabled() && showNotifications()"
>
  notifications
</mat-icon>
