import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActiveWorkspaceService } from '../../../../../services/workspace/active-workspace.service';
import { Participant } from '../../../../../models/participant';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { FilterByFuseService } from '../../../../../services/fuzzy-search/filter-by-fuse.service';
import { CommonDialogParticipantsComponent } from '../common-dialog-participants.component';
import { WorkspaceEventsService } from '../../../../../services/events/workspace-events.service';
import { finalize, first } from 'rxjs/operators';

@Component({
  selector: 'app-dialog-add-event-participants',
  templateUrl: './dialog-add-event-participants.html',
  styleUrls: [
    '../../add-update..scss',
    '../../../../../pages/workspace/one-workspace-view/participants/participants.component.scss',
    '../common-dialog-participants.component.scss'
  ]
})
export class DialogAddEventParticipantsComponent extends CommonDialogParticipantsComponent {
  loadInProgress = false;

  selectable = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  /**
   * True means that this dialog is only step of the complex scenario
   */
  embedded: boolean;

  public isVisibleListBlock = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddEventParticipantsComponent>,
    public activeWorkspaceService: ActiveWorkspaceService,
    private filterByFuse: FilterByFuseService,
    private eventsService: WorkspaceEventsService
  ) {
    super();

    this.embedded = data.embedded;
    if (data.event) {
      this.activeWorkspaceService
        .getWorkspaceByIdIfNeeded(data.event.workspaceId)
        .then(() => {
          this.previouslyInvitedPeople =
            this.activeWorkspaceService.workspace?.participants ?? [];
          this.isVisibleListBlock = this.availableForSelection().length > 0;
        });
    }
  }

  save(): void {
    this.loadInProgress = true;

    const event = this.data.event;
    event.participants = this.participants;
    const request = this.embedded
      ? this.eventsService.createEvent(event)
      : this.eventsService.addEventParticipants(
          event,
          this.participants.map((p) => p.userId)
        );

    request
      .pipe(
        first(),
        finalize(() => (this.loadInProgress = false))
      )
      .subscribe({
        next: (newEvent) => this.dialogRef.close(newEvent)
      })
  }

  availableForSelection(): Participant[] {
    const value = this.inputNewEmail?.nativeElement.value;
    const participants = this.previouslyInvitedPeople
      .filter((p) => this.data.event.inviterId !== p.userId)
      .filter((p) => this.isNotEmptyUserId(p))
      .filter((p) => this.isNotAlreadyInInviteList(p))
      .filter((p) =>
        this.isNotAlreadyInTheList(p.email, this.data.participants)
      );

    return value
      ? this.filterByFuse.filterParticipants(participants, value)
      : participants;
  }

  selectAllParticipants(): void {
    this.participants = [...this.participants, ...this.availableForSelection()];
  }

  clearSelectedParticipants(): void {
    this.participants = [];
  }

  onParticipantSelect(participant: Participant) {
    this.participants.push(participant);
  }
}
