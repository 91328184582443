import { WorkspaceComponent } from './workspace.component';
import { Route, RouterModule } from '@angular/router';
import { OneWorkspaceViewComponent } from './one-workspace-view/one-workspace-view.component';
import { EventComponent } from '../event/event.component';

export const routes: Array<Route> = [
  // TODO: FIXME: Lazy load modules
  { path: '', component: WorkspaceComponent },
  {
    path: ':id/library/audio',
    loadChildren: () =>
      import('./one-workspace-view/archive/audio/audio.module').then(
        (m) => m.AudioModule
      )
  },
  {
    path: ':id/library/documents',
    loadChildren: () =>
      import('./one-workspace-view/archive/documents/documents.module').then(
        (m) => m.DocumentsModule
      )
  },
  {
    path: ':id/library/links',
    loadChildren: () =>
      import('./one-workspace-view/archive/links/links.module').then(
        (m) => m.LinksModule
      )
  },
  {
    path: ':id/library/media',
    loadChildren: () =>
      import('./one-workspace-view/archive/media/media.module').then(
        (m) => m.MediaModule
      )
  },
  { path: ':id', component: OneWorkspaceViewComponent },
  { path: ':id/:tab', component: OneWorkspaceViewComponent },
  { path: ':workspacesId/events/:id', component: EventComponent }
];

export const WORKSPACE_ROUTES = RouterModule.forChild(routes);
