export class Notification {
  notificationId!: number;
  createTime!: Date;
  userNameWhoTriggered!: string;
  whoTriggeredProfileImageUrl: string | undefined;
  whoTriggeredUserId!: number;
  workspaceTitle!: string;
  workspaceId!: number;
  eventId!: number;
  eventTitle!: string;
  attachmentType!: string;
  seen!: boolean;
  type: NotificationTypes | undefined;
  section: 'POST' | 'EVENT' | 'WORKSPACE' | undefined;
  taskTitle!: string;
  taskId!: number;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export enum NotificationTypes {
  // post
  like = 'LIKE',
  dislike = 'DISLIKE',
  comment = 'COMMENT',
  reply = 'REPLY',
  mention = 'MENTION',

  // event
  created = 'CREATED',
  updated = 'UPDATED',
  attending = 'ATTENDING',
  interested = 'INTERESTED',
  cancelled = 'CANCELLED',
  startsTomorrow = 'STARTS_TOMORROW',
  notGoingAttendanceAttendee = 'NOT_GOING_ATTENDANCE_ATTENDEE',
  notGoingAttendanceMentor = 'NOT_GOING_ATTENDANCE_MENTOR',
  eventMention = 'EVENT_MENTION',
  eventReportPublished = 'REPORT_PUBLISHED',
  eventParticipantAdded = 'EVENT_PARTICIPANT_ADDED',

  // question
  first = 'FIRST',
  closed = 'CLOSED',
  opened = 'OPENED',
  create = 'CREATE',
  noAnswerFirst = 'NO_ANSWER_FIRST',
  noAnswer = 'NO_ANSWER',

  // workspace
  inviteToWorkspace = 'INVITE_TO_WORKSPACE',
  pinnedComment = 'PINNED_COMMENT',
  fileAttached = 'FILE_ATTACHED',
  sampleExpiresSoon = 'SAMPLE_WORKSPACE_EXPIRE_SOON',

  // tasks
  assigneeCompletedTask = 'ASSIGNEE_COMPLETED_TASK',
  taskClosed = 'TASK_COMPLETED',
  taskDueDateIn7Days = 'DUE_DATE_7_DAYS',
  taskDueDateIn3Days = 'DUE_DATE_3_DAYS',
  taskDueDateTomorrow = 'DUE_DATE_TOMORROW'
}
