<div *ngIf="isCanEdit">
  <div fxLayout="row" fxLayoutGap="15px">
    <button
      class="attendance-status-button not-going-button"
      mat-stroked-button
      [class.disabled]="disabled"
      [class.active]="userStatus === attendance.Rejected"
      (click)="onClickNotGoing($event)"
    >
      {{ stringForStatus(attendance.Rejected) }}
    </button>

    <button
      class="attendance-status-button attending-button"
      mat-stroked-button
      [class.disabled]="disabled"
      [class.active]="userStatus === attendance.Attending"
      (click)="onClickAttending($event)"
    >
      {{ stringForStatus(attendance.Attending) }}
    </button>
  </div>
</div>
