export class DateUtils {
  public static atStartOfDate(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  public static atEndOfDate(date: Date): Date {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);
    return d;
  }

  public static atStartOfNextDay(date: Date): Date {
    const time: number = date.getTime();
    const nextDayDate = new Date(time + 24 * 60 * 60 * 1000);
    nextDayDate.setHours(0, 0, 0, 0);
    return nextDayDate;
  }

  public static equals(date1: Date, date2: Date): boolean {
    return date1.getTime() === date2.getTime();
  }
}
