import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OneWorkspaceService } from '../../../services/workspace/one-workspace.service';
import { Workspace } from '../../../models/workspace';
import { BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { PostService } from '../../../services/post/post.service';
import { UtilsService } from '../../../services/utils/utils.service';
import { Location } from '@angular/common';
import { ProfileService } from '../../../services/user/profile/profile.service';
import { GlobalConstants } from '../../../common/global-constants';
import { TaskListType } from '../../../models/task-list-type';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogBannerComponent } from './banner-dialog/dialog-banner.component';
import { AppConstantsService } from '../../../services/app-constants/app-constants.service';
import { SelectableButtonComponent } from '../../../component/selectable-button/selectable-button.component';
import { FnOnNextItem } from '../../../component/showspace-tutorial/showspace-tutorial.component';
import { Observable } from 'rxjs';
import { ShowspaceTutorialProcessor } from '../../../component/showspace-tutorial/showspace-tutorial-processor';
import { PublicEventService } from '../../../services/public-event/public-event.service';

@Component({
  selector: 'app-one-workspace-view',
  templateUrl: './one-workspace-view.component.html',
  styleUrls: ['./one-workspace-view.component.scss']
})
export class OneWorkspaceViewComponent implements OnInit, OnDestroy {
  @ViewChild('header') header?: ElementRef;
  @ViewChild('workspace') workspace?: ElementRef;
  @ViewChildren(SelectableButtonComponent, {
    read: ElementRef,
    emitDistinctChangesOnly: false
  })
  menuElems = new QueryList<ElementRef>();

  private tutorialProcessor?: ShowspaceTutorialProcessor;

  public headerWhite = false;
  headerStyle: any;
  tab = GlobalConstants.routes.workspaceMessages;
  routes = GlobalConstants.routes;
  publicEventRoutes = GlobalConstants.publicEventRoutes;
  taskViewType = TaskListType.buttons;
  loadInProgress = false;
  isBannerEditVisible = false;
  isEventPageVisible = false;
  publicEventRoute: string | undefined;

  menuItems = [
    {
      title: MenuItemsTitles.MESSAGE_BOARD,
      route: this.routes.workspaceMessages
    },
    {
      title: MenuItemsTitles.EVENTS,
      route: this.routes.workspaceEvents
    },
    {
      title: MenuItemsTitles.LIBRARY,
      route: this.routes.workspaceArchive
    },
    {
      title: MenuItemsTitles.PARTICIPANTS,
      route: this.routes.workspaceParticipants
    },
    {
      title: MenuItemsTitles.CONFLICTS,
      route: this.routes.workspaceConflicts
    },
    {
      title: MenuItemsTitles.TASKS,
      route: this.routes.workspaceTasks
    }
  ];

  private get defaultBannerUrl(): string {
    return (
      this.constants.appConstants.defaultBannerUrl ??
      'assets/workspaces/default_banner.png'
    );
  }

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public postService: PostService,
    public publicEventService: PublicEventService,
    public workspaceService: OneWorkspaceService,
    public breadcrumbService: BreadcrumbService,
    public utils: UtilsService,
    public profileService: ProfileService,
    private dialog: MatDialog,
    private constants: AppConstantsService
  ) {}

  private showTutorial(workspace: Workspace): void {
    const processor = new ShowspaceTutorialProcessor(this.dialog);
    if (!processor.seenTutorial) {
      this.tutorialProcessor = processor;
      this.tutorialProcessor?.showTutorial(
        this.menuItems.map((item) => item.title),
        this.canManage(workspace),
        this.onNextTutorialItem
      );
      processor.setTutorialAsSeen();
    }
  }

  private onNextTutorialItem: FnOnNextItem = (index: number) => {
    return new Observable((subscriber) => {
      const nextItem = this.menuElems.get(index)?.nativeElement.firstChild;
      nextItem.scrollIntoView({ block: 'nearest', inline: 'center' });
      subscriber.next(nextItem.getBoundingClientRect());
      subscriber.complete();
    });
  };

  private eventPageRoute(): string {
    const workspace = this.workspaceService.workspace;
    const workspaceId = workspace?.workspaceId ?? 0;
    if (workspace && this.canManage(workspace)) {
      return this.publicEventService.publicEventDraftRoute(workspaceId);
    }
    return this.publicEventService.publicEventPageRoute(workspaceId);
  }

  private setupBreadcrumbs(): void {
    this.breadcrumbService.setAlias('', 1);

    this.activatedRoute.params.subscribe((params) => {
      this.tab = params.tab ?? this.tab;
      if (params.id) {
        this.loadInProgress = true;
        this.workspaceService
          .getWorkspaceById(params.id)
          .then((workspace: Workspace) => {
            this.stopLoading();
            this.breadcrumbService.setAlias(workspace.title, 1);
            this.adjustMenuAccessForCurrentUser(workspace);
            this.isBannerEditVisible = this.canManage(workspace);
            this.isEventPageVisible =
              this.canManage(workspace) || workspace.hasPublicPage;
            this.publicEventRoute = this.eventPageRoute();
            setTimeout(() => {
              this.showTutorial(workspace);
            });
          }, this.stopLoading.bind(this));
      }
    });
  }

  private adjustMenuAccessForCurrentUser(workspace: Workspace): void {
    if (!this.canManage(workspace)) {
      this.menuItems = this.menuItems.filter(
        (item) => item.title !== MenuItemsTitles.CONFLICTS
      );
    }
  }

  private canManage(workspace: Workspace): boolean {
    return this.profileService.isAdminOrManager(workspace.participants);
  }

  private stopLoading(): void {
    this.loadInProgress = false;
  }

  ngOnInit(): void {
    this.setupBreadcrumbs();
    this.headerStyle = () => {
      let h = window.innerWidth < 959 ? 80 : 100;
      h = window.innerWidth < 600 ? 70 : h;
      this.headerWhite =
        this.header?.nativeElement.getBoundingClientRect().top === h;
    };

    window.addEventListener('scroll', this.headerStyle, true);
  }

  setActive(link: string): void {
    this.tab = link;
    this.location.replaceState(
      '/' +
        this.routes.workspaces +
        '/' +
        this.workspaceService.workspace?.workspaceId +
        '/' +
        link
    );
  }

  get bannerUrl(): string {
    const bannerUrl = this.workspaceService.workspace?.bannerUrl;
    return bannerUrl ?? this.defaultBannerUrl;
  }

  openBannerDialog(): void {
    this.showBannerPopup(this.workspaceService.workspace?.bannerUrl ?? null);
  }

  showBannerPopup(src: string | null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['edit-banner-popup', 'disable-user-select'];
    dialogConfig.data = {
      src,
      workspaceId: this.workspaceService.workspace?.workspaceId
    };

    const dialogRef = this.dialog.open(DialogBannerComponent, dialogConfig);

    const sub = dialogRef.componentInstance.changeBanner.subscribe(() => {
      if (this.workspaceService.workspace?.workspaceId) {
        this.workspaceService
          .getWorkspaceById(this.workspaceService.workspace.workspaceId)
          .then();
      }

      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.workspaceService.clearWorkspace();
    this.postService.clearPost();
    window.removeEventListener('scroll', this.headerStyle, true);
  }
}

export enum MenuItemsTitles {
  MESSAGE_BOARD = 'Message board',
  EVENTS = 'Events',
  LIBRARY = 'Library',
  PARTICIPANTS = 'Participants',
  CONFLICTS = 'Conflicts',
  TASKS = 'Tasks'
}
