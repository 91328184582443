import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ProfileService } from '../../services/user/profile/profile.service';
import { User } from '../../models/user';
import { GlobalConstants } from '../../common/global-constants';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() user: User | undefined;
  @Input() userIdentity: number | undefined;
  @Input() src: string | null | undefined;
  @Input() isClickable = true;
  @Input() customRoute: string | undefined;
  @Input() size:
    | 'veryBig'
    | 'big'
    | 'small'
    | 'verySmall'
    | 'default'
    | 'size56' = 'small';

  classCss: string[] = [];

  profileSettingsBase = '/' + GlobalConstants.routes.profileAndSettings + '/';

  public defaultSrc = 'assets/avatar/avatar.svg';
  profileRoute = '';
  showError = false;

  constructor(public profileService: ProfileService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setAvatarData();
  }

  ngOnInit(): void {
    this.user = this.user ? this.user : this.profileService.user;
    this.setAvatarData();

    this.classCss = [this.size];
  }

  setAvatarData(): void {
    this.showError = false;
    this.setSourceUrl();
    if (this.customRoute) {
      this.profileRoute = this.customRoute;
    } else if (this.userIdentity) {
      this.profileRoute = this.profileSettingsBase + this.userIdentity;
    }
  }

  get defaultAvatarSrc(): string {
    return User.DEFAULT_AVATAR_SRC;
  }

  private setSourceUrl(): void {
    this.src =
      this.src === undefined
        ? this.user?.photoThumbnailURL ||
          this.user?.photoURL ||
          User.DEFAULT_AVATAR_SRC
        : this.src || User.DEFAULT_AVATAR_SRC;
  }
}
