import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { CalendarEventsCount } from '../../../models/calendar.events.count';
import { DateRange } from '../models/date.range';
import { DateUtils } from '../../../common/date.utils';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewCalendarEventComponent } from '../../../component/dialog/new-calendar-event/new-calendar-event.component';
import { CalendarEventsListComponent } from '../calendar-events-list/calendar.events.list.component';
import { CalendarFilter } from '../../../component/dialog/calendar-filter/calendar-filter.component';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';

const DEFAULT_DATE = DateUtils.atStartOfDate(new Date());

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  eventsCounts: Array<CalendarEventsCount> = [];
  selectedPeriod: DateRange | null = null;
  selectedFilter: CalendarFilter = new CalendarFilter(true, true);

  selectedDate$: Observable<Date> = this.activatedRoute.queryParams.pipe(
    map((params) => {
      const selectedDate = params['date'];
      if (selectedDate) {
        return new Date(selectedDate);
      } else {
        this.navigateToDate(DEFAULT_DATE);
      }
      return DEFAULT_DATE;
    })
  );

  @ViewChild('calendarEventsList')
  calendarEventsListComponent!: CalendarEventsListComponent;

  constructor(
    private calendarService: CalendarService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  onPeriodChange(newPeriod: DateRange): void {
    this.selectedPeriod = newPeriod;
    this._loadEventsCounts();
  }

  onSelectedDateChange(newDate: Date): void {
    this.navigateToDate(newDate);
  }

  private navigateToDate(date: Date): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { date: date.toISOString() },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  onFilterChange(filter: CalendarFilter): void {
    this.selectedFilter = filter;
    this._loadEventsCounts();
  }

  onCreateEvent(selectedDate: Date): void {
    this.dialog
      .open(NewCalendarEventComponent, {
        panelClass: 'confirm-dialog-container',
        data: {
          selectedDate
        }
      })
      .afterClosed()
      .subscribe((eventObservable: EventEmitter<number>) => {
        eventObservable.subscribe((eventId: number) => {
          this.calendarEventsListComponent?.reloadData();
          if (this.selectedPeriod) {
            this._loadEventsCounts();
          }
        });
      });
  }

  onCalendarReload(): void {
    this._loadEventsCounts();
    this.calendarEventsListComponent?.reloadData();
  }

  private _loadEventsCounts(): void {
    if (this.selectedPeriod) {
      this.calendarService
        .getCalendarEventsCount(
          this.selectedPeriod.start,
          this.selectedPeriod.end,
          this.selectedFilter.getCalendarEventType()
        )
        .subscribe({
          next: (eventsCounts: Array<CalendarEventsCount>) => {
            this.eventsCounts = eventsCounts;
            this.changeDetector.detectChanges();
          },
          error: () => {
            this.eventsCounts = [];
          }
        });
    }
  }
}
