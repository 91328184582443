import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-float-button',
  templateUrl: './float-button.component.html',
  styleUrls: ['./float-button.component.scss']
})
export class FloatButtonComponent implements OnInit {
  @Input() callback: (() => void) | undefined;
  @Input() relative = false;
  @Input() imgScr = 'assets/icons/plus2.svg';

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  click(): void {
    if (this.callback) {
      this.callback();
    }
  }
}
