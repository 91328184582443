import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ShareModule } from './../../../component/share/share.module';
import { NotificationModule } from './notification/notification.module';
import { AvatarModule } from './../../../component/avatar/avatar.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
    AvatarModule,
    NotificationModule,
    ShareModule,
    MatMenuModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}
