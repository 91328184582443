import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from './text-input.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [TextInputComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  exports: [TextInputComponent]
})
export class TextInputModule {}
