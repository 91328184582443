import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { TutorialItem } from './tutorial-item';

export type FnOnNextItem = (nextItemIndex: number) => Observable<DOMRect>;

@Component({
  selector: 'app-showspace-tutorial',
  templateUrl: './showspace-tutorial.component.html',
  styleUrls: ['./showspace-tutorial.component.scss']
})
export class ShowspaceTutorialComponent implements OnInit, AfterViewInit {
  private dialogRef?: MatDialogRef<ShowspaceTutorialComponent>;
  private resizeObservable$?: Observable<Event>;
  private resizeSubscription$?: Subscription;

  readonly currentItemIndex = new BehaviorSubject<number>(0);

  tutorialItems: TutorialItem[] = [];

  // Position of a tutorial view
  currentPosition = new Position(0, 0, 0, 0);

  tailPosition = new Position(0, 0, 0, 0);

  // Rect of an element that highlight some functionality to display tutorial for
  anchorRect: DOMRect | undefined;

  @ViewChild('tutorialView', { static: false })
  tutorialView!: ElementRef;

  private fnOnNextItem: FnOnNextItem = (index) => {
    console.error('fn onNextItem is not defined');
    return new Observable<DOMRect>((subscriber) => {
      subscriber.next(undefined);
      subscriber.complete();
    });
  };

  ngAfterViewInit(): void {
    this.adjustPosition();
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.tutorialItems = data.tutorialItems;
    this.fnOnNextItem = data.fnOnNextItem;
  }

  ngOnInit(): void {
    this.currentItemIndex.subscribe((index) => {
      this.fnOnNextItem(index).subscribe((rect) => {
        this.anchorRect = rect;
        this.adjustPosition();
      });
    });

    this.dialogRef?.afterOpened().subscribe(() => {
      this.resizeObservable$ = fromEvent(window, 'resize');
      this.resizeSubscription$ = this.resizeObservable$.subscribe((evt) => {
        this.dialogRef?.close();
      });
    });

    this.dialogRef?.afterClosed().subscribe(() => {
      this.resizeSubscription$?.unsubscribe();
    });
  }

  setDialogRef(dialogRef: MatDialogRef<ShowspaceTutorialComponent>): void {
    this.dialogRef = dialogRef;
  }

  get isLastItem(): boolean {
    return this.currentItemIndex.value === this.tutorialItems.length - 1;
  }

  get isFirstItem(): boolean {
    return this.currentItemIndex.value === 0;
  }

  private adjustPosition(): void {
    if (this.anchorRect) {
      const tutorialViewWidth = this.tutorialView.nativeElement.width;
      const windowWidth = window.innerWidth;
      const anchorLeft = this.anchorRect.left;
      const anchorCenter = this.anchorRect.left + this.anchorRect.width / 2;
      const minFullScreenWidth = 450;

      const bottom =
        window.innerHeight -
        this.anchorRect.bottom +
        this.anchorRect.height +
        20;
      const right = windowWidth - this.anchorRect.right;

      // For mobile size screens
      if (windowWidth < minFullScreenWidth) {
        const offset = 15;
        this.currentPosition = new Position(offset, offset, undefined, bottom);
        this.tailPosition = new Position(
          anchorCenter - offset - 10,
          undefined,
          undefined,
          -10
        );
        return;
      }

      // If anchor start is to the right from the window center
      if (anchorCenter > windowWidth / 2) {
        this.currentPosition = new Position(0, right, undefined, bottom);
        this.tailPosition = new Position(
          undefined,
          this.anchorRect.width / 2,
          undefined,
          -10
        );
        return;
      }

      // If anchor start is to the left from the window center
      let left = anchorLeft;
      if (anchorLeft + tutorialViewWidth + 1 > windowWidth) {
        left = windowWidth - tutorialViewWidth - 1;
      }
      this.currentPosition = new Position(left, 0, undefined, bottom);
      this.tailPosition = new Position(
        this.anchorRect.width / 2,
        undefined,
        undefined,
        -10
      );
    }
  }

  onNext(): void {
    if (!this.isLastItem) {
      this.currentItemIndex.next(this.currentItemIndex.value + 1);
    } else {
      this.dialogRef?.close();
    }
  }

  onBack(): void {
    if (!this.isFirstItem) {
      this.currentItemIndex.next(this.currentItemIndex.value - 1);
    }
  }

  offset(value: number | undefined, debug: string = ''): string {
    if (value) {
      return value + 'px';
    }
    return 'auto';
  }
}

export class Position {
  constructor(
    public left: number | undefined,
    public right: number | undefined,
    public top: number | undefined,
    public bottom: number | undefined
  ) {}
}
