import { Injectable } from '@angular/core';
import { LSKeys } from './storage-keys';
import * as debug from 'debug';

const trace = debug('apg:storage-service');

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  setItem<T>(key: LSKeys, value: T | undefined | null): void {
    if (value === undefined || value === null) {
      trace(
        `Error settings item ${key} in localStorage`,
        'Value is Null or Undefined'
      );
      return;
    }

    try {
      if (typeof value === 'boolean') {
        localStorage.setItem(key, value.toString());
      } else {
        const jsonValue = JSON.stringify(value);
        localStorage.setItem(key, jsonValue);
      }

      trace(`Saved ${key} to localStorage`, value);
    } catch (error) {
      trace(`Error setting item ${key} in localStorage`, error);
    }
  }

  getItem<T>(key: LSKeys): T | null {
    try {
      const storedValue = localStorage.getItem(key);

      if (storedValue === null) {
        return null;
      }

      // Handle booleans
      if (storedValue === 'true') {
        return true as T;
      } else if (storedValue === 'false') {
        return false as T;
      }

      return JSON.parse(storedValue) as T;
    } catch (error) {
      trace(`Error getting item ${key} from localStorage`, error);
      return null;
    }
  }

  removeItem(key: LSKeys): void {
    try {
      localStorage.removeItem(key);
      trace(`Removed ${key} from localStorage`);
    } catch (error) {
      trace(`Error removing item ${key} from localStorage`, error);
    }
  }

  clear(): void {
    try {
      localStorage.clear();
      trace('Cleared all items from localStorage');
    } catch (error) {
      trace('Error clearing localStorage', error);
    }
  }

  exists(key: LSKeys): boolean {
    return localStorage.getItem(key) !== null;
  }
}
