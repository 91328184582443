<div
  [fxFlex]="size.includes('small') ? '38px' : '46px'"
  style="margin-right: 8px"
>
  <app-avatar
    [user]="user"
    [src]="post?.profileImageThumbnail || post?.profileImage"
    [userIdentity]="post?.userId"
    [size]="size"
  ></app-avatar>
</div>
<div fxFlex="auto">
  <ng-container *ngIf="user">
    <div class="name" [class]="classCss">
      {{ user.name }} {{ user.surname }}
    </div>
  </ng-container>
  <ng-container *ngIf="post">
    <div class="name" [class]="classCss">
      {{ post.name }} {{ post.surname }}
    </div>
  </ng-container>

  <div class="date" [class]="classCss">{{ date ? date : 'DATE' }}</div>
</div>
