import { Component, Input } from '@angular/core';
import { Post } from '../../models/post';
import { PostService } from '../../services/post/post.service';
import { ProfileService } from '../../services/user/profile/profile.service';

@Component({
  selector: 'app-reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.scss']
})
export class ReactionComponent {
  @Input() post: Post | undefined;
  cLike: boolean | undefined;
  cDislike: boolean | undefined;
  constructor(
    public postService: PostService,
    public profileService: ProfileService
  ) {}

  clickLike(): void {
    if (this.post && this.profileService?.user) {
      this.cLike = true;
      this.cDislike = false;
      this.postService.like(this.post, this.profileService.user);
    }
  }
  clickDislike(): void {
    if (this.post && this.profileService?.user) {
      this.cDislike = true;
      this.cLike = false;
      this.postService.dislike(this.post, this.profileService.user);
    }
  }

  removeReaction(): void {
    if (this.post && this.profileService?.user) {
      this.cLike = false;
      this.cDislike = false;
      this.cDislike = false;
      this.postService.removeReaction(this.post);
    }
  }
}
