import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {
  constructor() {}

  clearInput(value: any): any {
    return value ? value.trim() : value;
  }
}

export function notEquals(valueInput: string | undefined): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (valueInput === undefined) {
      return { equals: { value: control.value } };
    }

    return valueInput === control.value
      ? { equals: { value: control.value } }
      : null;
  };
}

export function equals(valueInput: string | undefined): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (valueInput === undefined) {
      return { notEquals: { value: control.value } };
    }

    return valueInput !== control.value
      ? { notEquals: { value: control.value } }
      : null;
  };
}

export function greaterThan(field: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.parent?.get(field)?.value > control.value
      ? { lessThenStart: { value: control.value } }
      : null;
  };
}

export function noWhitespaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const isWhitespace = (control.value || '').trim().length === 0;
    if (control.value === undefined) {
      return null;
    }
    return isWhitespace ? { whitespace: true } : null;
  };
}

export function atLeastOneFieldFilled(): ValidatorFn {
  return (formGroup) => {
    const controls = (formGroup as FormGroup).controls;
    if (controls) {
      const filledField = Object.keys(controls).find((key) => {
        if (controls[key].value) {
          return controls[key].value.trim() !== '';
        } else {
          return null;
        }
      });
      if (!filledField) {
        return {
          atLeastOneRequired: {
            text: 'At least one field should be filled'
          }
        };
      }
    }
    return null;
  };
}
