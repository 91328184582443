<ng-container *ngIf="profileService.user">
  <div [class]="stickyClick ? 'sticky' : ''">
    <div fxFlex="38px" style="margin-right: 9px" *ngIf="avatar && avatarShow">
      <app-avatar
        [user]="profileService.user"
        [userIdentity]="profileService.user.userId"
        [size]="'small'"
      ></app-avatar>
    </div>

    <div
      [fxLayout]="stickyClick ? 'row' : 'column'"
      [fxFlex]="stickyClick ? '100%' : 'auto'"
      [fxLayoutAlign]="stickyClick ? 'space-around center' : 'start stretch'"
    >
      <div
        [fxFlex]="stickyClick ? '628px' : 'auto'"
        [fxFlex.xs]="stickyClick ? '100%' : 'auto'"
      >
        <div [fxFlex]="'auto'" [fxFlex.xs]="stickyClick ? '100%' : 'auto'">
          <span class="badge-wrapper" [class]="white ? 'white' : ''">
            <span
              contenteditable="true"
              #spanInput
              class="badge alert-info"
              appClearFormatOnPaste
              appMentionable
              [fnSearchParticipant]="fnSearchParticipant"
              [drawPosition]="MentionablePosition.ABOVE"
              [class]="getClasses()"
              (click)="focusin($event)"
              (input)="focusin($event); inputText()"
              (focusout)="focusOut()"
              [innerHTML]="defaultValue"
            ></span>
          </span>
        </div>

        <ng-container *ngIf="spanInput.innerText.trim()">
          <div
            [fxFlex]="'50px'"
            fxLayout="row"
            fxFlex
            dir="rtl"
            fxLayoutAlign=" center"
            class="description"
          >
            <img
              [src]="
                sendError
                  ? 'assets/send/send-disable.svg'
                  : 'assets/send/send.svg'
              "
              (click)="sentSpan($event)"
              (touchend)="sentSpan($event)"
              alt="send"
            />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
