import { Component } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent {
  constructor(
    private clipboard: Clipboard,
    private snackBar: SnackbarService
  ) {}

  copyURL(): void {
    if (this.clipboard.copy(window.location.href)) {
      this.snackBar.open('URL copied');
    }
  }
}
