import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventBusNames, EventBusService } from '../event-bus/event-bus.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../common/global-constants';
import { SharedService } from '../shared/shared.service';
import { lastValueFrom } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { LSKeys } from '../storage/storage-keys';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // https://staging.actorspocketguide.com/authenticate?username=<your_email>&password=<your_password>
  // mmm@buffergroup.com -123456
  private urlToSigning: string = environment.url + 'auth';
  private urlToSendLink: string = environment.url + 'auth/code/random';
  private urlToResetPassword: string = environment.url + 'auth/reset';
  private urlToLogOut: string = environment.url + 'auth/logout';

  private token = '';
  // private code = '';
  private error = '';
  private defaultReturnUrl = GlobalConstants.routes.workspaces;
  private returnUrl = this.defaultReturnUrl;

  constructor(
    private httpClient: HttpClient,
    private eventBusService: EventBusService,
    private router: Router,
    private sharedService: SharedService,
    private storage: StorageService
  ) {
    this.initialAuth();
    this.eventBusService.on(EventBusNames.logout, this.logout.bind(this));
  }

  getReturnUrl(): string {
    return this.returnUrl;
  }

  resetReturnUrl(): string {
    return this.setReturnUrl(this.defaultReturnUrl);
  }

  setReturnUrl(returnUrl?: string): string {
    return (this.returnUrl = returnUrl || this.returnUrl);
  }

  initialAuth(): void {
    const token = this.storage.getItem<string>(LSKeys.AUTH_TOKEN);
    this.setToken(token ? token : '');
  }

  getToken(): string {
    return this.token;
  }

  resetError(): void {
    this.setError('');
  }

  private setToken(token: string): string {
    this.token = token;
    this.storage.setItem(LSKeys.AUTH_TOKEN, token);
    return this.token;
  }

  getError(): string {
    return this.error;
  }

  private setError(error: string): string {
    return (this.error = error);
  }

  checkAuth(): boolean {
    return !!this.getToken();
  }

  logout(): void {
    this.logoutRequest()
      .then(
        () => {
          this.setToken('');
        },
        () => {
          this.setToken('');
        }
      )
      .then(() => {
        window.location.reload();
      });
  }

  logoutRequest(): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(this.urlToLogOut, { all: false })
    );
  }

  signing(username: string, password: string): Promise<any> {
    const success = (value: any) => {
      this.setToken(value.token ? value.token : '');
      this.resetError();

      this.goToHomePage().then(() => {
        this.sharedService.stopLoading();
        this.resetReturnUrl();
      });
    };

    const error = (value: HttpErrorResponse) => {
      this.setError(value.error.message);
    };

    return lastValueFrom(
      this.httpClient.post(this.urlToSigning, { username, password })
    ).then(success, error);
  }

  goToHomePage(): Promise<any> {
    return this.router
      .navigate([this.getReturnUrl()], { replaceUrl: true })
      .then();
  }

  sendLink(email: string): Promise<any> {
    const success = () => this.setError('');
    const error = (value: HttpErrorResponse) => {
      this.setError(value.error ? value.error.message : value.message);
    };
    // const params = {
    //     email: email,
    //     appType: 'WEB'
    // };
    return lastValueFrom(
      this.httpClient.post(this.urlToSendLink, { email })
    ).then(success, error);
  }

  /**
   * @description Reset password
   * @param newPassword - new password required
   * @param email - mail required
   * @param oldPassword - old password not required
   * @param code - code
   */

  resetPassword(
    newPassword: string,
    email: string,
    oldPassword?: string,
    code?: string
  ): Promise<any> {
    const success = () => this.setError('');
    const error = (value: HttpErrorResponse) =>
      this.setError(value.error.message);
    return lastValueFrom(
      this.httpClient.post(this.urlToResetPassword, {
        newPassword,
        email,
        oldPassword,
        code
      })
    ).then(success.bind(this), error.bind(this));
  }

  resetPasswordForAnotherUser(
    newPassword: string,
    userId: number | undefined
  ): Promise<any> {
    const success = () => this.setError('');
    const error = (value: HttpErrorResponse) =>
      this.setError(value.error.message);
    return lastValueFrom(
      this.httpClient.put(environment.url + 'users/' + userId, { newPassword })
    ).then(success.bind(this), error.bind(this));
  }
}
