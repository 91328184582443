<ng-container *ngIf="show">
  <div
    [class]="
      this.notification.seen
        ? 'notification-block seen'
        : 'notification-block unseen'
    "
    (click)="openNotification()"
    (mouseover)="isMouseHere = true"
    (mouseout)="isMouseHere = false"
  >
    <div
      class="notification-options"
      [hidden]="!isMouseHere && !isTabletOrMobile"
    >
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="menu">
        <ng-container *ngIf="!notification.seen">
          <button mat-menu-item (click)="readNotification()">
            <span>Mark as read</span>
          </button>
        </ng-container>
        <button class="danger" mat-menu-item (click)="deleteNotification()">
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>
    <app-avatar class="notification-img" [src]="this.src"></app-avatar>
    <div class="notification-info">
      <div class="notification-title">{{ this.title }}</div>
      <div class="notification-date">
        {{ this.time() }}
      </div>
      <div class="notification-content" [innerHtml]="htmlToAdd"></div>
    </div>
  </div>
</ng-container>
