import { finalize, first } from 'rxjs/operators';
import { Component, Input, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskModel } from 'src/app/models/task.model';
import {
  TaskLoadStatus,
  TaskService
} from 'src/app/services/task/task.service';
import { Workspace } from 'src/app/models/workspace';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ProfileService } from '../../../services/user/profile/profile.service';
import { Observable } from 'rxjs';
import { TaskCollection } from '../../../models/TaskCollection';
import { TaskListType } from '../../../models/task-list-type';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  @Input()
  @Optional()
  workspace: Workspace | undefined;

  @Input() type: TaskListType = TaskListType.tabs;

  private currentPage = 0;
  private limit = 20;
  private defaultStatus: TaskLoadStatus = 'open';
  loadInProgress = false;
  currentTabStatus: TaskLoadStatus = 'open';

  openedTasks: TaskCollection;
  closedTasks: TaskCollection;

  allTypes = TaskListType;

  constructor(
    private readonly dialog: MatDialog,
    private readonly taskService: TaskService,
    public profile: ProfileService
  ) {
    this.openedTasks = new TaskCollection('open');
    this.closedTasks = new TaskCollection('closed');
  }

  ngOnInit(): void {
    this.loadTasks(true, this.defaultStatus);
  }

  private loadTasks(cleanUp: boolean = true, status: TaskLoadStatus): void {
    const collection = status === 'open' ? this.openedTasks : this.closedTasks;
    collection.cleanUp(cleanUp);

    if (collection.hasNext) {
      this.loadTasksByStatus(collection, cleanUp);
    }
  }

  private loadTasksByStatus(
    collection: TaskCollection,
    cleanUp: boolean
  ): void {
    this.loadInProgress = true;
    this.getTasksByStatus(collection.status)
      .pipe(finalize(() => (this.loadInProgress = false)))
      .subscribe((PageableTaskList) => {
        collection.hasNext = PageableTaskList
          ? PageableTaskList.hasNext
          : false;

        if (cleanUp) {
          collection.tasks = [];
        }

        if (PageableTaskList?.tasks) {
          collection.tasks.push(...PageableTaskList.tasks);
        }
      });
  }

  private getTasksByStatus(status: TaskLoadStatus): Observable<any> {
    return this.taskService
      .getAllTasks(
        this.currentPage,
        this.limit,
        status,
        this.workspace?.workspaceId
      )
      .pipe(first());
  }

  get shouldDisplayAddButton(): boolean {
    if (this.workspace) {
      return this.profile.isAdminOrManager(this.workspace.participants ?? []);
    }
    return true;
  }

  taskTrackByFn(index: number, task: TaskModel): number | undefined {
    return task.id;
  }

  async openCreateTaskDialog(): Promise<void> {
    const { TaskCreateModule } = await import(
      '../task-create/task-create-module'
    );

    const dialogRef = this.dialog.open(TaskCreateModule.getComponent(), {
      data: {
        workspaceId: this.workspace?.workspaceId,
        showspace: this.workspace?.title
      } as Partial<TaskModel>,
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'task-dialog'
    });

    dialogRef.afterClosed().subscribe((isShouldBeReload) => {
      if (isShouldBeReload) {
        this.onLoadTasks(this.defaultStatus);
      }
    });
  }

  onTaskStatusChanged(task: TaskModel): void {
    const array = task.isOpen ? this.closedTasks.tasks : this.openedTasks.tasks;
    const index = array.findIndex((element) => element.id === task.id);
    array.splice(index, 1);
  }

  onLoadTasks(status: TaskLoadStatus): void {
    this.currentTabStatus = status;
    this.currentPage = 0;
    this.loadTasks(true, status);
  }

  onScrollDown(status: TaskLoadStatus): void {
    this.currentPage++;
    this.loadTasks(false, status);
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currentTabStatus = event.index === 0 ? 'open' : 'closed';
    this.onLoadTasks(this.currentTabStatus);
  }
}
