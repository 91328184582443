<div class="spinner-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="spinner">
    <ngx-spinner
      [showSpinner]="false"
      [bdColor]="'#FFFFFF'"
      [color]="'#6A6AD4'"
      [name]="'loader'"
      [size]="'medium'"
      type="ball-scale-multiple"
      [fullScreen]="false"
    >
    </ngx-spinner>
  </div>
</div>
