export class WorkspaceInfo {
  workspaceId: number;
  creatorId: number;
  title: string;
  dateStart: Date;
  dateEnd: Date;

  /**
   * Indicates that the current user is manager for that workspace
   */
  canManage: boolean;

  constructor(
    workspaceId: number,
    creatorId: number,
    title: string,
    dateStart: Date,
    dateEnd: Date,
    canManage: boolean
  ) {
    this.workspaceId = workspaceId;
    this.creatorId = creatorId;
    this.title = title;
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.canManage = canManage;
  }

  static fromJson(json: any): WorkspaceInfo {
    return new WorkspaceInfo(
      json.workspaceId,
      json.creatorId,
      json.title,
      new Date(json.dateStart),
      new Date(json.dateEnd),
      json.canManage
    );
  }
}
