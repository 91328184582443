import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { noWhitespaceValidator } from '../../../services/form-validators/form-validator.service';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  title: string;
  message: string;
  confirmButtonTitle: string;
  form: FormGroup = new FormGroup({});
  inputText = '';

  maxLength = 512;
  required = true;

  get maxLengthError(): string {
    return `Max length is ${this.maxLength} symbols`;
  }

  requiredError = 'Field is required';

  constructor(
    public dialogRef: MatDialogRef<TextInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TextInputDialogModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.confirmButtonTitle = data.confirmButtonTitle;
    this.maxLength = data.maxLength;
    this.required = data.required;

    const validators = [
      Validators.maxLength(data.maxLength),
      noWhitespaceValidator()
    ];

    if (this.required) {
      validators.push(Validators.required);
    }

    this.form = new FormGroup({
      description: new FormControl(this.inputText, validators)
    });

    this.form.controls.description.setValue(data.reason);
  }

  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogRef.close({
      description: this.form?.value.description,
      required: this.required
    });
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  public get description(): AbstractControl | null {
    return this.form?.get('description');
  }

  shouldDisplayErrorBlock(input: AbstractControl | null): boolean {
    if (input) {
      return input.invalid && (input.dirty || input.touched);
    }
    return false;
  }
}

export class TextInputDialogModel {
  constructor(
    public title: string,
    public message: string,
    public reason: string,
    public required: boolean = true,
    public confirmButtonTitle: string = 'Send',
    public maxLength: number = 512
  ) {}
}
