import { TaskPriority } from './taskPriority';
import { User } from './user';
import { WorkspaceInfo } from './workspace.info';
import { TaskAssignee } from './task.assignee';
import { TaskProgress } from './task.progress';
import { Priority, TaskModel } from './task.model';

export class Task {
  id: number;
  title: string;
  description: string;
  dueDate: string | undefined;
  priority: TaskPriority;
  opened: boolean;
  statusChangedDate: Date | null;
  workspace: WorkspaceInfo;
  assignees: Array<TaskAssignee>;
  creator: User;
  createTime: Date;
  updateTime: Date | null;
  canManage: boolean;

  constructor(
    id: number,
    title: string,
    description: string,
    dueDate: string | undefined,
    priority: TaskPriority,
    opened: boolean,
    statusChangedDate: Date | null,
    workspace: WorkspaceInfo,
    assignees: Array<TaskAssignee>,
    creator: User,
    createTime: Date,
    updateTime: Date | null,
    canManage: boolean
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.dueDate = dueDate;
    this.priority = priority;
    this.opened = opened;
    this.statusChangedDate = statusChangedDate;
    this.workspace = workspace;
    this.assignees = assignees;
    this.creator = creator;
    this.createTime = createTime;
    this.updateTime = updateTime;
    this.canManage = canManage;
  }

  static fromJson(json: any): Task {
    const dueDate = json.dueDate;
    const statusChangedDate = json.statusChangedDate
      ? new Date(json.statusChangedDate)
      : null;
    const updateTime = json.updateTime ? new Date(json.updateTime) : null;

    return new Task(
      json.id,
      json.title,
      json.description,
      dueDate,
      TaskPriority[json.priority as keyof typeof TaskPriority],
      json.opened,
      statusChangedDate,
      WorkspaceInfo.fromJson(json.workspace),
      json.assignees,
      new User().deserialize(json.creator),
      new Date(json.createTime),
      updateTime,
      json.workspace.canManage
    );
  }

  toModel(): TaskModel {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      dueDate: this.dueDate,
      priority: this.getTaskPriorityAsPriority(this.priority),
      showspace: this.workspace.title,
      assignees: this.assignees,
      isOpen: this.opened,
      canManage: this.canManage
    };
  }

  getTaskPriorityAsPriority(priority: TaskPriority): Priority {
    switch (priority) {
      case TaskPriority.LOW:
        return Priority.LOW;
      case TaskPriority.MEDIUM:
        return Priority.MEDIUM;
      case TaskPriority.HIGH:
        return Priority.HIGH;
    }
    return Priority.NONE;
  }

  get taskProgress(): TaskProgress | null {
    if (!this.assignees || this.assignees.length === 0) {
      return null;
    }

    const completedCount: number = this.assignees.filter(
      (assignee) => assignee.completed
    ).length;
    return new TaskProgress(completedCount, this.assignees.length);
  }
}
