import { Component, Input } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb/breadcrumb.service';
import { ProfileService } from '../../../services/user/profile/profile.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../common/global-constants';
import { Location } from '@angular/common';
import { EventBusService } from '../../../services/event-bus/event-bus.service';
import { TwilioService } from '../../../services/user/twilio/twilio.service';
import { UserReportingService } from '../../../services/user/user-reporting/user-reporting.service';
import { UiHelperService } from '../../../services/utils/ui-helper.service';
import { UserService } from '../../../services/user/user/user.service';
import {
  SnackbarService,
  SnackbarType
} from '../../../services/snackbar/snackbar.service';

const pages = [
  '/' + GlobalConstants.routes.workspaces,
  '/' + GlobalConstants.routes.chatsHome,
  '/' + GlobalConstants.routes.tasksHome,
  '/' + GlobalConstants.routes.calendar,
  '/' + GlobalConstants.routes.faq
];

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() drawer: any;
  @Input() opacity = false;
  @Input() breadcrumbEnabled = true;

  userProfileRoute = GlobalConstants.routes.profile;
  private isNeedBurger = false;

  constructor(
    public breadcrumbService: BreadcrumbService,
    public profileService: ProfileService,
    public router: Router,
    private location: Location,
    public eventBusService: EventBusService,
    private twilioService: TwilioService,
    private snackBar: SnackbarService,
    private userService: UserService,
    private reportingService: UserReportingService,
    private uiHelperService: UiHelperService
  ) {
    this.updateIsNeedBurger();

    router.events.subscribe(() => {
      this.updateIsNeedBurger();
    });
  }

  updateIsNeedBurger(): void {
    this.isNeedBurger =
      pages.includes(this.router.url) ||
      this.router.url.startsWith('/' + GlobalConstants.routes.faq) ||
      (this.router.url.startsWith('/' + GlobalConstants.routes.chatsHome) &&
        window.innerWidth > 590 &&
        !this.router.url.endsWith('/settings'));
  }

  getChatUrl(): string {
    const channel = this.twilioService.getCurrentChannel();
    if (channel) {
      return this.twilioService.isPrivateChat(channel)
        ? channel.participants[0]?.photoThumbnailUrl ||
            channel.participants[0]?.photoUrl ||
            ''
        : (channel.coverUrl ?? '');
    }
    return '';
  }

  get shouldShowBackButton(): boolean {
    return this.breadcrumbService.breadcrumbs.every((b) =>
      b.every((bc) => bc.alias === '')
    );
  }

  getLongBreadcrumb(): boolean {
    return window.innerWidth > 960;
    // return window.innerWidth < 960
    //     && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  }

  back(): void {
    if (history.length) {
      this.location.back();
    } else {
      const arr = this.router.url.split('/');
      const countRemove = this.isNumeric(arr[arr.length - 1]) ? 2 : 1;
      arr.splice(-countRemove, countRemove);
      this.router.navigate([arr.join('/')]).then(() => {});
    }
  }

  isNumeric(str: string | number): boolean {
    if (typeof str !== 'string') {
      return false;
    } // we only process strings!
    return (
      !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  isBreadcrumbEnabled(): boolean {
    return this.breadcrumbEnabled;
  }

  get burger(): boolean {
    return this.isNeedBurger;
  }

  get canBlock(): boolean {
    return this.profileService.user?.userId !== this.userId;
  }

  get userId(): number {
    return Number(this.breadcrumbService.getRealNameI(1));
  }

  onMenu(): void {
    this.drawer.toggle();
  }

  isChatMode(): boolean {
    const first = this.breadcrumbService.getRealNameI(0);
    const second = this.breadcrumbService.getRealNameI(1);
    return first.trim() === GlobalConstants.routes.chatsHome && second !== '';
  }

  settingsRoute(): string {
    return (
      this.breadcrumbService.getRouteI(1) + GlobalConstants.routes.chatsSettings
    );
  }

  onShare(): void {
    this.userService.shareLink(this.userId);
  }

  onReport(): void {
    this.uiHelperService
      .showFreeFormDialog('Report user', 'Please specify report reason')
      .then((result) => {
        if (result) {
          this.reportingService
            .reportUser(this.userId, result.description)
            .subscribe({
              next: () => this.snackBar.open('User has been reported.'),
              error: () =>
                this.snackBar.open(
                  'Failed to send complaint! Please try again.',
                  SnackbarType.Error
                )
            });
        }
      });
  }

  onBlock(): void {
    const userId = Number(this.breadcrumbService.getRealNameI(1));
    this.reportingService.blockUserAndShowAlert(userId);
  }
}
