import { CalendarEventType } from './calendar.event.type';

export class CalendarEventsCount {
  date: Date;
  type: CalendarEventType;
  count: number;

  constructor(date: Date, type: CalendarEventType, count: number) {
    this.date = date;
    this.type = type;
    this.count = count;
  }

  static fromJson(json: any): CalendarEventsCount {
    const date = new Date(
      new Date(json.date).getTime() + new Date().getTimezoneOffset() * 60000
    );
    return new CalendarEventsCount(
      date,
      CalendarEventType[json.type as keyof typeof CalendarEventType],
      json.count
    );
  }

  static fromJsonArray(jsonArray: any): Array<CalendarEventsCount> {
    const result: Array<CalendarEventsCount> = [];
    for (const record of jsonArray) {
      const entity = CalendarEventsCount.fromJson(record);
      result.push(entity);
    }
    return result;
  }
}
