<div
  [routerLink]="[profileRoute]"
  [class.not-clickable]="!isClickable"
  class="avatar"
  [class]="classCss"
>
  <img
    *ngIf="!showError"
    mat-card-avatar
    [src]="src"
    (error)="showError = true"
    alt="User Avatar"
  />
  <img
    *ngIf="showError"
    mat-card-avatar
    [src]="defaultAvatarSrc"
    alt="User Avatar"
  />

  <div class="overlay">
    <ng-content></ng-content>
  </div>
</div>
