import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  constructor(private platform: Platform) {}

  get isNativeMobile(): boolean {
    return this.platform.is('cordova') || this.platform.is('capacitor');
  }

  ready(): Promise<string> {
    return this.platform.ready();
  }
}
