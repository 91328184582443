import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Announcement,
  AnnouncementList,
  AnnouncementType
} from 'src/app/models/announcement';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { LSKeys } from '../storage/storage-keys';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private storage: StorageService
  ) {}

  public checkForAnnouncement(): void {
    this.fetchAnnouncement().subscribe((announcements) => {
      this.showNewAnnouncements(announcements);
    });
  }

  private showNewAnnouncements(announcements: Announcement[]): void {
    const notSeen = announcements.filter(
      (item) => !this.seenAnnouncementsIds.includes(item.id)
    );

    const messages: Message[] = notSeen.map((item) => {
      return {
        key: 'main',
        severity: announcementSeverity[item.type],
        data: { icon: announcementIcon[item.type] },
        summary: item.title,
        detail: item.description,
        id: item.id,
        sticky: true,
        styleClass: 'announcement-toast'
      };
    });
    this.messageService.addAll(messages);
  }

  public onMessageClose(message: Message): void {
    if (message.id) {
      this.addSeenAnnouncement(message.id);
    }
  }

  private fetchAnnouncement(): Observable<Announcement[]> {
    return this.http
      .get<AnnouncementList>(environment.url + 'announcements')
      .pipe(map((response) => response.content));
  }

  private addSeenAnnouncement(id: number): void {
    const storedItems = this.seenAnnouncementsIds;
    if (!storedItems.includes(id)) {
      storedItems.push(id);
      this.storage.setItem(LSKeys.ANNOUNCEMENTS_SEEN, storedItems);
    }
  }

  get seenAnnouncementsIds(): number[] {
    return (
      this.storage.getItem<number[]>(LSKeys.ANNOUNCEMENTS_SEEN) ?? []
    );
  }
}

const announcementIcon: Record<AnnouncementType, string> = {
  [AnnouncementType.MAINTENANCE]: 'warning',
  [AnnouncementType.OTHER]: 'info'
};

const announcementSeverity: Record<AnnouncementType, string> = {
  [AnnouncementType.MAINTENANCE]: 'warn',
  [AnnouncementType.OTHER]: 'info'
};
