import { FileUploadModule as PrimeFileUploadModule } from 'primeng/fileupload';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    FlexLayoutModule,
    MatIconModule,
    MatDialogModule,
    PrimeFileUploadModule
  ],
  exports: [FileUploadComponent]
})
export class FileUploadModule {}
