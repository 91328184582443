export class Role {
  role: 'ROLE_ADMIN' | 'ROLE_MENTOR' | 'ROLE_USER' = 'ROLE_USER';

  constructor(role: 'ROLE_ADMIN' | 'ROLE_MENTOR' | 'ROLE_USER' = 'ROLE_USER') {
    this.role = role;
  }

  get toTitle(): string {
    switch (this.role) {
      case 'ROLE_ADMIN':
        return 'Admin';
      case 'ROLE_USER':
        return 'User';
      case 'ROLE_MENTOR':
        return 'Mentor';
    }
  }
}
