<ng-container *ngIf="post">
  <div fxLayout="row" dir="ltr">
    <div
      [ngSwitch]="postService.isIncludesLike(post, cLike)"
      fxFlex="44px"
      fxFlex.gt-xs="42px"
      class="button"
    >
      <img
        *ngSwitchCase="false"
        src="assets/reaction/like.svg"
        alt="like"
        (click)="clickLike()"
      />
      <img
        *ngSwitchCase="true"
        src="assets/reaction/like-active.svg"
        alt="like-active"
        (click)="removeReaction()"
      />
      <span class="count">{{ post.likeCounter || 0 }}</span>
    </div>
    <div
      [ngSwitch]="postService.isIncludesDislike(post, cDislike)"
      fxFlex="44px"
      fxFlex.gt-xs="42px"
      class="button"
    >
      <img
        *ngSwitchCase="false"
        src="assets/reaction/dislike.svg"
        alt="dislike"
        (click)="clickDislike()"
      />
      <img
        *ngSwitchCase="true"
        src="assets/reaction/dislike-active.svg"
        alt="dislike-active"
        (click)="removeReaction()"
      />
      <span class="count">{{ post.dislikeCounter || 0 }}</span>
    </div>
  </div>
</ng-container>
