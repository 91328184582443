import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StringUtils } from 'src/app/common/string-utils';
import { MentionableDirective } from 'src/app/directive/mentionable.directive';
import { FnMentionableSearchParticipant } from 'src/app/directive/mentionable.fn.search.participant';
import { MentionablePosition } from 'src/app/directive/mentionable.position';

@Component({
  selector: 'app-dialog-input-write',
  templateUrl: 'dialog-input-write.html',
  styleUrls: ['./dialog-input-write.scss']
})
export class DialogInputWriteComponent {
  MentionablePosition = MentionablePosition;
  form: FormGroup | undefined;
  fnSearchParticipant?: FnMentionableSearchParticipant;

  @ViewChild(MentionableDirective) mentionableDirective?: MentionableDirective;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogInputWriteComponent>
  ) {
    this.form = new FormGroup({
      description: new FormControl(data.defaultValue, [
        Validators.required,
        Validators.maxLength(1500)
      ])
    });

    this.fnSearchParticipant = data.fnSearchParticipant;
  }

  send(): void {
    if (this.form?.value.description) {
      const description = StringUtils.removeHtmlTags(
        this.form.value.description.trim()
      );
      const mentionedParticipantsIds: Array<number> =
        this.mentionableDirective?.mentionedParticipantsIds || [];
      this.dialogRef.close({
        description,
        mentionedParticipantsIds
      });
    }
  }
}
