import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Workspace } from '../../models/workspace';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActiveWorkspaceService {
  public workspace: Workspace | undefined;
  /**
   * GET /workspaces/{id}
   */
  private urlWorkspacesById: string = environment.url + 'workspaces/';

  constructor(private http: HttpClient) {}

  clearWorkspace(): void {
    this.workspace = undefined;
  }

  async getWorkspaceById(workspaceId: number): Promise<Workspace> {
    this.clearWorkspace();
    const workspace = await lastValueFrom(
      this.http.get(this.urlWorkspacesById + workspaceId)
    );
    return this.next(workspace);
  }

  async getWorkspaceByIdIfNeeded(
    workspaceId: number
  ): Promise<Workspace | null> {
    if (
      !this.workspace?.workspaceId ||
      this.workspace.workspaceId !== workspaceId
    ) {
      return this.getWorkspaceById(workspaceId);
    }
    return null;
  }

  next(workspace: object): Workspace {
    return (this.workspace = new Workspace().deserialize(workspace));
  }
}
