import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import OneSignal from 'onesignal-cordova-plugin';
import { DeviceSettingsCreateDto } from 'src/app/models/device_settings';
import { environment } from 'src/environments/environment';
import { PlatformService } from '../platform/platform.service';
import { lastValueFrom } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { LSKeys } from '../storage/storage-keys';
import * as debug from 'debug';

const trace = debug('apg:push-notifications-service');

const ONE_SIGNAL_APP_ID = 'cc18263e-76af-44f6-b878-47d2bbf093f1';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  private urlUpdateDeviceSettings: string = environment.url + '/devicesettings';

  constructor(
    private platform: PlatformService,
    private http: HttpClient,
    private storage: StorageService
  ) {}

  initialize(): void {
    if (!this.platform.isNativeMobile) return;

    OneSignal.Debug.setLogLevel(6);

    OneSignal.initialize(ONE_SIGNAL_APP_ID);
    OneSignal.Notifications.addEventListener('click', async (e) => {});
    OneSignal.Notifications.requestPermission(true).then((success: Boolean) => {
      trace('Notification permission granted ' + success);
    });
  }

  async subscribe(): Promise<void> {
    if (!this.platform.isNativeMobile) return;

    const subId = await this.getSubscriptionId();

    const deviceSettings: DeviceSettingsCreateDto = {
      hwid: this.storage.getItem(LSKeys.HWID) ?? '',
      pushNotified: true,
      oneSignalPlayerId: subId ?? undefined
    };

    try {
      await this.updateUserDeviceSettings(deviceSettings);
      OneSignal.User.pushSubscription.optIn();
      this.storage.setItem(LSKeys.PUSH_NOTIFICATIONS_ENABLED, true);
    } catch (error) {
      trace('Error subscribing from notifications:', error);
    }
  }

  async unsubscribe(): Promise<void> {
    if (!this.platform.isNativeMobile) return;

    const deviceSettings: DeviceSettingsCreateDto = {
      hwid: this.storage.getItem(LSKeys.HWID) ?? '',
      pushNotified: false,
      oneSignalPlayerId: ''
    };

    try {
      await this.updateUserDeviceSettings(deviceSettings);
      OneSignal.User.pushSubscription.optOut();
      this.storage.setItem(LSKeys.PUSH_NOTIFICATIONS_ENABLED, false);
    } catch (error) {
      trace('Error unsubscribing from notifications:', error);
    }
  }

  private async updateUserDeviceSettings(
    settings: DeviceSettingsCreateDto
  ): Promise<void> {
    await lastValueFrom(this.http.post(this.urlUpdateDeviceSettings, settings));
  }

  private async getSubscriptionId(): Promise<string | null> {
    return await OneSignal.User.pushSubscription.getIdAsync();
  }
}
