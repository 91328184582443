import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-row-textarea',
  templateUrl: './row-textarea.component.html',
  styleUrls: ['./row-textarea.component.scss']
})
export class RowTextareaComponent implements OnInit {
  @Input() name = '';
  @Input() label = '';
  @Input() form: FormGroup = new FormGroup({});
  @Input() readonly = true;
  @ViewChild('textAreaElement') textAreaElement: ElementRef | undefined;
  @Output() fieldChanged = new EventEmitter<string>();

  private previousValue: any;
  private timer: NodeJS.Timeout | undefined;
  private timerMs = 5000;

  private spinnerPrefix = 'spinner_';
  public spinnerName = '';

  constructor(private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.form.markAllAsTouched();
    this.previousValue = this.control.value;
    this.spinnerName = this.spinnerPrefix + this.name;

    setTimeout(() => {
      this.textAreaElement?.nativeElement.focus();
    }, 1);
  }

  get control(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  get isChangedControl(): boolean {
    return (
      this.control !== null &&
      this.control.invalid &&
      (this.control.dirty || this.control.touched)
    );
  }

  autoHeight(event: any): void {
    event.target.style.height = '0px';
    event.target.style.height = event.target.scrollHeight + 'px';
  }

  updateReport(): boolean {
    const currentValue =
      this.control.value !== null ? this.control.value.trim() : null;
    if (!this.previousValue && !currentValue) {
      return false;
    }

    if (this.previousValue !== currentValue) {
      this.previousValue = currentValue;
      this.spinner.show(this.spinnerName).then();
      this.fieldChanged.emit(this.spinnerName);
      return true;
    }

    return false;
  }

  inputChanged(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => this.updateReport(), this.timerMs);
  }
}
