<ng-container *ngIf="form && name">
  <div class="apg-form-group" [formGroup]="form">
    <label
      >{{ label }}
      <textarea
        class="heightResize"
        [formControlName]="name"
        [class]="isChangedControl ? 'errorInput' : ''"
        (input)="autoHeight($event)"
        [readOnly]="readonly"
        (focus)="autoHeight($event)"
        (focusout)="updateReport()"
        (keydown)="inputChanged()"
        autofocus
        #textAreaElement
      ></textarea>

      <ngx-spinner
        class="spinner"
        [name]="spinnerName"
        bdColor="transparent"
        size="small"
        color="#6A6AD4"
        type="ball-clip-rotate"
        [fullScreen]="false"
      ></ngx-spinner>
    </label>

    <ng-container *ngIf="isChangedControl">
      <div class="error" *ngIf="control?.hasError('maxlength')">
        Max length {{ control.errors?.maxlength?.requiredLength }} symbols
      </div>
      <div class="error" *ngIf="control?.hasError('required')">Required</div>
    </ng-container>
  </div>
</ng-container>
