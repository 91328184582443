<input
  type="text"
  maxlength="255"
  [matAutocomplete]="workspaceAutocomplete"
  [(ngModel)]="selectedWorkspace"
  value="{{ selectedWorkspace?.title }}"
  (ngModelChange)="onChangeEvent($event)"
  (keydown.backspace)="onClearSearch($event)"
/>
<mat-autocomplete
  #workspaceAutocomplete="matAutocomplete"
  [autoActiveFirstOption]="true"
  [displayWith]="getWorkspaceTitle"
>
  <ng-container *ngIf="availableWorkspaces">
    <mat-option
      *ngFor="let availableWorkspace of availableWorkspaces.content"
      [value]="availableWorkspace"
    >
      {{ availableWorkspace.title }}
    </mat-option>
    <span
      *ngIf="availableWorkspaces.hasNext"
      class="load-more"
      (click)="loadMoreSearchResults()"
      >...</span
    >
  </ng-container>
</mat-autocomplete>
<ng-container *ngIf="invalid">
  <div class="error">Workspace is required</div>
</ng-container>
