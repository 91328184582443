import { Component, Input } from '@angular/core';
import { Attendance } from '../../models/participant';
import { attendanceUtils } from './attendance.utils';

@Component({
  selector: 'app-attendance-label',
  templateUrl: './attendance-label.component.html',
  styleUrls: ['./attendance-label.component.scss']
})
export class AttendanceLabelComponent {
  @Input() userStatus: Attendance = Attendance.Unmarked;

  get attendanceString(): string {
    return attendanceUtils.attendanceLabel[this.userStatus];
  }

  get classForStatus(): string {
    switch (this.userStatus) {
      case Attendance.Attending:
        return 'attending';
      case Attendance.Unmarked:
        return 'undecided';
      case Attendance.Rejected:
        return 'not-going';
    }
  }

  get icon(): string {
    switch (this.userStatus) {
      case Attendance.Attending:
        return 'assets/workspaces/attending.svg';
      case Attendance.Unmarked:
        return '';
      case Attendance.Rejected:
        return 'assets/workspaces/not_going.svg';
    }
  }
}
