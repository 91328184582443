import { ElementRef, EventEmitter } from '@angular/core';
import { MentionableParticipant } from './mentionable.participant';
import { StringUtils } from '../common/string-utils';
import { DialogMentionListComponent } from '../component/dialog/dialog-mention-list/dialog-mention-list.component';
import { MatDialog } from '@angular/material/dialog';
import { FnMentionableSearchParticipant } from './mentionable.fn.search.participant';
import { MentionablePosition } from './mentionable.position';
import { Observable, Subscriber } from 'rxjs';
import { MentionableNodeUtils } from './mentionable.node.utils';
import { Participant } from '../models/participant';
import { MentionableListActionsResult } from './mentionable.list.actions.result';

export class MentionableProcessor {
  private el: ElementRef;
  private dialog: MatDialog;
  private fnSearchParticipant?: FnMentionableSearchParticipant;
  private drawPosition: MentionablePosition;
  private mentionEmitter: EventEmitter<MentionableParticipant>;
  private mentionRemovedEmitter: EventEmitter<number>;

  private activated = false;
  private mentionListComponent?: DialogMentionListComponent;

  constructor(
    el: ElementRef,
    dialog: MatDialog,
    drawPosition: MentionablePosition,
    mentionEmitter: EventEmitter<MentionableParticipant>,
    mentionRemovedEmitter: EventEmitter<number>,
    fnSearchParticipant?: FnMentionableSearchParticipant
  ) {
    this.el = el;
    this.dialog = dialog;
    this.fnSearchParticipant = fnSearchParticipant;
    this.drawPosition = drawPosition;
    this.mentionEmitter = mentionEmitter;
    this.mentionRemovedEmitter = mentionRemovedEmitter;
  }

  startMention(): MentionableListActionsResult {
    this.activated = true;

    const rect = this.el.nativeElement.getBoundingClientRect();
    const textCaretX: number = this.getSelectionCaretXPosition();
    const offsetHeight = this.el.nativeElement.offsetHeight || 0;
    const dialogTopPosition = rect.bottom - offsetHeight;
    const dialogLeftPosition = textCaretX + 1;

    const dialogRef = this.dialog.open(DialogMentionListComponent, {
      restoreFocus: false,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      autoFocus: false,
      panelClass: 'no-padding-dialog',
      data: {
        leftPosition: dialogLeftPosition,
        topPosition: dialogTopPosition,
        inputHeight: offsetHeight,
        fnSearchParticipant: this.fnSearchParticipant,
        drawPosition: this.drawPosition
      }
    });

    this.mentionListComponent = dialogRef.componentInstance;
    this.mentionListComponent.setDialogRef(dialogRef);

    const afterOpenedObservable: Observable<void> = dialogRef.afterOpened();
    const afterClosedObservable: Observable<MentionableParticipant> =
      dialogRef.afterClosed();

    afterOpenedObservable.subscribe(() => {
      setTimeout(() => {
        this.filterParticipantsList('');
      }, 500);
    });

    afterClosedObservable.subscribe({
      next: (participant) => this.stopMention(participant)
    });

    return new MentionableListActionsResult(
      afterOpenedObservable,
      afterClosedObservable
    );
  }

  /**
   * Stop finding participant
   */
  stopMention(mentionedParticipant?: MentionableParticipant): void {
    this.activated = false;

    if (mentionedParticipant) {
      const nativeElement = this.el.nativeElement;

      if (nativeElement?.innerText) {
        // find the index of last time word was used
        const text: string = nativeElement.innerHTML;
        const idx = text.toLowerCase().lastIndexOf('@');
        const replacedParticipant =
          StringUtils.format(
            MentionableNodeUtils.MENTIONED_PARTICIPANT_HTML_TEMPLATE,
            mentionedParticipant.getId().toString(),
            mentionedParticipant.getPrintedName()
          ) + '&shy; ';

        const newValue =
          text.slice(0, idx) +
          text.slice(idx).replace(/@[a-zA-Z_0-9]*/, replacedParticipant);
        MentionableNodeUtils.updateNativeElementHtmlContent(
          nativeElement,
          newValue
        );
        MentionableNodeUtils.moveCursorToEnd(nativeElement);
      }

      this.mentionEmitter.emit(mentionedParticipant);
    }
  }

  closeMentionDialogIfOpened(): Observable<any> {
    if (this.mentionListComponent?.dialogRef) {
      this.mentionListComponent?.dialogRef?.close();
      return this.mentionListComponent.dialogRef.afterClosed();
    }

    return new Observable<any>((subscriber: Subscriber<any>) => {
      subscriber.next();
      subscriber.complete();
    });
  }

  filterParticipantsList(keyword: string): void {
    this.mentionListComponent?.onFilter(keyword);
  }

  notifyMentionRemoved(removedMentionId: number): void {
    this.mentionRemovedEmitter.emit(removedMentionId);
  }

  private getSelectionCaretXPosition(): number {
    const rect = window.getSelection()?.getRangeAt(0)?.getBoundingClientRect();
    return rect?.x || 0;
  }

  get isActivated(): boolean {
    return this.activated;
  }
}
