import { Observable, throwError } from 'rxjs';
import { TaskService } from './task.service';
import { ProfileService } from '../user/profile/profile.service';
import { Injectable } from '@angular/core';
import { TaskModel } from '../../models/task.model';
import { TaskAssignee } from '../../models/task.assignee';
import { map } from 'rxjs/operators';
import { Task } from '../../models/task';
import { SnackbarService, SnackbarType } from '../snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class TaskCompletionService {
  constructor(
    private readonly tasksService: TaskService,
    private readonly profileService: ProfileService,
    private readonly snackbar: SnackbarService
  ) {}

  private generalErrorMessage =
    'Oops there was an error. Try reload page and repeat';

  handleTaskCheckmarkClick(
    task: TaskModel,
    close: boolean
  ): Observable<TaskModel> {
    if (task.id) {
      return task.canManage
        ? this.changeTaskStatus(task.id, task, close)
        : this.changeAssigneeStatus(task.id, task, close);
    }
    return throwError(() => this.generalErrorMessage);
  }

  changeTaskStatus(
    taskId: number,
    task: TaskModel,
    close: boolean
  ): Observable<TaskModel> {
    const request = close
      ? this.tasksService.closeTask(taskId)
      : this.tasksService.openTask(taskId);
    return request.pipe(
      map((closed: boolean) => {
        task.isOpen = !closed;
        return task;
      })
    );
  }

  changeAssigneeStatus(
    taskId: number,
    task: TaskModel,
    complete: boolean
  ): Observable<TaskModel> {
    const userId = this.profileService.user?.userId;
    if (userId) {
      const request = complete
        ? this.tasksService.markCompletedAsAssignee(taskId)
        : this.tasksService.markUncompletedAsAssignee(taskId);
      return request.pipe(
        map((completed: boolean) => {
          const taskAssignees = task.assignees ?? [];
          const currentUserAssignee = this.currentUserAssigneeIn(taskAssignees);
          if (currentUserAssignee) {
            currentUserAssignee.completed = completed;
          }
          return task;
        })
      );
    }
    return throwError(() => this.generalErrorMessage);
  }

  isCurrentUserCompletedTask(task: TaskModel): boolean {
    if (task.assignees) {
      const currentUserAssignee = this.currentUserAssigneeIn(task.assignees);
      if (currentUserAssignee) {
        return currentUserAssignee.completed;
      }
      return false;
    }
    return false;
  }

  isCheckmarkChecked(task: TaskModel): boolean {
    if (task.isOpen) {
      return task.canManage ? false : this.isCurrentUserCompletedTask(task);
    } else {
      return task.canManage ? true : this.isCurrentUserCompletedTask(task);
    }
  }

  showErrorSnackbar(error: string): void {
    this.snackbar.open(error, SnackbarType.Error);
  }

  setCurrentUserStatusFor(task: Task, completed: boolean): void {
    const currentUserAssignee = this.currentUserAssigneeIn(task.assignees);
    if (currentUserAssignee) {
      currentUserAssignee.completed = completed;
    }
  }

  currentUserAssigneeIn(assignees: TaskAssignee[]): TaskAssignee | undefined {
    const currentUserId = this.profileService.user?.userId;

    if (currentUserId) {
      return assignees.find((assignee) => assignee.userId === currentUserId);
    }
    return undefined;
  }
}
