<ng-container *ngIf="form">
  <div fxLayout="column" [formGroup]="form">
    <div fxFlex="80px" fxFlex.lt-md="70px" mat-dialog-title fxLayout="row">
      <div
        fxLayout="row"
        fxFlex
        dir="ltr"
        fxLayoutAlign=" center"
        class="description"
      >
        <mat-icon
          aria-hidden="false"
          aria-label="Example home icon"
          mat-dialog-close
          >close</mat-icon
        >
      </div>
      <div
        fxLayout="row"
        fxFlex
        dir="rtl"
        fxLayoutAlign=" center"
        class="description"
      >
        <img
          [src]="form.controls['description'].invalid && (form.controls['description'].dirty || form.controls['description'].touched)
                ? 'assets/send/send-disable.svg' : 'assets/send/send.svg'"
          (click)="send()"
          alt="send"
        />
      </div>
    </div>
    <div fxLayout="row" fxFlex="100vh" fxFlexAlign="stretch" class="content">
      <label class="full-size">
        <div
          contenteditable="true"
          formControlName="description"
          class="input-description-contenteditable"
          placeholder="Write here..."
          required="required"
          maxlength="1500"
          appMentionable
          [fnSearchParticipant]="fnSearchParticipant"
          [drawPosition]="MentionablePosition.BOTTOM"
        ></div>

        <ng-container
          *ngIf="form.controls['description'].invalid && form.controls['description'].touched"
        >
          <div
            class="error"
            *ngIf="form.get('description')?.hasError('required')"
          >
            Message text cannot be empty
          </div>
          <div
            class="error"
            *ngIf="form.get('description')?.hasError('maxlength')"
          >
            Max length
            {{form.get('description')?.errors?.maxlength?.requiredLength}}
            symbols
          </div>
        </ng-container>
      </label>
    </div>
  </div>
</ng-container>
