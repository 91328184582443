<ng-container *ngIf="postTopic">
  <div fxLayout="column" class="workspace-card">
    <div fxLayout="row">
      <div fxLayout="row">
        <app-avatar-for-post
          [post]="postTopic"
          [date]="getDate()"
          [size]="'big'"
        ></app-avatar-for-post>
      </div>
      <div fxFlex fxLayoutAlign="end center" class="pin-wrapper">
        <span
          class="pin"
          [ngSwitch]="postTopic.pinned"
          *ngIf="
            postService.permissionPinUnpin(workspace?.participants ?? []);
            else noPermissionPinUnpin
          "
        >
          <img
            *ngSwitchCase="false"
            src="assets/pin/pin.svg"
            (click)="pinClick()"
            class="pin-active"
            alt="pin"
          />
          <img
            *ngSwitchCase="true"
            src="assets/pin/pin-active.svg"
            (click)="unpinClick()"
            alt="pin-active"
          />
        </span>

        <ng-template #noPermissionPinUnpin>
          <span class="pin-disabled" [ngSwitch]="postTopic.pinned">
            <img
              *ngSwitchCase="true"
              src="assets/pin/pin-active.svg"
              alt="pin-active"
            />
          </span>
        </ng-template>

        <div>
          <button
            class="more"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu">
            <button
              mat-menu-item
              [cdkCopyToClipboard]="postTopic.description || ''"
              (click)="onMessageCopied()"
            >
              <span>Copy</span>
            </button>
            <button
              *ngIf="postService.permissionReportPost(postTopic)"
              mat-menu-item
              (click)="postService.reportPost(postTopic)"
            >
              <span>Report</span>
            </button>
            <button
              *ngIf="
                postService.permissionEditingPost(postTopic) &&
                (!editing || postService.getPostId() !== postTopic.postId)
              "
              mat-menu-item
              (click)="editingPostWrapper(postTopic)"
            >
              <span>Edit</span>
            </button>
            <button
              *ngIf="postService.permissionDeletingPost(postTopic)"
              class="danger"
              mat-menu-item
              (click)="postService.deletePostWrapper(postTopic)"
            >
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>

    <div class="description">
      <app-post-description
        [editing]="editing"
        [post]="postTopic"
        [fullscreen]="true"
        [callbackBad]="notSave.bind(this)"
        [callback]="onPostUpdated.bind(this)"
        [fnSearchParticipant]="searchWorkspaceParticipants"
      >
      </app-post-description>
    </div>
    <div fxLayout="row">
      <app-reaction [post]="postTopic"></app-reaction>
    </div>

    <div fxLayout="row" *ngFor="let postComment of postTopic.children">
      <app-post-comment
        [fnSearchParticipant]="searchWorkspaceParticipants"
        [postComment]="postComment"
        [workspace]="workspace"
      >
      </app-post-comment>
    </div>

    <div fxLayout="row">
      <div fxFlex="auto">
        <app-input-write-post-and-comment
          [callback]="savePostComment.bind(this)"
          [fnSearchParticipant]="searchWorkspaceParticipants"
          [sticky]="true"
          [avatar]="true"
        >
        </app-input-write-post-and-comment>
      </div>
    </div>
  </div>
</ng-container>
