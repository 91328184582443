import { Attendance } from 'src/app/models/participant';

const attendanceLabel: Record<Attendance, string> = {
  [Attendance.Attending]: 'Attending',
  [Attendance.Rejected]: 'Not going',
  [Attendance.Unmarked]: 'Undecided'
};

export const attendanceUtils = {
  attendanceLabel
};
