import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appSelectableButton]'
})
export class SelectableButtonDirective {
  @Input() height = '30px';
  @Input() gap = 15;

  @Input() set isSelected(isSelected: boolean) {
    if (isSelected) {
      this.elementRef.nativeElement.style.backgroundColor = '#6A6AD4';
      this.elementRef.nativeElement.style.color = 'white';
      this.elementRef.nativeElement.style.borderColor = '#6A6AD4';
    } else {
      this.elementRef.nativeElement.style.backgroundColor = '#F6F6F9';
      this.elementRef.nativeElement.style.color = '#7F8FA8';
      this.elementRef.nativeElement.style.borderColor = '#A9ADC933';
    }
  }

  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.style.cursor = 'pointer';
    this.elementRef.nativeElement.style.display = 'flex';
    this.elementRef.nativeElement.style.justifyContent = 'center';
    this.elementRef.nativeElement.style.alignItems = 'center';
    this.elementRef.nativeElement.style.borderStyle = 'solid';
    this.elementRef.nativeElement.style.marginInline = `${this.gap / 2}px`;
    this.elementRef.nativeElement.style.borderWidth = '1px';
    this.elementRef.nativeElement.style.borderRadius = '100px';
    this.elementRef.nativeElement.style.height = this.height;
    this.elementRef.nativeElement.style.padding = '6px 16px';
  }
}
