import { KeyboardEventProcessor } from './keyboard.event.processor';

export class SafariKeyboardEventProcessor extends KeyboardEventProcessor {
  protected startMention(): void {
    this.participantNameTyped = '';
    const selection = window.getSelection();
    const focusNode = selection?.focusNode;
    const focusOffset = selection?.focusOffset;

    const result = this.mentionableProcessor.startMention();

    result.afterOpened.subscribe(() => {
      if (focusNode) {
        selection?.setPosition(focusNode, focusOffset);
      }
    });

    result.afterClosed.subscribe(() => {
      // do nothing....
    });
  }
}
