import { ArchiveFile } from './archiveFile';
import { WorkspaceEvent } from './workspaceEvent';
import { Workspace } from './workspace';

export class PublicPageData {
  attachments: ArchiveFile[] = [];
  events: WorkspaceEvent[] = [];
  workspace: Workspace | undefined | null;

  deserialize(input: any): this {
    Object.assign(this, input);
    this.events = this.events.map((file) =>
      new WorkspaceEvent(0).deserialize(file)
    );
    this.workspace = new Workspace().deserialize(this.workspace);
    return this;
  }
}
