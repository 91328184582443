import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

export interface ParticipantsGroup {
  name: string;
  value: string | null;
}

const DEFAULT_GROUPS: ParticipantsGroup[] = [
  {
    name: 'All Users',
    value: null
  },
  {
    name: 'Not Assigned',
    value: 'not_assigned'
  },
  {
    name: 'Featured',
    value: 'featured'
  },
  {
    name: 'Lead',
    value: 'lead'
  },
  {
    name: 'Support',
    value: 'support'
  }
];

@Component({
  selector: 'app-group-select',
  standalone: true,
  imports: [CommonModule, FlexLayoutModule, MatButtonModule],
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSelectComponent {
  @Input() groups: ParticipantsGroup[] = DEFAULT_GROUPS;
  @Input() selectedGroupValue: string | null = null;
  @Output() groupSelected = new EventEmitter<ParticipantsGroup>();
}
