import { Attendance } from './participant';

export class AttendanceObject {
  actual: ActualValues = ActualValues.user;
  attendance: Attendance = Attendance.Unmarked;
  eventId = 0;
  reason = '';
  userId = 0;

  constructor(
    attendanceStatus: Attendance,
    userId: number,
    eventId: number,
    actual: ActualValues,
    reason: string
  ) {
    this.attendance = attendanceStatus;
    this.userId = userId;
    this.eventId = eventId;
    this.actual = actual;
    this.reason = reason;
  }
}

export enum ActualValues {
  user = 'USER',
  mentor = 'MENTOR'
}
