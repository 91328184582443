import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as debug from 'debug';

const trace = debug('apg:twilio-service');

@Injectable({
  providedIn: 'root'
})
export class TwilioLogger {
  constructor() {}

  log(message?: any, ...optionalParams: any[]): void {
    if (environment.debugTwilio) {
      trace('TWILIO LOG:---', message, optionalParams);
    }
  }
}
