export class Breadcrumb {
  name: string;
  alias: string;
  queryParams: any;

  constructor(name: string) {
    this.name = name;
    this.alias = name;
    this.queryParams = {};
  }

  addQueryParam(name: string, value: string): void {
    this.queryParams[name] = value;
  }

  setAlias(alias: string): void {
    this.alias = alias;
  }
}
