import { MentionableDirectiveModule } from 'src/app/directive/mentionable.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DialogInputWriteComponent } from './dialog-input-write.component';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';

@NgModule({
  declarations: [DialogInputWriteComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatIconModule,
    FlexLayoutModule,
    MentionableDirectiveModule,
    MatDialogModule,
    ContenteditableValueAccessorModule
  ],
  exports: [DialogInputWriteComponent]
})
export class DialogInputWriteModule {}
