<ng-container #mainForm *ngIf="!nextStepComponentRef">
  <form class="add-update-form" [formGroup]="taskCreateForm" novalidate>
    <div class="closeElement" mat-dialog-close>
      <span class="material-icons">close</span>
    </div>

    <div class="titleBlock">
      <h1 mat-dialog-title class="apg-title">
        <span *ngIf="!isInEditMode">Create Task</span>
        <span *ngIf="isInEditMode">Edit Task</span>
      </h1>
    </div>

    <mat-dialog-content>
      <section>
        <div class="apg-form-group">
          <label
            >Title
            <input
              type="text"
              formControlName="taskTitle"
              placeholder="Enter task title"
              [class.errorInput]="shouldDisplayErrorBlock(taskTitle)"
            />
          </label>
          <ng-container *ngIf="shouldDisplayErrorBlock(taskTitle)">
            <div class="error" *ngIf="taskTitle?.hasError('required')">
              Title is required
            </div>
            <div class="error" *ngIf="taskTitle?.hasError('maxlength')">
              Max length is
              {{ taskTitle?.errors?.maxlength?.requiredLength }} symbols
            </div>
          </ng-container>
        </div>

        <div class="apg-form-group">
          <label
            >Description
            <textarea
              type="text"
              formControlName="description"
              placeholder="Enter task description"
              [class]="
                description?.invalid && formSubmitAttempt ? 'errorInput' : ''
              "
              (change)="onChange($event)"
            ></textarea>
          </label>
          <div class="error" *ngIf="description?.hasError('maxlength')">
            Max length
            {{ description?.errors?.maxlength?.requiredLength }} symbols
          </div>
        </div>
      </section>

      <section>
        <div
          *ngIf="!isInEditMode && !showspacePreSelected"
          class="apg-form-group apg-inline"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <label> Showspace </label>
          <button
            mat-button
            class="choose-button"
            (click)="showShowspaceSearchComponent()"
            [ngClass]="{
              selected: showspace?.touched,
              errorOnButton: showspace?.invalid && formSubmitAttempt
            }"
          >
            {{ showspace?.value?.title ? showspace?.value?.title : 'Choose' }}
          </button>
        </div>
        <div
          class="errorForButton"
          *ngIf="
            showspace?.invalid &&
            formSubmitAttempt &&
            showspace?.hasError('required')
          "
        >
          Showspace is required
        </div>

        <div
          class="apg-form-group apg-inline"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <label style="flex: 0 0 auto"> Due date </label>

          <input
            [ngClass]="{ selected: dueDate }"
            class="no_input_borders"
            style="width: 100%"
            (click)="onPickDueDate()"
            [ngxMatDatetimePicker]="dueDatePicker"
            formControlName="dueDate"
            (dateInput)="dueDatePicker.close()"
            readonly
          />

          <button
            *ngIf="!dueDate?.value"
            mat-button
            class="choose-button"
            (click)="onPickDueDate()"
            [ngClass]="{ errorOnButton: dueDate?.invalid && formSubmitAttempt }"
          >
            Choose
          </button>

          <ngx-mat-datetime-picker
            [enableMeridian]="true"
            hidden
            #dueDatePicker
          >
          </ngx-mat-datetime-picker>
        </div>
        <div
          class="errorForButton"
          *ngIf="
            dueDate?.invalid &&
            formSubmitAttempt &&
            dueDate?.hasError('required')
          "
        >
          Due date is required
        </div>

        <div
          class="apg-form-group apg-inline"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <label> Priority </label>
          <button
            mat-flat-button
            [ngClass]="'priority priority__' + priorityClass"
            (click)="onNextPriority()"
          >
            {{ priorityButtonNaming }}
          </button>
        </div>

        <ng-container *ngIf="!isInEditMode">
          <div
            class="apg-form-group apg-inline"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <label>Assignees</label>
            <button
              mat-button
              class="choose-button disabled-no-borders"
              [disabled]="!selectedShowspaceId"
              (click)="showAssigneeSearchComponent()"
            >
              Choose
            </button>
          </div>
          <div class="assignee-list">
            <app-assignee-list
              [assignees]="assignees?.value"
            ></app-assignee-list>
          </div>
        </ng-container>
      </section>

      <section>
        <div class="apg-form-group">
          <button
            mat-flat-button
            class="btn upper"
            color="accent"
            [class.spinner]="loadInProgress"
            (click)="isInEditMode ? updateTaskForm() : saveTaskForm()"
            [disabled]="
              (formSubmitAttempt && taskCreateForm.invalid) ||
              (formSubmitAttempt && loadInProgress)
            "
          >
            <span *ngIf="!isInEditMode">Create</span>
            <span *ngIf="isInEditMode">Save</span>
          </button>
        </div>
      </section>
    </mat-dialog-content>
  </form>
</ng-container>

<ng-container #nextContent></ng-container>
