<ng-container>
  <app-loading *ngIf="!workspaceEvent && loadInProgress"></app-loading>

  <ng-container *ngIf="workspaceEvent">
    <div fxLayout="column">
      <div class="workspace" fxLayout="row" fxLayoutAlign="center none">
        <div class="central-block" fxFlex="530px" fxFlex.lt-sm="100">
          <div class="description">{{ workspaceEvent.name }}</div>

          <div class="one-group lowerCase">
            <div class="first">Event type</div>
            <div class="second capitalize">{{ workspaceEvent.type }}</div>
          </div>

          <div class="one-group">
            <div class="first">Date</div>
            <div class="second">
              {{
                utils.getDateRange(
                  this.workspaceEvent.dateStart,
                  this.workspaceEvent.dateEnd,
                  dateFormat
                )
              }}
            </div>
          </div>

          <div class="one-group" *ngIf="workspaceEvent.location">
            <div class="first">Location</div>
            <div class="second">{{ workspaceEvent.location }}</div>
          </div>

          <div class="one-group" *ngIf="workspaceEvent.link">
            <div class="first">Link</div>
            <div class="second">
              <a [href]="workspaceEvent.link" target="_blank">{{
                workspaceEvent.link
              }}</a>
            </div>
          </div>

          <div class="one-group" *ngIf="workspaceEvent.description">
            <div class="first">Description</div>
            <div
              class="second multiline-text"
              [innerHTML]="workspaceEvent.description"
            ></div>
          </div>

          <div
            class="one-group"
            *ngIf="canEditReport || this.eventReport?.published"
          >
            <div class="first">Event report</div>
            <div class="second">
              <span
                *ngIf="this.eventReport.published"
                (click)="openEventReportDialog()"
                class="reportLink"
                >Updated
                {{
                  this.eventReport.updateTime | date: 'MM/dd/YYYY h:mm a'
                }}</span
              >
              <button
                class="apg-button red-button small-button"
                *ngIf="!this.eventReport.published"
                (click)="openEventReportDialog()"
              >
                Create
              </button>
            </div>
          </div>

          <div class="one-group" *ngIf="isVisibleBlockParticipation()">
            <div class="first">My participation</div>

            <ng-container
              *ngIf="
                shouldHideAttendanceButtons(workspaceEvent);
                else attendanceToggle
              "
            >
              <div class="second last">
                {{ currentStatusForDisplay(workspaceEvent) }}
              </div>
            </ng-container>

            <ng-template #attendanceToggle>
              <div class="last attendance-toggle">
                <app-attendance-toggle
                  [disabled]="workspaceEvent.isPastEvent"
                  [userStatus]="
                    eventService.currentUserStatusFor(workspaceEvent)
                  "
                  [workspaceEvent]="workspaceEvent"
                >
                </app-attendance-toggle>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="center"
        [class]="['header-links', 'top', 'white']"
        fxFlex="44px"
      >
        <ng-container
          *ngFor="let status of attendanceStatuses; let idx = index"
        >
          <div
            appSelectableButton
            [isSelected]="activeTab === idx"
            class="header-one-link"
            (click)="setActive(idx)"
          >
            {{ status }}
          </div>
        </ng-container>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxFill>
        <div
          [ngSwitch]="activeTab"
          fxLayout="column"
          fxFlex="580px"
          fxFlex.lt-sm="auto"
          style="margin: 0 16px"
        >
          <div *ngSwitchCase="0">
            <app-participants-block
              [attendance]="allAttendanceTypes.Attending"
              [workspaceEvent]="workspaceEvent"
              [workspace]="workspace"
            ></app-participants-block>
          </div>
          <div *ngSwitchCase="1">
            <app-participants-block
              [attendance]="allAttendanceTypes.Rejected"
              [workspaceEvent]="workspaceEvent"
              [workspace]="workspace"
            ></app-participants-block>
          </div>
          <div *ngSwitchCase="2">
            <app-participants-block
              [attendance]="allAttendanceTypes.Unmarked"
              [workspaceEvent]="workspaceEvent"
              [workspace]="workspace"
            ></app-participants-block>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
