import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { ProfileService } from '../../../../services/user/profile/profile.service';
import { MatDialog } from '@angular/material/dialog';
import {
  EventBusService,
  EventBusNames
} from '../../../../services/event-bus/event-bus.service';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() interactionEnabled = true;

  public count = 0;

  private onDestroy = new Subject<void>();

  constructor(
    public service: NotificationsService,
    private dialog: MatDialog,
    private eventBusService: EventBusService,
    private profile: ProfileService
  ) {}

  ngOnInit(): void {
    this.fetchNotificationsCount(this.profile.user?.userId);

    this.eventBusService.on(
      EventBusNames.profile,
      () => this.fetchNotificationsCount(this.profile.user?.userId),
      this.onDestroy
    );
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private fetchNotificationsCount(userId: number | undefined): void {
    if (userId && userId > 0) {
      this.service.getCountUnseen();
      this.service.getCount().then((count) => (this.count = count));
    }
  }

  isInteractionEnabled(): boolean {
    return this.interactionEnabled;
  }

  showNotifications(): void {
    this.dialog.open(NotificationDialogComponent, {
      panelClass: 'notification-dialog'
    });
  }
}
