<ng-container class="text-input-container" *ngIf="form">
  <form class="add-update-form" [formGroup]="form">
    <div class="closeElement" mat-dialog-close>
      <span class="material-icons">close</span>
    </div>

    <mat-dialog-content>
      <div>
        <h1 class="apg-title no-margin" *ngIf="title">{{ title }}</h1>
      </div>
      <div class="detail-text">{{ message }}</div>

      <div class="apg-form-group">
        <textarea
          formControlName="description"
          [class.errorInput]="shouldDisplayErrorBlock(description)"
        >
        </textarea>

        <ng-container *ngIf="shouldDisplayErrorBlock(description)">
          <div
            class="error"
            *ngIf="
              description?.errors?.whitespace || description?.errors?.required
            "
          >
            {{ requiredError }}
          </div>
          <div class="error" *ngIf="description?.errors?.maxlength">
            {{ maxLengthError }}
          </div>
        </ng-container>
      </div>

      <div mat-dialog-actions class="confirm-button">
        <ng-container>
          <button
            mat-flat-button
            class="btn upper"
            color="accent"
            [disabled]="form.invalid"
            (click)="onConfirm()"
          >
            {{ confirmButtonTitle }}
          </button>
        </ng-container>
      </div>
    </mat-dialog-content>
  </form>
</ng-container>
