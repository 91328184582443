import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Notification } from 'src/app/models/notification';
import { StompSubscription } from '@stomp/stompjs';
import { ProfileService } from '../user/profile/profile.service';
import { Title } from '@angular/platform-browser';
import { WebsocketSubscriptionService } from '../websocket/websocket-subscription.service';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {
  notifications: Notification[] = [];
  countUnseen: number | undefined;
  count: number | undefined = 0;
  page = 0;
  limit = 10;
  loadInProgress = false;

  notificationsCountSubscription: StompSubscription | null = null;
  notificationsSubscription: StompSubscription | null = null;

  private baseUrl = environment.url + 'notifications';

  constructor(
    private http: HttpClient,
    public profileService: ProfileService,
    public title: Title,
    private websocketSubscriptionService: WebsocketSubscriptionService
  ) {
    this.subscribeToTopics();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromTopics();
  }

  setPage(page: number): void {
    this.page = page;
  }

  setNotification(): void {
    this.notifications = [];
    this.page = 0;
  }

  writeNotifications(res: any): void {
    this.loadInProgress = false;
    this.getCountUnseen().then();
    this.getCount().then();
    this.appendNotifications(res);
  }

  updateCountUnseen(res: any): void {
    this.countUnseen = res;
    if (this.countUnseen !== undefined && this.countUnseen > 0) {
      this.title.setTitle('(' + this.countUnseen + ") Actor's Pocket Guide");
    } else {
      this.title.setTitle("Actor's Pocket Guide");
    }
  }

  updateCount(res: any): void {
    this.count = res;
  }

  getCountUnseen(): Promise<any> {
    return lastValueFrom(this.http.get(this.baseUrl + '/count/unseen')).then(
      this.updateCountUnseen.bind(this)
    );
  }

  async getPageCount(): Promise<number> {
    const params = new HttpParams().set('limit', this.limit.toString());
    const count = await lastValueFrom(
      this.http.get(this.baseUrl + '/pages', { params, responseType: 'text' })
    );
    return parseInt(count, 10);
  }

  async getNotifications(): Promise<any> {
    const params = new HttpParams()
      .set('limit', this.limit.toString())
      .set('page', this.page.toString());
    this.loadInProgress = true;
    const notifications = await lastValueFrom(
      this.http.get(this.baseUrl, { params })
    );
    this.writeNotifications(notifications);
    this.loadInProgress = false;
  }

  getAllNotifications(): Promise<any> {
    const params = new HttpParams()
      .set('limit', this.limit * (this.page + 1))
      .set('page', '0');
    this.loadInProgress = true;
    return lastValueFrom(this.http.get(this.baseUrl, { params })).then(
      (items: any) => {
        this.loadInProgress = false;
        this.cacheNotifications(items);
      },
      () => {
        this.loadInProgress = false;
      }
    );
  }

  getCount(): Promise<any> {
    return lastValueFrom(this.http.get(this.baseUrl + '/count')).then(
      this.updateCount.bind(this)
    );
  }

  readNotification(notificationId: number): Promise<any> {
    const ids = [notificationId];
    return lastValueFrom(this.http.put(this.baseUrl, ids)).then(() => {
      this.getCountUnseen().then();
    });
  }

  readAllNotification(): Promise<any> {
    return lastValueFrom(this.http.put(this.baseUrl, null)).then(() => {
      this.getCountUnseen().then();
      this.notifications?.forEach((notification) => (notification.seen = true));
    });
  }

  deleteNotification(notification: Notification): Promise<any> {
    return lastValueFrom(
      this.http.delete(this.baseUrl + '/' + notification.notificationId)
    ).then(() => {
      this.getAllNotifications().then();
      this.getCountUnseen().then();
      this.getCount().then();
    });
  }

  deleteAllNotification(): Promise<any> {
    return lastValueFrom(this.http.delete(this.baseUrl)).then(() => {
      this.notifications = [];
      this.getCountUnseen().then();
      this.getCount().then();
    });
  }

  private parsedNotifications(data: any): Notification[] {
    const notifications = [];

    for (const item of data) {
      notifications.push(new Notification().deserialize(item));
    }
    return notifications;
  }

  private cacheNotifications(data: any): void {
    this.notifications = this.parsedNotifications(data);
  }

  private appendNotifications(data: any): void {
    this.notifications = this.notifications.concat(
      this.parsedNotifications(data)
    );
  }

  private appendNotification(notification: Notification): void {
    this.notifications.splice(0, 0, notification);
    this.count = this.count ? this.count++ : 1;
  }

  private subscribeToTopics(): void {
    const self = this;

    this.websocketSubscriptionService
      .subscribeToNotificationsCountQueue((count: number) => {
        self.updateCountUnseen(count);
      })
      .subscribe((subscription: StompSubscription) => {
        self.notificationsCountSubscription = subscription;
      });

    this.websocketSubscriptionService
      .subscribeToNotificationsQueue((notification: Notification) => {
        self.appendNotification(notification);
      })
      .subscribe((subscription: StompSubscription) => {
        self.notificationsSubscription = subscription;
      });
  }

  private unsubscribeFromTopics(): void {
    this.notificationsCountSubscription?.unsubscribe();
    this.notificationsSubscription?.unsubscribe();
  }
}
