import { TaskAssignee } from './task.assignee';

export enum Priority {
  NONE,
  LOW,
  MEDIUM,
  HIGH
}

export class TaskModel {
  title: string | undefined;
  id?: number;
  description: string | undefined;
  dueDate: string | undefined;
  priority: Priority | undefined;
  showspace: string | undefined;
  assignees: TaskAssignee[] | undefined;
  workspaceId?: number;
  isOpen = true;
  canManage = false;
}
