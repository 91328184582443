import { User } from './user';

export interface Participant {
  userId: number;
  email: string;
  name?: string;
  surname?: string;
  photoUrl?: string;
  photoThumbnailUrl?: string;
  workspaceId: number;
  workspaceRole?: string;
  workspaceManager: boolean;
  isCastMember: boolean;
  enabled: boolean;
}

export interface ChatParticipant extends Participant {
  identity: string;
  fullName: string;
}

export interface EventParticipant extends Participant {
  actual: string;
  attendance: Attendance;
  eventId: number;
  reason?: string;
}

export class ExtendedParticipant {
  participantData: Participant;

  isClicked = false;

  constructor(participant: Participant) {
    this.participantData = participant;
  }

  get fullName(): string {
    return `${this.participantData.name?.trim()} ${this.participantData.surname?.trim()}`;
  }

  getId(): number {
    return this.participantData.userId;
  }

  getVisibleName(): string {
    return this.fullName;
  }

  public getPrintedName(): string {
    // @ts-ignore
    return this.fullName.replaceAll(' ', '_');
  }

  getAvatarSrc(): string {
    return (
      this.participantData.photoThumbnailUrl ||
      this.participantData.photoUrl ||
      User.DEFAULT_AVATAR_SRC
    );
  }
}

export function participantFullName(participant: Participant): string {
  if (participant.name && participant.surname) {
    return `${participant.name?.trim()} ${participant.surname.trim()}`;
  }
  return '';
}

export function toExtendedParticipant(p: Participant): ExtendedParticipant {
  return new ExtendedParticipant(p);
}

export enum Attendance {
  Unmarked = 'UNMARKED',
  Attending = 'GOING',
  Rejected = 'NOT_GOING'
}
