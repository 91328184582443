import { CursorUtils } from '../common/cursor-utils';

export class MentionableNodeUtils {
  public static MENTIONED_PARTICIPANT_HTML_TEMPLATE =
    '<span class="mentioned-participant-id-{0} mention-participant-name">@{1}</span>';
  private static MENTIONED_CLASS_REGEXP = new RegExp(
    /mentioned-participant-id-([0-9]+)/
  );

  public static getMentionedParticipantsIds(nativeElement: any): Array<number> {
    const participantIds: Array<number> = [];
    for (const node of nativeElement.childNodes) {
      const mentionedId: number | null = this.getMentionIdFromNode(node);
      if (mentionedId) {
        if (!participantIds.includes(mentionedId)) {
          participantIds.push(mentionedId);
        }
      }
    }

    return participantIds;
  }

  public static removeMentionAndShy(mentionNode: any): number | null {
    const parentNode = mentionNode.parentNode;
    const nextSiblingStringData: string = mentionNode.nextSibling?.data;

    if (nextSiblingStringData?.charCodeAt(0) === 173) {
      // next sibling data starts from &shy; - should remove &shy;
      mentionNode.nextSibling.data = nextSiblingStringData.substring(1);
    }

    const mentionId: number | null = this.getMentionIdFromNode(mentionNode);
    parentNode.removeChild(mentionNode);
    return mentionId;
  }

  public static insertContentIntoSelectedNode(
    selection: Selection,
    value: string
  ): void {
    if (selection.focusNode?.nodeValue) {
      let noveValue = selection.focusNode?.nodeValue;
      const index = selection.focusOffset;
      noveValue =
        noveValue.substring(0, index) + value + noveValue.substring(index);
      selection.focusNode.nodeValue = noveValue;
      this.fireHtmlInputEvent(selection.focusNode);
      setTimeout(() => {
        if (selection.focusNode) {
          const range = document.createRange();
          range.selectNode(selection.focusNode);
          range.setStart(selection.focusNode, index + value.length);
          range.setEnd(selection.focusNode, index + value.length);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 1);
    }
  }

  public static updateNativeElementHtmlContent(
    nativeElement: any,
    content: string
  ): void {
    nativeElement.innerHTML = content;
    this.fireHtmlInputEvent(nativeElement);
  }

  public static fireHtmlInputEvent(nativeElement: any): void {
    const evt = new Event('input', {
      bubbles: false,
      cancelable: true
    });
    nativeElement.dispatchEvent(evt);
  }

  public static isMentionedNode(node: any): boolean {
    return (
      node?.nodeName === 'SPAN' &&
      node.classList?.contains('mention-participant-name')
    );
  }

  public static isNodeContainsNode(node: any, searchNode: any): boolean {
    if (searchNode === node) {
      return true;
    }

    for (const childNode of node.childNodes) {
      if (searchNode === childNode) {
        return true;
      }

      if (childNode.nodeName === 'SPAN') {
        // checking it child nodes
        for (const innerChildNode of childNode.childNodes) {
          if (searchNode === innerChildNode) {
            return true;
          }
        }
      }
    }

    return false;
  }

  public static moveCursorToEnd(nativeElement: any): void {
    const offset = nativeElement.innerText.length;
    CursorUtils.setCurrentCursorPosition(offset, nativeElement);
    nativeElement.focus();
  }

  private static getMentionIdFromNode(node: any): number | null {
    if (MentionableNodeUtils.isMentionedNode(node)) {
      for (const nodeCssClass of node.classList) {
        if (MentionableNodeUtils.MENTIONED_CLASS_REGEXP.test(nodeCssClass)) {
          const mentionedId: number | null =
            this.getMentionIdFromCssClass(nodeCssClass);

          if (mentionedId) {
            return mentionedId;
          }
        }
      }
    }

    return null;
  }

  private static getMentionIdFromCssClass(cssClass: string): number | null {
    const mentIdResult: RegExpMatchArray | null = cssClass.match(
      MentionableNodeUtils.MENTIONED_CLASS_REGEXP
    );

    if (mentIdResult && mentIdResult.length === 2) {
      return Number(mentIdResult[1]);
    }

    return null;
  }
}
