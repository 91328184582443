import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MentionableParticipant } from './mentionable.participant';
import { FnMentionableSearchParticipant } from './mentionable.fn.search.participant';
import { MentionablePosition } from './mentionable.position';
import { MentionableNodeUtils } from './mentionable.node.utils';
import { MentionableProcessor } from './mentionable.processor';
import { KeyboardEventProcessor } from './keyboard-event-processor/keyboard.event.processor';
import { PlatformUtils } from '../common/platform-utils';
import { AndroidKeyboardEventProcessor } from './keyboard-event-processor/android.keyboard.event.processor';
import { SafariKeyboardEventProcessor } from './keyboard-event-processor/safari.keyboard.event.processor';
import { Participant } from '../models/participant';

@Directive({
  selector: '[appMentionable]'
})
export class MentionableDirective implements AfterViewInit {
  private el: ElementRef;
  private mentionableProcessor: MentionableProcessor | undefined;

  @Output()
  mention: EventEmitter<MentionableParticipant> =
    new EventEmitter<MentionableParticipant>();

  @Output()
  mentionRemoved: EventEmitter<number> = new EventEmitter<number>(); // emits removed participantId

  @Input() participants?: Participant[];

  @Input()
  fnSearchParticipant?: FnMentionableSearchParticipant;

  @Input()
  drawPosition: MentionablePosition = MentionablePosition.ABOVE;

  constructor(
    el: ElementRef,
    private dialog: MatDialog
  ) {
    this.el = el;
  }

  ngAfterViewInit(): void {
    this.mentionableProcessor = new MentionableProcessor(
      this.el,
      this.dialog,
      this.drawPosition,
      this.mention,
      this.mentionRemoved,
      this.fnSearchParticipant
    );

    if (PlatformUtils.isClientMobileAndroid()) {
      // will register to keyboard events
      new AndroidKeyboardEventProcessor(this.el, this.mentionableProcessor);
    } else if (PlatformUtils.isClientSafari()) {
      // will register to keyboard events
      new SafariKeyboardEventProcessor(this.el, this.mentionableProcessor);
    } else {
      // will register to keyboard events
      new KeyboardEventProcessor(this.el, this.mentionableProcessor);
    }
  }

  get mentionedParticipantsIds(): Array<number> {
    return MentionableNodeUtils.getMentionedParticipantsIds(
      this.el.nativeElement
    );
  }
}

@NgModule({
  declarations: [MentionableDirective],
  exports: [MentionableDirective]
})
export class MentionableDirectiveModule {}
