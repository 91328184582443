<mat-list
  #mentionsList
  class="dialog-mentions-list"
  *ngIf="participants && participants.length > 0"
>
  <mat-list-item
    *ngFor="let participant of participants"
    (click)="selectParticipant(participant)"
    class="mention-participant-item"
  >
    <img
      class="mention-participant-avatar"
      [src]="participant.getAvatarSrc()"
      (error)="onAvatarImageLoadingError($event)"
    />&nbsp;
    {{ participant.getVisibleName() }}
  </mat-list-item>
</mat-list>
