import { Pipe, type PipeTransform } from '@angular/core';
import { Participant } from 'src/app/models/participant';
import { Workspace } from 'src/app/models/workspace';

@Pipe({
  name: 'appSortParticipants',
  standalone: true
})
export class SortParticipantsPipe implements PipeTransform {
  transform<T extends Participant>(value: T[], workspace: Workspace): T[] {
    return value
      .sort((p1, p2) =>
        this.compareString(p1).localeCompare(this.compareString(p2))
      )
      .sort(
        (p1, p2) =>
          Number(this.isOwner(p2, workspace)) -
          Number(this.isOwner(p1, workspace))
      );
  }

  private compareString(p: Participant): string {
    const str = p.name ? p.name.concat(p.surname ?? '') : p.email;
    return str.toLowerCase();
  }

  private isOwner(participant: Participant, workspace: Workspace): boolean {
    return workspace.creatorId === participant.userId;
  }
}
