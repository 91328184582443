import { TermsOfUseModule } from './../../../pages/texts/terms-of-use/terms-of-use.module';
import { PrivacyPolicyModule } from './../../../pages/texts/privacy-policy/privacy-policy.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { DialogTextComponent } from './dialog-text.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [DialogTextComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    PrivacyPolicyModule,
    TermsOfUseModule
  ],
  exports: [DialogTextComponent]
})
export class DialogTextModule {}
