import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class EmptyContentComponent implements OnInit {
  @Input() whiteBackground = false;
  @Input() text = 'Nothing here yet';
  placeholderImage = 'assets/common/placeholder-image-gray-back.svg';
  constructor() {}

  ngOnInit(): void {
    // By design placeholder is different on white vs gray background
    this.placeholderImage = this.whiteBackground
      ? 'assets/common/placeholder-image-white-back.svg'
      : 'assets/common/placeholder-image-gray-back.svg';
  }
}
