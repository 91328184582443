import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewComponent,
  CalendarView,
  DateAdapter
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { CalendarEventsCount } from '../../../models/calendar.events.count';
import { CalendarEventType } from '../../../models/calendar.event.type';
import { ArrayUtils } from '../../../common/array-utils';
import { DateRange } from '../models/date.range';
import {
  CalendarFilter,
  CalendarFilterComponent
} from '../../../component/dialog/calendar-filter/calendar-filter.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomDateFormatter } from './custom-calendar-date-formatter';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../services/auth/auth.service';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../../component/dialog/dialog-confirm/dialog-confirm.component';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#6A6AD4',
    secondary: '#a5a5e0'
  },
  green: {
    primary: '#10662c',
    secondary: '#8ab798'
  }
};

@Component({
  selector: 'app-calendar-big',
  templateUrl: './calendar-big.component.html',
  styleUrls: ['./calendar-big.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class CalendarBigComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  currentDate!: Date;

  @Input()
  showsToolBar = true;

  @Input()
  eventsCounts!: Array<CalendarEventsCount>;

  @Input()
  filter!: CalendarFilter;

  @Output()
  calendarPeriodChanged: EventEmitter<DateRange> =
    new EventEmitter<DateRange>();

  @Output()
  calendarSelectedDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  @Output()
  calendarFilterChanged: EventEmitter<CalendarFilter> =
    new EventEmitter<CalendarFilter>();

  @ViewChild('monthCalendar')
  monthCalendar!: CalendarMonthViewComponent;

  view: CalendarView = CalendarView.Month;
  onRefresh = new Subject<void>();
  events: CalendarEvent[] = [];
  activeDate!: Date;
  activePeriod: DateRange | null = null;

  constructor(
    private dialog: MatDialog,
    private dateAdapter: DateAdapter,
    private authService: AuthService
  ) {}

  beforeViewCalendar(event: CalendarMonthViewBeforeRenderEvent): void {
    const dateRange = new DateRange(event.period.start, event.period.end);
    if (!this.activePeriod || !dateRange.equals(this.activePeriod)) {
      this.calendarPeriodChanged.emit(dateRange);
      this.activePeriod = dateRange;
    }
  }

  ngOnInit(): void {
    this.activeDate = this.currentDate;
    this._initEventsCount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !ArrayUtils.equals(
        changes.eventsCounts?.currentValue,
        changes.eventsCounts?.previousValue
      )
    ) {
      this._initEventsCount();
    }
  }

  ngOnDestroy(): void {
    this.calendarPeriodChanged.complete();
    this.calendarSelectedDateChanged.complete();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    this.calendarSelectedDateChanged.emit(date);
    this.activeDate = date;
    this.currentDate = date;
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.dayClicked({ date: event.start, events: [] });
  }

  openFilterDialog(): void {
    this.dialog
      .open(CalendarFilterComponent, {
        panelClass: 'filter-dialog-container',
        data: this.filter
      })
      .afterClosed()
      .subscribe((filter: CalendarFilter) => {
        if (filter && !filter.equals(this.filter)) {
          this.filter = filter;
          this.calendarFilterChanged.emit(this.filter);
        }
      });
  }

  onSwipeLeft(event: any): void {
    this.currentDate = this.dateAdapter.addMonths(this.currentDate, 1);
    this.monthCalendar.viewDate = this.currentDate;
    this.monthCalendar.refresh.next({});
  }

  onSwipeRight(event: any): void {
    this.currentDate = this.dateAdapter.subMonths(this.currentDate, 1);
    this.monthCalendar.viewDate = this.currentDate;
    this.monthCalendar.refresh.next({});
  }

  synchronize(): void {
    const message = `Would you like to export all tasks and events to your Google Calendar?`;
    const dialogData = new ConfirmDialogModel(message, 'Proceed', 'Cancel', '');
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: 'confirm-dialog-container',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        // available URL params for googleLoginUrl see here: https://googleapis.dev/nodejs/google-auth-library/latest/interfaces/GenerateAuthUrlOpts.html
        const googleLoginUrl = `https://accounts.google.com/o/oauth2/auth?client_id=${environment.googleCalendar.clientId}&redirect_uri=${environment.url}calendar/sync/google&response_type=code&scope=https://www.googleapis.com/auth/calendar&prompt=select_account&state=Bearer%20${this.authService.getToken()}`;
        window.open(googleLoginUrl, 'popup', 'width=500,height=500,');
      }
    });
  }

  get screenWidth(): number {
    return window.innerWidth;
  }

  private _initEventsCount(): void {
    if (this.eventsCounts) {
      this.events = this.eventsCounts.map(
        (eventsCount: CalendarEventsCount) => {
          const title =
            this.screenWidth >= 560
              ? `${eventsCount.count} ${this._getEventsTypeString(eventsCount.type)}`
              : '';
          return {
            allDay: true,
            color: colors.blue,
            draggable: false,
            start: eventsCount.date,
            end: eventsCount.date,
            title
          };
        }
      );
    }
  }

  private _getEventsTypeString(type: CalendarEventType): string {
    switch (type) {
      case CalendarEventType.WORKSPACE_EVENT:
        return 'Event(s)';

      case CalendarEventType.TASK:
        return 'Task(s)';
    }

    return 'Events and Tasks';
  }
}
