import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { Observable } from 'rxjs';
import { StompSubscription } from '@stomp/stompjs';
import { Notification } from '../../models/notification';

@Injectable({
  providedIn: 'root'
})
export class WebsocketSubscriptionService {
  public static readonly WS_QUEUE_NOTIFICATIONS_COUNT =
    '/user/queue/notifications-count';
  public static readonly WS_QUEUE_NOTIFICATIONS = '/user/queue/notifications';

  constructor(private websocketService: WebsocketService) {}

  subscribeToNotificationsCountQueue(
    callback: (count: number) => void
  ): Observable<StompSubscription> {
    return this.websocketService.subscribeToTopic(
      WebsocketSubscriptionService.WS_QUEUE_NOTIFICATIONS_COUNT,
      (message: string) => {
        const body = JSON.parse(message);
        callback(body.count);
      }
    );
  }

  subscribeToNotificationsQueue(
    callback: (notification: Notification) => void
  ): Observable<StompSubscription> {
    return this.websocketService.subscribeToTopic(
      WebsocketSubscriptionService.WS_QUEUE_NOTIFICATIONS,
      (message: string) => {
        const body = JSON.parse(message);
        const notification: Notification = new Notification().deserialize(body);
        callback(notification);
      }
    );
  }
}
