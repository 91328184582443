import { Component, Input, OnInit } from '@angular/core';
import { PostService } from '../../../../services/post/post.service';
import { Workspace } from '../../../../models/workspace';
import { FnMentionableSearchParticipant } from '../../../../directive/mentionable.fn.search.participant';
import { MentionableParticipant } from '../../../../directive/mentionable.participant';
import { Observable, Subscriber } from 'rxjs';
import {
  ExtendedParticipant,
  toExtendedParticipant
} from '../../../../models/participant';

@Component({
  selector: 'app-one-workspace-view-feed-news',
  templateUrl: './feed-news.component.html',
  styleUrls: ['./feed-news.component.scss']
})
export class FeedNewsComponent implements OnInit {
  @Input() workspace: Workspace | undefined;

  page = 0;
  throttle = 50;
  scrollDistance = 4;
  elem = 'mat-drawer-content';

  // Todo duplicate
  searchWorkspaceParticipants: FnMentionableSearchParticipant = (
    keyword: string
  ) => {
    return new Observable<Array<MentionableParticipant>>(
      (subscriber: Subscriber<Array<MentionableParticipant>>) => {
        keyword = keyword.trim();
        const participants: Array<ExtendedParticipant> =
          this.workspace?.participants.map(toExtendedParticipant) || [];
        const foundParticipants = participants.filter(
          (participant: ExtendedParticipant) => {
            return (
              participant.getId() &&
              participant
                .getVisibleName()
                .toLowerCase()
                .includes(keyword.toLowerCase())
            );
          }
        );

        subscriber.next(foundParticipants);
        subscriber.complete();
      }
    );
  };

  constructor(public postService: PostService) {}

  ngOnInit(): void {
    this.page = 0;
    if (this.workspace) {
      this.postService.clearPost();
      this.postService.getWorkspace(this.workspace);
    }
  }

  savePost(text: string, mentionedParticipantsIds: Array<number>): void {
    if (this.workspace) {
      this.postService
        .saveNewPost(this.workspace, text, mentionedParticipantsIds)
        .then();
    }
  }

  async onScrollDown() {
    const count = await this.postService.getCommentsWithChildrenPageCount();
    if (this.page >= count) {
      return;
    }
    this.postService.setPage(++this.page);
    this.postService.getCommentsWithChildren().then();
  }
}
