<ng-container *ngIf="color === 'white'">
  <div class="header-start-logo" [style]="{ background: color }">
    <a [routerLink]="['/']"
      ><img src="assets/first-page-layout/header/logo.png" alt=""
    /></a>
    <div class="help" (click)="showInfo()" fxHide.gt-md>
      How It Works
      <img
        style="width: 44px; height: 44px"
        src="assets/first-page-layout/header/info.png"
        alt=""
      />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="color !== 'white'">
  <div class="header-auth-logo" [style]="{ background: color }">
    <a [routerLink]="['/' + routes.workspaces]">
      <img src="assets/first-page-layout/header/logo-white.png" alt="" />
    </a>
  </div>
</ng-container>
