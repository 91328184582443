<ng-container *ngIf="participant">
  <div
    class="oneParticipant"
    [style.cursor]="canSelectItem ? 'pointer' : 'default'"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [class.hovered]="(isHovered || isTabletOrMobile) && canSelectItem"
    [class.active]="isSelected"
  >
    <div class="img">
      <app-avatar
        [src]="participant.photoUrl"
        [userIdentity]="participant.userId"
        [isClickable]="allowProfileNavigation"
      ></app-avatar>
    </div>
    <div class="info">
      <div class="name">
        {{ title }}
        <span class="owner-label" *ngIf="role === 'OWNER'">(Owner)</span>
        <span class="owner-label" *ngIf="role === 'MANAGER'">(Manager)</span>
      </div>

      <ng-container *ngIf="canEditRole; else elseRoleText">
        <input
          class="role role-editable"
          type="text"
          #roleInput
          [style.minWidth]="'150px'"
          (click)="$event.stopPropagation()"
          value="{{
            participant.workspaceRole ? participant.workspaceRole : ''
          }}"
          (focusout)="roleChanged.next(roleInput.value)"
          placeholder="Assign role or title"
        />
      </ng-container>
      <ng-template #elseRoleText>
        <div class="role role-text">
          {{
            participant.workspaceRole ? participant.workspaceRole : 'No Role'
          }}
        </div>
      </ng-template>
    </div>

    <span class="accessoryIcon">
      <ng-content></ng-content>
    </span>

    <span class="checked-icon material-icons">
      <mat-icon [svgIcon]="iconForParticipant"></mat-icon>
    </span>
  </div>
</ng-container>
