import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from './loading.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [LoadingComponent],
  imports: [CommonModule, NgxSpinnerModule, FlexLayoutModule],
  exports: [LoadingComponent]
})
export class LoadingModule {}
