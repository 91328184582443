import { Character } from './character';
import { DeviceSettings } from './device_settings';
import { Education } from './education';
import { Role } from './role';

export class User implements Deserializable {
  public static DEFAULT_AVATAR_SRC = 'assets/avatar/avatar.svg';

  age: string | undefined;
  characters: Character[] | undefined;
  educations: Education[] | undefined;
  city: string | undefined;
  deviceSettings: DeviceSettings[] | undefined;
  email: string | undefined;
  enabled: boolean | undefined;
  gender: string | undefined;
  identity: string | undefined;
  mailSubscribed: boolean | undefined;
  name: string | undefined;
  photoURL: string | undefined;
  photoThumbnailURL: string | undefined;
  photoUpdated: string | undefined;
  pushNotified: boolean | undefined;
  roles: Role[] = [];
  state: string | undefined;
  surname: string | undefined;
  userId: number | undefined;
  userInfoId: number | undefined;
  bio: string | undefined;
  vocalRange: string | undefined;
  websiteUrl: string | undefined | null;
  performanceSkills: string[] = [];
  theaterSkills: string[] = [];

  public static allAges(): string[] {
    return [
      'Child',
      'Early teen',
      'Late teen',
      'Young adult',
      'Adult',
      'Mature adult'
    ];
  }

  public static allGenders(): string[] {
    return [
      'Male',
      'Female',
      'Transgender Female',
      'Transgender Male',
      'Gender Variant/Non-Conforming',
      'Not Listed',
      'Prefer Not To Answer'
    ];
  }

  public static allStates(): string[] {
    return [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Nevada',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'Washington, D.C.',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Other'
    ];
  }

  public static allPerformanceSkills(): string[] {
    return [
      'Actor',
      'Comedian',
      'Dancer',
      'Improviser',
      'Model',
      'Opera',
      'Puppetry',
      'Singer',
      'Voice Over'
    ];
  }

  public static allTheatreSkills(): string[] {
    return [
      'Acting Teacher',
      'Agent',
      'Casting Director',
      'Choreographer',
      'Composer',
      'Costume Designer',
      'Director',
      'Hair/Makeup',
      'Lighting Designer',
      'Marketing / Business',
      'Musical Director',
      'Playwright',
      'Producer',
      'Scenic/Set Designer',
      'Sound Designer',
      'Stage Manager',
      'Technician',
      'Theatre Educator',
      'Vocal Coach'
    ];
  }

  public static allVocalRanges(): string[] {
    return [
      'Alto',
      'Baritone',
      'Bass',
      'Contralto',
      'Countertenor',
      'Mezzo-Soprano',
      'Soprano',
      'Tenor',
      'Treble/Boy Soprano'
    ];
  }

  constructor() {}

  deserialize(input: any): this {
    Object.assign(this, input);
    this.deserializeCharacters();
    this.deserializeEducations();
    this.deserializeRoles();
    return this;
  }

  private deserializeCharacters(): void {
    const characters: Character[] = [];
    this.characters?.forEach((value) => {
      characters.push(
        new Character(
          value.role,
          value.performanceLocation,
          value.performanceDate,
          value.characterId
        )
      );
    });
    this.characters = characters;
  }

  private deserializeEducations(): void {
    const educations: Education[] = [];
    this.educations?.forEach((value) => {
      educations.push(
        new Education(
          value.training,
          value.teacherOrSchool,
          value.date,
          value.educationId
        )
      );
    });
    this.educations = educations;
  }

  private deserializeRoles(): void {
    const roles: Role[] = [];
    this.roles.forEach((value) => {
      const newRole = new Role();
      newRole.role = value.role;
      roles.push(newRole);
    });
    this.roles = roles;
  }

  fullName(): string {
    return this.name + ' ' + this.surname;
  }

  get appRole(): Role {
    return this.roles[0];
  }

  subscription(): string {
    if (this.mailSubscribed) {
      return 'Mail Pushes';
    }

    if (this.pushNotified) {
      return 'Pushes';
    }

    return '';
  }
}

export interface Deserializable {
  deserialize(input: any): this;
}
