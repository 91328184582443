<ng-container
  infinite-scroll
  [infiniteScrollDistance]="4"
  [infiniteScrollThrottle]="50"
  [infiniteScrollContainer]="'mat-drawer-content'"
  [fromRoot]="true"
  (scrolled)="onScrollDown(currentTabStatus)"
>
  <ng-container *ngIf="this.type === allTypes.tabs">
    <mat-tab-group
      [animationDuration]="0"
      class="profile-tabs"
      selectedIndex="0"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab label="Open">
        <ng-container
          *ngIf="this.openedTasks.tasks.length > 0; else empty"
          [ngTemplateOutlet]="taskListTemplate"
          [ngTemplateOutletContext]="{ tasks: this.openedTasks.tasks }"
        ></ng-container>
      </mat-tab>
      <mat-tab label="Closed">
        <ng-container
          *ngIf="this.closedTasks.tasks.length > 0; else empty"
          [ngTemplateOutlet]="taskListTemplate"
          [ngTemplateOutletContext]="{ tasks: this.closedTasks.tasks }"
        ></ng-container>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <ng-container *ngIf="this.type === allTypes.buttons">
    <div class="block-size">
      <div class="parent-button-panel">
        <div class="button-panel">
          <div class="external-block"></div>

          <div>
            <button
              class="button-task-list"
              [class]="this.currentTabStatus === 'open' ? 'active' : ''"
              (click)="onLoadTasks('open')"
            >
              Open
            </button>

            <button
              class="button-task-list"
              [class]="this.currentTabStatus === 'closed' ? 'active' : ''"
              (click)="onLoadTasks('closed')"
            >
              Closed
            </button>
          </div>

          <div class="external-block group-button-operations">
            <!--                        <img src="assets/tasks/filter.png" alt="">-->
            <!--                        <img src="assets/tasks/sort.png" alt="">-->
          </div>
        </div>
      </div>

      <ng-container *ngIf="this.currentTabStatus === 'open'">
        <ng-container
          *ngIf="this.openedTasks.tasks.length > 0; else empty"
          [ngTemplateOutlet]="taskListTemplate"
          [ngTemplateOutletContext]="{ tasks: this.openedTasks.tasks }"
        >
        </ng-container>
      </ng-container>

      <ng-container *ngIf="this.currentTabStatus === 'closed'">
        <ng-container
          *ngIf="this.closedTasks.tasks.length > 0; else empty"
          [ngTemplateOutlet]="taskListTemplate"
          [ngTemplateOutletContext]="{ tasks: this.closedTasks.tasks }"
        >
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #taskListTemplate let-tasks="tasks">
  <div
    class="task-list"
    fxLayout="row"
    fxLayoutAlign="center none"
    [class]="this.type === allTypes.buttons ? 'taskListMode' : ''"
    infinite-scroll
  >
    <div class="task-cards" fxFlex="0 1 570px">
      <ng-container *ngFor="let task of tasks; trackBy: taskTrackByFn">
        <app-task-card
          [workspace]="workspace"
          [taskModel]="task"
          (loadTasksEvent)="onLoadTasks(currentTabStatus)"
          (taskChanged)="onTaskStatusChanged(task)"
        >
        </app-task-card>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #empty>
  <app-empty-content
    *ngIf="!loadInProgress; else loading"
    fxFlexFill
    fxLayoutAlign="center center"
  ></app-empty-content>
</ng-template>

<ng-template #loading>
  <app-loading *ngIf="loadInProgress"></app-loading>
</ng-template>

<app-float-button
  *ngIf="shouldDisplayAddButton"
  [callback]="openCreateTaskDialog.bind(this)"
></app-float-button>
