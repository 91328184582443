import { Subscription, timer } from 'rxjs';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { ParticipantsService } from 'src/app/services/workspace/participants.service';
import { debounce, finalize, map, switchMap, tap } from 'rxjs/operators';
import { FilterByFuseService } from 'src/app/services/fuzzy-search/filter-by-fuse.service';
import { Participant } from 'src/app/models/participant';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ParticipantsListComponent } from 'src/app/standalone/participants-list/participants-list.component';
import { NgFor, NgIf } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'app-participants-search',
  templateUrl: './participant-search.component.html',
  styleUrls: [
    '../dialog/add-update/add-update..scss',
    '../../pages/workspace/one-workspace-view/participants/participants.component.scss',
    './participant-search.component.scss'
  ],
  standalone: true,
  imports: [
    MatDialogModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    InfiniteScrollModule,
    ParticipantsListComponent,
    NgIf,
    NgFor,
    LoadingComponent
  ]
})
export class ParticipantSearchComponent implements OnInit, OnDestroy {
  @Output()
  selectParticipants = new EventEmitter<Participant[]>();

  @Input()
  @Optional()
  title = 'Add Participants';

  @Input()
  @Optional()
  showBackButton = false;

  @Input()
  @Optional()
  showSaveButton = false;

  @Input()
  @Optional()
  participants: Participant[] = [];

  @Input()
  workspaceId!: number;

  selectedParticipants: Participant[] = [];
  foundParticipants: Participant[] = [];
  loadInProgress = false;

  private searchLimit = 30;
  private searchPage = 0;
  private firstTimeLoading = true;
  private searchEvent$: EventEmitter<string> = new EventEmitter<string>();
  private searchEventSubscription: Subscription | undefined;

  @ViewChild('searchInput')
  private searchInput!: ElementRef;

  constructor(
    private readonly participantsService: ParticipantsService,
    private readonly searchFilterService: FilterByFuseService
  ) {}

  ngOnInit(): void {
    this.selectedParticipants = [...this.participants];

    this.searchForParticipants();

    // first time loading
    this.searchEvent$.next('');
  }

  ngOnDestroy(): void {
    this.searchEventSubscription?.unsubscribe();
    this.searchEventSubscription = undefined;
  }

  remove(item: Participant): void {
    const removedParticipants: Participant[] = [];

    this.selectedParticipants = this.selectedParticipants.filter(
      (_participant) => {
        const isEqual = _participant.email !== item.email;

        if (!isEqual) {
          removedParticipants.push(item);
        }

        return isEqual;
      }
    );

    this.foundParticipants = [
      ...this.foundParticipants,
      ...removedParticipants
    ];
  }

  onSearch(search: Event) {
    this.foundParticipants = [];
    this.searchPage = 0;
    this.searchEvent$.emit((<HTMLInputElement>search?.target)?.value);
  }

  onScrollDown(): void {
    this.searchPage = this.searchPage + 1;
    this.searchEvent$.emit(this.searchInput.nativeElement.value);
  }

  onSelectParticipants(): void {
    this.selectParticipants.emit(this.selectedParticipants);
  }

  get areAllParticipantsSelected(): boolean {
    return this.foundParticipants.length === 0;
  }

  selectAllParticipants(): void {
    this.selectedParticipants = [
      ...this.selectedParticipants,
      ...this.foundParticipants
    ].filter(
      (val, index, array) =>
        array.findIndex((arr) => arr.email === val.email) === index
    );

    this.foundParticipants = [];
  }

  onSelectParticipant(participant: Participant): void {
    this.selectedParticipants.push(participant);
    this.foundParticipants = this.foundParticipants.filter(
      (item) => item.email !== participant.email
    );
  }

  clearSelectedParticipants(): void {
    this.foundParticipants = this.selectedParticipants;
    this.selectedParticipants = [];
  }

  participantsTrackByFn(index: number, value: Participant): string {
    return value.email;
  }

  private searchForParticipants(): void {
    // search event
    this.loadInProgress = true;
    this.searchEventSubscription = this.searchEvent$
      .pipe(
        debounce(() => timer(this.firstTimeLoading ? 0 : 500)),
        switchMap((search) =>
          this.participantsService
            .searchForParticipantsForWorkspace(
              this.workspaceId,
              this.searchLimit,
              this.searchPage
            )
            .pipe(
              map((items) =>
                items.filter((item) => item.userId && item.userId !== 0)
              ), // filter out users without id first
              map((items) =>
                search.length > 0
                  ? this.searchFilterService.filterParticipants(items, search)
                  : items
              ), // use fuzzy search
              map((items) =>
                items.filter(
                  (item) =>
                    !this.selectedParticipants.find(
                      (participant) => item.email === participant.email
                    )
                )
              ) // do not show already selected
            )
        ),
        tap(() => (this.firstTimeLoading = false)),
        finalize(() => (this.loadInProgress = false))
      )
      .subscribe((_participants) => {
        _participants = _participants.filter(
          (val) =>
            !this.foundParticipants.find((_next) => val.email === _next.email)
        );
        this.foundParticipants.push(..._participants);
      });
  }
}
