import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalendarEventType } from '../../../models/calendar.event.type';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'app-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss']
})
export class CalendarFilterComponent implements OnInit {
  calendarFilterForm: FormGroup = new FormGroup({});

  constructor(
    @Inject(MAT_DIALOG_DATA) private filter: CalendarFilter,
    private dialogRef: MatDialogRef<CalendarFilterComponent>
  ) {}

  ngOnInit(): void {
    this.calendarFilterForm = new FormGroup({
      showEvents: new FormControl(this.filter.showEvents, [
        Validators.required
      ]),
      showTasks: new FormControl(this.filter.showTasks, [Validators.required])
    });
  }

  validateShowEvents(event: MatCheckboxChange): void {
    if (!event.checked) {
      const filter = this.createFilter();
      if (!filter.showTasks) {
        // cancel unchecking
        this.calendarFilterForm.controls.showEvents.setValue(true);
      }
    }
  }

  validateShowTasks(event: MatCheckboxChange): void {
    if (!event.checked) {
      const filter = this.createFilter();
      if (!filter.showEvents) {
        // cancel unchecking
        this.calendarFilterForm.controls.showTasks.setValue(true);
      }
    }
  }

  save(): void {
    const filter = this.createFilter();
    if (filter.showTasks || filter.showEvents) {
      this.dialogRef.close(filter);
    }
  }

  private createFilter(): CalendarFilter {
    return new CalendarFilter(
      this.calendarFilterForm.value.showEvents,
      this.calendarFilterForm.value.showTasks
    );
  }

  get screenWidth(): number {
    return window.innerWidth;
  }
}

export class CalendarFilter {
  showEvents: boolean;
  showTasks: boolean;

  constructor(showEvents: boolean, showTasks: boolean) {
    this.showEvents = showEvents;
    this.showTasks = showTasks;
  }

  equals(o: CalendarFilter): boolean {
    if (!o) {
      return false;
    }

    return this.showEvents === o.showEvents && this.showTasks === o.showTasks;
  }

  getCalendarEventType(): CalendarEventType {
    if (this.showEvents && this.showTasks) {
      return CalendarEventType.ALL;
    }

    if (this.showEvents) {
      return CalendarEventType.WORKSPACE_EVENT;
    }

    if (this.showTasks) {
      return CalendarEventType.TASK;
    }

    console.warn(
      'No events in CalendarFilter selected. ALL events will be returned'
    );
    return CalendarEventType.ALL;
  }
}
