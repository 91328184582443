import { NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule
} from '@angular/material/legacy-dialog';
import { PrivacyPolicyModule } from 'src/app/pages/texts/privacy-policy/privacy-policy.module';
import { TermsOfUseModule } from 'src/app/pages/texts/terms-of-use/terms-of-use.module';

@Component({
  selector: 'app-dialog-text',
  templateUrl: './dialog-text.component.html',
  styleUrls: ['./dialog-text.component.scss'],
  standalone: true,
  imports: [
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    PrivacyPolicyModule,
    TermsOfUseModule,
    NgIf
  ]
})
export class DialogTextComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogTextData) {}

  ngOnInit(): void {}
}

export interface DialogTextData {
  title: string;
  type: string;
}
