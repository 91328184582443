import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TwilioLogger {
  constructor() {}

  log(message?: any, ...optionalParams: any[]): void {
    if (environment.debugTwilio) {
      console.log('TWILIO LOG:---', message, optionalParams);
    }
  }
}
