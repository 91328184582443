import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { SidebarComponent } from './sidebar.component';
import { NgModule } from '@angular/core';
import { LogoHeaderModule } from 'src/app/component/logo-header/logo-header.module';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    MatDialogModule,
    MatButtonModule,
    LogoHeaderModule,
    RouterModule,
    CommonModule,
    FlexModule
  ],
  exports: [SidebarComponent]
})
export class SidebarModule {}
