import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { WorkspaceEventManager } from '../../../services/utils/workspace-event-manager';
import { AsyncSubject } from 'rxjs';
import { TaskCreateComponent } from '../../../pages/tasks/task-create/task-create.component';
import { TaskModel } from '../../../models/task.model';

@Component({
  selector: 'app-new-calendar-event',
  templateUrl: './new-calendar-event.component.html',
  styleUrls: [
    './new-calendar-event.component.scss',
    '../add-update/add-update..scss',
    '../dialog-confirm/dialog-confirm.component.scss'
  ]
})
export class NewCalendarEventComponent implements OnInit {
  private onEventCreated: AsyncSubject<number> = new AsyncSubject<number>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewCalendarEventComponent>,
    private workspaceEventManager: WorkspaceEventManager
  ) {}

  ngOnInit(): void {}

  openCreateEventDialog(): void {
    this.workspaceEventManager.createEvent(
      null,
      this.data.selectedDate,
      this.onWorkspaceEventCreated.bind(this)
    );
    this.dialogRef.close(this.onEventCreated);
  }

  onWorkspaceEventCreated(eventId: number): void {
    this.onEventCreated.next(eventId);
    this.onEventCreated.complete();
  }

  openCreateTaskDialog(): void {
    const dialogRef = this.dialog.open(TaskCreateComponent, {
      data: {
        dueDate: this.data.selectedDate.toISOString()
      } as Partial<TaskModel>,
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'task-dialog'
    });

    dialogRef.afterClosed().subscribe((isShouldBeReload) => {
      if (isShouldBeReload) {
        this.onEventCreated.next(-1);
        this.onEventCreated.complete();
      }
    });

    this.dialogRef.close(this.onEventCreated);
  }
}
