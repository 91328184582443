import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WorkspaceEventsService } from '../../../../../services/events/workspace-events.service';
import { DialogAddUpdateEventComponent } from '../../../../../component/dialog/add-update/event/dialog-add-update-event.component';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../../../../component/dialog/dialog-confirm/dialog-confirm.component';
import { GoogleCalendar, ICalendar } from 'datebook';
import { Attendance } from '../../../../../models/participant';
import { ProfileService } from '../../../../../services/user/profile/profile.service';
import { WorkspaceEvent } from '../../../../../models/workspaceEvent';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { GlobalConstants } from '../../../../../common/global-constants';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { UtilsService } from '../../../../../services/utils/utils.service';
import { ActiveWorkspaceService } from '../../../../../services/workspace/active-workspace.service';
import { UserReportingService } from 'src/app/services/user/user-reporting/user-reporting.service';
import { UiHelperService } from '../../../../../services/utils/ui-helper.service';
import { Workspace } from '../../../../../models/workspace';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  SnackbarService,
  SnackbarType
} from '../../../../../services/snackbar/snackbar.service';
import { CommonModule } from '@angular/common';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { AttendanceLabelComponent } from 'src/app/component/attendance-label/attendance-label.component';
import { AttendanceToggleComponent } from 'src/app/component/attendance-toggle/attendance-toggle.component';
import { AvatarsPreviewComponent } from 'src/app/component/avatars-preview/avatars-preview.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@Component({
  selector: 'app-one-event',
  templateUrl: './one-event.component.html',
  styleUrls: ['./one-event.component.scss'],
  animations: [
    trigger('cardFlip', [
      state('default', style({ transform: 'none' })),
      state('flipped', style({ transform: 'rotateX(180deg)' })),
      transition('default <=> flipped', [animate('400ms')])
    ])
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    AttendanceLabelComponent,
    AttendanceToggleComponent,
    AvatarsPreviewComponent,
    MatIconModule,
    RouterModule,
    MatButtonModule
  ]
})
export class OneEventComponent implements OnInit {
  @Input() workspaceEvent: WorkspaceEvent;
  @Input() workspace: Workspace | undefined;
  @Input() currentUserStatus: Attendance = Attendance.Unmarked;
  @Input() isCompact = false;
  @Input() eventRouteActive = true;
  @Input() showType = true;
  @Input() showWorkspaceTitle = false;
  @Output() clickedEvent = new EventEmitter<WorkspaceEvent>();
  @Output() eventUpdated = new EventEmitter<WorkspaceEvent>();
  @Output() eventDeleted = new EventEmitter<WorkspaceEvent>();

  dateFormat = 'ddd, MMM D, h:mm a';
  state: 'default' | 'flipped' = 'default';
  private workspaceId = 0;

  constructor(
    public dialog: MatDialog,
    public eventService: WorkspaceEventsService,
    public profileService: ProfileService,
    public activatedRoute: ActivatedRoute,
    public utils: UtilsService,
    public activeWorkspaceService: ActiveWorkspaceService,
    private reportingService: UserReportingService,
    private uiHelperService: UiHelperService,
    public spinner: NgxSpinnerService,
    private utilService: UtilsService,
    private snackBar: SnackbarService
  ) {
    this.workspaceEvent = new WorkspaceEvent(0);

    this.activatedRoute.params.subscribe((params) => {
      this.workspaceId = params.id ?? this.workspaceId;
    });
  }

  ngOnInit(): void {
    if (
      (!this.workspaceId || this.workspaceId === 0) &&
      this.workspace?.workspaceId
    ) {
      this.workspaceId = this.workspace.workspaceId;
    }
  }

  onClick(): void {
    this.clickedEvent.emit(this.workspaceEvent);
  }

  onStatusChanged(status: Attendance): void {
    this.currentUserStatus = status;
    switch (status) {
      case Attendance.Attending:
        this.onChangedToAttending();
        return;
    }
  }

  private onChangedToAttending(): void {
    this.flipToBack();
  }

  get eventRoute(): string {
    return (
      '/' +
      GlobalConstants.routes.workspaces +
      '/' +
      this.workspaceId +
      '/events/' +
      this.workspaceEvent.eventId
    );
  }

  get userSetAttendance(): boolean {
    return this.currentUserStatus !== Attendance.Unmarked;
  }

  get canDelete(): boolean {
    if (this.workspace) {
      return this.eventService.canManageEvent(this.workspace.participants);
    }
    return false;
  }

  get canEdit(): boolean {
    return this.canDelete;
  }

  showEditDialog(): void {
    this.dialog
      .open(DialogAddUpdateEventComponent, {
        panelClass: 'popup-dialog',
        data: {
          type: 'update',
          event: this.workspaceEvent,
          workspace: this.activeWorkspaceService.workspace
        }
      })
      .afterClosed()
      .subscribe((dialogResult: WorkspaceEvent) => {
        if (dialogResult) {
          this.eventUpdated.emit(dialogResult);
        }
      });
  }

  showReportDialog(): void {
    this.uiHelperService
      .showFreeFormDialog('Report event', 'Please specify report reason')
      .then((result) => {
        if (result) {
          this.reportingService
            .reportEvent(
              this.workspaceEvent.eventId,
              this.workspaceEvent.inviterId,
              result.description
            )
            .subscribe({
              next: () => this.snackBar.open('Event has been reported.'),
              error: () =>
                this.snackBar.open(
                  'Failed to send complaint! Please try again.',
                  SnackbarType.Error
                )
            });
        }
      });
  }

  showDeleteDialog(): void {
    const message = 'Do you really want to delete this event?';
    const data = new ConfirmDialogModel(message, 'Delete', 'Cancel');
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: 'confirm-dialog-container',
      data
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.eventService.deleteEvent(this.workspaceEvent).then(() => {
          this.eventDeleted.emit(this.workspaceEvent);
        });
      }
    });
  }

  addToICalendar(): void {
    const icalendar = new ICalendar(
      WorkspaceEvent.getCalendarConfig(this.workspaceEvent)
    );
    this.utilService.downloadICalendar(icalendar, this.workspaceEvent.name);
  }

  addToGoogle(): void {
    const google = new GoogleCalendar(
      WorkspaceEvent.getCalendarConfig(this.workspaceEvent)
    );
    window.open(google.render(), 'Workspace event calendar');
  }

  get workspaceTitle(): string {
    return this.workspace?.title || '';
  }

  private flipToBack(): void {
    if (this.state === 'flipped') {
      return;
    }
    this.state = 'flipped';
    setTimeout(() => this.flipToDefault(), 1500);
  }

  private flipToDefault(): void {
    this.state = 'default';
  }
}
