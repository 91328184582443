import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthLayoutModule } from './pages/auth-layout/auth-layout.module';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule
} from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth/auth.service';
import { AuthInterceptor } from './services/auth/AuthHttpInterceptor';
import { AuthGuard } from './guards/auth.guard';
import { EncodeHttpParamsInterceptor } from './services/auth/EncodeHttpParamsInterceptor';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { momentAdapterFactory } from './modules/calendar/calendar.module';
import { WorkspaceEventManager } from './services/utils/workspace-event-manager';
import * as Hammer from 'hammerjs';
import { IonicModule } from '@ionic/angular';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { MatIconModule } from '@angular/material/icon';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { velocity: 0.4, threshold: 20, direction: Hammer.DIRECTION_ALL }
  };

  buildHammer(element: HTMLElement): HammerManager {
    return new Hammer.Manager(element, {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_ALL }]]
    });
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HammerModule,

    // for some reason this module is very heavy about 1mb ...
    AuthLayoutModule,

    // this is a must to have modules in app-module, because not everything is fixed to lazy load and some
    // services are used where they should not be.
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatMomentModule,
    MatDialogModule,
    MatSelectModule, // because of events tab in showspaces
    MatChipsModule, // // because of events tab in showspaces
    MatDatepickerModule,
    NgxSpinnerModule,
    InfiniteScrollModule,
    MatIconModule,
    // ideally above modules should be removed from here and only added to child modules where needed.

    HttpClientModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: momentAdapterFactory
    }),
    IonicModule.forRoot(),
    ToastModule
  ],
  exports: [],
  providers: [
    AuthService,
    WorkspaceEventManager,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    MessageService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
