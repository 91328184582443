<div
  #tutorialView
  class="dialogView"
  *ngIf="anchorRect"
  [ngStyle]="{
    left: offset(currentPosition.left),
    bottom: offset(currentPosition.bottom),
    top: offset(currentPosition.top),
    right: offset(currentPosition.right)
  }"
>
  <span class="closeElement icon material-icons" mat-dialog-close>close</span>

  <div style="padding-top: 10px">
    <app-tutorial-hint
      [borderClass]="'none'"
      [title]="tutorialItems[currentItemIndex.value].title"
      [description]="tutorialItems[currentItemIndex.value].description"
    >
      <div
        buttons
        fxLayout="row"
        fxLayoutGap="12px"
        fxLayoutAlign="center center"
      >
        <button
          buttons
          class="back-button"
          *ngIf="!isLastItem && !isFirstItem"
          (click)="onBack()"
        >
          Back
        </button>
        <button buttons mat-ripple class="nextButton" (click)="onNext()">
          {{ isLastItem ? 'Got it!' : 'Next' }}
        </button>
      </div>
    </app-tutorial-hint>

    <div
      class="tail"
      [ngStyle]="{
        left: offset(tailPosition.left),
        bottom: offset(tailPosition.bottom),
        top: offset(tailPosition.top),
        right: offset(tailPosition.right)
      }"
    ></div>
  </div>
</div>

<div
  *ngIf="anchorRect"
  class="anchor-rect"
  [ngStyle]="{
    height: anchorRect.height + 'px',
    width: anchorRect.width + 'px',
    left: offset(anchorRect.left),
    bottom: offset(anchorRect.bottom),
    top: offset(anchorRect.top),
    right: offset(anchorRect.right)
  }"
></div>
