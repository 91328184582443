import { Injectable } from '@angular/core';
import Fuse, { FuseResult } from 'fuse.js';

/**
 * https://fusejs.io/
 */
@Injectable({
  providedIn: 'root'
})
export class FuzzySearchService {
  constructor() {}

  search(arrayData: any, keys: string[], pattern: string): FuseResult<any>[] {
    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys
    };

    return new Fuse(arrayData, options).search(pattern);
  }
}
