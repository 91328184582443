import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoadingModule } from './../../../../../component/loading/loading.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { NotificationDialogComponent } from './notification-dialog.component';
import { NgModule } from '@angular/core';
import { NotificationModule } from '../notification.module';
import { AvatarModule } from 'src/app/component/avatar/avatar.module';
import { OneNotificationModule } from '../one-notification/one-notification.module';

@NgModule({
  declarations: [NotificationDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    NotificationModule,
    AvatarModule,
    MatMenuModule,
    LoadingModule,
    OneNotificationModule,
    InfiniteScrollModule
  ],
  exports: [NotificationDialogComponent]
})
export class NotificationDialogModule {}
