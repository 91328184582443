import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { GlobalConstants } from '../../../common/global-constants';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '../../snackbar/snackbar.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private http: HttpClient,
    private snackbar: SnackbarService,
    private clipboard: Clipboard
  ) {}

  getUserById(userId: string | number): Promise<any> {
    return lastValueFrom(this.http.get(environment.url + 'users/' + userId));
  }

  shareLink(userId: number): void {
    const shareLink =
      environment.appUrl +
      GlobalConstants.routes.profileAndSettings +
      '/' +
      userId;
    if (this.clipboard.copy(shareLink)) {
      this.snackbar.open('Link copied to clipboard.');
    }
  }
}
