import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Workspace } from '../../../models/workspace';
import { MatDialog } from '@angular/material/dialog';
import { WorkspaceService } from '../../../services/workspace/workspace.service';
import { DialogAddUpdateWorkspaceComponent } from '../../../component/dialog/add-update/workspace/dialog-add-update-workspace.component';
import { GoogleCalendar, ICalendar } from 'datebook';
import { ParticipantsService } from '../../../services/workspace/participants.service';
import { ProfileService } from '../../../services/user/profile/profile.service';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../../component/dialog/dialog-confirm/dialog-confirm.component';
import { UserReportingService } from 'src/app/services/user/user-reporting/user-reporting.service';
import { GlobalConstants } from '../../../common/global-constants';
import { UtilsService } from '../../../services/utils/utils.service';
import { UiHelperService } from '../../../services/utils/ui-helper.service';
import {
  SnackbarService,
  SnackbarType
} from '../../../services/snackbar/snackbar.service';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-one-workspace',
  templateUrl: './one-workspace.component.html',
  styleUrls: ['../workspace.component.scss']
})
export class OneWorkspaceComponent {
  @Input() workspace: Workspace;
  @Output() updatedWorkspace = new EventEmitter<Workspace>();
  @Output() deletedWorkspace = new EventEmitter<Workspace>();

  routes = GlobalConstants.routes;

  constructor(
    public dialog: MatDialog,
    public workspaceService: WorkspaceService,
    public participantsService: ParticipantsService,
    private profileService: ProfileService,
    private snackBar: SnackbarService,
    public utils: UtilsService,
    private reportingService: UserReportingService,
    private uiHelperService: UiHelperService,
    private utilService: UtilsService
  ) {
    this.workspace = new Workspace();
  }

  onEditWorkspace(): void {
    const dialog = this.dialog.open(DialogAddUpdateWorkspaceComponent, {
      panelClass: 'popup-dialog',
      data: {
        type: 'update',
        workspace: this.workspace,
        showSaveButton: true
      }
    });
    dialog.afterClosed().subscribe((workspace) => {
      if (workspace) {
        this.updatedWorkspace.emit(workspace);
      }
    });
  }

  onLeaveWorkspace(): void {
    if (this.workspaceService.canLeaveWorkspace(this.workspace)) {
      const message = `Are you sure you want to leave workspace?`;
      const dialogData = new ConfirmDialogModel(message, 'Leave', 'Cancel');
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: dialogData,
        panelClass: 'confirm-dialog-container'
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        console.log(dialogResult);
        if (dialogResult) {
          this.leave();
        }
      });
    }
  }

  onReportWorkspace(): void {
    this.uiHelperService
      .showFreeFormDialog('Report showspace', 'Please specify report reason')
      .then((result) => {
        if (result) {
          this.reportingService
            .reportWorkspace(
              this.workspace.workspaceId!,
              this.workspace.creatorId,
              result.description
            )
            .subscribe({
              next: () => this.snackBar.open('Showspace has been reported.'),
              error: () =>
                this.snackBar.open(
                  'Failed to send complaint! Please try again.',
                  SnackbarType.Error
                )
            });
        }
      });
  }

  onDeleteWorkspace(): void {
    if (this.workspaceService.canDeleteWorkspace(this.workspace)) {
      const message = `Do you really want to delete workspace?`;
      const dialogData = new ConfirmDialogModel(message, 'Delete', 'Cancel');
      const dialogRef = this.dialog.open(DialogConfirmComponent, {
        data: dialogData,
        panelClass: 'confirm-dialog-container'
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        console.log(dialogResult);
        if (dialogResult) {
          this.workspaceService.deleteWorkspace(this.workspace).then(() => {
            this.deletedWorkspace.emit(this.workspace);
          });
        }
      });
    }
  }

  private leave(): void {
    if (
      this.workspace.workspaceId &&
      this.profileService.user?.email &&
      this.workspaceService.canLeaveWorkspace(this.workspace)
    ) {
      this.participantsService
        .leave(
          { emails: [this.profileService.user.email] },
          this.workspace.workspaceId
        )
        .then(() => {
          this.deletedWorkspace.emit(this.workspace);
        });
    }
  }

  addToICalendar(): void {
    const icalendar = new ICalendar(
      Workspace.getCalendarConfig(this.workspace)
    );
    this.utilService.downloadICalendar(icalendar, this.workspace.title);
  }

  addToGoogle(): void {
    const googleCalendar = new GoogleCalendar(
      Workspace.getCalendarConfig(this.workspace)
    );
    window.open(googleCalendar.render(), 'Workspace calendar');
  }
}
