import { Component, Input } from '@angular/core';
import { Post, PostComment } from '../../../../../models/post';
import { ProfileService } from '../../../../../services/user/profile/profile.service';
import { PostService } from '../../../../../services/post/post.service';
import { Workspace } from '../../../../../models/workspace';
import { FnMentionableSearchParticipant } from '../../../../../directive/mentionable.fn.search.participant';
import { UtilsService } from '../../../../../services/utils/utils.service';
import {
  SnackbarService,
  SnackbarType
} from '../../../../../services/snackbar/snackbar.service';
import { toExtendedParticipant } from 'src/app/models/participant';

@Component({
  selector: 'app-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss']
})
export class PostCommentComponent {
  @Input() postComment: PostComment | undefined;
  @Input() workspace: Workspace | undefined;
  @Input() fnSearchParticipant?: FnMentionableSearchParticipant;
  replayShow = false;
  editing = false;

  constructor(
    public profileService: ProfileService,
    public snackBar: SnackbarService,
    public postService: PostService,
    public utilsService: UtilsService
  ) {}

  savePostReply(text: string): void {
    if (this.postComment) {
      this.postService.saveNewPostReplay(this.postComment, text).then();
    }
  }

  replayCheck(): void {
    this.replayShow = true;
  }

  getDate(): string {
    return this.postComment ? Post.getDateCreated(this.postComment) : '';
  }

  notSave(): void {
    this.editing = false;
  }

  onCommentUpdated(): void {
    this.editing = false;
    if (this.postComment) {
      this.utilsService.highlightMentionedPostParticipants(
        this.postComment,
        this.workspace?.participants.map(toExtendedParticipant)
      );
    }
  }

  editingPostWrapper(post?: Post): void {
    this.editing = true;
    this.postService.updatePostId(post?.postId ?? 0);
  }

  onMessageCopied(): void {
    this.snackBar.open('Message copied', SnackbarType.Info, ['snackbar']);
  }
}
