import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tutorial-hint',
  templateUrl: './tutorial-hint.component.html',
  styleUrls: ['./tutorial-hint.component.scss']
})
export class TutorialHintComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() buttonText = 'Got it';
  @Input() borderClass: 'dashed' | 'none' | 'solid' = 'dashed';

  @Output()
  buttonClicked = new EventEmitter<any>();

  constructor() {}
}
