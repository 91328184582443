<div style="margin-bottom: 20px">
  <app-input-write-post-and-comment
    [white]="true"
    [callback]="savePost.bind(this)"
    [appPlaceholder]="'post'"
    [fullscreen]="true"
    [fnSearchParticipant]="searchWorkspaceParticipants"
  >
  </app-input-write-post-and-comment>
</div>
<div
  infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollThrottle]="throttle"
  [infiniteScrollContainer]="elem"
  [fromRoot]="true"
  (scrolled)="onScrollDown()"
>
  <ng-container *ngFor="let post of postService.posts">
    <app-one-workspace-view-post
      [postTopic]="post"
      [workspace]="workspace"
    ></app-one-workspace-view-post>
  </ng-container>
</div>

<ng-container *ngIf="postService.posts.length === 0">
  <app-loading *ngIf="postService.loadInProgress; else empty"></app-loading>
</ng-container>

<ng-template #empty>
  <app-empty-content
    fxFlexFill
    fxLayoutAlign="center center"
  ></app-empty-content>
</ng-template>
