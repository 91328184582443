import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Priority, TaskModel } from 'src/app/models/task.model';
import { environment } from 'src/environments/environment';
import { Task } from '../../models/task';
import { TaskInfo } from '../../models/task.info';
import { TaskPriority } from '../../models/taskPriority';
import { TaskAssignee } from '../../models/task.assignee';

interface TasksResponse {
  content: TaskInfo[];
  hasNext: boolean;
}

type TaskDelete = {
  taskIds: Array<number>;
};

type PageableTaskList = {
  hasNext: boolean;
  tasks: Array<TaskModel>;
};

export type TaskLoadStatus = 'open' | 'closed';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private readonly taskBaseUrl: string = environment.url + 'tasks';

  constructor(private http: HttpClient) {}

  addAssigneesToTask(
    taskId: number,
    assignees: Partial<TaskAssignee>[]
  ): Observable<any> {
    const body = { userIds: assignees.map((ta) => ta.userId) };
    return this.http
      .post(this.taskBaseUrl + '/' + taskId + '/assignees', body)
      .pipe(
        catchError((err) =>
          this.handleError('add task assignees', assignees, err)
        )
      );
  }

  removeAssigneesToTask(
    taskId: number,
    assignees: TaskAssignee[]
  ): Observable<any> {
    const body = { userIds: assignees.map((ta) => ta.userId) };
    return this.http
      .post(this.taskBaseUrl + '/' + taskId + '/assignees/delete', body)
      .pipe(
        catchError((err) =>
          this.handleError('remove task assignees', assignees, err)
        )
      );
  }

  createNewTask(newTask: TaskModel): Observable<TaskModel> {
    return this.http.post<TaskModel>(this.taskBaseUrl, newTask).pipe(
      mergeMap((_task) => {
        const ids = newTask.assignees?.map((_val) => _val.userId);
        return this.http.post<TaskModel>(
          `${this.taskBaseUrl}/${_task.id}/assignees`,
          { userIds: ids }
        );
      }),
      catchError((_err) => this.handleError('createNewTask', newTask, _err))
    );
  }

  closeTask(taskId: number): Observable<boolean> {
    return this.http
      .put(this.taskBaseUrl + '/' + taskId + '/complete', null)
      .pipe(
        map(() => true),
        catchError((err) => this.handleError('close task', false, err))
      );
  }

  openTask(taskId: number): Observable<boolean> {
    return this.http
      .put(this.taskBaseUrl + '/' + taskId + '/reopen', null)
      .pipe(
        map(() => false),
        catchError((err) => this.handleError('reopen task', true, err))
      );
  }

  markCompletedAsAssignee(taskId: number): Observable<boolean> {
    return this.http
      .put(this.taskCompletionByAssigneeUrl(taskId, true), null)
      .pipe(
        map(() => true),
        catchError((err) =>
          this.handleError('mark task completed as assignee', false, err)
        )
      );
  }

  markUncompletedAsAssignee(taskId: number): Observable<boolean> {
    return this.http
      .put(this.taskCompletionByAssigneeUrl(taskId, false), null)
      .pipe(
        map(() => false),
        catchError((err) =>
          this.handleError('mark task uncompleted as assignee', true, err)
        )
      );
  }

  markTaskForAssignee(
    taskId: number,
    assigneeId: number,
    completed: boolean
  ): Observable<number | null> {
    return completed
      ? this.completeTaskForAssignee(taskId, assigneeId)
      : this.uncompleteTaskForAssignee(taskId, assigneeId);
  }

  completeTaskForAssignee(
    taskId: number,
    assigneeId: number
  ): Observable<number | null> {
    return this.http
      .put(this.completeTaskForAssigneeUrl(taskId, assigneeId, true), null)
      .pipe(
        map(() => assigneeId),
        catchError((err) =>
          this.handleError('complete task for assignee', null, err)
        )
      );
  }

  uncompleteTaskForAssignee(
    taskId: number,
    assigneeId: number
  ): Observable<number | null> {
    return this.http
      .put(this.completeTaskForAssigneeUrl(taskId, assigneeId, false), null)
      .pipe(
        map(() => assigneeId),
        catchError((err) =>
          this.handleError('uncomplete task for assignee', null, err)
        )
      );
  }

  updateTask(updateTask: TaskModel): Observable<TaskModel> {
    return this.http
      .put<TaskModel>(this.taskBaseUrl + '/' + updateTask.id, updateTask)
      .pipe(
        catchError((_err) =>
          this.handleError('createNewTask', updateTask, _err)
        )
      );
  }

  deleteTask(deleteTask: TaskModel): Observable<TaskModel> {
    return this.http
      .post<TaskModel>(this.taskBaseUrl + '/delete', {
        taskIds: [deleteTask.id]
      } as TaskDelete)
      .pipe(
        catchError((_err) => this.handleError('deleteTask', deleteTask, _err))
      );
  }

  getAllTasks(
    page: number = 0,
    limit: number = 20,
    status: TaskLoadStatus,
    workspaceId?: number
  ): Observable<PageableTaskList | undefined> {
    let params = { page, limit, status: status === 'open' };

    if (workspaceId) {
      params = Object.assign(params, { workspaceId });
    }

    return this.http.get<TasksResponse>(this.taskBaseUrl, { params }).pipe(
      map((_resp) => {
        const tasks: Array<TaskModel> = _resp.content.map((_task) =>
          this.toModel(_task)
        );

        return {
          tasks,
          hasNext: _resp.hasNext
        } as PageableTaskList;
      }),
      catchError((_err) => this.handleError('getAllTasks', undefined, _err))
    );
  }

  getTaskById(id: number): Observable<Task> {
    return this.http.get(`${this.taskBaseUrl}/${id}`).pipe(
      map((res: any) => {
        return Task.fromJson(res);
      })
    );
  }

  private taskCompletionByAssigneeUrl(
    taskId: number,
    completed: boolean
  ): string {
    const complete = completed ? '/complete' : '/reopen';
    return this.taskBaseUrl + '/' + taskId + '/assignee' + complete;
  }

  private completeTaskForAssigneeUrl(
    taskId: number,
    assigneeId: number,
    completed: boolean
  ): string {
    const complete = completed ? '/complete' : '/reopen';
    return (
      this.taskBaseUrl + '/' + taskId + '/assignee/' + assigneeId + complete
    );
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }

  private toModel(fromResponse: TaskInfo): TaskModel {
    return {
      id: fromResponse.id,
      title: fromResponse.title,
      description: fromResponse.description,
      dueDate: fromResponse.dueDate,
      priority: this.getTaskPriorityAsPriority(fromResponse.priority),
      showspace: fromResponse.workspaceTitle,
      assignees: fromResponse.assignees,
      isOpen: fromResponse.opened,
      canManage: fromResponse.canManage
    };
  }

  getTaskPriorityAsPriority(priority: TaskPriority): Priority {
    switch (priority) {
      case TaskPriority.LOW:
        return Priority.LOW;
      case TaskPriority.MEDIUM:
        return Priority.MEDIUM;
      case TaskPriority.HIGH:
        return Priority.HIGH;
    }
    return Priority.NONE;
  }
}
