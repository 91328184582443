<ng-container *ngIf="events.length === 0">
  <app-loading *ngIf="loadInProgress; else empty"></app-loading>
</ng-container>

<ng-template #empty>
  <app-empty-content
    fxFlexFill
    fxLayoutAlign="center center"
  ></app-empty-content>
</ng-template>

<div
  class="workspace-events"
  infinite-scroll
  [infiniteScrollDistance]="scrollDistance"
  [infiniteScrollThrottle]="throttle"
  [infiniteScrollContainer]="elem"
  [fromRoot]="true"
  (scrolled)="onScrollDown()"
>
  <ng-container *ngIf="events.length !== 0">
    <div
      *ngFor="let workspaceEvent of events"
      [id]="'workspace-event-' + workspaceEvent.eventId"
      fxLayout="column"
    >
      <app-one-event
        (eventDeleted)="onEventDeleted($event)"
        [workspaceEvent]="workspaceEvent"
        [workspace]="this.oneWorkspaceService.workspace"
        [currentUserStatus]="eventsService.currentUserStatusFor(workspaceEvent)"
      >
      </app-one-event>
    </div>
  </ng-container>
</div>

<app-float-button
  *ngIf="
    profile.isAdminOrManager(
      this.oneWorkspaceService.workspace?.participants ?? []
    )
  "
  [callback]="openAddEvent.bind(this)"
></app-float-button>
