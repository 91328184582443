import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { CalendarEventsCount } from '../../../models/calendar.events.count';
import { DateRange } from '../models/date.range';
import { DateUtils } from '../../../common/date.utils';
import { MatDialog } from '@angular/material/dialog';
import { NewCalendarEventComponent } from '../../../component/dialog/new-calendar-event/new-calendar-event.component';
import { CalendarEventsListComponent } from '../calendar-events-list/calendar.events.list.component';
import { CalendarFilter } from '../../../component/dialog/calendar-filter/calendar-filter.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  selectedDate: Date = DateUtils.atStartOfDate(new Date());
  eventsCounts: Array<CalendarEventsCount> = [];
  selectedPeriod: DateRange | null = null;
  selectedFilter: CalendarFilter = new CalendarFilter(true, true);

  @ViewChild('calendarEventsList')
  calendarEventsListComponent!: CalendarEventsListComponent;

  constructor(
    private calendarService: CalendarService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  onPeriodChange(newPeriod: DateRange): void {
    this.selectedPeriod = newPeriod;
    this._loadEventsCounts();
  }

  onSelectedDateChange(newDate: Date): void {
    this.selectedDate = newDate;
  }

  onFilterChange(filter: CalendarFilter): void {
    this.selectedFilter = filter;
    this._loadEventsCounts();
  }

  onCreateEvent(): void {
    this.dialog
      .open(NewCalendarEventComponent, {
        panelClass: 'confirm-dialog-container',
        data: {
          selectedDate: this.selectedDate
        }
      })
      .afterClosed()
      .subscribe((eventObservable: EventEmitter<number>) => {
        eventObservable.subscribe((eventId: number) => {
          this.calendarEventsListComponent?.reloadData();
          if (this.selectedPeriod) {
            this._loadEventsCounts();
          }
        });
      });
  }

  onCalendarReload(): void {
    this._loadEventsCounts();
    this.calendarEventsListComponent?.reloadData();
  }

  private _loadEventsCounts(): void {
    if (this.selectedPeriod) {
      this.calendarService
        .getCalendarEventsCount(
          this.selectedPeriod.start,
          this.selectedPeriod.end,
          this.selectedFilter.getCalendarEventType()
        )
        .subscribe({
          next: (eventsCounts: Array<CalendarEventsCount>) => {
            this.eventsCounts = eventsCounts;
            this.changeDetector.detectChanges();
          },
          error: () => {
            this.eventsCounts = [];
          }
        });
    }
  }
}
