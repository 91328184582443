import { Injectable } from '@angular/core';
import { Workspace } from '../../models/workspace';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddUpdateEventComponent } from '../../component/dialog/add-update/event/dialog-add-update-event.component';
import { WorkspaceEvent } from '../../models/workspaceEvent';
import { DialogAddEventParticipantsComponent } from '../../component/dialog/add-update/participants/event/dialog-add-event-participants.component';
import { EventBusNames, EventBusService } from '../event-bus/event-bus.service';
import { DateUtils } from '../../common/date.utils';
import { ProfileService } from '../user/profile/profile.service';

@Injectable()
export class WorkspaceEventManager {
  constructor(
    private dialog: MatDialog,
    private eventBusService: EventBusService,
    private profileService: ProfileService
  ) {}

  createEvent(
    workspace: Workspace | undefined | null,
    startDate: Date | null,
    callback: ((eventId: number) => void) | null
  ): void {
    const workspaceEvent: WorkspaceEvent = new WorkspaceEvent(
      workspace?.workspaceId || -1
    );
    workspaceEvent.inviterId = this.profileService.user?.userId;
    if (startDate) {
      workspaceEvent.dateStart = startDate.toISOString();
      workspaceEvent.dateEnd =
        DateUtils.atStartOfNextDay(startDate).toISOString();
    }

    // Step 1: creating event dialog
    this.dialog
      .open(DialogAddUpdateEventComponent, {
        panelClass: 'popup-dialog',
        data: {
          type: 'add',
          event: workspaceEvent,
          workspace
        }
      })
      .afterClosed()
      .subscribe((event: WorkspaceEvent | undefined) => {
        // Step 2: adding participants dialog
        if (event) {
          this.dialog
            .open(DialogAddEventParticipantsComponent, {
              panelClass: 'popup-dialog',
              data: {
                event,
                participants: [],
                embedded: true
              }
            })
            .afterClosed()
            .subscribe((newEvent: WorkspaceEvent | undefined) => {
              if (newEvent) {
                // if event is undefined then it means the user cancelled the operation
                if (newEvent.participants?.length > 0) {
                  this.eventBusService.emit({
                    name: EventBusNames.workspaceEvent
                  });
                  if (callback) {
                    callback(newEvent.eventId);
                  }
                }
              } else {
                // no participants has been added at that step
                if (callback) {
                  callback(event.eventId);
                }
              }
            });
        }
      });
  }
}
