import { Component, Input, OnInit } from '@angular/core';
import { Workspace } from '../../../../models/workspace';
import { WorkspaceService } from '../../../../services/workspace/workspace.service';
import { Participant } from '../../../../models/participant';
import { ParticipantsService } from '../../../../services/workspace/participants.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { OneWorkspaceService } from '../../../../services/workspace/one-workspace.service';
import { DialogAddParticipantsComponent } from '../../../../component/dialog/add-update/participants/workspace/dialog-add-participants.component';
import { MatDialog } from '@angular/material/dialog';
import { ProfileService } from '../../../../services/user/profile/profile.service';
import { WorkspaceInvite } from '../../../../models/workspaceInvite';
import { UtilsService } from '../../../../services/utils/utils.service';
import { SnackbarService } from '../../../../services/snackbar/snackbar.service';
import { capitalize } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ParticipantsGroup } from './group-select/group-select.component';
import { ParticipantItem } from 'src/app/pages/tasks/task-details/one-participant/participant.model';

const GROUP_QUERY_KEY = 'group';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit {
  @Input() workspace: Workspace | undefined;

  selectedParticipants: Set<Participant> = new Set();

  selectedGroup$: Observable<string> = this.route.queryParams.pipe(
    map((params) => params[GROUP_QUERY_KEY] ?? null)
  );

  sentInvites: string[] = [];
  canEditParticipants = false;

  constructor(
    public workspaceService: WorkspaceService,
    private participantsService: ParticipantsService,
    private clipboard: Clipboard,
    private snackBar: SnackbarService,
    public dialog: MatDialog,
    public profile: ProfileService,
    public oneWorkspaceService: OneWorkspaceService,
    public utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (this.workspace?.participants) {
      this.canEditParticipants = this.profile.isAdminOrManager(
        this.workspace.participants
      );
    }
  }

  onGroupSelected(group: ParticipantsGroup): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { group: group.value }
    });
  }

  updateRole(value: string, participant: ParticipantItem): void {
    if (!this.workspace?.workspaceId) {
      return;
    }

    const prevValue = participant.workspaceRole;
    participant.workspaceRole = value?.trim();

    if (prevValue !== participant.workspaceRole) {
      const data = {
        participants: [
          {
            email: participant.email,
            workspaceRole: capitalize(participant.workspaceRole)
          }
        ]
      };

      this.participantsService
        .updateUserRole(data, this.workspace.workspaceId)
        .then();
    }
  }

  clickRow(participant: Participant): void {
    if (this.selectedParticipants.has(participant)) {
      this.selectedParticipants.delete(participant);
    } else {
      this.selectedParticipants.add(participant);
    }
  }

  isWorkspaceCreator(participant: Participant): boolean {
    if (this.workspace) {
      return participant.userId === this.workspace.creatorId;
    }
    return false;
  }

  private checkedEmails(): string[] {
    return Array.from(this.selectedParticipants).map((p) => {
      return p.email;
    });
  }

  private clearChecked(): void {
    this.selectedParticipants.clear();
  }

  copyEmails(): void {
    if (this.clipboard.copy(this.checkedEmails().join(' '))) {
      this.clearChecked();
      this.snackBar.open('Emails copied');
    }
  }

  deleteParticipants(): void {
    if (
      this.workspace &&
      this.workspace.workspaceId &&
      this.workspaceService.canManageWorkspace(this.workspace.participants)
    ) {
      this.participantsService
        .leave({ emails: this.checkedEmails() }, this.workspace.workspaceId)
        .then(() => {
          this.refreshWorkspaceData();
        });
    }
  }

  openDialog(): void {
    if (this.workspace) {
      this.dialog.open(DialogAddParticipantsComponent, {
        panelClass: ['popup-dialog', 'popup-dialog-participants'],
        data: {
          workspaceId: this.workspace.workspaceId,
          workspaceParticipants: this.workspace.participants,
          showsAddButton: true
        }
      });
    }
  }

  isCanAddManager(participants: Participant[]): boolean {
    return (
      this.profile.isAdminOrManager(participants) &&
      this.isManagers(false) &&
      this.allRegistered()
    );
  }

  isCanRevokeManger(participants: Participant[]): boolean {
    return (
      this.profile.isAdminOrManager(participants) &&
      this.isManagers(true) &&
      this.allRegistered()
    );
  }

  private isManagers(isManager: boolean): boolean {
    const arr = [...this.selectedParticipants];
    if (isManager) {
      return arr.every((p) => p.workspaceManager);
    }
    return arr.every((p) => !p.workspaceManager);
  }

  private allRegistered(): boolean {
    return [...this.selectedParticipants].every((p) => p.userId);
  }

  assignAsManager(): void {
    if (this.workspace?.workspaceId) {
      this.workspaceService
        .assignAsManagers(
          this.workspace.workspaceId,
          [...this.selectedParticipants].map((p) => p.userId)
        )
        .then(() => this.refreshWorkspaceData());
    }
  }

  revokeManager(): void {
    if (this.workspace?.workspaceId) {
      this.workspaceService
        .revokeManagers(
          this.workspace.workspaceId,
          [...this.selectedParticipants].map((p) => p.userId)
        )
        .then(() => {
          this.refreshWorkspaceData();
        });
    }
  }

  canResentInviteTo(participant: Participant): boolean {
    return (
      this.profile.isAdminOrManager(this.workspace?.participants ?? []) &&
      !participant.userId
    );
  }

  hasSentInviteTo(email: string): boolean {
    return this.sentInvites.includes(email);
  }

  onResendInvite(event: MouseEvent, participant: Participant): void {
    event.stopPropagation();
    if (this.hasSentInviteTo(participant.email)) {
      return;
    }

    this.sentInvites.push(participant.email);

    if (this.workspace?.workspaceId) {
      const invite: WorkspaceInvite = {
        emails: [participant.email]
      };

      this.participantsService
        .resendInvite(invite, this.workspace.workspaceId)
        .then(() => {
          this.snackBar.open(`Invite to ${participant.email} is resent!`);
        });
    }
  }

  private refreshWorkspaceData(): void {
    this.clearChecked();

    if (this.workspace && this.workspace.workspaceId) {
      this.oneWorkspaceService
        .getWorkspaceById(this.workspace.workspaceId)
        .then();
    }
  }

  isOwner(participant: Participant): boolean {
    return this.workspace?.creatorId === participant.userId;
  }

  isManager(participant: Participant): boolean {
    return !this.isOwner(participant) && participant.workspaceManager;
  }

  participantRole(participant: Participant): 'OWNER' | 'MANAGER' | undefined {
    if (this.isOwner(participant)) {
      return 'OWNER';
    } else if (this.isManager(participant)) {
      return 'MANAGER';
    }
    return undefined;
  }
}
