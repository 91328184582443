import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { Participant } from 'src/app/models/participant';
import { OneParticipantComponent } from 'src/app/standalone/one-participant/one-participant.component';

@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.scss'],
  standalone: true,
  imports: [CommonModule, OneParticipantComponent, MatButtonModule]
})
export class ParticipantsListComponent<T extends Participant> {
  @Input() titleText = '';
  @Input() availableParticipants: T[] = [];
  @Input() selectedParticipants: T[] = [];
  @Input() subtitleOption: 'none' | 'email' | 'title' = 'email';

  /**
   * If true, then user will navigate to participant profile while clicking on avatar
   */
  @Input() allowProfileNavigation = true;

  @Output()
  selectParticipant = new EventEmitter<T>();

  constructor() {}

  clickRow(participant: T): void {
    this.selectParticipant.emit(participant);
  }

  subtitleText(participant: T): string {
    switch (this.subtitleOption) {
      case 'none':
        return '';
      case 'email':
        return participant.email ? participant.email.toLowerCase() : '';
      case 'title':
        return participant.workspaceRole
          ? participant.workspaceRole.toUpperCase()
          : '';
    }
  }
}
