import { Component, Input } from '@angular/core';
import { TaskAssignee } from 'src/app/models/task.assignee';

@Component({
  selector: 'app-assignee-list',
  templateUrl: './assignee-list.component.html',
  styleUrls: [
    './assignee-list.component.scss',
    '../../../../component/dialog/add-update/participants/common-dialog-participants.component.scss',
    '../../../../component/participant-search/participant-search.component.scss'
  ]
})
export class AssigneeListComponent {
  @Input()
  assignees: TaskAssignee[] = [];

  assigneesTrackByFn(index: number, assignee: TaskAssignee): string {
    return assignee.email;
  }
}
