import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TaskCardComponent } from './task-card.component';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [SharedModule, MatIconModule, MatButtonModule, RouterModule],
  declarations: [TaskCardComponent],
  exports: [TaskCardComponent]
})
export class TaskCardModule {}
