import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { ParticipantsService } from '../../../../../services/workspace/participants.service';
import { OneWorkspaceService } from '../../../../../services/workspace/one-workspace.service';
import { UtilsService } from '../../../../../services/utils/utils.service';
import { FilterByFuseService } from '../../../../../services/fuzzy-search/filter-by-fuse.service';
import { CommonDialogParticipantsComponent } from '../common-dialog-participants.component';
import { TutorialHint } from '../../../../tutorial-hint/tutorial-hint';
import { TutorialsStorageKeys } from '../../../../../common/tutorials-storage-keys';
import { SnackbarService } from '../../../../../services/snackbar/snackbar.service';
import { finalize } from 'rxjs';
import { ParticipantItem } from 'src/app/pages/tasks/task-details/one-participant/participant.model';
import { Participant } from 'src/app/models/participant';

@Component({
  selector: 'app-dialog-add-participants',
  templateUrl: './dialog-add-participants.html',
  styleUrls: [
    '../../add-update..scss',
    '../../../../../pages/workspace/one-workspace-view/participants/participants.component.scss',
    '../common-dialog-participants.component.scss'
  ]
})
export class DialogAddParticipantsComponent
  extends CommonDialogParticipantsComponent
  implements OnInit
{
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  @Input() showsAddButton = true;
  @Input() closeButtonNeeded = true;
  @Input() contentMaxWidth?: string;
  @Input() needsShowTutorial = false;
  @Input() showTitleOnSmallDeviceOnly = false;

  readonly tutorialHint = new TutorialHint(
    'Invite Cast & Crew',
    'Once invited, participants have access to everything. Assign show managers to run attendance'
  );

  loadInProgress = false;
  currentWorkspaceParticipants: Participant[] = [];
  workspaceId = 0;

  public titleText = `You've recently invited`;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    @Optional() private dialogRef: MatDialogRef<DialogAddParticipantsComponent>,
    public oneWorkspaceService: OneWorkspaceService,
    private participantsService: ParticipantsService,
    private filterByFuse: FilterByFuseService,
    private snackBar: SnackbarService,
    private utils: UtilsService
  ) {
    super();
    if (this.data) {
      this.previouslyInvitedPeople =
        this.data.invitedPeople !== undefined ? this.data.invitedPeople : [];
      this.showsAddButton = this.data.showsAddButton;
      this.currentWorkspaceParticipants = this.data.workspaceParticipants;
      this.workspaceId = data.workspaceId;
      this.closeButtonNeeded = true;
    }
  }

  markTutorialSeen(): void {
    localStorage.setItem(
      TutorialsStorageKeys.seenAddParticipantsTutorial,
      'true'
    );
  }

  get seenTutorial(): boolean {
    return (
      localStorage.getItem(TutorialsStorageKeys.seenAddParticipantsTutorial) ===
      'true'
    );
  }

  ngOnInit(): void {
    this.participantsService
      .userInvitedByMe()
      .then((invitedPeople: Participant[]) => {
        this.previouslyInvitedPeople = invitedPeople;
      });
  }

  add(valueEvent: string): void {
    let inputValue = '';
    const values = valueEvent.split(' ');
    for (const value of values) {
      if (this.utils.isEmail(value)) {
        const email = (value || '').trim();
        // Add our participants
        if (
          email &&
          this.isNotExistsOnParticipants(email) &&
          this.isNotAlreadyInTheList(email, this.currentWorkspaceParticipants)
        ) {
          this.participants.push({
            email,
            userId: 0,
            workspaceId: 0,
            workspaceManager: false,
            isCastMember: false,
            enabled: false
          });
        } else {
          this.snackBar.open(email + ' is already in participants list');
        }
      } else if (value) {
        inputValue += value + ' ';
      }
    }

    // Reset the input value
    if (this.inputNewEmail?.nativeElement.value) {
      this.inputNewEmail.nativeElement.value = inputValue;
    }
  }

  isNotExistsOnParticipants(email: string): boolean {
    const found = this.participants.find((participant: ParticipantItem) => {
      return participant.email === email;
    });
    return found === undefined;
  }

  addParticipants(): void {
    if (!this.loadInProgress) {
      this.loadInProgress = true;

      const data = {
        participants: this.participants
      };

      this.participantsService
        .invite(data, this.workspaceId)
        .pipe(finalize(() => (this.loadInProgress = false)))
        .subscribe(() => {
          this.oneWorkspaceService.getWorkspaceById(this.workspaceId).then();
          this.dialogRef.close();
        });
    }
  }

  filteredPeople(): Participant[] {
    const value = this.inputNewEmail?.nativeElement.value;

    const participants = this.previouslyInvitedPeople
      .filter((p) => this.isNotAlreadyInInviteList(p))
      .filter((p) =>
        this.isNotAlreadyInTheList(p.email, this.currentWorkspaceParticipants)
      );

    return value
      ? this.filterByFuse.filterParticipants(participants, value)
      : participants;
  }
}
