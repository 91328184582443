import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  DialogPosition,
  MAT_DIALOG_DATA,
  MatDialogRef
} from '@angular/material/dialog';
import { FnMentionableSearchParticipant } from '../../../directive/mentionable.fn.search.participant';
import { Observable, Subscriber } from 'rxjs';
import { MentionableParticipant } from '../../../directive/mentionable.participant';
import { MentionablePosition } from '../../../directive/mentionable.position';
import { User } from '../../../models/user';
import { CursorUtils } from '../../../common/cursor-utils';

@Component({
  selector: 'app-dialog-mention-list',
  templateUrl: './dialog-mention-list.component.html',
  styleUrls: ['./dialog-mention-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogMentionListComponent implements OnInit {
  participants: Array<MentionableParticipant> = [];
  dialogRef?: MatDialogRef<DialogMentionListComponent, MentionableParticipant>;

  initialTopPosition = 0;
  initialLeftPosition = 0;
  inputHeight = 0;
  drawPosition: MentionablePosition = MentionablePosition.ABOVE;

  fnSearchParticipant?: FnMentionableSearchParticipant = (keyword: string) => {
    console.error('fnSearchParticipant is not defined');
    return new Observable<Array<MentionableParticipant>>(
      (subscriber: Subscriber<Array<MentionableParticipant>>) => {
        subscriber.next([]);
        subscriber.complete();
      }
    );
  };

  @ViewChild('mentionsList', { static: false })
  mentionsListElement: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.initialLeftPosition = data.leftPosition;
    this.initialTopPosition = data.topPosition;
    this.inputHeight = data.inputHeight;
    this.drawPosition = data.drawPosition;

    if (data.fnSearchParticipant) {
      this.fnSearchParticipant = data.fnSearchParticipant;
    }
  }

  ngOnInit(): void {}

  setDialogRef(
    dialogRef: MatDialogRef<DialogMentionListComponent, MentionableParticipant>
  ): void {
    this.dialogRef = dialogRef;
    this.dialogRef.addPanelClass('not-visible');
  }

  setFnSearchParticipant(fn: FnMentionableSearchParticipant | undefined): void {
    this.fnSearchParticipant = fn;
  }

  onFilter(input: string): void {
    if (!this.fnSearchParticipant) {
      console.error('fnSearchParticipant is not defined');
      return;
    }

    this.fnSearchParticipant(input).subscribe(
      (participants: Array<MentionableParticipant>) => {
        this.participants = participants.sort(
          (a: MentionableParticipant, b: MentionableParticipant) => {
            return a.getVisibleName().localeCompare(b.getVisibleName());
          }
        );
        this.updatePosition();
      }
    );
  }

  selectParticipant(participant: MentionableParticipant): void {
    this.dialogRef?.close(participant);
  }

  private updatePosition(): void {
    setTimeout(() => {
      let position: DialogPosition;
      const selectionCaretPosition: DOMRect | undefined =
        CursorUtils.getSelectionCaretPosition();
      let leftPosition = selectionCaretPosition?.left;
      if (leftPosition) {
        leftPosition += 5;
      }
      const dialogWidth =
        this.mentionsListElement?._elementRef?.nativeElement?.parentElement
          ?.parentElement?.clientWidth || 0;
      const windowWidth = window.innerWidth;
      if (leftPosition + dialogWidth + 1 > windowWidth) {
        leftPosition = windowWidth - dialogWidth - 1;
      }
      const leftPositionValue = leftPosition ? leftPosition + 'px' : undefined;

      switch (this.drawPosition) {
        case MentionablePosition.ABOVE:
          const dialogHeight =
            this.mentionsListElement?._elementRef?.nativeElement?.parentElement
              ?.parentElement?.clientHeight || 0;
          let dialogTopOffset = this.initialTopPosition - dialogHeight;

          if (dialogTopOffset < 0) {
            dialogTopOffset = this.initialTopPosition + this.inputHeight + 1;
          }

          position = { left: leftPositionValue, top: dialogTopOffset + 'px' };
          break;

        case MentionablePosition.BOTTOM:
          //let topPosition = selectionCaretPosition?.top;
          //
          //if (topPosition) {
          //    topPosition += 15;
          //}
          //
          //const topPositionValue = (topPosition) ? topPosition + 'px' : undefined;
          position = { bottom: '1px', left: leftPositionValue };
          break;
      }

      this.dialogRef?.updatePosition(position);
      this.dialogRef?.removePanelClass('not-visible');
    }, 1);
  }

  onAvatarImageLoadingError(event: any): void {
    event.target.src = User.DEFAULT_AVATAR_SRC;
  }
}
