<ng-container>
  <div class="apg-title apg-long-title" *ngIf="title">{{ title }}</div>

  <div mat-dialog-content>
    <div class="dialog-message">{{ message }}</div>
  </div>

  <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
    <ng-container *ngIf="no">
      <button
        mat-flat-button
        class="btn secondary upper"
        [ngClass.lt-md]="'md'"
        [ngClass.lt-sm]="'sm'"
        (click)="onDismiss()"
      >
        {{ no }}
      </button>
    </ng-container>
    <ng-container *ngIf="yes">
      <button
        mat-flat-button
        color="warn"
        class="btn upper"
        [ngClass.lt-md]="'md'"
        [ngClass.lt-sm]="'sm'"
        (click)="onConfirm()"
      >
        {{ yes }}
      </button>
    </ng-container>
  </div>
</ng-container>
