import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';
import { CustomSnackbarComponent } from '../../pages/custom-snackbar/custom-snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  open(
    message: string,
    type: SnackbarType = SnackbarType.Info,
    customStyleClasses: string[] = [],
    duration: number = 2000
  ): MatSnackBarRef<any> {
    return this.snackbar.openFromComponent(CustomSnackbarComponent, {
      duration,
      data: { text: message },
      panelClass: this.panelClass(type, customStyleClasses)
    });
  }

  private panelClass(type: SnackbarType, customStyle: string[]): string[] {
    const panelClass =
      customStyle.length > 0 ? customStyle : ['bar-main-container'];
    if (type === SnackbarType.Error) {
      panelClass.push('error');
    }
    return panelClass;
  }
}

export enum SnackbarType {
  Error,
  Info
}
