<div fxFlexFill fxLayout="row" fxLayoutAlign="stretch" class="header">
  <div
    fxFlex="64px"
    fxFlex.lt-sm="50px"
    fxFlexFill
    [fxHide.gt-sm]="!shouldShowBackButton"
    fxLayoutAlign="center center"
  >
    <mat-icon
      svgIcon="burger"
      (click)="onMenu()"
      class="burger"
      *ngIf="burger"
    ></mat-icon>
    <mat-icon
      svgIcon="back-button"
      [class]="opacity ? ['header-opacity', 'burger'] : ['burger']"
      *ngIf="!burger"
      (click)="back()"
    ></mat-icon>
  </div>

  <div fxFlex fxFlexFill fxLayoutAlign="start center" class="breadcrumb">
    <ng-container
      *ngFor="let breadcrumb of breadcrumbService.breadcrumbs; let i = index"
    >
      <ng-container *ngIf="getLongBreadcrumb()">
        <ng-container
          *ngIf="
            i !== breadcrumbService.breadcrumbs.length - 1 &&
            breadcrumbService.getNameI(i)
          "
        >
          <span
            [routerLink]="[breadcrumbService.getRouteI(i)]"
            [queryParams]="breadcrumbService.getRouteQueryParams(i)"
          >
            {{ breadcrumbService.getNameI(i) | titlecase }}
          </span>
          <ng-container *ngIf="breadcrumbService.isNeedSlash">
            <span>&nbsp;/&nbsp;</span>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="i === breadcrumbService.breadcrumbs.length - 1 && i !== 0"
        >
          <span class="last">
            {{ breadcrumbService.getNameI(i) | titlecase }}
          </span>
        </ng-container>
        <ng-container
          *ngIf="i === breadcrumbService.breadcrumbs.length - 1 && i === 0"
        >
          <span> {{ breadcrumbService.getNameI(i) | titlecase }} </span>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          !getLongBreadcrumb() &&
          i === 0 &&
          isBreadcrumbEnabled() &&
          (burger || isChatMode())
        "
      >
        <span
          [routerLink]="[breadcrumbService.getRouteI(i)]"
          [queryParams]="breadcrumbService.getRouteQueryParams(i)"
        >
          {{ breadcrumbService.getNameI(i) | titlecase }}
        </span>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="isChatMode(); else modeDefault">
    <div
      fxFlex="46px"
      fxFlex.lt-md="38px"
      fxFlexFill
      fxLayoutAlign="end center"
      class="chatAvatar"
    >
      <app-avatar
        [src]="getChatUrl()"
        [customRoute]="settingsRoute()"
      ></app-avatar>
    </div>
  </ng-container>

  <ng-template #modeDefault>
    <ng-container *ngIf="opacity">
      <div fxFlex="50px" fxFlexFill fxLayoutAlign="end center">
        <app-share class="header-opacity" style="display: none"></app-share>
        <div class="header-opacity">
          <div
            mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_horiz</mat-icon>
          </div>
          <mat-menu #menu="matMenu" class="menu">
            <ng-container>
              <button mat-menu-item (click)="onShare()">
                <span>Share</span>
              </button>
            </ng-container>
            <ng-container>
              <button mat-menu-item (click)="onReport()">
                <span>Report</span>
              </button>
            </ng-container>
            <ng-container>
              <button *ngIf="canBlock" mat-menu-item (click)="onBlock()">
                <span>Block</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </div>
      <div fxFlex="16px" fxFlexFill fxLayoutAlign="end center"></div>
    </ng-container>

    <ng-container *ngIf="!opacity">
      <div fxFlex="50px" fxFlexFill fxLayoutAlign="end center">
        <app-notification class="notification"></app-notification>
      </div>

      <div
        fxFlex="46px"
        fxFlex.lt-md="38px"
        fxFlexFill
        fxLayoutAlign="end center"
        style="margin-right: 45px"
      >
        <app-avatar
          [customRoute]="userProfileRoute"
          [src]="
            profileService.user?.photoThumbnailURL ||
            profileService.user?.photoURL
          "
          [userIdentity]="profileService.user?.userId"
        ></app-avatar>
      </div>

      <div fxFlex="40px" fxFlexFill fxLayoutAlign="end center"></div>
    </ng-container>
  </ng-template>
</div>
