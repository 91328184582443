<form class="add-update-form container" [style.max-width]="contentMaxWidth">
  <div *ngIf="closeButtonNeeded" class="closeElement" mat-dialog-close>
    <span class="material-icons">close</span>
  </div>

  <div class="titleBlock content-inputs">
    <h1 class="apg-title" [class.showOnSmallOnly]="showTitleOnSmallDeviceOnly">
      Add Participants
    </h1>
  </div>
  <mat-dialog-content>
    <div class="content-inputs">
      <div class="input-with-chip">
        <label class="color-text">To: </label>
        <mat-chip-list #toListElement>
          <mat-chip
            *ngFor="let participant of participants"
            (removed)="remove(participant)"
          >
            {{participant.email}}
            <mat-icon matChipRemove>close</mat-icon>
          </mat-chip>

          <input
            class="input-for-chip emails"
            [matChipInputFor]="toListElement"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event.value)"
            (paste)="onPaste()"
            #inputNewEmail
            [placeholder]="getPlaceholder()"
          />
        </mat-chip-list>
      </div>

      <div class="apg-form-group" *ngIf="showsAddButton">
        <button
          class="apg-button red-button button-custom-margins"
          [class.spinner]="loadInProgress"
          [disabled]="this.participants.length === 0 || loadInProgress"
          (click)="addParticipants()"
        >
          Add
        </button>
      </div>
    </div>

    <ng-container
      *ngIf="needsShowTutorial && !seenTutorial; then tutorial else participantsList"
    ></ng-container>
  </mat-dialog-content>
</form>

<ng-template #tutorial>
  <div style="padding-top: 50px; max-width: 400px; margin: auto">
    <app-tutorial-hint
      [borderClass]="'dashed'"
      [title]="tutorialHint.title"
      [description]="tutorialHint.description"
      (buttonClicked)="markTutorialSeen()"
    >
    </app-tutorial-hint>
  </div>
</ng-template>

<ng-template #participantsList>
  <app-participants-list
    [availableParticipants]="filteredPeople()"
    [participants]="participants"
    [titleText]="titleText"
    [subtitleOption]="'email'"
    [allowProfileNavigation]="false"
  ></app-participants-list>
</ng-template>
