import { StringUtils } from './string-utils';

export class PlatformUtils {
  public static isClientMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent
    );
  }

  public static isClientMobileAndroid(): boolean {
    return /Android/i.test(navigator.userAgent);
  }

  public static isClientSafari(): boolean {
    return (
      StringUtils.contains(navigator.userAgent, 'Apple') &&
      StringUtils.contains(navigator.userAgent, 'Safari') &&
      StringUtils.contains(navigator.userAgent, 'Mac') &&
      !StringUtils.contains(navigator.userAgent, 'Chrome')
    );
  }

  public static isClientIOS(): boolean {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  public static isClientFirefox(): boolean {
    return StringUtils.contains(navigator.userAgent, 'Firefox');
  }

  public static isClientMobileSmallScreen(): boolean {
    return window.innerWidth < 960 && PlatformUtils.isClientMobile();
  }
}
