import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogAddParticipantsComponent } from './dialog-add-participants.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../../../../modules/shared/shared.module';
import { TutorialHintModule } from '../../../../tutorial-hint/tutorial-hint.module';

@NgModule({
  declarations: [DialogAddParticipantsComponent],
  exports: [DialogAddParticipantsComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatChipsModule,
    MatIconModule,
    SharedModule,
    TutorialHintModule
  ]
})
export class DialogAddWorkspaceParticipantsModule {}
