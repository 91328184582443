<div
  class="card-container"
  *ngFor="let section of sections"
  [routerLink]="section.route"
>
  <div
    class="archive-card"
    fxLayout="row"
    fxLayoutGap="8px"
    fxLayoutAlign="center center"
  >
    <div class="icon"><img [src]="section.icon" alt="" /></div>
    <div>{{ section.label }}</div>
  </div>
</div>
