import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { EventBusService, EventBusNames } from '../event-bus/event-bus.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../../pages/custom-snackbar/custom-snackbar.component';
import { GlobalConstants } from '../../common/global-constants';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private eventBusService: EventBusService,
    private snackBar: MatSnackBar
  ) {}

  private static ignoreMessages = [
    `Report for workspace event with id: \\d+ not found`,
    `User with userId: [\\w-]+ not found`,
    `DeviceSettings with hwid [\\w-]+ not found`
  ];

  private static notInIgnoreList(err: HttpErrorResponse): boolean {
    const notInIgnoreMessages: boolean =
      this.ignoreMessages.find((message: string) => {
        const re = new RegExp(message, 'g');
        return (
          err.error && err.error.message && err.error.message.match(re) !== null
        );
      }) === undefined;
    const notUserFindByUUIDForbiddenError = !(
      err.error?.statusCode === 403 &&
      err.error?.path.match(new RegExp('/users/[a-zA-Z0-9-]+', 'g'))
    );
    return notInIgnoreMessages && notUserFindByUUIDForbiddenError;
  }

  success(
    event:
      | HttpSentEvent
      | HttpHeaderResponse
      | HttpResponse<any>
      | HttpProgressEvent
      | HttpUserEvent<any>
  ): void {}

  error(err: any): void {
    if (err instanceof HttpErrorResponse) {
      if (AuthInterceptor.notInIgnoreList(err)) {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          duration: 5000,
          data: {
            text: err.error.message
          },
          panelClass: ['bar-main-container', 'reset-password-bar', 'error']
        });
      }

      if (err.status === 401) {
        this.router.navigate([GlobalConstants.routes.signin]).then();
        this.eventBusService.emit({ name: EventBusNames.unauthorized });
      }
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let headers = req.headers.set(
      'X-APG-API',
      environment.apiVersion.toString()
    );
    headers = headers.set('hwid', localStorage.getItem('hwid') || '');
    headers = headers.set('app-version', environment.appVersion);
    headers = headers.set('timezone', timeZone);
    if (token) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    const authReq = req.clone({
      headers: headers
    });
    return next.handle(authReq).pipe(
      tap({
        next: this.success.bind(this),
        error: this.error.bind(this)
      })
    );
  }
}
