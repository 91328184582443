<mat-dialog-content>
  <div class="closeElement" mat-dialog-close>
    <span class="material-icons">close</span>
  </div>

  <p-fileUpload
    styleClass="upload-panel"
    #fileUploader
    name="files[]"
    [customUpload]="true"
    (uploadHandler)="onUpload($event)"
    [multiple]="allowMultipleUpload"
    [maxFileSize]="maxSize"
    (onProgress)="onProgress($event)"
    [showCancelButton]="false"
  >
    <ng-template let-file pTemplate="file">
      <div class="file-row">
        <div class="file-name">{{ file.name }}</div>
        <div class="size-close">
          <div>{{ this.docService.formatBytes(file.size, 2) }}</div>
          <button
            *ngIf="!fileUploader.uploading"
            type="button"
            class="p-button delete"
            (click)="clearFile(file, $event)"
          >
            <span class="p-button-icon pi pi-times" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div>
        Choose or drop files here. Your files will be sorted after upload to the
        appropriate library section. Check other library sections if you don't
        see them in the current one.
      </div>
    </ng-template>
  </p-fileUpload>
</mat-dialog-content>
