import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-text',
  templateUrl: './dialog-text.component.html',
  styleUrls: ['./dialog-text.component.scss']
})
export class DialogTextComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogTextData) {}

  ngOnInit(): void {}
}

export interface DialogTextData {
  title: string;
  type: string;
}
