import { AvatarModule } from './../../../../../component/avatar/avatar.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { OneNotificationComponent } from './one-notification.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [OneNotificationComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    AvatarModule
  ],
  exports: [OneNotificationComponent]
})
export class OneNotificationModule {}
