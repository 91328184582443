import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkspaceEvent } from '../../../../models/workspaceEvent';
import { WorkspaceEventsService } from '../../../../services/events/workspace-events.service';
import { OneWorkspaceService } from '../../../../services/workspace/one-workspace.service';
import { Attendance } from '../../../../models/participant';
import {
  EventBusService,
  EventBusNames
} from '../../../../services/event-bus/event-bus.service';
import { Carousel } from 'primeng/carousel';
import { UtilsService } from '../../../../services/utils/utils.service';
import { Observable, Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-conflicts',
  templateUrl: './conflicts.component.html',
  styleUrls: ['./conflicts.component.scss']
})
export class ConflictsComponent implements OnInit, OnDestroy {
  attendance: Attendance = Attendance.Rejected;
  pickedEvent: WorkspaceEvent | undefined;
  isTabletOrMobile = false;
  responsiveOptions: any;
  page = 0;

  // TODO: change back to 20 items when BE fixed https://app.clickup.com/t/2d3dcrt
  limit = 100;

  conflicts: WorkspaceEvent[] = [];
  workspaceId = 0;
  loadInProgress = false;

  private onDestroy = new Subject<void>();

  constructor(
    public eventsService: WorkspaceEventsService,
    public oneWorkspaceService: OneWorkspaceService,
    private utils: UtilsService,
    private eventBusService: EventBusService
  ) {
    this.setupOptions();
  }

  ngOnInit(): void {
    this.isTabletOrMobile = this.utils.isTabletOrMobile();
    this.workspaceId = this.oneWorkspaceService.workspace?.workspaceId ?? 0;

    this.loadConflicts().subscribe(() => {
      this.pickedEvent = this.defaultConflict;
    });

    this.eventBusService.on(
      EventBusNames.workspaceEvent,
      () => this.onConflictParticipantsUpdate(),
      this.onDestroy
    );
  }

  get defaultConflict(): WorkspaceEvent {
    return this.conflicts[0];
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  showNavigators(carousel: Carousel): boolean {
    return this.conflicts.length > carousel.numVisible;
  }

  private loadConflicts(cleanUp: boolean = true): Observable<any> {
    this.loadInProgress = true;
    return this.eventsService
      .getConflicts(this.workspaceId, this.page, this.limit)
      .pipe(
        tap((conflicts) => {
          this.storeConflicts(conflicts, cleanUp);
          this.loadInProgress = false;
        }),
        first()
      );
  }

  private storeConflicts(
    conflicts: WorkspaceEvent[],
    cleanUp: boolean = true
  ): void {
    if (cleanUp) {
      this.conflicts = [];
    }
    this.conflicts.push(...conflicts);
  }

  private onConflictParticipantsUpdate(): void {
    this.eventsService
      .getConflicts(this.workspaceId, 0, this.limit * (this.page + 1))
      .subscribe((conflicts) => {
        this.storeConflicts(conflicts);
        const updatedEvent = this.conflicts.find(
          (event) => event.eventId === this.pickedEvent?.eventId
        );
        this.pickedEvent = updatedEvent ?? this.defaultConflict;
      });
  }

  private setupOptions(): void {
    this.responsiveOptions = [
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 1
      },
      {
        breakpoint: '450px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  onEventPicked(event: WorkspaceEvent): void {
    this.pickedEvent = event;
  }

  scroll(carousel: Carousel): void {
    if (carousel.lastIndex() === this.conflicts.length - 1) {
      this.page++;
      this.loadConflicts(false).subscribe();
    }
  }
}
