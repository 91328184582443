import { Injectable } from '@angular/core';
import { ArchiveType } from '../../../models/archiveFile';
import { LibraryManager } from './library-manager';

@Injectable()
export class MediaLibraryManager extends LibraryManager {
  get type(): ArchiveType {
    return ArchiveType.Image;
  }
}
