<ng-container *ngIf="postComment">
  <div fxLayout="column">
    <div fxLayout="column" class="post-comment">
      <div fxLayout="row">
        <div fxFlex="auto">
          <app-avatar-for-post
            [date]="getDate()"
            [post]="postComment"
            [size]="'small'"
          ></app-avatar-for-post>
        </div>
        <div fxFlex="40px" fxLayout="row" fxLayoutAlign="end top" class="more">
          <button
            class="more"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="menu">
            <button
              mat-menu-item
              [cdkCopyToClipboard]="postComment.description || ''"
              (click)="onMessageCopied()"
            >
              <span>Copy</span>
            </button>
            <button
              *ngIf="postService.permissionReportPost(postComment)"
              mat-menu-item
              (click)="postService.reportPost(postComment)"
            >
              <span>Report</span>
            </button>
            <button
              *ngIf="
                postService.permissionEditingPost(postComment) &&
                (!editing || postService.getPostId() !== postComment.postId)
              "
              mat-menu-item
              (click)="editingPostWrapper(postComment)"
            >
              <span>Edit</span>
            </button>
            <button
              *ngIf="postService.permissionDeletingPost(postComment)"
              class="danger"
              mat-menu-item
              (click)="postService.deletePostWrapper(postComment)"
            >
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="description">
        <app-post-description
          [editing]="editing"
          [post]="postComment"
          [callbackBad]="notSave.bind(this)"
          [callback]="onCommentUpdated.bind(this)"
          [fnSearchParticipant]="fnSearchParticipant"
        ></app-post-description>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex>
        <app-reaction [post]="postComment"></app-reaction>
      </div>
    </div>

    <div fxFlex="row" *ngIf="replayShow">
      <div fxFlex="46px" style="margin-right: 9px">
        <app-avatar
          [src]="postComment.profileImageThumbnail || postComment.profileImage"
          [userIdentity]="postComment.userId"
        ></app-avatar>
      </div>
      <div fxFlex>
        <app-input-write-post-and-comment
          [fnSearchParticipant]="fnSearchParticipant"
          [callback]="savePostReply.bind(this)"
        >
        </app-input-write-post-and-comment>
      </div>
    </div>
  </div>
</ng-container>
