import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Participant } from '../../../../models/participant';
import { UtilsService } from '../../../../services/utils/utils.service';

@Component({
  selector: 'app-one-participant',
  templateUrl: './one-participant.component.html',
  styleUrls: ['./one-participant.component.scss']
})
export class OneParticipantComponent implements OnInit {
  @Input() participant: Participant | undefined;
  @Input() isSelected = false;
  @Input() canSelectItem = false;
  @Input() role?: 'OWNER' | 'MANAGER';
  @Input() subtitle?: string;
  @Input() allowProfileNavigation = false;
  @Input() canEditRole: boolean = false;

  @Output() roleChanged = new EventEmitter<string>();

  isHovered = false;
  isTabletOrMobile = false;

  get title(): string | undefined {
    if (this.participant?.name && this.participant.surname) {
      return `${this.participant.name?.trim()} ${this.participant.surname?.trim()}`;
    }
    return this.participant?.email;
  }

  get iconForParticipant(): string {
    if (this.isHovered || this.isTabletOrMobile) {
      return this.isSelected ? 'checked_circle' : 'unchecked_circle';
    } else {
      return 'checked_circle';
    }
  }

  constructor(private utils: UtilsService) {}

  ngOnInit(): void {
    this.isTabletOrMobile = this.utils.isTabletOrMobile();
  }
}
