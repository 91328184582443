export enum ArchiveType {
  Image = 'IMAGE',
  Document = 'DOCUMENT',
  Audio = 'AUDIO',
  Video = 'VIDEO',
  Link = 'LINK'
}

export interface ArchiveFile {
  artist?: string;
  attachmentId?: number;
  createTime: string;
  creatorId?: number;
  extension?: string;
  fileSize: number;
  metadata?: string;
  previewImageUrl?: string;
  title?: string;
  type?: ArchiveType;
  url?: string;
  workspaceId?: number;
  creatorPhotoURL?: string;
  creatorPhotoThumbnailURL?: string;
  creatorFullName?: string;
  isPublic: boolean;
}
