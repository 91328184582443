import { Injectable } from '@angular/core';
import { EventTypes, WorkspaceEvent } from '../../models/workspaceEvent';
import {
  CreatedEventsList,
  WorkspaceEventList
} from '../../models/workspaceEventList';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../user/profile/profile.service';
import {
  Attendance,
  Participant,
  EventParticipant
} from '../../models/participant';
import { ActualValues, AttendanceObject } from '../../models/AttendanceObject';
import {
  TextInputComponent,
  TextInputDialogModel
} from '../../component/dialog/text-input/text-input.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceEventsService {
  constructor(
    private httpClient: HttpClient,
    private profileService: ProfileService,
    private dialog: MatDialog
  ) {}

  urlConflicts(workspaceId: number): string {
    return (
      environment.url +
      'workspaces/' +
      workspaceId +
      '/events/with/users/by/attendance'
    );
  }

  urlWorkspaceEvents(workspaceId: number): string {
    return environment.url + 'workspaces/' + workspaceId + '/events';
  }

  urlWorkspaceEventsUpdateDelete(event: WorkspaceEvent): string {
    return (
      environment.url +
      'workspaces/' +
      event.workspaceId +
      '/events/' +
      event.eventId
    );
  }

  getEvents(
    workspaceId: number,
    limit: number,
    page: number,
    type: EventTypes | null = null
  ): Observable<WorkspaceEvent[]> {
    let params = { page, limit };
    if (type) {
      params = Object.assign(params, { type });
    }

    return this.httpClient
      .get<WorkspaceEventList>(this.urlWorkspaceEvents(workspaceId), { params })
      .pipe(
        map((response) => {
          return response.content.map((event) =>
            new WorkspaceEvent(event.workspaceId).deserialize(event)
          );
        }),
        catchError((error) => this.handleError('get events', [], error))
      );
  }

  getConflicts(
    workspaceId: number,
    page: number,
    limit: number
  ): Observable<WorkspaceEvent[]> {
    const params = { page, limit, attendance: Attendance.Rejected };
    return this.httpClient
      .get<WorkspaceEventList>(this.urlConflicts(workspaceId), { params })
      .pipe(
        map((response) => {
          return response.content.map((event) =>
            new WorkspaceEvent(event.workspaceId).deserialize(event)
          );
        }),
        catchError((error) => this.handleError('get conflicts', [], error))
      );
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }

  getEventById(eventId: number, workspaceId: number): Promise<any> {
    return lastValueFrom(
      this.httpClient.get(
        environment.url + 'workspaces/' + workspaceId + '/events/' + eventId
      )
    );
  }

  addEventParticipants(
    event: WorkspaceEvent,
    participantsIds: number[]
  ): Observable<any> {
    return this.httpClient.put(
      environment.url +
        'workspaces/' +
        event.workspaceId +
        '/events/' +
        event.eventId +
        '/attendances',
      { users: participantsIds }
    );
  }

  deleteUserFromEvent(
    workspaceId: number,
    eventId: number,
    participants: EventParticipant[]
  ): Promise<any> {
    const users = participants.map((p) => p.userId);
    const url =
      environment.url +
      'workspaces/' +
      workspaceId +
      '/events/' +
      eventId +
      '/attendances/delete';
    return lastValueFrom(this.httpClient.post(url, { users }));
  }

  updateAttendances(
    workspaceId: number,
    attendances: AttendanceObject[]
  ): Promise<any> {
    return lastValueFrom(
      this.httpClient.put(
        environment.url + 'workspaces/' + workspaceId + '/events/attendances',
        {
          attendances
        }
      )
    );
  }

  createEvents(
    events: WorkspaceEvent[],
    workspaceId: number
  ): Observable<WorkspaceEvent[]> {
    return this.httpClient
      .post<CreatedEventsList>(
        environment.url + `workspaces/${workspaceId}/events/batch`,
        events
      )
      .pipe(
        map((response) => {
          return response.data.map((event) =>
            new WorkspaceEvent(workspaceId).deserialize(event)
          );
        }),
        catchError((err) => this.handleError('create events', events, err))
      );
  }

  createEvent(newEvent: WorkspaceEvent): Observable<WorkspaceEvent> {
    return this.createEvents([newEvent], newEvent.workspaceId).pipe(
      map((response) => {
        return response[0];
      }),
      mergeMap((event) => {
        return this.addEventParticipants(
          event,
          newEvent.participants.map((p) => p.userId)
        );
      }),
      catchError((err) => this.handleError('createNewTask', newEvent, err))
    );
  }

  updateEvent(event: WorkspaceEvent): Observable<WorkspaceEvent> {
    return this.httpClient.put<WorkspaceEvent>(
      this.urlWorkspaceEventsUpdateDelete(event),
      event
    );
  }

  deleteEvent(event: WorkspaceEvent): Promise<any> {
    return lastValueFrom(
      this.httpClient.delete(this.urlWorkspaceEventsUpdateDelete(event))
    );
  }

  isEventCreator(event: WorkspaceEvent): boolean {
    const user = this.profileService.user;
    if (user) {
      return event.inviterId === user.userId;
    }
    return false;
  }

  getActualFromEvent(event: WorkspaceEvent): ActualValues {
    return event.isNotStarted ? ActualValues.user : ActualValues.mentor;
  }

  showRejectReasonDialog(reason: string): MatDialogRef<any> {
    const message = `It's really important for us to know`;
    const title = 'Indicate the reason';
    const dialogData = new TextInputDialogModel(
      title,
      message,
      reason,
      !this.profileService.isAdmin(),
      'Send',
      255
    );

    return this.dialog.open(TextInputComponent, {
      panelClass: 'popup-dialog',
      data: dialogData
    });
  }

  getOneAttendanceByUserId(userId: number, event: WorkspaceEvent): string {
    const participant = this.getOneParticipantByUserId(userId, event);
    return participant !== undefined && participant.reason
      ? participant.reason
      : '';
  }

  getOneParticipantByUserId(
    userId: number,
    event: WorkspaceEvent
  ): EventParticipant | undefined {
    return event.participants.find((p) => p.userId === userId);
  }

  canManageEvent(participants: Participant[]): boolean {
    return this.profileService.isAdminOrManager(participants);
  }

  currentEventParticipant(event: WorkspaceEvent): EventParticipant | undefined {
    const currentUserId = this.profileService.user?.userId;
    return currentUserId
      ? this.getOneParticipantByUserId(currentUserId, event)
      : undefined;
  }

  currentUserStatusFor(event: WorkspaceEvent): Attendance {
    const currentStatus = this.currentEventParticipant(event)?.attendance;
    if (currentStatus) {
      return currentStatus;
    }
    return Attendance.Unmarked;
  }
}
