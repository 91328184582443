import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkspaceService } from '../../../services/workspace/workspace.service';
import { DialogAddUpdateWorkspaceComponent } from '../../../component/dialog/add-update/workspace/dialog-add-update-workspace.component';
import { Workspace } from '../../../models/workspace';
import { GlobalConstants } from '../../../common/global-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-workspace-card',
  templateUrl: './new-workspace-card.component.html',
  styleUrls: ['../workspace.component.scss']
})
export class NewWorkspaceCardComponent {
  @Output() newWorkspaceCreated = new EventEmitter<Workspace>();

  public i18n = GlobalConstants.i18n;

  constructor(
    public dialog: MatDialog,
    public workspaceService: WorkspaceService,
    private router: Router
  ) {}

  addWorkspace(): void {
    this.router.navigate(['new-workspace']).then();
    return;

    const dialogRef = this.dialog.open(DialogAddUpdateWorkspaceComponent, {
      panelClass: 'popup-dialog',
      data: {
        type: 'add',
        workspace: new Workspace(),
        showSaveButton: true
      }
    });

    dialogRef.afterClosed().subscribe((workspace) => {
      if (workspace) {
        this.newWorkspaceCreated.emit(workspace);
      }
    });
  }
}
