<div
  class="attendance-label"
  [class]="classForStatus"
  fxLayout="row"
  fxLayoutGap="6px"
  fxLayoutAlign=" center"
>
  <div class="attendance-icon"><img [src]="icon" alt="" /></div>
  <div>{{ attendanceString }}</div>
</div>
