import { Component, Input } from '@angular/core';
import { Post, PostReply } from '../../../../../models/post';
import { PostService } from '../../../../../services/post/post.service';

// @todo: Maybe it's dead code
@Component({
  selector: 'app-post-replay',
  templateUrl: './post-replay.component.html',
  styleUrls: ['./post-replay.component.scss']
})
export class PostReplayComponent {
  @Input() postReplay: PostReply | undefined;
  editing = false;

  constructor(public postService: PostService) {}

  getDate(): string {
    return this.postReplay ? Post.getDateCreated(this.postReplay) : '';
  }
}
