import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../models/user';
import { Role } from '../../../models/role';
import {
  EventBusService,
  EventBusNames
} from '../../event-bus/event-bus.service';
import { Participant } from '../../../models/participant';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { PushNotificationsService } from '../../push-notifications/push-notifications.service';
import { StorageService } from '../../storage/storage.service';
import { LSKeys } from '../../storage/storage-keys';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private eventBusService: EventBusService,
    private pushNotificationsService: PushNotificationsService,
    private storage: StorageService
  ) {
    eventBusService.on(EventBusNames.logout, this.logout.bind(this));
    eventBusService.on(EventBusNames.unauthorized, this.clearUser.bind(this));
    eventBusService.on(
      EventBusNames.authorized,
      this.requestCurrentUser.bind(this)
    );
  }

  private urlCurrentUser: string = environment.url + 'users/current';
  private urlSaveUserInfo: string = environment.url + 'users/profile';
  private urlDeleteCharacters: string =
    environment.url + 'users/profile/characters/delete';
  private urlDeleteEducations: string =
    environment.url + 'users/profile/educations/delete';
  private urlProfilePicture: string = environment.url + 'users/profile/picture';

  user?: User;

  async success(value: any): Promise<void> {
    this.setUser(new User().deserialize(value));

    const pushNotificationsEnabled =
      this.storage.getItem<boolean>(LSKeys.PUSH_NOTIFICATIONS_ENABLED) ?? true;

    pushNotificationsEnabled
      ? await this.pushNotificationsService.subscribe()
      : await this.pushNotificationsService.unsubscribe();

    this.eventBusService.emit({ name: EventBusNames.profile });
  }

  error(value: HttpErrorResponse): void {
    console.error(value);
    this.clearUser().then(() => window.location.reload());
  }

  async clearUser(): Promise<void> {
    this.setUser(undefined);
  }


  private async logout(): Promise<void> {
    await this.pushNotificationsService.unsubscribe();
    this.clearUser();
  }

  private requestCurrentUser(): Promise<any> {
    return this.getCurrentUser().then(
      this.success.bind(this),
      this.error.bind(this)
    );
  }

  public getCurrentUser(): Promise<any> {
    return lastValueFrom(this.http.get(this.urlCurrentUser));
  }

  public deleteCurrentUser(): Promise<any> {
    return lastValueFrom(this.http.delete(this.urlCurrentUser));
  }

  private setUser(user: User | undefined): User | undefined {
    return (this.user = user);
  }

  private updateCurrentUser(user: User): void {
    if (user.userId === this.user?.userId) {
      this.setUser(user);
      this.eventBusService.emit({
        name: EventBusNames.twilioUserUpdate,
        value: this.user
      });
    }
  }

  changeRoleFor(user: User, role: Role): Observable<User> {
    const body = {
      role: role.role
    };
    return this.http
      .put<User>(environment.url + 'users/' + user.userId, body)
      .pipe(
        map((value) => new User().deserialize(value)),
        catchError((err) =>
          this.handleError('updating user role', user ?? new User(), err)
        )
      );
  }

  saveUserInfo(user?: User): Observable<User> {
    return this.http.post(this.urlSaveUserInfo, user).pipe(
      map((newUser) => new User().deserialize(newUser)),
      tap((updatedUser) => this.updateCurrentUser(updatedUser)),
      catchError((err) =>
        this.handleError('saving user info', user ?? new User(), err)
      )
    );
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }

  deleteProfilePicture(user: User): Promise<any> {
    return lastValueFrom(this.http.delete(this.profilePictureUrlForUser(user)));
  }

  updateProfilePicture(file: File, user: User): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return lastValueFrom(
      this.http.post(this.profilePictureUrlForUser(user), formData)
    );
  }

  private profilePictureUrlForUser(user: User): string {
    return this.isCurrentUser(user)
      ? this.urlProfilePicture
      : environment.url + 'users/' + user.userId + '/profile/picture';
  }

  isCurrentUser(user: User): boolean {
    return this.user?.userId === user.userId;
  }

  hasRoles(
    name:
      | 'ROLE_ADMIN'
      | 'ROLE_MENTOR'
      | 'ROLE_USER'
      | Array<'ROLE_ADMIN' | 'ROLE_MENTOR' | 'ROLE_USER'>
  ): boolean {
    return Array.isArray(name)
      ? this.user?.roles.find((role: Role) => {
          return (
            name.find(
              (item: 'ROLE_ADMIN' | 'ROLE_MENTOR' | 'ROLE_USER') =>
                item === role.role
            ) !== undefined
          );
        }) !== undefined
      : this.user?.roles.find((role: Role) => role.role === name) !== undefined;
  }

  isAdmin(): boolean {
    return this.hasRoles('ROLE_ADMIN');
  }

  isManager(participants: Participant[]): boolean {
    const participant = this.foundParticipant(participants);
    return participant !== undefined && participant.workspaceManager;
  }

  isManagerOrCreator(
    participants: Participant[],
    userId: number | undefined
  ): boolean {
    return this.isManager(participants) || this.user?.userId === userId;
  }

  isParticipant(participants: Participant[]): boolean {
    return this.foundParticipant(participants) !== undefined;
  }

  private foundParticipant(
    participants: Participant[]
  ): Participant | undefined {
    const filtered = participants?.filter((p: Participant) => {
      return (
        this.user?.userId && p.userId === this.user.userId && p.workspaceManager
      );
    });

    return filtered !== undefined && filtered.length === 1
      ? filtered[0]
      : undefined;
  }

  isAdminOrManager(participants: Participant[]): boolean {
    return this.isAdmin() || this.isManager(participants);
  }

  deleteUserRoles(rolesIds: number[]): Promise<any> {
    return lastValueFrom(
      this.http.post(this.urlDeleteCharacters, { ids: rolesIds })
    );
  }

  deleteUserEducations(educationsIds: number[]): Promise<any> {
    return lastValueFrom(
      this.http.post(this.urlDeleteEducations, { ids: educationsIds })
    );
  }
}
