<div
  class="wrapper popupHowItWorks"
  fxLayout="column"
  fxFlexFill
  fxLayoutAlign="center center"
>
  <div
    fxFlex="80px"
    fxFlex.xs="96px"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div
      fxFlex="812px"
      fxFlex.lg="666px"
      fxFlex.lt-md="560px"
      fxFlex.xs="300px"
      class="title center"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-lg="center center"
    >
      <div>Everything you need to manage your Theatrical Production</div>
    </div>
  </div>

  <div fxFlex="531px" fxFlex.xs="300px" fxLayout="row">
    <div
      fxFlex="266px"
      fxLayout="column"
      fxLayoutAlign="center start"
      fxHide.lt-lg
      class="hide768-1400"
    >
      <ng-container *ngFor="let page of demoPages">
        <div
          fxFlex="56px"
          class="button"
          fxFill
          fxLayout="row"
          fxLayoutAlign="center center"
          (click)="setActive(page.index)"
          [class.active]="activeImg === page.index"
        >
          <div class="group">
            <div fxFlex="35px">
              <img
                class="th"
                width="32"
                height="32"
                [src]="
                  activeImg === page.index ? page.iconActive : page.iconPassive
                "
                loading="lazy"
                alt="1"
              />
            </div>
            <div fxFlex="192px">{{ page.title }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <div
      fxFlex="546px"
      fxFlex.lt-xl="400px"
      fxFlex.xs="300px"
      fxLayout="column"
    >
      <div
        fxFlex="531px"
        fxFlex.xs="343px"
        fxLayout="column"
        fxLayoutAlign="center center"
        style="position: relative"
      >
        <ng-container *ngFor="let page of demoPages">
          <img
            [class.active]="activeImg === page.index"
            class="prev"
            [src]="page.mainImage"
            [srcset]="page.imageSet"
            loading="lazy"
            [alt]="page.index"
          />
        </ng-container>
      </div>
    </div>
  </div>
  <div
    fxFlex="88px"
    fxLayoutAlign="center center"
    class="hide1400-1600 hide1600-"
    style="padding-top: 0px"
    fxLayout="column"
  >
    <div fxFlex class="group" fxLayoutAlign="center center">
      <div class="groupContent">
        <div class="icon" *ngFor="let page of demoPages">
          <img
            class="th"
            *ngIf="activeImg === page.index"
            [src]="
              activeImg === page.index ? page.iconActive : page.iconPassive
            "
            loading="lazy"
            alt="1"
          />
        </div>
        <div class="buffer"></div>
        <div class="groupText" *ngFor="let page of demoPages">
          <span *ngIf="activeImg === page.index">{{ page.title }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    fxFlex="20px"
    fxLayoutAlign="center center"
    class="hide1400-1600 hide1600-"
    style="padding: 10px 0 20px 0"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngFor="let page of demoPages"
    >
      <div
        class="round right"
        [class.active]="activeImg === page.index"
        (click)="setActive(page.index)"
      ></div>
    </div>
  </div>

  <div fxFlex="32px" fxLayout="row" fxHide.lt-lg class="hide768-1400">
    <div
      fxFlex="266px"
      fxLayout="column"
      fxLayoutAlign="center start"
      style="
        font-style: normal;
        opacity: 0.5;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        display: flex;
        align-items: center;
        color: #ffffff;
      "
    >
      <div
        fxFlex="56px"
        (click)="showMore()"
        class="button"
        fxFill
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div class="group">
          <div fxFlex="35px">
            <img
              class="th"
              width="32"
              height="32"
              src="assets/first-page-layout/right-column/more.png"
              loading="lazy"
              alt="1"
            />
          </div>
          <div fxFlex="192px">and more...</div>
        </div>
      </div>
    </div>
    <div
      fxFlex="546px"
      fxFlex.lt-xl="400px"
      fxFlex.xs="343px"
      fxLayout="column"
    ></div>
  </div>
</div>
