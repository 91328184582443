import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attendance } from '../../models/participant';
import { MatDialog } from '@angular/material/dialog';
import { WorkspaceEvent } from '../../models/workspaceEvent';
import { ProfileService } from '../../services/user/profile/profile.service';
import { WorkspaceEventsService } from '../../services/events/workspace-events.service';
import { AttendanceObject } from '../../models/AttendanceObject';
import {
  EventBusService,
  EventBusNames
} from '../../services/event-bus/event-bus.service';
import { attendanceUtils } from '../attendance-label/attendance.utils';

@Component({
  selector: 'app-attendance-toggle',
  templateUrl: './attendance-toggle.component.html',
  styleUrls: ['./attendance-toggle.component.scss']
})
export class AttendanceToggleComponent implements OnInit {
  attendance = Attendance;

  @Input() userStatus: Attendance = Attendance.Unmarked;
  @Input() disabled = false;
  @Input() workspaceEvent: WorkspaceEvent | undefined;

  @Output() statusChanged = new EventEmitter<Attendance>();

  public isCanEdit = false;

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService,
    private workspaceEventService: WorkspaceEventsService,
    private eventBusService: EventBusService
  ) {}

  ngOnInit(): void {
    this.updateVisibility();
  }

  updateVisibility(): void {
    if (this.profileService.user?.userId && this.workspaceEvent) {
      const user = this.workspaceEventService.getOneParticipantByUserId(
        this.profileService.user.userId,
        this.workspaceEvent
      );
      this.isCanEdit = user !== undefined;
    }
  }

  onClickAttending(event: MouseEvent): void {
    event.stopPropagation();

    if (this.workspaceEvent && this.profileService.user?.userId) {
      this.userStatus = Attendance.Attending;

      const attendance = new AttendanceObject(
        Attendance.Attending,
        this.profileService.user.userId,
        this.workspaceEvent.eventId,
        this.workspaceEventService.getActualFromEvent(this.workspaceEvent),
        ''
      );
      this.workspaceEventService
        .updateAttendances(this.workspaceEvent?.workspaceId, [attendance])
        .then(() => {
          this.statusChanged.emit(this.userStatus);
          this.eventBusService.emit({ name: EventBusNames.workspaceEvent });
        });
    }
  }

  onClickNotGoing(event: MouseEvent): void {
    event.stopPropagation();

    if (this.profileService.user?.userId && this.workspaceEvent) {
      const reason = this.workspaceEventService.getOneAttendanceByUserId(
        this.profileService.user?.userId,
        this.workspaceEvent
      );
      const dialogRef =
        this.workspaceEventService.showRejectReasonDialog(reason);

      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult?.description || dialogResult.required === false) {
          if (this.workspaceEvent && this.profileService.user?.userId) {
            this.userStatus = Attendance.Rejected;

            const attendance = new AttendanceObject(
              Attendance.Rejected,
              this.profileService.user.userId,
              this.workspaceEvent.eventId,
              this.workspaceEventService.getActualFromEvent(
                this.workspaceEvent
              ),
              dialogResult.description
            );
            this.workspaceEventService
              .updateAttendances(this.workspaceEvent?.workspaceId, [attendance])
              .then(() => {
                this.statusChanged.emit(this.userStatus);
                this.eventBusService.emit({
                  name: EventBusNames.workspaceEvent
                });
              });
          }
        }
      });
    }
  }

  stringForStatus(attendance: Attendance): string {
    return attendanceUtils.attendanceLabel[attendance];
  }
}
