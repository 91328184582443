<div class="cropper-container" #container>
  <div class="bg-layer bg-layer-top"></div>

  <div class="crop-area">
    <table>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>
  </div>

  <div class="bg-layer bg-layer-bottom">
    <div class="content-layer">
      <img class="icon" src="assets/workspaces/banner/icon-expand.png" alt="" />
      <span>Drag to move cover photo</span>
    </div>
  </div>

  <img
    src="{{ src }}"
    alt=""
    #imageElement
    (load)="initListeners()"
    (change)="initListeners()"
    class="image"
    crossorigin="anonymous"
  />
</div>
