import { Component, Input, OnInit } from '@angular/core';
import { WorkspaceEvent } from '../../models/workspaceEvent';
import { Participant } from '../../models/participant';

@Component({
  selector: 'app-avatars-preview',
  templateUrl: './avatars-preview.component.html',
  styleUrls: ['./avatars-preview.component.scss']
})
export class AvatarsPreviewComponent implements OnInit {
  @Input() workspaceEvent: WorkspaceEvent | undefined;

  public extraUserCount = 0;
  public countVisibleIcons = 3;

  constructor() {}

  get firstParticipants(): Participant[] {
    return this.workspaceEvent?.participants?.slice(0, 3) || [];
  }

  ngOnInit(): void {
    if (this.workspaceEvent && this.workspaceEvent.participants?.length) {
      this.extraUserCount =
        this.workspaceEvent.participants.length > this.countVisibleIcons
          ? this.workspaceEvent.participants.length - this.countVisibleIcons
          : 0;
    }
  }
}
