<div
  [class]="
    relative ? ['float-button-position', 'relative'] : ['float-button-position']
  "
>
  <div class="float-button" (click)="click()">
    <span>
      <img [src]="imgScr" alt="" />
    </span>
  </div>
</div>
