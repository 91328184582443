<ng-container *ngIf="workspace">
  <div fxLayout="column" class="workspace-card participant-list">
    <div
      class="sticky-dots"
      *ngIf="profile.isAdminOrManager(workspace.participants)"
    >
      <div class="dots">
        <button
          class="more"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="menu">
          <button
            mat-menu-item
            (click)="copyEmails()"
            [disabled]="selectedParticipants.size === 0"
          >
            <span>Copy e-mail</span>
          </button>
          <button
            mat-menu-item
            *ngIf="isCanAddManager(workspace.participants)"
            (click)="assignAsManager()"
            [disabled]="selectedParticipants.size === 0"
          >
            <span>Assign as a manager</span>
          </button>
          <button
            mat-menu-item
            *ngIf="isCanRevokeManger(workspace.participants)"
            (click)="revokeManager()"
            [disabled]="selectedParticipants.size === 0"
          >
            <span>Revoke manager</span>
          </button>
          <button
            mat-menu-item
            (click)="deleteParticipants()"
            class="danger"
            [disabled]="selectedParticipants.size === 0"
          >
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div fxLayout fxLayoutAlign="start stretch" fxLayoutGap="15px">
      <app-group-select
        [selectedGroupValue]="selectedGroup$ | async"
        (groupSelected)="onGroupSelected($event)"
      ></app-group-select>

      <div fxLayout="column" fxLayoutGap="10px" style="width: 100%">
        <ng-container
          *ngFor="
            let participant of workspace.participants
              | appSortParticipants: workspace
          "
        >
          <app-one-participant
            [participant]="participant"
            [canSelectItem]="canEditParticipants"
            [canEditRole]="canEditParticipants"
            (roleChanged)="updateRole($event, participant)"
            (click)="clickRow(participant)"
            [role]="participantRole(participant)"
            [isSelected]="selectedParticipants.has(participant)"
          >
            <button
              mat-icon-button
              class="resendButton"
              *ngIf="canResentInviteTo(participant)"
              [disableRipple]="hasSentInviteTo(participant.email)"
              (click)="onResendInvite($event, participant)"
            >
              <mat-icon
                [class.disabled]="hasSentInviteTo(participant.email)"
                class="resendIcon"
                >mail_outline</mat-icon
              >
            </button>
          </app-one-participant>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="workspaceService.canManageWorkspace(workspace.participants)"
  >
    <app-float-button [callback]="openDialog.bind(this)"></app-float-button>
  </ng-container>
</ng-container>
