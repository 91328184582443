<div class="avatars">
  <span class="users-count" *ngIf="extraUserCount > 0"
    >{{ extraUserCount }}+</span
  >

  <ng-container *ngFor="let user of firstParticipants">
    <app-avatar
      class="single-avatar"
      [isClickable]="false"
      [size]="'verySmall'"
      [src]="user.photoThumbnailUrl || user.photoUrl"
    ></app-avatar>
  </ng-container>
</div>
