<ng-container>
  <app-loading
    *ngIf="!workspaceService.workspace && loadInProgress"
  ></app-loading>

  <ng-container *ngIf="workspaceService.workspace">
    <div class="page-container">
      <div class="showspace-container">
        <div class="banner">
          <img
            *ngIf="isBannerEditVisible"
            class="banner-edit-icon banner-edit-position"
            src="assets/workspaces/banner/icon-change-banner.svg"
            alt="change banner"
            (click)="openBannerDialog()"
          />
          <img
            src="{{ bannerUrl }}"
            class="banner-image"
            alt="banner"
            #bannerImg
          />
        </div>

        <div fxLayout="column">
          <div
            class="workspace"
            fxLayout="row"
            fxLayoutAlign="center none"
            #workspace
          >
            <div class="central-block" fxFlex="530px" fxFlex.lt-sm="100">
              <div class="description">
                <span>{{ workspaceService.workspace.title }}</span>
                <span
                  *ngIf="workspaceService.workspace.channelId"
                  style="float: right"
                >
                  <button
                    class="chat"
                    [ngClass.xs]="'mini'"
                    mat-button
                    [routerLink]="[
                      '/' + routes.chatsHome,
                      workspaceService.workspace.channelId
                    ]"
                  >
                    <img
                      src="../../../../assets/sidebar/chat_a.svg"
                      class="white"
                      alt="Chats"
                    />
                    <span fxShow fxHide.lt-sm="true">START CHAT</span>
                  </button>
                </span>
              </div>

              <div class="one-group">
                <div class="first">Date</div>
                <div class="second">
                  {{
                    utils.getDateRange(
                      this.workspaceService.workspace.dateStart,
                      this.workspaceService.workspace.dateEnd
                    )
                  }}
                </div>
              </div>

              <div
                class="one-group"
                *ngIf="workspaceService.workspace.location"
              >
                <div class="first">Location</div>
                <div class="second">
                  <span
                    [innerHTML]="workspaceService.workspace.location | linky"
                  ></span>
                </div>
              </div>

              <div class="one-group" *ngIf="workspaceService.workspace.siteUrl">
                <div class="first">Link</div>
                <div class="second">
                  <span
                    [innerHTML]="workspaceService.workspace.siteUrl | linky"
                  ></span>
                </div>
              </div>

              <div class="one-group">
                <div class="first">Description</div>
                <div class="second last multiline-text">
                  <span
                    [innerHTML]="workspaceService.workspace.description | linky"
                  ></span>
                </div>
              </div>

              <div style="padding-block: 15px">
                <button
                  class="apg-button accent2-button small-button"
                  matTooltip="Advertise and sell tickets for your show!"
                  matTooltipPosition="right"
                  matTooltipHideDelay="0"
                  matTooltipShowDelay="600"
                  matTooltipClass="apg-tooltip"
                  *ngIf="isEventPageVisible && publicEventRoute"
                  [routerLink]="'/' + publicEventRoute"
                >
                  Show Page
                </button>
              </div>
            </div>
          </div>

          <div
            fxLayout="row"
            #header
            [class]="['header-links', 'top', 'white']"
            fxFlex="44px"
          >
            <ng-container *ngFor="let menuItem of menuItems">
              <app-selectable-button
                [menuItem]="menuItem"
                [isSelected]="tab === menuItem.route"
                (clicked)="setActive(menuItem.route)"
              >
              </app-selectable-button>
            </ng-container>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" fxFill>
            <div
              class="workspace-sections"
              [class.conflicts-container]="tab === routes.workspaceConflicts"
            >
              <app-one-workspace-view-feed-news
                *ngIf="tab === routes.workspaceMessages"
                [workspace]="workspaceService.workspace"
              ></app-one-workspace-view-feed-news>
              <app-events *ngIf="tab === routes.workspaceEvents"></app-events>
              <app-archive
                *ngIf="tab === routes.workspaceArchive"
              ></app-archive>
              <app-participants
                *ngIf="tab === routes.workspaceParticipants"
                [workspace]="workspaceService.workspace"
              ></app-participants>
              <app-conflicts
                *ngIf="
                  tab === routes.workspaceConflicts &&
                  profileService.isAdminOrManager(
                    workspaceService.workspace.participants
                  )
                "
              ></app-conflicts>
              <app-task-list
                [workspace]="workspaceService.workspace"
                *ngIf="tab === routes.workspaceTasks"
                [type]="taskViewType"
              ></app-task-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
