import { Component, OnInit } from '@angular/core';
import { WorkspaceEventsService } from '../../../../services/events/workspace-events.service';
import { OneWorkspaceService } from '../../../../services/workspace/one-workspace.service';
import { MatDialog } from '@angular/material/dialog';
import { WorkspaceEvent } from '../../../../models/workspaceEvent';
import { Attendance } from '../../../../models/participant';
import { ProfileService } from '../../../../services/user/profile/profile.service';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { RouteWatcherService } from '../../../../services/utils/route.watcher.service';
import { GlobalConstants } from '../../../../common/global-constants';
import { first, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { WorkspaceEventManager } from '../../../../services/utils/workspace-event-manager';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  constructor(
    private routeWatcher: RouteWatcherService,
    public eventsService: WorkspaceEventsService,
    public oneWorkspaceService: OneWorkspaceService,
    private workspaceEventManager: WorkspaceEventManager,
    public dialog: MatDialog,
    public profile: ProfileService
  ) {}

  attendance = Attendance;
  events: WorkspaceEvent[] = [];
  page = 0;
  limit = 20;
  throttle = 50;
  scrollDistance = 4;
  elem = 'mat-drawer-content';
  workspaceId = 0;
  loadInProgress = false;

  private static scrollToEvent(eventId: number): void {
    const element = document.getElementById('workspace-event-' + eventId);
    if (element) {
      elementScrollIntoView(element, {
        behavior: 'smooth',
        inline: 'start',
        block: 'start'
      });
    }
  }

  private static scrollToTopEvent(): void {
    const element = document.getElementsByClassName('description').item(0);
    if (element) {
      elementScrollIntoView(element, {
        behavior: 'smooth',
        inline: 'start',
        block: 'start'
      });
    }
  }

  ngOnInit(): void {
    this.workspaceId = this.oneWorkspaceService.workspace?.workspaceId ?? 0;
    this.loadEvents().subscribe(() => this.setInitialScrollPosition());
  }

  private loadEvents(cleanUp: boolean = true): Observable<any> {
    this.loadInProgress = true;
    return this.eventsService
      .getEvents(this.workspaceId, this.limit, this.page)
      .pipe(
        tap((items) => {
          this.storeEvents(items, cleanUp);
          this.loadInProgress = false;
        }),
        first()
      );
  }

  private reloadCurrentEvents(): Observable<any> {
    return this.eventsService
      .getEvents(this.workspaceId, this.limit * (this.page + 1), 0)
      .pipe(
        tap((items) => this.storeEvents(items)),
        first()
      );
  }

  private setInitialScrollPosition(): void {
    setTimeout(() => {
      if (
        this.routeWatcher.previousRoute?.routePath ===
        GlobalConstants.routes.workspaceEvent
      ) {
        const eventId: number = Number(
          this.routeWatcher.previousRoute.routeParams?.id
        );

        if (eventId > 0) {
          this.scrollToEventAboveSpecified(eventId);
          return;
        }
      }
      this.scrollToLastEventInPast();
    }, 100);
  }

  onEventDeleted(event: WorkspaceEvent): void {
    const deleteIndex = this.events.findIndex(
      (ev) => ev.eventId === event.eventId
    );
    this.events.splice(deleteIndex, 1);
  }

  openAddEvent(): void {
    this.workspaceEventManager.createEvent(
      this.oneWorkspaceService.workspace,
      null,
      this.reloadAndScrollToEventAbove.bind(this)
    );
  }

  private reloadAndScrollToEventAbove(eventId: number): void {
    this.reloadCurrentEvents().subscribe(() => {
      setTimeout(() => {
        this.scrollToEventAboveSpecified(eventId);
      }, 100);
    });
  }

  private storeEvents(events: WorkspaceEvent[], cleanUp: boolean = true): void {
    if (cleanUp) {
      this.events = [];
    }
    this.events.push(...events);
  }

  onScrollDown(): void {
    this.page++;
    this.loadEvents(false).subscribe();
  }

  public scrollToLastEventInPast(): void {
    if (this.events.length < 2 || !this.events[0].isPastEvent) {
      // nothing to scroll || there is no past events so don't need to scroll
      return;
    }
    // searching for the last event in past
    EventsComponent.scrollToEvent(
      this.events
        .slice()
        .reverse()
        .find((e) => e.isPastEvent)?.eventId || 0
    );
  }

  /**
   * Looking for an element which is placed before specified eventId and scrolls to it
   * @param eventId specified eventId
   */
  private scrollToEventAboveSpecified(eventId: number): void {
    const eventIndex = this.events.findIndex(
      (event: WorkspaceEvent) => eventId === event.eventId
    );
    if (eventIndex > 0) {
      // scrolling to event which is placed before the target event
      const eventIdToScroll = this.events[eventIndex - 1].eventId;
      EventsComponent.scrollToEvent(eventIdToScroll);
    } else {
      EventsComponent.scrollToTopEvent();
    }
  }
}
