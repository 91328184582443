import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUpload } from 'primeng/fileupload';
import { DocumentService } from '../../../services/archive/document.service';
import { LibraryService } from '../../../services/archive/library.service';
import { HttpResponse } from '@angular/common/http';
import { SnackbarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  maxSize = 52428800;
  allowMultipleUpload = true;

  bytesTransferred = 0;
  totalBytes = 0;

  files: File[] = [];
  uploadedFiles: File[] = [];
  rejectedFiles: File[] = [];

  @ViewChild('fileUploader') uploader?: FileUpload;

  constructor(
    public docService: DocumentService,
    public archiveService: LibraryService,
    private snackBar: SnackbarService,
    public dialogRef: MatDialogRef<FileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FileUploadData
  ) {}

  ngOnInit(): void {}

  private processUploadedFile(event: any, file: File): void {
    this.uploadedFiles.push(file);
    this.bytesTransferred += 1; // Additional step for calling backend...
    this.updateStatus();

    if (this.uploader) {
      const index = this.uploader.files.indexOf(file);
      this.uploader.remove(event, index);
    }
  }

  private processRejectedFile(event: any, file: File, error: any): void {
    this.rejectedFiles.push(file);
    this.bytesTransferred += 1; // Additional step for calling backend...

    this.updateStatus();
  }

  private updateStatus(): void {
    if (!this.uploader) {
      return;
    }

    this.uploader.progress =
      this.totalBytes > 0
        ? Math.min(100.0, (this.bytesTransferred / this.totalBytes) * 100.0)
        : 0;
    this.uploader.onProgress.emit(Math.round(this.uploader.progress));

    if (
      this.uploadedFiles.length + this.rejectedFiles.length ===
      this.files.length
    ) {
      const message =
        this.rejectedFiles.length > 0
          ? 'Failed uploading some files. Please try again.'
          : 'All files uploaded.';
      this.snackBar.open(message);

      this.uploader.disabled = false;
      this.uploader.uploading = false;

      if (this.rejectedFiles.length === 0) {
        // Close the dialog upon success.
        this.dialogRef.close();
      }
    }
  }

  isUploaded(file: File): boolean {
    return this.uploadedFiles.indexOf(file) >= 0;
  }

  onUpload(event: any): void {
    if (!this.uploader || !this.data.workspaceId) {
      return;
    }

    if (this.uploader.uploading) {
      return;
    }

    this.bytesTransferred = 0;
    this.totalBytes = 0;

    this.files = [];
    this.uploadedFiles = [];
    this.rejectedFiles = [];

    // First calculate total progress...
    for (const file of this.uploader.files) {
      this.totalBytes += file.size;
      this.totalBytes += 1; // Additional step for calling backend...
      this.files.push(file);
    }

    this.uploader.disabled = true;
    this.uploader.uploading = true;

    this.updateStatus();

    // Queue uploads...
    for (const file of this.uploader.files) {
      let transferred = 0;
      let finished = false;
      this.archiveService
        .uploadAttachment(file, this.data.workspaceId)
        .subscribe(
          (r: any) => {
            if (r instanceof HttpResponse && r?.body) {
              this.processUploadedFile(event, file);
              finished = true;
            } else if (r.loaded !== undefined && r.total) {
              this.bytesTransferred -= transferred;
              transferred = r.loaded;

              this.bytesTransferred += r.loaded;

              if (r.loaded !== r.total && r.total > 0 && !finished) {
                this.updateStatus();
              }
            } else if (!r.total) {
              transferred = 0;
            }
          },
          (error: any) => {
            console.log(error);
            this.processRejectedFile(event, file, error);
          }
        );
    }
  }

  onProgress(event: any): void {
    if (!this.uploader) {
      return;
    }
    this.uploader.progress = event;
  }

  clearFile(file: File, event: MouseEvent): void {
    if (!this.uploader) {
      return;
    }

    const index = this.uploader.files.indexOf(file);
    this.uploader.remove(event, index);
  }
}

export interface FileUploadData {
  workspaceId?: number;
}
