import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../services/breadcrumb/breadcrumb.service';
import { EventTypes, WorkspaceEvent } from '../../models/workspaceEvent';
import { WorkspaceEventsService } from '../../services/events/workspace-events.service';
import { Attendance, toExtendedParticipant } from '../../models/participant';
import { UtilsService } from '../../services/utils/utils.service';
import {
  EventBusNames,
  EventBusService
} from '../../services/event-bus/event-bus.service';
import { ProfileService } from '../../services/user/profile/profile.service';
import { DialogEventReportComponent } from '../../component/dialog/add-update/dialog-event-report/dialog-event-report.component';
import { MatDialog } from '@angular/material/dialog';
import { EventReportService } from '../../services/event-report/event-report.service';
import { EventReport } from '../../models/EventReport';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../../models/workspace';
import { OneWorkspaceService } from '../../services/workspace/one-workspace.service';
import { StringUtils } from '../../common/string-utils';
import { MentionableNodeUtils } from '../../directive/mentionable.node.utils';
import { Subject } from 'rxjs';
import { attendanceUtils } from 'src/app/component/attendance-label/attendance.utils';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: [
    './event.component.scss',
    '../workspace/one-workspace-view/one-workspace-view.component.scss'
  ]
})
export class EventComponent implements OnInit, OnDestroy {
  public headerWhite = false;
  public activeTab = 0;
  public workspaceEvent: WorkspaceEvent | null;
  public workspace: Workspace | undefined;
  public allAttendanceTypes = Attendance;
  public eventReport: EventReport = new EventReport();
  loadInProgress = false;

  dateFormat = 'ddd, MMM D, h:mm a';

  attendanceStatuses: string[] = [];

  private onDestroy = new Subject<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    public eventService: WorkspaceEventsService,
    public breadcrumbService: BreadcrumbService,
    public utils: UtilsService,
    public workspaceService: OneWorkspaceService,
    private eventBusService: EventBusService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private eventReportService: EventReportService
  ) {
    this.workspaceEvent = null;
    this.activatedRoute.params.subscribe((params) => {
      setTimeout(() => {
        this.breadcrumbService.setAlias('', 3);
        this.breadcrumbService.setAlias('', 1);
      }, 0);
      this.workspaceService
        .getWorkspaceById(params.workspacesId)
        .then((workspace: Workspace) => {
          this.breadcrumbService.setAlias(workspace.title, 1);
          this.workspace = workspace;
          this.setEventById(params.id, workspace.workspaceId);
        });
    });
  }

  get canEditReport(): boolean {
    if (this.workspace) {
      return this.profileService.isAdminOrManager(this.workspace.participants);
    }
    return false;
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  static eventReportTitle(type: EventTypes | undefined): string {
    switch (type) {
      case EventTypes.Audition:
        return 'Audition report';
      case EventTypes.Class:
        return 'Class report';
      case EventTypes.Rehearsal:
        return 'Rehearsal report';
      case EventTypes.Performance:
        return 'Performance report';
      default:
        return 'Report';
    }
  }

  ngOnInit(): void {
    this.eventBusService.on(
      EventBusNames.workspaceEvent,
      () => {
        this.setEventById(
          this.workspaceEvent?.eventId,
          this.workspaceEvent?.workspaceId
        );
      },
      this.onDestroy
    );

    this.eventBusService.on(
      EventBusNames.deletedReport,
      () => {
        this.setEventById(
          this.workspaceEvent?.eventId,
          this.workspaceEvent?.workspaceId
        );
      },
      this.onDestroy
    );
  }

  setActive(linkId: number): void {
    this.activeTab = linkId;
  }

  setEventById(id: number | undefined, workspaceId: number | undefined): void {
    if (id && workspaceId) {
      this.loadInProgress = true;
      this.eventService
        .getEventById(id, workspaceId)
        .then(
          (event: WorkspaceEvent) => {
            const newEvent = new WorkspaceEvent(event.eventId).deserialize(
              event
            );
            this.workspaceEvent = newEvent;
            this.loadInProgress = false;
            this.highlightMentionedParticipant();
            this.updateStatuses(newEvent);
            this.breadcrumbService.setAlias(event.name, 3);
          },
          () => {
            this.loadInProgress = false;
          }
        )
        .then(() => {
          this.updateReportInfo();
        });
    }
  }

  private updateStatuses(event: WorkspaceEvent): void {
    this.attendanceStatuses = [
      'Attending',
      event.isNotStarted ? 'Not Going' : 'Absent',
      'Undecided'
    ];
  }

  updateReportInfo(): void {
    if (this.workspaceEvent?.workspaceId && this.workspaceEvent.eventId) {
      this.eventReportService
        .getReport(this.workspaceEvent.workspaceId, this.workspaceEvent.eventId)
        .then(
          (eventReport: EventReport) => {
            this.eventReport = eventReport;
            this.addTitle();
          },
          (err) => {
            if (
              err instanceof HttpErrorResponse &&
              err.error.status === 'NOT_FOUND'
            ) {
              this.eventReport = new EventReport();
              this.addTitle();
            }
          }
        );
    }
  }

  shouldHideAttendanceButtons(event: WorkspaceEvent): boolean {
    return !this.isUnmarkedEvent(event) && event.isPastEvent;
  }

  isUnmarkedEvent(event: WorkspaceEvent): boolean {
    return (
      this.eventService.currentUserStatusFor(event) === Attendance.Unmarked
    );
  }

  currentStatusForDisplay(event: WorkspaceEvent): string {
    return attendanceUtils.attendanceLabel[
      this.eventService.currentUserStatusFor(event)
    ];
  }

  isVisibleBlockParticipation(): boolean {
    if (this.profileService.user?.userId && this.workspaceEvent) {
      const user = this.eventService.getOneParticipantByUserId(
        this.profileService.user.userId,
        this.workspaceEvent
      );
      return user !== undefined;
    }
    return false;
  }

  openEventReportDialog(): void {
    if (this.workspaceEvent?.type) {
      const dialogRef = this.dialog.open(DialogEventReportComponent, {
        panelClass: 'popup-dialog',
        data: {
          type: this.eventReport?.type
            ? this.eventReport.type
            : this.workspaceEvent.type,
          isCanEdit: this.canEditReport,
          eventReport: this.eventReport,
          workspaceEvent: this.workspaceEvent
        }
      });

      dialogRef.afterClosed().subscribe(() => {
        this.updateReportInfo();
      });
    }
  }

  private addTitle(): void {
    if (this.eventReport.title === null) {
      this.eventReport.title = EventComponent.eventReportTitle(
        this.workspaceEvent?.type
      );
    }
  }

  private highlightMentionedParticipant(): void {
    if (this.workspaceEvent && this.workspace) {
      let description = this.workspaceEvent.description || '';
      for (const participant of this.workspace.participants.map((p) =>
        toExtendedParticipant(p)
      )) {
        if (description.includes('@' + participant.getPrintedName())) {
          const htmlParticipant = StringUtils.format(
            MentionableNodeUtils.MENTIONED_PARTICIPANT_HTML_TEMPLATE,
            participant.getId().toString(),
            participant.getPrintedName()
          );
          // TODO: replaceAll - standard 2021
          // @ts-ignore
          description = description.replaceAll(
            '@' + participant.getPrintedName(),
            htmlParticipant
          );
        }
      }
      this.workspaceEvent.description = description;
    }
  }
}
