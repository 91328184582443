import { RoleEducationDataSource } from './role-education-datasource';

export class Character implements RoleEducationDataSource {
  characterId: number;
  role: string;
  performanceLocation: string;
  performanceDate: string;

  constructor(
    role: string,
    performanceLocation: string,
    performanceDate: string,
    id: number
  ) {
    this.role = role;
    this.performanceLocation = performanceLocation;
    this.performanceDate = performanceDate;
    this.characterId = id;
  }

  get firstField(): string {
    return this.role;
  }

  get secondField(): string {
    return this.performanceLocation;
  }

  get thirdField(): string {
    return this.performanceDate;
  }

  get id(): number {
    return this.characterId;
  }
}
