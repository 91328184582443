import { DisclaimerModule } from './../disclaimer/disclaimer.module';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [DisclaimerModule],
  exports: [PrivacyPolicyComponent]
})
export class PrivacyPolicyModule {}
