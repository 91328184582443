<ng-container *ngIf="post">
  <p *ngIf="editing === false || post.postId !== postService.getPostId()">
    <ng-container *ngIf="moreShow">
      <span
        class="description-text no-less"
        #description
        [innerHTML]="post.description | linky"
        >{{ post.description }}</span
      >
      <span class="more" (click)="setMore(false)"> More</span>
    </ng-container>
    <ng-container *ngIf="lessShow">
      <span
        class="description-text less"
        #descriptionLess
        [innerHTML]="post.description | linky"
        >{{ post.description }}</span
      >
    </ng-container>
  </p>

  <ng-container *ngIf="editing && post.postId === postService.getPostId()">
    <p>
      <app-input-write-post-and-comment
        [defaultValue]="post.description || ''"
        [focusAuto]="true"
        [callback]="editPostReply.bind(this)"
        [callbackBad]="notSave.bind(this)"
        [fullscreen]="fullscreen"
        [id]="post.postId"
        [fnSearchParticipant]="fnSearchParticipant"
      ></app-input-write-post-and-comment>
    </p>
  </ng-container>
</ng-container>
