import { MatTabsModule } from '@angular/material/tabs';
import { TaskCardModule } from '../task-card/task-card.module';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TaskListComponent } from './task-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

@NgModule({
  imports: [
    SharedModule,
    TaskCardModule,
    MatTabsModule,
    NgxMatMomentModule,
    InfiniteScrollModule
  ],
  declarations: [TaskListComponent],
  exports: [TaskListComponent]
})
export class TaskListModule {}
