import { Component } from '@angular/core';
import { LibraryService } from '../../../../services/archive/library.service';
import { OneWorkspaceService } from '../../../../services/workspace/one-workspace.service';
import { GlobalConstants } from '../../../../common/global-constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent {
  private workspaceId = 0;

  sections: { label: string; route: string; icon: string }[] = [];

  constructor(
    public archiveService: LibraryService,
    private activatedRoute: ActivatedRoute,
    public oneWorkspaceService: OneWorkspaceService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.workspaceId = params.id ?? this.workspaceId;
      const path = 'assets/workspaces/archive/';
      this.sections = [
        {
          label: 'Documents',
          route:
            '/' +
            GlobalConstants.routes.workspaces +
            '/' +
            this.workspaceId +
            '/library/documents',
          icon: path + 'document-archive.svg'
        },
        {
          label: 'Audio',
          route:
            '/' +
            GlobalConstants.routes.workspaces +
            '/' +
            this.workspaceId +
            '/library/audio',
          icon: path + 'audio-archive.svg'
        },
        {
          label: 'Media',
          route:
            '/' +
            GlobalConstants.routes.workspaces +
            '/' +
            this.workspaceId +
            '/library/media',
          icon: path + 'media-archive.svg'
        },
        {
          label: 'Links',
          route:
            '/' +
            GlobalConstants.routes.workspaces +
            '/' +
            this.workspaceId +
            '/library/links',
          icon: path + 'links-archive.svg'
        }
      ];
    });
  }
}
