<ng-container *ngIf="assignees?.length">
  <mat-chip-list #toListElement>
    <mat-chip *ngFor="let assignee of assignees; trackBy: assigneesTrackByFn">
      <span
        *ngIf="
          assignee.name?.trim() || assignee.surname?.trim();
          else showEmail
        "
        >{{ (assignee.name + ' ' + assignee.surname).trim() }}</span
      >

      <ng-template #showEmail>
        <span>{{ assignee.email }}</span>
      </ng-template>
    </mat-chip>
  </mat-chip-list>
</ng-container>
