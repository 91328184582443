import { Injectable } from '@angular/core';
import { GlobalConstants } from '../../common/global-constants';
import { Observable, of } from 'rxjs';
import { PublicPageData } from '../../models/publicPage';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ReceiptsSlice } from '../../modules/receipts/receipt';

@Injectable({
  providedIn: 'root'
})
export class PublicEventService {
  private routes = GlobalConstants.publicEventRoutes;

  publicEventPageRoute(workspaceId: number): string {
    return this.routes.publicEventBase + '/' + workspaceId;
  }

  publicEventDraftRoute(workspaceId: number): string {
    return this.publicEventPageRoute(workspaceId) + '/draft';
  }

  constructor(private http: HttpClient) {}

  fetchPublicPageInfo(workspaceId: number): Observable<PublicPageData> {
    const url =
      environment.url + `workspaces/${workspaceId}/public/performance`;
    return this.http.get<PublicPageData>(url).pipe(
      map((response) => {
        return new PublicPageData().deserialize(response);
      }),
      catchError((err) =>
        this.handleError('fetch public page', new PublicPageData(), err)
      )
    );
  }

  checkSalesStatus(workspaceId: number): Observable<boolean> {
    const url = environment.url + `workspaces/${workspaceId}/sales/status`;
    return this.http.get<boolean>(url).pipe(
      map((response: any) => response.ready),
      catchError((err) => this.handleError('fetch public page', false, err))
    );
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }
}
