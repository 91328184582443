import { Injectable } from '@angular/core';
import { ExtendedParticipant } from '../../models/participant';
import * as moment from 'moment/moment';
import { Moment } from 'moment/moment';
import { Post, PostTopic } from '../../models/post';
import { StringUtils } from '../../common/string-utils';
import { MentionableNodeUtils } from '../../directive/mentionable.node.utils';
import { GlobalConstants } from '../../common/global-constants';
import { ICalendar } from 'datebook';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {}

  public static toLocalDate(date: string | undefined): Date {
    return date ? moment.utc(date).local().toDate() : new Date();
  }

  openLinkInSameTab(url: string): void {
    window.open(url, '_self');
  }

  openLink(url: string): void {
    const link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    link.download = '';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  isValidUrl(urlString: string): boolean {
    let url: URL;

    try {
      url = new URL(urlString);
    } catch (_) {
      return false;
    }
    return url !== null;
  }

  getDateRange(
    dateStart: string | undefined,
    dateEnd: string | undefined,
    format: string = 'MMM, D h:mm a'
  ): string {
    if (dateStart) {
      return (
        moment.utc(dateStart).local().format(format) +
        (dateEnd ? ' - ' + moment.utc(dateEnd).local().format(format) : '')
      );
    }
    return '';
  }

  dateToDefaultString(date: Moment | string): string {
    return moment(date).format('YYYY-MM-DD');
  }

  highlightMentionedPostTopicParticipants(
    postTopic: PostTopic,
    availableParticipants?: Array<ExtendedParticipant>
  ): void {
    if (postTopic && availableParticipants) {
      this.highlightMentionedPostParticipants(postTopic, availableParticipants);
      for (const childPost of postTopic.children) {
        this.highlightMentionedPostParticipants(
          childPost,
          availableParticipants
        );
      }
    }
  }

  highlightMentionedPostParticipants(
    post: Post,
    availableParticipants?: Array<ExtendedParticipant>
  ): void {
    if (post.mentionedUserIds) {
      for (const mentionedUserId of post.mentionedUserIds) {
        const foundParticipants = availableParticipants?.filter(
          (participant) => participant.getId() === mentionedUserId
        );
        if (!foundParticipants || foundParticipants.length === 0) {
          console.error(
            'Could not find mentioned participant in ' +
              GlobalConstants.i18n.showspace
          );
          continue;
        }

        const mentionedParticipant: ExtendedParticipant = foundParticipants[0];
        const htmlParticipant = StringUtils.format(
          MentionableNodeUtils.MENTIONED_PARTICIPANT_HTML_TEMPLATE,
          mentionedParticipant.getId().toString(),
          mentionedParticipant.getPrintedName()
        );

        // TODO: replaceAll - standard 2021
        // @ts-ignore
        post.description = post.description.replaceAll(
          '@' + mentionedParticipant.getPrintedName(),
          htmlParticipant
        );
      }
    }
  }

  isTabletOrMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent
    );
  }

  isMacOrIOS(): boolean {
    const platform = window.navigator?.userAgent;
    const isMacOS = platform.includes('Mac');
    const isIOS = this.getMobileOperatingSystem() === 'iOS';
    return isMacOS || isIOS;
  }

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   */
  getMobileOperatingSystem(): string {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710 ie - deprecated/not support
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }

  isEmail(str: string): boolean {
    const pattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    const res = str.match(pattern);
    return res !== null && res.length === 1;
  }

  downloadICalendar(calendar: ICalendar, title: string) {
    const ics = calendar.render();

    const blob = new Blob([ics], {
      type: 'text/calendar'
    });

    saveAs(blob, title);
  }
}
