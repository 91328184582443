<div class="new-calendar-event-dialog">
  <div class="titleBlock">
    <h1 class="dialog-message">What do you want to create?</h1>
  </div>
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
      <button
        class="apg-button accent2-button"
        (click)="openCreateEventDialog()"
      >
        EVENT
      </button>
      <button class="apg-button red-button" (click)="openCreateTaskDialog()">
        TASK
      </button>
    </div>
  </mat-dialog-content>
</div>
