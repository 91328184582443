export class ArrayUtils {
  public static equals(a: Array<any>, b: Array<any>): boolean {
    if (a === b) {
      return true;
    }

    if (a == null || b == null) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; ++i) {
      if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) {
        return false;
      }
    }

    return true;
  }

  public static equalsIgnoreOrder(
    a: Array<any>,
    b: Array<any>,
    comparator: ((a: any, b: any) => number) | null = null
  ): boolean {
    if (a === b) {
      return true;
    }

    if (a == null || b == null) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    if (comparator == null) {
      a.sort();
      b.sort();
    } else {
      a.sort(comparator);
      b.sort(comparator);
    }

    for (let i = 0; i < a.length; ++i) {
      if (JSON.stringify(a[i]) !== JSON.stringify(b[i])) {
        return false;
      }
    }

    return true;
  }
}
