import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkspaceBannerService } from '../../../../services/workspace/workspace-banner.service';
import { MatSlider, MatSliderChange } from '@angular/material/slider';
import { ImageCropperComponent } from '../../../../component/image-cropper/image-cropper.component';

@Component({
  selector: 'app-dialog-banner',
  templateUrl: './dialog-banner.component.html',
  styleUrls: ['./dialog-banner.component.scss']
})
export class DialogBannerComponent implements AfterViewInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BannerDialogData,
    private bannerService: WorkspaceBannerService,
    private dialogRef: MatDialogRef<DialogBannerComponent>
  ) {}

  @ViewChild('inputFile') inputFile?: ElementRef;
  @ViewChild('imageCropper') imageCropper?: ImageCropperComponent;
  @ViewChild('zoomSlider') zoomSlider?: MatSlider;
  @ViewChild('divElement') divElement?: ElementRef;

  cropperWidth = 670;

  @Output() changeBanner: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit(): void {
    const isMobile =
      screen.width < 415 ||
      (screen.orientation.type === 'landscape-primary' && screen.height < 400);

    if (isMobile) {
      this.cropperWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
    }

    if (!this.data.src) {
      this.selectPhoto();
    }
  }

  onResize($event?: any): void {
    const width = $event ? $event.target.innerWidth : window.innerWidth;
    let cropperWidth =
      this.divElement?.nativeElement.getElementsByClassName('popup-content')[0]
        .offsetWidth;

    if (width > 600) {
      cropperWidth -= 60;
    }

    if (this.zoomSlider) {
      this.zoomSlider.value = 0;
    }
    this.imageCropper?.updateWidth(cropperWidth);
  }

  get currentWindowWidth(): number {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  selectPhoto(): void {
    this.inputFile?.nativeElement.click();
  }

  handleFileInput(event: any): void {
    const file = event.target?.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          if (this.zoomSlider) {
            this.zoomSlider.value = 0;
          }

          this.imageCropper?.changeImage(reader.result);
        }
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    }
  }

  apply(): void {
    if (this.imageCropper && this.imageCropper.nativeElementSrc) {
      this.data.imageSrc = this.imageCropper.crop();

      this.bannerService
        .uploadBanner(this.data.workspaceId, this.data)
        .then(() => {
          this.changeBanner.emit();
        });
    }
  }

  get isDisabledButtons(): boolean {
    return !this.imageCropper?.nativeElementSrc;
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  deletePhoto(): void {
    this.bannerService.deleteBanner(this.data.workspaceId).then(() => {
      this.changeBanner.emit();
    });
  }

  onZoom($event: MatSliderChange): void {
    if ($event.value !== null) {
      this.imageCropper?.getScaleFromValue($event.value);
    }
  }

  // changeAngle($event: MatSliderChange): void {
  //   if ($event.value !== null) {
  //     this.imageCropper?.changeAngle($event.value);
  //   }
  // }

  // rotateImage(): void {
  //   this.imageCropper?.rotateImage();
  // }
}

export interface BannerDialogData {
  bannerImg: HTMLImageElement;
  imageSrc: string;
  workspaceId: number;
  src: string | null;
}
