import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { NewCalendarEventComponent } from './new-calendar-event.component';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [NewCalendarEventComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, FlexLayoutModule],
  exports: [NewCalendarEventComponent]
})
export class NewCalendarEventModule {}
