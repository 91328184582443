import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkspaceService } from '../../services/workspace/workspace.service';
import { AuthService } from '../../services/auth/auth.service';
import { ProfileService } from '../../services/user/profile/profile.service';
import {
  EventBusNames,
  EventBusService
} from '../../services/event-bus/event-bus.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Workspace } from '../../models/workspace';
import { Observable, Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';
import { User } from '../../models/user';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit, OnDestroy {
  page = 0;
  throttle = 50;
  limit = 10;
  scrollDistance = 4;
  elem = 'mat-drawer-content';
  loadInProgress = false;

  private defaultStatus: WorkspaceLoadStatus = 'active';
  currentTabStatus: WorkspaceLoadStatus = 'active';

  archivedWorkspaces: WorkspaceCollection;
  activeWorkspaces: WorkspaceCollection;

  private onDestroy = new Subject<void>();

  private heightOneWorkspace = 130;

  constructor(
    public workspaceService: WorkspaceService,
    public authService: AuthService,
    public profileService: ProfileService,
    public eventBusService: EventBusService
  ) {
    this.archivedWorkspaces = new WorkspaceCollection('archived');
    this.activeWorkspaces = new WorkspaceCollection('active');
    this.limit =
      Math.ceil(
        document.documentElement.clientHeight / this.heightOneWorkspace
      ) * 2;
  }

  ngOnInit(): void {
    if (this.profileService.user instanceof User) {
      if (this.authService.getToken()) {
        this.loadWorkspaces(true, this.defaultStatus);
      }
    } else {
      this.eventBusService.on(
        EventBusNames.profile,
        () => this.loadWorkspaces(true, this.defaultStatus),
        this.onDestroy
      );
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private loadWorkspaces(
    cleanUp: boolean = true,
    status: WorkspaceLoadStatus
  ): void {
    const collection =
      status === 'active' ? this.activeWorkspaces : this.archivedWorkspaces;
    this.loadWorkspacesByStatus(collection, cleanUp);
  }

  private loadWorkspacesByStatus(
    collection: WorkspaceCollection,
    cleanUp: boolean
  ): void {
    this.loadInProgress = true;
    this.getWorkspacesByStatus(collection.status)
      .pipe(finalize(() => (this.loadInProgress = false)))
      .subscribe((workspaces) => {
        if (cleanUp) {
          collection.workspaces = [];
        }

        if (workspaces) {
          collection.workspaces.push(...workspaces);
        }
      });
  }

  private getWorkspacesByStatus(
    status: WorkspaceLoadStatus
  ): Observable<Workspace[]> {
    return this.workspaceService
      .getWorkspaces(this.page, this.limit, status)
      .pipe(first());
  }

  onLoadWorkspaces(status: WorkspaceLoadStatus): void {
    this.page = 0;
    this.loadWorkspaces(true, status);
  }

  onScrollDown(status: WorkspaceLoadStatus): void {
    this.page++;
    this.loadWorkspaces(false, status);
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.currentTabStatus = event.index === 0 ? 'active' : 'archived';
    this.onLoadWorkspaces(this.currentTabStatus);
  }

  onDeletedWorkspace(workspace: Workspace): void {
    this.currentTabStatus === 'active'
      ? this.activeWorkspaces.remove(workspace)
      : this.archivedWorkspaces.remove(workspace);
  }

  onUpdatedWorkspace(workspace: Workspace): void {
    this.currentTabStatus === 'active'
      ? this.activeWorkspaces.update(workspace)
      : this.archivedWorkspaces.update(workspace);
  }

  onCreatedWorkspace(workspace: Workspace): void {
    this.currentTabStatus === 'active'
      ? this.activeWorkspaces.add(workspace)
      : this.archivedWorkspaces.add(workspace);
  }
}

export type WorkspaceLoadStatus = 'active' | 'archived';

export class WorkspaceCollection {
  status: WorkspaceLoadStatus;
  workspaces: Array<Workspace>;

  constructor(status: WorkspaceLoadStatus) {
    this.status = status;
    this.workspaces = [];
  }

  add(workspace: Workspace): void {
    this.workspaces.unshift(workspace);
  }

  remove(workspace: Workspace): void {
    const index = this.workspaces.findIndex(
      (item) => item.workspaceId === workspace.workspaceId
    );
    this.workspaces.splice(index, 1);
  }

  update(workspace: Workspace): void {
    const index = this.workspaces.findIndex(
      (item) => item.workspaceId === workspace.workspaceId
    );
    this.workspaces[index] = workspace;
  }
}
