import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { Post } from '../../models/post';

@Component({
  selector: 'app-avatar-for-post',
  templateUrl: './avatar-for-post.component.html',
  styleUrls: ['./avatar-for-post.component.scss']
})
export class AvatarForPostComponent implements OnInit {
  @Input() user: User | undefined;
  @Input() post: Post | undefined;
  @Input() date: string | undefined;
  @Input() size: 'veryBig' | 'big' | 'small' | 'verySmall' | 'default' =
    'small';

  classCss: string[] = [];

  constructor() {}

  ngOnInit(): void {
    this.classCss = [this.size];
  }
}
