export const environment = {
    production: false,
    debugTwilio: false,
    url: 'https://dev.actorspocketguide.com/',
    appUrl: 'https://web-dev.actorspocketguide.com/',
    wsUrl: 'https://dev-rtn.actorspocketguide.com/rts/rts-messaging',
    appVersion: require('../../package.json').version + '-dev',
    googleCalendar: {
        clientId: '954539990645-k7kao208udnhj9m8gcf5ub60t9tbd97n.apps.googleusercontent.com'
    },
    apiVersion: 9,
    firebase: {
        apiKey: "AIzaSyClkP_0KN8DqfttYipxAUexoMXkgfmZ85g",
        authDomain: "actor-s-pocket-guide.firebaseapp.com",
        databaseURL: "https://actor-s-pocket-guide.firebaseio.com",
        projectId: "actor-s-pocket-guide",
        storageBucket: "actor-s-pocket-guide.appspot.com",
        messagingSenderId: "954539990645",
        appId: "1:954539990645:web:bc684f8c2ec66abcc3a23c",
        measurementId: "G-QERVD5P936"
    }
};
