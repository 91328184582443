<div class="add-update-form task">
  <div *ngIf="showBackButton" class="closeElement">
    <mat-icon svgIcon="back-button" (click)="onSelectParticipants()"></mat-icon>
  </div>

  <div *ngIf="!showBackButton" class="closeElement" mat-dialog-close>
    <span class="material-icons">close</span>
  </div>

  <div class="titleBlock content-inputs">
    <h1 class="apg-title">
      {{ title }}

      <button
        class="mat-button btn-add-all-participants"
        *ngIf="!areAllParticipantsSelected"
        (click)="selectAllParticipants()"
      >
        Add All
      </button>

      <button
        class="mat-button btn-add-all-participants"
        *ngIf="areAllParticipantsSelected"
        (click)="clearSelectedParticipants()"
      >
        Remove All
      </button>
    </h1>
  </div>

  <mat-dialog-content class="participants-search-block">
    <div class="content-inputs">
      <div class="input-with-chip">
        <label class="color-text">To: </label>
        <mat-chip-list #toListElement>
          <mat-chip
            *ngFor="
              let participant of selectedParticipants;
              trackBy: participantsTrackByFn
            "
            (removed)="remove(participant)"
          >
            {{ participant.name + ' ' + participant.surname }}
            <mat-icon matChipRemove>close</mat-icon>
          </mat-chip>

          <input
            #searchInput
            class="input-for-chip emails"
            [matChipInputFor]="toListElement"
            (input)="onSearch($event)"
            #inputNewEmail
            placeholder="Search ..."
          />
        </mat-chip-list>
      </div>
    </div>

    <ng-container *ngIf="loadInProgress && !foundParticipants?.length">
      <app-loading></app-loading>
    </ng-container>

    <ng-container
      *ngIf="foundParticipants?.length"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="'mat-dialog-container'"
      (scrolled)="onScrollDown()"
      [fromRoot]="true"
    >
      <!-- a must to have 2, first is for mobile, second for desktop -->
      <div
        class="participants-container"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="'.task-dialog'"
        (scrolled)="onScrollDown()"
        [fromRoot]="true"
      >
        <app-participants-list
          [availableParticipants]="foundParticipants"
          [selectedParticipants]="selectedParticipants"
          (selectParticipant)="onSelectParticipant($event)"
          [subtitleOption]="'title'"
          [allowProfileNavigation]="false"
        ></app-participants-list>
      </div>
    </ng-container>

    <div class="apg-form-group" *ngIf="showSaveButton">
      <button
        mat-flat-button
        color="accent"
        class="btn upper"
        (click)="selectParticipants.emit(this.selectedParticipants)"
      >
        Save
      </button>
    </div>
  </mat-dialog-content>
</div>
