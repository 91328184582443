import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { CommonUser } from '../../../models/common.user';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../../component/dialog/dialog-confirm/dialog-confirm.component';
import { Complaint } from '../../../modules/admin-panel/models/Complaint';
import { PaginatedPage } from '../../../modules/admin-panel/models/PaginatedPage';
import { PaginatedPageParams } from '../../../modules/admin-panel/models/PaginatedPageParams';
import { SnackbarService } from '../../snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class UserReportingService {
  private readonly blockUserUrl: string = environment.url + 'users/block';
  private readonly unblockUserUrl: string = environment.url + 'users/unblock';
  private readonly blockedUsersListUrl: string =
    environment.url + 'users/blocked';
  private readonly complaintUrl: string = environment.url + 'complaints/v2';

  constructor(
    private http: HttpClient,
    private snackbar: SnackbarService,
    private dialog: MatDialog
  ) {}

  blockUserAndShowAlert(userId: number): void {
    const message = 'Are you sure you want to block the user?';
    const dialogData = new ConfirmDialogModel(message, 'Block', 'Cancel');
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: dialogData,
      panelClass: 'confirm-dialog-container'
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.blockUsers([userId]).subscribe(() => {
          this.snackbar.open(
            'User is blocked. You can unblock the user from your Profile - Account Settings - Block list section'
          );
        });
      }
    });
  }

  blockUsers(userIds: number[]): Observable<any> {
    return this.http
      .post(this.blockUserUrl, { userIds })
      .pipe(
        catchError((err) =>
          this.handleError('add users to block list', userIds, err)
        )
      );
  }

  unBlockUsers(userIds: number[]): Observable<any> {
    return this.http
      .post(this.unblockUserUrl, { userIds })
      .pipe(
        catchError((err) =>
          this.handleError('remove users from block list', userIds, err)
        )
      );
  }

  getBlockList(page: number = 0, limit: number = 20): Observable<CommonUser[]> {
    const params = { page, limit };
    return this.http
      .get<CommonUser[]>(this.blockedUsersListUrl, { params })
      .pipe(
        map((response) => {
          return response.map((user) => new CommonUser().deserialize(user));
        }),
        catchError((err) =>
          this.handleError('getting blocked users list', [], err)
        )
      );
  }

  reportUser(
    userId: number,
    comment: string = '',
    additionalParams: any = {}
  ): Observable<any> {
    return this.report('user', comment, userId, additionalParams);
  }

  reportWorkspace(
    workspaceId: number,
    userId?: number,
    comment: string = '',
    additionalParams: any = {}
  ): Observable<any> {
    return this.report('workspace', comment, userId, {
      workspaceId,
      ...additionalParams
    });
  }

  reportWorkspacePost(
    postId: number,
    userId: number,
    comment: string = '',
    additionalParams: any = {}
  ): Observable<any> {
    return this.report('workspace_post', comment, userId, {
      postId,
      ...additionalParams
    });
  }

  reportEvent(
    eventId: number,
    userId?: number,
    comment: string = '',
    additionalParams: any = {}
  ): Observable<any> {
    return this.report('event', comment, userId, {
      eventId,
      ...additionalParams
    });
  }

  reportChatMessage(
    channelSid: string,
    messageSid: string,
    userId: number,
    comment: string = '',
    additionalParams: any = {}
  ): Observable<any> {
    return this.report('chat_message', comment, userId, {
      channelSid,
      messageSid,
      ...additionalParams
    });
  }

  reportTask(
    taskId: number,
    userId?: number,
    comment: string = '',
    additionalParams: any = {}
  ): Observable<any> {
    return this.report('task', comment, userId, {
      taskId,
      ...additionalParams
    });
  }

  reportAttachment(
    attachmentId: number,
    userId?: number,
    comment: string = '',
    additionalParams: any = {}
  ): Observable<any> {
    return this.report('attachment', comment, userId, {
      attachmentId,
      ...additionalParams
    });
  }

  report(
    topic: string,
    comment: string = '',
    userId?: number,
    additionalParams: any = {}
  ): Observable<any> {
    return this.http
      .post(this.complaintUrl, {
        topic,
        comment,
        userId,
        anonymous: false, // Reserved for future use.
        additionalParams: JSON.stringify(additionalParams)
      })
      .pipe(catchError((err) => this.handleError('report ', topic, err)));
  }

  getComplaints(params: PaginatedPageParams): Promise<any> {
    const httpParams = new HttpParams()
      .set('count', params.pageSize.toString())
      .set('page', params.currentPage.toString())
      .set('sortingField', params.sortingField.toString());

    return lastValueFrom(
      this.http.get(this.complaintUrl, { params: httpParams })
    );
  }

  deleteComplaint(id: number): Promise<any> {
    const body = { ids: [id] };
    return lastValueFrom(this.http.post(this.complaintUrl + '/delete', body));
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }
}

export interface GetComplaintsResponse extends PaginatedPage {
  content: Complaint[];
}
