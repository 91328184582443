import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { DialogAddLinkModule } from './../../component/dialog/add-update/library-links/dialog-add-link.module';
import { CalendarFilterModule } from './../../component/dialog/calendar-filter/calendar-filter.module';
import { FullScreenImageModule } from './../../component/dialog/fullscreen-image/fullscreen-image.module';
import { NewCalendarEventModule } from './../../component/dialog/new-calendar-event/new-calendar-event.module';
import { TextInputModule } from './../../component/dialog/text-input/text-input.module';
import { DialogTextModule } from './../../component/dialog/dialog-text/dialog-text.module';
import { InputWritePostAndCommentModule } from './../../component/input/input-write-post-and-comment/input-write-post-and-comment.module';
import { ArchiveComponent } from './one-workspace-view/archive/archive.component';
import { TaskListModule } from '../tasks/task-list/task-list.module';
import { WorkspaceComponent } from './workspace.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { NgModule } from '@angular/core';
import { WORKSPACE_ROUTES } from './workspace.router';
import { NewWorkspaceCardComponent } from './new-workspace-card/new-workspace-card.component';
import { ConflictsComponent } from './one-workspace-view/conflicts/conflicts.component';
import { EventsComponent } from './one-workspace-view/events/events.component';
import { OneEventComponent } from './one-workspace-view/events/one-event/one-event.component';
import { FeedNewsComponent } from './one-workspace-view/feed-news/feed-news.component';
import { PostCommentComponent } from './one-workspace-view/feed-news/post-comment/post-comment.component';
import { PostReplayComponent } from './one-workspace-view/feed-news/post-reply/post-replay.component';
import { PostComponent } from './one-workspace-view/feed-news/post/post.component';
import { OneWorkspaceViewComponent } from './one-workspace-view/one-workspace-view.component';
import { OneWorkspaceComponent } from './one-workspace/one-workspace.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatIconModule } from '@angular/material/icon';
import { AttendanceLabelComponent } from 'src/app/component/attendance-label/attendance-label.component';
import { AttendanceToggleComponent } from 'src/app/component/attendance-toggle/attendance-toggle.component';
import { MatButtonModule } from '@angular/material/button';
import { PostDescriptionComponent } from 'src/app/component/post-description/post-description.component';
import { ReactionComponent } from 'src/app/component/reaction/reaction.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AvatarForPostComponent } from 'src/app/component/avatar-for-post/avatar-for-post.component';
import { AvatarsPreviewComponent } from 'src/app/component/avatars-preview/avatars-preview.component';
import { EventComponent } from '../event/event.component';
import { ParticipantsBlockComponent } from '../event/participants-block/participants-block.component';
import { CarouselModule } from 'primeng/carousel';
import { LinkyModule } from 'ngx-linky';
import { MentionableDirectiveModule } from 'src/app/directive/mentionable.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogAddUpdateEventComponent } from '../../component/dialog/add-update/event/dialog-add-update-event.component';
import { MatSelectModule } from '@angular/material/select';
import { DialogAddParticipantsComponent } from '../../component/dialog/add-update/participants/workspace/dialog-add-participants.component';
import { MatChipsModule } from '@angular/material/chips';
import { DialogAddEventParticipantsComponent } from '../../component/dialog/add-update/participants/event/dialog-add-event-participants.component';
import { DialogEventReportComponent } from '../../component/dialog/add-update/dialog-event-report/dialog-event-report.component';
import { RowTextareaComponent } from '../../component/row-textarea/row-textarea.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { ClearFormatOnPasteDirectiveModule } from '../../directive/clear-format-on-paste.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { DialogBannerComponent } from './one-workspace-view/banner-dialog/dialog-banner.component';
import { MatSliderModule } from '@angular/material/slider';
import { ImageCropperComponent } from '../../component/image-cropper/image-cropper.component';
import { SelectableButtonComponent } from '../../component/selectable-button/selectable-button.component';
import { ShowspaceTutorialModule } from '../../component/showspace-tutorial/showspace-tutorial.module';
import { DialogAddUpdateWorkspaceModule } from '../../component/dialog/add-update/workspace/dialog-add-update-workspace.module';
import { DialogAddWorkspaceParticipantsModule } from '../../component/dialog/add-update/participants/workspace/dialog-add-workspace-participants.module';
import { FileUploadModule } from 'src/app/component/dialog/file-upload/file-upload.module';
import { DialogMentionListModule } from 'src/app/component/dialog/dialog-mention-list/dialog-mention-list.module';
import { NotificationDialogModule } from '../auth-layout/header/notification/notification-dialog/notification-dialog.module';
import { OneNotificationModule } from '../auth-layout/header/notification/one-notification/one-notification.module';
import { WithoutAuthErrorsModule } from '../errors/without-auth-errors/without-auth-errors.module';
import { TermsOfUseModule } from '../texts/terms-of-use/terms-of-use.module';
import { PrivacyPolicyModule } from '../texts/privacy-policy/privacy-policy.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GroupSelectComponent } from './one-workspace-view/participants/group-select/group-select.component';

@NgModule({
  imports: [
    SharedModule,
    InfiniteScrollModule,
    MatIconModule,
    MatButtonModule,
    ClipboardModule,
    CarouselModule,
    LinkyModule,
    MentionableDirectiveModule,
    TaskListModule,
    ContenteditableValueAccessorModule,
    ClearFormatOnPasteDirectiveModule,
    GroupSelectComponent,

    WORKSPACE_ROUTES,
    MatDialogModule,
    NgxMatDatetimePickerModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatChipsModule,
    NgxSpinnerModule,
    MatTabsModule,
    MatSliderModule,
    InputWritePostAndCommentModule,
    ShowspaceTutorialModule,
    DialogAddUpdateWorkspaceModule,
    DialogAddWorkspaceParticipantsModule,

    NgxMatMomentModule,
    DialogTextModule,
    TextInputModule,
    NewCalendarEventModule,
    FullScreenImageModule,
    FileUploadModule,
    DialogMentionListModule,
    CalendarFilterModule,
    DialogAddLinkModule,
    NotificationDialogModule,
    OneNotificationModule,
    WithoutAuthErrorsModule,
    TermsOfUseModule,
    PrivacyPolicyModule,
    MatTooltipModule
  ],
  declarations: [
    WorkspaceComponent,
    AttendanceLabelComponent,
    AttendanceToggleComponent,
    NewWorkspaceCardComponent,
    OneWorkspaceComponent,
    OneWorkspaceViewComponent,
    FeedNewsComponent,
    PostComponent,
    PostCommentComponent,
    PostReplayComponent,
    EventsComponent,
    ConflictsComponent,
    OneEventComponent,
    PostDescriptionComponent,
    ReactionComponent,
    AvatarForPostComponent,
    AvatarsPreviewComponent,
    EventComponent,
    ArchiveComponent,
    ParticipantsBlockComponent,
    DialogAddUpdateEventComponent,
    DialogAddEventParticipantsComponent,
    DialogEventReportComponent,
    DialogBannerComponent,
    RowTextareaComponent,
    ImageCropperComponent,
    SelectableButtonComponent
  ],
  exports: [
    WorkspaceComponent,
    OneEventComponent,
    DialogAddParticipantsComponent
  ]
})
export class WorkspaceModule {}
