<ng-container *ngIf="availableParticipants?.length">
  <div class="container">
    <div class="color-text recently-invited-title" *ngIf="titleText">
      {{ titleText }}
    </div>

    <ng-template #tutorialPlaceholder></ng-template>

    <ng-container *ngFor="let participant of availableParticipants">
      <app-one-participant
        [participant]="participant"
        [allowProfileNavigation]="allowProfileNavigation"
        [subtitle]="subtitleText(participant)"
        (click)="clickRow(participant)"
      >
        <button
          class="participant-button"
          mat-icon-button
          (click)="clickRow(participant)"
        >
          <img [src]="'assets/icons/plus-white.svg'" alt="" />
        </button>
      </app-one-participant>
    </ng-container>
  </div>
</ng-container>
