import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../models/user';
import { Role } from '../../../models/role';
import {
  EventBusService,
  EventBusNames
} from '../../event-bus/event-bus.service';
import { Participant } from '../../../models/participant';
import OneSignal from 'onesignal-cordova-plugin';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    private eventBusService: EventBusService,
    private platform: Platform
  ) {
    eventBusService.on(EventBusNames.logout, this.clearUser.bind(this));
    eventBusService.on(EventBusNames.unauthorized, this.clearUser.bind(this));
    eventBusService.on(
      EventBusNames.authorized,
      this.requestCurrentUser.bind(this)
    );
  }

  private urlCurrentUser: string = environment.url + 'users/current';
  private urlSaveUserInfo: string = environment.url + 'users/profile';
  private urlDeleteCharacters: string =
    environment.url + 'users/profile/characters/delete';
  private urlDeleteEducations: string =
    environment.url + 'users/profile/educations/delete';
  private urlProfilePicture: string = environment.url + 'users/profile/picture';
  private urlUpdateDeviceSettings: string = environment.url + '/devicesettings';

  private token = '';

  user?: User;

  success(value: any): void {
    // Save token for later use: we have a special case for disabling push notifications...
    this.token = localStorage.getItem('token') ?? '';

    this.setUser(new User().deserialize(value));

    if (this.platform.is('cordova')) {
      const pushNotificationsStatus =
        localStorage.getItem('push_notifications_enabled') ?? '1';
      this.subscribeToPushNotifications(pushNotificationsStatus === '1')
        .then((data) => {
          console.log('Push notifications subscribed!');
        })
        .catch((error) => {
          console.log('Failed to subscribe to pushes!');
          console.log(error);
        })
        .finally(() => {
          this.eventBusService.emit({ name: EventBusNames.profile });
        });
    } else {
      this.eventBusService.emit({ name: EventBusNames.profile });
    }
  }

  error(value: HttpErrorResponse): void {
    console.error(value);
    this.clearUser().then(() => window.location.reload());
  }

  clearUser(): Promise<any> {
    if (this.platform.is('cordova')) {
      return this.subscribeToPushNotifications(false)
        .then((data) => {
          console.log('Notifications unsubscribed!');
        })
        .catch((error) => {
          console.log('Failed to unsubscribe from pushes!');
          console.log(error);
        })
        .finally(() => {
          console.log('Clearing user session...');

          localStorage.removeItem('push_notifications_enabled');

          this.setUser(undefined);
          this.token = '';
        });
    } else {
      return new Promise<any>((resolve, reject) => {
        console.log('Clearing user session...');

        this.setUser(undefined);
        this.token = '';

        resolve(null);
      });
    }
  }

  private requestCurrentUser(): Promise<any> {
    return this.getCurrentUser().then(
      this.success.bind(this),
      this.error.bind(this)
    );
  }

  public getCurrentUser(): Promise<any> {
    return lastValueFrom(this.http.get(this.urlCurrentUser));
  }

  public deleteCurrentUser(): Promise<any> {
    return lastValueFrom(this.http.delete(this.urlCurrentUser));
  }

  private setUser(user: User | undefined): User | undefined {
    return (this.user = user);
  }

  subscribeToPushNotifications(notify: boolean): Promise<any> {
    const userInfoId = this.user?.userInfoId;
    return new Promise((resolve, reject) => {
      if (userInfoId) {
        OneSignal.getDeviceState((response) => {
          const deviceSettings = {
            hwid: localStorage.getItem('hwid') || '',
            pushNotified: notify,
            oneSignalPlayerId: notify ? response.userId : ''
          };

          if (notify) {
            console.log(
              'Enabling notifications for ' +
                userInfoId +
                ', device: ' +
                deviceSettings.hwid +
                ', ID: ' +
                deviceSettings.oneSignalPlayerId
            );
          } else {
            console.log(
              'Disabling notifications for ' +
                userInfoId +
                ', device: ' +
                deviceSettings.hwid +
                ', ID: ' +
                deviceSettings.oneSignalPlayerId
            );
          }

          OneSignal.disablePush(!notify);

          localStorage.setItem(
            'push_notifications_enabled',
            notify ? '1' : '0'
          );

          lastValueFrom(
            this.http.post(this.urlUpdateDeviceSettings, deviceSettings, {
              headers: {
                Authorization: 'Bearer ' + this.token
              }
            })
          )
            .then(resolve)
            .catch(reject);
        });
      } else {
        reject();
      }
    });
  }

  private updateCurrentUser(user: User): void {
    if (user.userId === this.user?.userId) {
      this.setUser(user);
      this.eventBusService.emit({
        name: EventBusNames.twilioUserUpdate,
        value: this.user
      });
    }
  }

  changeRoleFor(user: User, role: Role): Observable<User> {
    const body = {
      role: role.role
    };
    return this.http
      .put<User>(environment.url + 'users/' + user.userId, body)
      .pipe(
        map((value) => new User().deserialize(value)),
        catchError((err) =>
          this.handleError('updating user role', user ?? new User(), err)
        )
      );
  }

  saveUserInfo(user?: User): Observable<User> {
    return this.http.post(this.urlSaveUserInfo, user).pipe(
      map((newUser) => new User().deserialize(newUser)),
      tap((updatedUser) => this.updateCurrentUser(updatedUser)),
      catchError((err) =>
        this.handleError('saving user info', user ?? new User(), err)
      )
    );
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }

  deleteProfilePicture(user: User): Promise<any> {
    return lastValueFrom(this.http.delete(this.profilePictureUrlForUser(user)));
  }

  updateProfilePicture(file: File, user: User): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return lastValueFrom(
      this.http.post(this.profilePictureUrlForUser(user), formData)
    );
  }

  private profilePictureUrlForUser(user: User): string {
    return this.isCurrentUser(user)
      ? this.urlProfilePicture
      : environment.url + 'users/' + user.userId + '/profile/picture';
  }

  isCurrentUser(user: User): boolean {
    return this.user?.userId === user.userId;
  }

  hasRoles(
    name:
      | 'ROLE_ADMIN'
      | 'ROLE_MENTOR'
      | 'ROLE_USER'
      | Array<'ROLE_ADMIN' | 'ROLE_MENTOR' | 'ROLE_USER'>
  ): boolean {
    return Array.isArray(name)
      ? this.user?.roles.find((role: Role) => {
          return (
            name.find(
              (item: 'ROLE_ADMIN' | 'ROLE_MENTOR' | 'ROLE_USER') =>
                item === role.role
            ) !== undefined
          );
        }) !== undefined
      : this.user?.roles.find((role: Role) => role.role === name) !== undefined;
  }

  isAdmin(): boolean {
    return this.hasRoles('ROLE_ADMIN');
  }

  isManager(participants: Participant[]): boolean {
    const participant = this.foundParticipant(participants);
    return participant !== undefined && participant.workspaceManager;
  }

  isManagerOrCreator(
    participants: Participant[],
    userId: number | undefined
  ): boolean {
    return this.isManager(participants) || this.user?.userId === userId;
  }

  isParticipant(participants: Participant[]): boolean {
    return this.foundParticipant(participants) !== undefined;
  }

  private foundParticipant(
    participants: Participant[]
  ): Participant | undefined {
    const filtered = participants?.filter((p: Participant) => {
      return (
        this.user?.userId && p.userId === this.user.userId && p.workspaceManager
      );
    });

    return filtered !== undefined && filtered.length === 1
      ? filtered[0]
      : undefined;
  }

  isAdminOrManager(participants: Participant[]): boolean {
    return this.isAdmin() || this.isManager(participants);
  }

  deleteUserRoles(rolesIds: number[]): Promise<any> {
    return lastValueFrom(
      this.http.post(this.urlDeleteCharacters, { ids: rolesIds })
    );
  }

  deleteUserEducations(educationsIds: number[]): Promise<any> {
    return lastValueFrom(
      this.http.post(this.urlDeleteEducations, { ids: educationsIds })
    );
  }
}
