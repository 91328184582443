import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  TextInputComponent,
  TextInputDialogModel
} from '../../component/dialog/text-input/text-input.component';

@Injectable({
  providedIn: 'root'
})
export class UiHelperService {
  constructor(private dialog: MatDialog) {}

  showFreeFormDialog(
    title: string,
    message: string,
    limit: number = 2000
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const dialogData = new TextInputDialogModel(
        title,
        message,
        '',
        true,
        'Send',
        limit
      );
      const dialogRef = this.dialog.open(TextInputComponent, {
        panelClass: 'popup-dialog',
        data: dialogData
      });

      dialogRef.afterClosed().subscribe((dialogResult) => {
        resolve(dialogResult);
      });
    });
  }
}
