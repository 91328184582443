import { Directive, HostListener, NgModule } from '@angular/core';

@Directive({
  selector: '[appClearFormatOnPaste]'
})
export class ClearFormatOnPasteDirective {
  constructor() {}

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  }
}

@NgModule({
  declarations: [ClearFormatOnPasteDirective],
  exports: [ClearFormatOnPasteDirective]
})
export class ClearFormatOnPasteDirectiveModule {}
