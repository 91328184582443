import { Injectable } from '@angular/core';
import { Subject, Subscriber } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  constructor() {}

  private subject$ = new Subject();
  public params: any = {};

  emit(event: CanvasEventData, params?: any): EventBusService {
    if (params) {
      this.params = params;
    }

    this.subject$.next(event);
    return this;
  }

  on(
    eventName: EventBusNames,
    action: any,
    destroy$: Subject<void> = new Subject<void>()
  ): Subscriber<any> {
    // @ts-ignore
    return this.subject$
      .pipe(
        takeUntil(destroy$),
        filter((e: any) => e.name === eventName),
        map((e: CanvasEventData) => e.value)
      )
      .subscribe(action);
  }
}

export class CanvasEventData {
  name: EventBusNames = EventBusNames.empty;
  value?: any;
}

export enum EventBusNames {
  empty = '',
  logout = 'logout',
  unauthorized = 'unauthorized',
  profile = 'profile',
  authorized = 'authorized',
  workspaceEvent = 'workspace-event',
  playAudio = 'play-audio',
  modifiedAttachment = 'modifiedAttachment',
  pauseAudio = 'pause-audio',
  deletedReport = 'deletedReport',
  otherImage = 'otherImage',
  scrollMessages = 'scrollMessages',
  chatMessagesLoaded = 'chatMessagesLoaded',
  twilioUserUpdate = 'twilioUserUpdate',
  seedObserverMessages = 'seedObserverMessages',
  twilioAddedNewUser = 'twilioAddedNewUser'
}
