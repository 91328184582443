<div [routerLink]="[taskDetailsRoute, taskModel.id]" class="task-card">
  <div>
    <div class="more-options">
      <button
        *ngIf="taskModel.canManage"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="menu">
        <ng-container>
          <button mat-menu-item (click)="onDeleteTask()">
            <span>Delete</span>
          </button>
        </ng-container>
        <ng-container>
          <button mat-menu-item (click)="onEditTask()">
            <span>Edit</span>
          </button>
        </ng-container>
        <ng-container>
          <button mat-menu-item (click)="onReportTask()">
            <span>Report</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>

    <div class="task-description" fxLayout="column" fxLayoutGap="13px">
      <div class="task-showspace">
        {{ taskModel.showspace }}
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="15px"
      >
        <div
          class="task-subtitle"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxFlex
        >
          <div
            fxLayout="row"
            *ngIf="!shouldHideCheckmark"
            (click)="onCheckmarkClick($event)"
          >
            <ng-container *ngTemplateOutlet="cardCircle"></ng-container>
          </div>

          <span>
            {{ taskModel.title }}
          </span>
        </div>

        <div class="status-label" *ngIf="displayStatus && !taskModel.isOpen">
          {{ taskModel.isOpen ? '' : 'Closed' }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #cardCircle>
  <svg
    *ngIf="!taskModel || !isCheckmarkChecked.value"
    class="cardCircle"
    [ngClass]="'priority__' + priorityClass"
  >
    <circle cx="11" cy="11" r="10" stroke="currentColor" />
  </svg>

  <svg
    *ngIf="taskModel && isCheckmarkChecked.value"
    class="cardCircle"
    [ngClass]="'priority__' + priorityClass"
  >
    <path
      d="M11 22C17.0304 22 22 17.0215 22 11.0058C22 4.97852 17.0189 0 11 0C4.9696 0 0 4.97852 0 11.0058C0 17.0215 4.98113 22 11 22ZM11 20.4096C5.78826 20.4096 1.59119 16.2148 1.59119 11.0058C1.59119 5.78523 5.77673 1.59036 11 1.59036C16.2117 1.59036 20.4088 5.78523 20.4203 11.0058C20.4319 16.2148 16.2233 20.4096 11 20.4096ZM9.81237 16.1687C10.1237 16.1687 10.3774 16.0189 10.5734 15.7423L15.739 7.64065C15.8428 7.46778 15.9581 7.24882 15.9581 7.04138C15.9581 6.62651 15.5891 6.34992 15.1971 6.34992C14.9549 6.34992 14.7243 6.49974 14.5514 6.77632L9.77778 14.3478L7.40252 11.3861C7.18344 11.0864 6.97589 11.0058 6.71069 11.0058C6.30713 11.0058 5.98428 11.3284 5.98428 11.7548C5.98428 11.9508 6.07652 12.1582 6.20335 12.3426L9.01677 15.7423C9.25891 16.0419 9.50105 16.1687 9.81237 16.1687Z"
      fill="currentColor"
    />
  </svg>
</ng-template>
