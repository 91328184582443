import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { AvatarMessageGroupComponent } from './avatar-message-group.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AvatarComponent, AvatarMessageGroupComponent],
  imports: [CommonModule, RouterModule, MatCardModule],
  exports: [AvatarComponent, AvatarMessageGroupComponent]
})
export class AvatarModule {}
