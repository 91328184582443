import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LinkMetadata } from 'src/app/models/linkMetadata';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LinksService {
  constructor(private httpClient: HttpClient) {}

  private urlGetLinkMetadata: string = environment.url + 'attachments/meta';

  async getLinkMetadata(url: string): Promise<LinkMetadata> {
    const params = new HttpParams().set('url', url);
    return await lastValueFrom(
      this.httpClient.get<LinkMetadata>(this.urlGetLinkMetadata, { params })
    );
  }
}
