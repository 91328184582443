import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent {
  constructor() {}

  get scaledImageWidth(): number {
    return this.imageWidth * this.scale;
  }

  get scaledImageHeight(): number {
    return this.imageHeight * this.scale;
  }

  @Input() imageHtml: HTMLImageElement | undefined;
  @Input() width = 1;
  @Input() height = 1;
  @Input() src: string | null = '';

  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement> | undefined;

  @ViewChild('imageElement', { static: true })
  imageElement?: ElementRef;

  @ViewChild('container', { static: true })
  container?: ElementRef;

  private scale = 1;
  private minScale = 1;

  private imageAngle = 0;
  private previousAngle = 0;

  private naturalWidth = 0;
  private naturalHeight = 0;

  private imageX = 0;
  private imageY = 0;

  private imageWidth = 0;
  private imageHeight = 0;

  private activeClick = false;
  private previousPosition: Position | null = null;

  private verticalSpace = 85;

  initListeners(): void {
    if (this.container) {
      const elem = this.container.nativeElement;

      try {
        elem.removeEventListener('mouseleave');
        elem.removeEventListener('mousedown');
        elem.removeEventListener('mousemove');
        elem.removeEventListener('mouseup');
        elem.removeEventListener('touchstart');
        elem.removeEventListener('touchmove');
        elem.removeEventListener('touchend');
      } catch (e) {}

      elem.addEventListener('mouseleave', this.mouseLeaveEvent.bind(this));
      elem.addEventListener('mousedown', this.mouseDownEvent.bind(this));
      elem.addEventListener('mousemove', this.mouseMoveEvent.bind(this));
      elem.addEventListener('mouseup', this.mouseUpEvent.bind(this));

      elem.addEventListener('touchstart', this.mouseDownEvent.bind(this));
      elem.addEventListener('touchmove', this.mouseMoveEvent.bind(this));
      elem.addEventListener('touchend', this.mouseUpEvent.bind(this));

      this.initDefaultParams();
    }
  }

  mouseLeaveEvent(): void {
    if (this.activeClick) {
      this.mouseUpEvent();
    }
  }

  mouseDownEvent(e: any): void {
    if (this.isVisiblePart(e)) {
      this.activeClick = true;
      this.previousPosition = new Position(this.clientX(e), this.clientY(e));
    }
  }

  clientX(e: any): number {
    return e.clientX ? e.clientX : e.touches[0].clientX;
  }

  clientY(e: any): number {
    return e.clientY ? e.clientY : e.touches[0].clientY;
  }

  mouseMoveEvent(e: any): void {
    if (this.activeClick && this.imageElement && this.previousPosition) {
      this.imageX += this.clientX(e) - this.previousPosition.x;
      this.imageY += this.clientY(e) - this.previousPosition.y;

      this.checkBorders();
      this.previousPosition = new Position(this.clientX(e), this.clientY(e));
    }
  }

  private checkBorders(): void {
    if (this.imageElement) {
      if (this.isLeftBorder()) {
        this.imageX = 0;
      } else if (this.isRightBorder()) {
        this.imageX = -this.maxPositionX;
      }

      if (this.isTopBorder()) {
        this.imageY = this.verticalSpace;
      } else if (this.isBottomBorder()) {
        this.imageY = -this.maxPositionY;
      }

      this.imageElement.nativeElement.style.left = this.imageX + 'px';
      this.imageElement.nativeElement.style.top = this.imageY + 'px';
    }
  }

  private isLeftBorder(): boolean {
    return this.imageX > 0;
  }

  private isRightBorder(): boolean {
    return -this.imageX > this.maxPositionX;
  }

  private isTopBorder(): boolean {
    return this.imageY > this.verticalSpace;
  }

  private isBottomBorder(): boolean {
    return -this.imageY > this.maxPositionY;
  }

  private get maxPositionX(): number {
    return this.imageElement?.nativeElement.width - this.width;
  }

  private get maxPositionY(): number {
    return (
      this.imageElement?.nativeElement.height - this.height + this.verticalSpace
    );
  }

  mouseUpEvent(): void {
    this.activeClick = false;
    this.previousPosition = null;
  }

  initDefaultParams(): void {
    if (this.imageElement) {
      const nElement = this.imageElement.nativeElement;

      this.naturalHeight = nElement.naturalHeight;
      this.naturalWidth = nElement.naturalWidth;

      this.minScale = 1;
      if (this.naturalHeight < this.height && this.naturalHeight !== 0) {
        this.minScale = this.height / this.naturalHeight;
      }

      if (this.naturalWidth < this.width && this.naturalWidth !== 0) {
        const hMinScale = this.width / this.naturalWidth;
        this.minScale = hMinScale > this.minScale ? hMinScale : this.minScale;
      } else if (this.naturalWidth > this.width && this.minScale <= 1) {
        const hMinScale = this.width / this.naturalWidth;
        this.minScale = hMinScale < this.minScale ? hMinScale : this.minScale;
      }

      this.scale = this.minScale;
      nElement.style.width = nElement.naturalWidth * this.scale + 'px';
    }
  }

  isVisiblePart(e: any): boolean {
    const rect = this.container?.nativeElement.getBoundingClientRect();
    const clientX = this.clientX(e);
    const clientY = this.clientY(e);

    return (
      clientX >= rect.left &&
      clientX <= rect.right &&
      clientY >= rect.top &&
      clientY <= rect.bottom
    );
  }

  crop(): string {
    let canvas;
    let context;

    canvas = document.createElement('canvas');

    canvas.height = 120;
    canvas.width = this.width;

    // const cx = -canvas.width / 2;
    // const cy = -canvas.height / 2;

    context = canvas.getContext('2d');

    if (context && this.imageElement) {
      // context.translate(this.imageX, this.imageY); //move to centre of canvas
      // context.scale(this.scale, this.scale);

      // const x = canvas.width / 2;
      // const y = canvas.height / 2;

      // context.translate(x, y); // move to centre of canvas
      // context.rotate(this.imageAngle * Math.PI / 180);
      // context.translate(-x, -y);

      context.drawImage(
        this.imageElement.nativeElement,
        this.imageX,
        this.imageY - this.verticalSpace,
        this.naturalWidth * this.scale,
        this.naturalHeight * this.scale
      );

      // context.translate(-cx, -cy); //move to centre of canvas
      // context.rotate(this.imageAngle * Math.PI / 180);
      // context.scale(this.imageScale, this.imageScale);

      // if (this.data.degrees == 0) { // simple offsets from canvas centre & scale
      //   context.drawImage(this.elements.image,
      //     (cx - this.data.x) / this.data.scale,
      //     (cy - this.data.y) / this.data.scale
      //   );
      // } else if (this.data.degrees == 90) { // swap axis and reverse the new y origin
      //   context.drawImage(this.elements.image,
      //     (cy - this.data.y) / this.data.scale,
      //     (-1 * this.elements.image.naturalHeight) + ((-cx + this.data.x) / this.data.scale)
      //   );
      // } else if (this.data.degrees == 180) { // reverse both origins
      //   context.drawImage(this.elements.image,
      //     (-1 * this.elements.image.naturalWidth) + ((-cx + this.data.x) / this.data.scale),
      //     (-1 * this.elements.image.naturalHeight) + ((-cy + this.data.y) / this.data.scale)
      //   );
      // } else if (this.data.degrees == 270) { // swap axis and reverse the new x origin
      //   context.drawImage(this.elements.image,
      //     (-1 * this.elements.image.naturalWidth) + ((-cy + this.data.y) / this.data.scale),
      //     (cx - this.data.x) / this.data.scale
      //   );
      // }

      return canvas.toDataURL('image/jpeg');
    }

    return '';
  }

  changeImage(data: string): void {
    fetch(data)
      .then((res) => res.blob())
      .then((blob) => {
        if (this.imageElement) {
          this.imageElement.nativeElement.src = URL.createObjectURL(blob);
          setTimeout(this.initListeners.bind(this), 100);
        }
      });
  }

  get nativeElementSrc(): string | null {
    if (this.imageElement && this.imageElement.nativeElement.height > 0) {
      return this.imageElement.nativeElement.src;
    }

    return null;
  }

  getScaleFromValue(value: number): void {
    if (this.imageElement) {
      this.scale = this.minScale * value;
      this.imageElement.nativeElement.style.width =
        this.imageElement.nativeElement.naturalWidth * this.scale + 'px';
      this.checkBorders();
    }
  }

  changeAngle(angle: number): void {
    if (this.imageElement) {
      this.imageAngle = this.imageAngle - this.previousAngle + angle;
      this.imageElement.nativeElement.style.transform =
        'rotate(' + this.imageAngle + 'deg)';

      this.previousAngle = angle;
    }
  }

  rotateImage(): void {
    if (this.imageElement) {
      this.imageAngle += 90;
      this.imageElement.nativeElement.style.transform =
        'rotate(' + this.imageAngle + 'deg)';
    }
  }

  updateWidth(width: number): void {
    this.width = width;
    this.initDefaultParams();
  }
}

class Position {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}
