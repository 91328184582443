import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LinksService } from '../../../../services/links/links.service';
import { LinkMetadata, toArchiveFile } from '../../../../models/linkMetadata';
import { finalize, of } from 'rxjs';
import { LibraryService } from '../../../../services/archive/library.service';
import { FormValidatorService } from '../../../../services/form-validators/form-validator.service';
import { UtilsService } from '../../../../services/utils/utils.service';

@Component({
  selector: 'app-library-links',
  templateUrl: './dialog-add-link.html',
  styleUrls: ['./dialog-add-link.scss', '../add-update..scss']
})
export class DialogAddLinkComponent {
  public form: any;
  private metadata: LinkMetadata = {};
  public isSavingInProgress = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAddLinkComponent>,
    private linksService: LinksService,
    private archiveService: LibraryService,
    private formValidator: FormValidatorService,
    private utils: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: { workspaceId: number }
  ) {
    this.setupForm();
  }

  private setupForm(): void {
    this.form = new FormGroup({
      link: new FormControl(null, [
        Validators.required,
        Validators.maxLength(2048)
      ]),
      title: new FormControl(null, [Validators.maxLength(255)])
    });
  }

  save(): void {
    this.setFormDirty();

    if (this.form.valid) {
      this.isSavingInProgress = true;
      this.linksService
        .getLinkMetadata(this.linkInput.value)
        .then((data: any) => {
          this.metadata = {};
          this.setFinalTitle();
          this.setFinalUrl();

          this.archiveService
            .uploadLink(toArchiveFile(this.metadata, this.data.workspaceId))
            .pipe(finalize(() => (this.isSavingInProgress = false)))
            .subscribe(() => this.dialogRef.close());
        });
    }
  }

  private setFormDirty(): void {
    this.form.markAllAsTouched();
    this.titleInput.setValue(
      this.formValidator.clearInput(this.titleInput.value)
    );
    this.linkInput.setValue(
      this.formValidator.clearInput(this.linkInput.value)
    );
  }

  shouldDisplayErrorBlock(input: FormControl): boolean {
    return input.invalid && (input.dirty || input.touched);
  }

  linkFieldDidLoseFocus(): void {
    if (!this.linkInput.value || this.linkInput.value.trim().isEmpty) {
      console.log('Link is empty, not requesting metadata');
      return;
    }

    this.linksService.getLinkMetadata(this.linkInput.value).then((data) => {
      this.metadata = data;
      this.setTitleFromMetadataIfNeeded();
    });
  }

  private setFinalUrl(): void {
    let url = this.linkInput.value;
    if (this.metadata.baseUrl && !this.utils.isValidUrl(url)) {
      url = 'https://' + url;
    }
    this.metadata.url = url;
  }

  private setFinalTitle(): void {
    let title = this.titleInput.value;

    if (this.titleInput.value) {
      title = this.titleInput.value.trim().isEmpty
        ? null
        : this.titleInput.value;
    }
    this.metadata.title = title;
  }

  private setTitleFromMetadataIfNeeded(): void {
    if (!this.titleInput.value || this.titleInput.value.isEmpty) {
      this.titleInput.setValue(this.metadata.title);
    }
  }

  get linkInput(): FormControl {
    return this.form.controls.link;
  }

  get titleInput(): FormControl {
    return this.form.controls.title;
  }
}
