export class TwilioInfo {
  identity!: string;
  twilioToken!: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export enum TwilioUserAttributes {
  profileImage = 'profile_image',
  email = 'email'
}
