import { EventTypes } from './workspaceEvent';

export class EventReport {
  costumeHair: string | null = null;
  createTime: string | null = null;
  generalNotes: string | null = null;
  lightning: string | null = null;
  nextScheduledEventId: number | null = null;
  notes: string | null = null;
  productionManagement: string | null = null;
  props: string | null = null;
  publishDate: any = null;
  published = false;
  schedule: string | null = null;
  scheduledActivities: string | null = null;
  set: string | null = null;
  sound: string | null = null;
  template: string | null = null;
  title: string | null = null;
  type: EventTypes | null = null;
  updateTime: string | null = null;
  workspaceEventId: number | null = null;
  workspaceEventReportId: number | null = null;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
