<div
  class="container"
  [ngClass]="borderClass"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
>
  <div fxLayout="column" fxLayoutGap="7px">
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
  </div>

  <div>
    <div #content>
      <ng-content select="[buttons]"></ng-content>
    </div>
    <button
      mat-ripple
      class="default-button"
      *ngIf="!content.hasChildNodes()"
      (click)="buttonClicked.emit($event)"
    >
      {{ buttonText }}
    </button>
  </div>
</div>
