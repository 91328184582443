import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import {
  Conversation,
  JSONArray,
  JSONObject,
  Message,
  Participant
} from '@twilio/conversations';
import { Client } from '@twilio/conversations';
import { TwilioInfo, TwilioUserAttributes } from '../../../models/twilio-info';
import { MapUsersForChatService } from '../mapUsersForChat/map-users-for-chat.service';
import {
  EventBusNames,
  EventBusService
} from '../../event-bus/event-bus.service';
import { BreadcrumbService } from '../../breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../common/global-constants';
import { UserService } from '../user/user.service';
import { User } from '../../../models/user';
import { ChannelParticipant } from '../../../models/channelParticipant';
import { TwilioLogger } from '../../../modules/chats/twilio-logger';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic';
import { Platform } from '@ionic/angular';
import { ProfileService } from '../profile/profile.service';
import { lastValueFrom } from 'rxjs';
import {
  ChatParticipant,
  ExtendedParticipant
} from 'src/app/models/participant';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {
  constructor(
    private httpClient: HttpClient,
    public breadcrumbService: BreadcrumbService,
    public mapUsersForChatService: MapUsersForChatService,
    public router: Router,
    public eventBusService: EventBusService,
    private userService: UserService,
    private twilioLogger: TwilioLogger,
    private platform: Platform,
    private profileService: ProfileService
  ) {
    this.getCurrentUsersTwilioInfo().then((twilioUser: TwilioInfo) => {
      this.userTwilio = new TwilioInfo().deserialize(twilioUser);
      this.connect();
    });

    this.eventBusService.on(
      EventBusNames.twilioUserUpdate,
      (user: User | undefined) => {
        this.updateTwilioUserDescription(user);
      }
    );
  }

  get isPageSetting(): boolean {
    return this.router.url.includes(GlobalConstants.routes.chatsSettings);
  }

  get showSpinnerLoad(): boolean {
    const channelParticipant = this.getCurrentChannel();
    if (channelParticipant) {
      return (
        channelParticipant.messages.size < channelParticipant.countMessages
      );
    }
    return false;
  }

  get channelsArraySortedByDateUpdated(): ChannelParticipant[] {
    return Array.from(this.channels.values()).sort(
      TwilioService.sortChats.bind(this)
    );
  }
  public static typeChats = {
    group_private: 'group_private',
    private: 'private'
  };

  public chatClient!: Client; // todo: client twilio
  private currentChannel: ChannelParticipant | undefined;
  public chatConnectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  public chatDisconnectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  public channelId = '';
  public channels: Map<string, ChannelParticipant> = new Map();
  public isConnected = false;
  public isConnecting = false;
  public isGettingChannels = false;
  public userTwilio?: TwilioInfo;
  public channelSendMessage = false;
  countLoadMessages = 50;

  private static sortChats(
    a: ChannelParticipant,
    b: ChannelParticipant
  ): number {
    return (
      (b.channel.lastMessage?.dateCreated?.getTime() ??
        b.channel.dateUpdated?.getTime() ??
        0) -
      (a.channel.lastMessage?.dateCreated?.getTime() ??
        a.channel.dateUpdated?.getTime() ??
        0)
    );
  }

  static getTypeChat(
    channel: Conversation
  ):
    | 'group_private'
    | 'private'
    | null
    | string
    | number
    | boolean
    | JSONObject
    | JSONArray {
    return (channel.attributes as JSONObject).type;
  }

  isGroupChat(channel: ChannelParticipant): boolean {
    return (
      TwilioService.getTypeChat(channel.channel) ===
      TwilioService.typeChats.group_private
    );
  }

  isPrivateChat(channel: ChannelParticipant): boolean {
    return (
      TwilioService.getTypeChat(channel.channel) !==
      TwilioService.typeChats.group_private
    );
  }

  connect(): void {
    if (this.userTwilio?.twilioToken) {
      this.chatClient = new Client(this.userTwilio.twilioToken);

      this.chatClient.on('stateChanged', (state) => {
        if (state === 'initialized') {
          this.subscribeToChannelEvents();
          this.subscribeToTokenEvents();

          this.mapUsersForChatService.updateUsers().then(() => {
            setTimeout(() => {
              this.eventBusService.emit(
                { name: EventBusNames.twilioAddedNewUser },
                this.mapUsersForChatService.mapUsers
              );
            }, 3000);
          });

          if (this.platform.is('cordova') && this.platform.is('android')) {
            FCM.getToken().then((token) => {
              // console.log ("FCM token: "  + token);
              this.chatClient.setPushRegistrationId('fcm', token);
            });
            FCM.onTokenRefresh().subscribe((token) => {
              this.chatClient.setPushRegistrationId('fcm', token);
            });
          }
        }
      });

      this.chatClient.on('connectionStateChanged', (state) => {
        if (state === 'disconnected') {
          this.chatDisconnectedEmitter.emit(true);
        } else if (state === 'connected') {
          this.chatConnectedEmitter.emit(true);
        } else if (state === 'connecting') {
          this.twilioLogger.log('connecting');
        }
      });
    }
  }

  updateTwilioUserDescription(user: User | undefined): void {
    if (user) {
      this.chatClient.user.updateFriendlyName(user.fullName()).then();
      const attributes: { [key: string]: string } = {};

      attributes[TwilioUserAttributes.email] = user.email ?? '';
      attributes[TwilioUserAttributes.profileImage] =
        user.photoThumbnailURL || user.photoURL || '';

      this.twilioLogger.log('Update current user attributes', attributes);
      this.chatClient.user.updateAttributes(attributes).then();
    }
  }

  resetCurrentChannel(): void {
    this.currentChannel = undefined;
  }

  setChannelFromId(sid: string): void {
    this.channelId = sid;
    if (this.breadcrumbService.getNameI(1)) {
      this.breadcrumbService.setAlias('', 1);
    }
    this.setCurrentChannel(this.channels.get(sid));
  }

  getMessagesMap(sid: string): ChannelParticipant | undefined {
    return this.channels.get(sid);
  }

  getCurrentMessagesMap(): Map<string, Message> | undefined {
    return this.getCurrentChannel()?.messages;
  }

  addMessagesMap(message: Message, channel: Conversation): void {
    this.getMessagesMap(channel.sid)?.messages.set(message.sid, message);
  }

  removeMessagesMap(message: Message, channel: Conversation): void {
    this.getMessagesMap(channel.sid)?.messages?.delete(message.sid);
  }

  getCurrentChannel(): ChannelParticipant | undefined {
    return this.currentChannel;
  }

  setCurrentChannel(channelParticipant: ChannelParticipant | undefined): void {
    if (this.currentChannel?.channel.sid !== channelParticipant?.channel.sid) {
      setTimeout(() => {
        this.eventBusService.emit({ name: EventBusNames.seedObserverMessages });
      }, 0);
    }
    this.currentChannel = channelParticipant;
    setTimeout(() => {
      this.breadcrumbService.setAlias('', this.isPageSetting ? 0 : 1);
      let alias = channelParticipant?.participants[0]?.fullName || 'Chats';
      if (channelParticipant && this.isGroupChat(channelParticipant)) {
        alias = channelParticipant.channel.friendlyName ?? alias;
      }
      this.breadcrumbService.setAlias(alias, this.isPageSetting ? 1 : 0);
      if (!this.isPageSetting) {
        this.eventBusService.emit({ name: EventBusNames.scrollMessages });
      }
    }, 10);

    if (channelParticipant?.messages.size === 0 && channelParticipant.channel) {
      this.loadMessages(channelParticipant).then();
    }
  }

  isOwn(message?: Message): boolean {
    return message ? this.chatClient.user.identity === message.author : true;
  }

  deleteMessage(message: Message): void {
    if (this.isOwn(message)) {
      message.remove().then(() => {});
    }
  }

  editMessage(message: Message): void {
    if (this.isOwn(message)) {
      message._update('edit');
    }
  }

  replayMessage(message: Message): void {
    message.remove().then(() => {});
  }

  loadMessages(channelParticipant: ChannelParticipant): Promise<any> {
    return channelParticipant.channel
      .getMessages(this.countLoadMessages)
      .then((m: { items: Message[] }) => {
        m.items.forEach((message: Message) =>
          this.addMessagesMap(message, channelParticipant.channel)
        );
      });
  }

  loadMessagesPrev(channelParticipant: ChannelParticipant): Promise<any> {
    const anchor = Array.from(channelParticipant.messages.values()).reduce(
      (prev, curr) => {
        return prev.index < curr.index ? prev : curr;
      }
    ).index;

    return channelParticipant.channel
      .getMessages(this.countLoadMessages, anchor, 'backwards')
      .then((m: { items: Message[] }) => {
        m.items.forEach((message: Message) =>
          this.addMessagesMap(message, channelParticipant.channel)
        );
      });
  }

  getCurrentUsersTwilioInfo(): Promise<any> {
    return lastValueFrom(
      this.httpClient.get(environment.url + 'users/twilio/')
    );
  }

  private subscribeToTokenEvents(): void {
    const updateToken = this.getCurrentUsersTwilioInfo();

    const updateTokenCallback = (twilioUser: TwilioInfo) => {
      return this.chatClient.updateToken(twilioUser.twilioToken);
    };

    // updateToken.then(updateTokenCallback);

    this.chatClient.on('tokenExpired', () => {
      this.twilioLogger.log('tokenExpired');
      updateToken.then(updateTokenCallback);
    });

    this.chatClient.on('tokenAboutToExpire', () => {
      this.twilioLogger.log('tokenAboutToExpire, time to live');
      updateToken.then(updateTokenCallback);
    });
  }

  getInvitedParticipant(channelParticipant: ChannelParticipant): void {
    channelParticipant.channel
      .getParticipants()
      .then((participants: Participant[]) => {
        participants.forEach((part: Participant) => {
          const identity1 = part.identity;
          if (identity1 && this.profileService.user?.identity !== identity1) {
            let participant =
              this.mapUsersForChatService.mapUsers.get(identity1);
            if (participant === undefined) {
              this.userService.getUserById(part.identity).then((us: User) => {
                const user = new User().deserialize(us);
                if (user.email && user.userId) {
                  participant = {
                    email: user.email,
                    name: user.name,
                    surname: user.surname,
                    fullName: user.name + ' ' + user.surname,
                    userId: user.userId,
                    identity: user.identity ?? '',
                    photoUrl: user.photoURL,
                    photoThumbnailUrl: user.photoThumbnailURL,
                    workspaceId: 0,
                    workspaceManager: false,
                    isCastMember: false,
                    enabled: true
                  };

                  this.mapUsersForChatService.mapUsers.set(
                    identity1,
                    participant
                  );

                  if (participant) {
                    channelParticipant.participants.push(participant);
                  }
                }
              });
            } else {
              channelParticipant.participants.push(participant);
            }
          }
        });
      })
      .then(() => {});
  }

  updateUnreadCounterForChannelParticipant(
    channelParticipant: ChannelParticipant
  ): void {
    if (channelParticipant.unreadCounterTimerId) {
      clearTimeout(channelParticipant.unreadCounterTimerId);
    }

    channelParticipant.channel
      .getUnreadMessagesCount()
      .then((data: number | null) => {
        const chParticipantIndex =
          channelParticipant.channel.lastMessage?.index;

        if (
          data === null &&
          this.channelId !== channelParticipant.channel.sid
        ) {
          channelParticipant.channel
            .getMessagesCount()
            .then((count: number) => {
              channelParticipant.unreadCounter = count;
            });
        } else if (
          chParticipantIndex !== undefined &&
          channelParticipant.lastMessage < chParticipantIndex
        ) {
          channelParticipant.unreadCounter = data || 0;
        } else {
          channelParticipant.unreadCounter = 0;
        }
      });
  }

  private subscribeToChannelEvents(): void {
    this.chatClient.on('conversationAdded', (channel: Conversation) => {
      this.twilioLogger.log('conversationAdded', channel);

      this.initChannelEvents(channel);
      const channelParticipant = {
        channel,
        participants: [],
        messages: new Map(),
        currentMessage: '',
        unreadCounter: 0,
        unreadCounterTimerId: undefined,
        lastMessage: channel.lastReadMessageIndex || 0,
        countMessages: 0,
        coverUrl: undefined,
        workspaceId: ''
      };
      channel.getMessagesCount().then((count: number) => {
        channelParticipant.countMessages = count;
      });
      this.updateUnreadCounterForChannelParticipant(channelParticipant);
      this.channels.set(channel.sid, channelParticipant);
      this.loadMessages(channelParticipant).then(() => {
        if (
          channel.sid === this.channelId &&
          this.getCurrentChannel()?.channel.sid !== channel.sid
        ) {
          this.setCurrentChannel(channelParticipant);
        }
      });
      channel.getAttributes().then((d: any) => {
        channelParticipant.coverUrl = d.cover_url ?? '';
        channelParticipant.workspaceId = d.workspaceId ?? '';
      });

      setTimeout(() => {
        this.getInvitedParticipant(channelParticipant);
      }, 400);
    });
    this.chatClient.on('conversationRemoved', (channel: Conversation) => {
      this.leaveForCustomChannel(channel).then();
      this.channels.delete(channel.sid);
      if (
        this.getCurrentChannel()?.channel.sid === channel.sid &&
        this.router.url ===
          '/' + GlobalConstants.routes.chatsHome + '/' + channel.sid
      ) {
        this.setCurrentChannel(undefined);
        this.twilioLogger.log('conversationRemoved', channel);
        this.router.navigate(['/chats']).then();
      }
    });

    this.chatClient.on(
      'conversationUpdated',
      (channel: { conversation: Conversation; updateReasons: string[] }) => {
        this.twilioLogger.log(
          'conversationUpdated',
          channel,
          this.currentChannel?.channel.sid
        );

        this.eventBusService.emit({ name: EventBusNames.scrollMessages });
        this.updateUnreadMessages(channel.conversation);
        if (channel.conversation.sid === this.currentChannel?.channel.sid) {
          this.eventBusService.emit({ name: EventBusNames.scrollMessages });
        }
      }
    );
  }

  private initChannelEvents(channel?: Conversation): void {
    if (channel) {
      channel.on('messageAdded', (message) => {
        this.twilioLogger.log('messageAdded', message.body ?? '');

        const identity = message?.author;
        const checkUser = identity
          ? this.mapUsersForChatService.mapUsers.get(identity)
          : undefined;

        if (checkUser === undefined && identity) {
          this.mapUsersForChatService.updateUsers().then(() => {
            setTimeout(() => {
              this.eventBusService.emit(
                { name: EventBusNames.twilioAddedNewUser },
                this.mapUsersForChatService.mapUsers
              );
            }, 2000);
          });
        }

        this.addMessagesMap(message, channel);
        this.updateUnreadMessages(channel);

        if (this.currentChannel?.channel.sid === message.conversation.sid) {
          setTimeout(() => {
            this.eventBusService.emit({
              name: EventBusNames.seedObserverMessages
            });
          }, 0);
        }
      });

      channel.on('messageRemoved', (message) => {
        this.twilioLogger.log('leave messageRemoved');

        this.removeMessagesMap(message, channel);
        this.updateUnreadMessages(channel);
      });

      channel.on('messageUpdated', () => {
        this.twilioLogger.log('messageUpdated');
      });

      channel.on('typingStarted', () => {
        this.twilioLogger.log('typingStarted');
      });

      channel.on('typingEnded', () => {
        this.twilioLogger.log('typingEnded');
      });

      channel.on('participantJoined', () => {
        this.twilioLogger.log('participantJoined');
      });

      channel.on('participantLeft', () => {
        this.twilioLogger.log('participantLeft');
      });
    }
  }

  leaveForCustomChannel(channel: Conversation): Promise<void> {
    return channel.leave().then((leftChannel) => {
      leftChannel.removeListener('messageAdded', () => {
        this.twilioLogger.log('Unsubscribe from messageAdded');
      });
      leftChannel.removeListener('messageRemoved', () => {
        this.twilioLogger.log('Unsubscribe from messageRemoved');
      });
      leftChannel.removeListener('messageUpdated', () => {
        this.twilioLogger.log('Unsubscribe from messageUpdated');
      });
      leftChannel.removeListener('typingStarted', () => {
        this.twilioLogger.log('Unsubscribe from typingStarted');
      });
      leftChannel.removeListener('typingEnded', () => {
        this.twilioLogger.log('Unsubscribe from typingEnded');
      });
      leftChannel.removeListener('participantJoined', () => {
        this.twilioLogger.log('Unsubscribe from participantJoined');
      });
      leftChannel.removeListener('participantLeft', () => {
        this.twilioLogger.log('Unsubscribe from participantLeft');
      });
    });
  }

  createChannel(participant: ChatParticipant): Promise<Conversation> {
    return this.chatClient
      .createConversation({
        friendlyName: participant.fullName,
        uniqueName: this.guid(
          participant.identity,
          this.chatClient.user.identity
        ),
        attributes: {
          type: TwilioService.typeChats.private
        }
      })
      .then((channel: Conversation) => {
        channel.add(participant.identity).then().then();
        channel.add(this.chatClient.user.identity).then();
        return channel;
      });
  }

  guid(identity1: string, identity2: string): string {
    return [identity1, identity2].sort().join('|');
  }

  getIdentity(id: string): string {
    return id.split('|').find((i) => i !== this.chatClient.user.identity) || '';
  }

  deleteChannel(channel: Conversation): Promise<any> {
    return channel.delete().then();
  }

  updateLastConsumedMessageIndex(
    channel: Conversation,
    index: number
  ): Promise<any> {
    return channel.updateLastReadMessageIndex(index);
  }

  updateUnreadMessages(channel: Conversation): void {
    const channelParticipant = this.channels.get(channel.sid);
    if (channelParticipant) {
      this.updateUnreadCounterForChannelParticipant(channelParticipant);
      channelParticipant.channel.getMessagesCount().then((data: number) => {
        channelParticipant.countMessages = data;
      });
    }
  }

  updateLastConsumedMessageIndex2(
    channelParticipant: ChannelParticipant | undefined,
    index: string | undefined
  ): void {
    if (channelParticipant && index) {
      const i = parseInt(index, 10);

      if (
        channelParticipant.lastMessage < i &&
        (channelParticipant.advancedLastIndex === undefined ||
          channelParticipant.advancedLastIndex < i)
      ) {
        channelParticipant.advancedLastIndex = i;
        if (channelParticipant.advancedLastIndexTimerId) {
          clearTimeout(channelParticipant.advancedLastIndexTimerId);
        }

        channelParticipant.advancedLastIndexTimerId = setTimeout(() => {
          channelParticipant.channel
            .advanceLastReadMessageIndex(i)
            .then((data: number) => {
              channelParticipant.unreadCounter = data;
              channelParticipant.advancedLastIndex = undefined;
            });
        }, 100);
      }
    }
  }
}
