import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import {
  PageableSearchList,
  SearchResultModel,
  WorkspaceService
} from '../../../services/workspace/workspace.service';
import { Observable, timer } from 'rxjs';
import { debounce, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-workspace-field',
  templateUrl: './search-workspace-field.component.html',
  styleUrls: ['./search-workspace-field.component.scss']
})
export class SearchWorkspaceFieldComponent implements OnInit, OnDestroy {
  availableWorkspaces: PageableSearchList | null = null;
  selectedWorkspace: SearchResultModel | null = { title: undefined };

  private _limit: number = 50;
  private _page: number = 0;
  private _lastSearchKeyword: string | null = null;
  private _dirtyInput = false;

  private _searchWorkspacesEvent$: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private workspaceService: WorkspaceService) {}

  ngOnInit(): void {
    this._searchWorkspacesEvent$
      .pipe(
        debounce(() => timer(500)),
        distinctUntilChanged(),
        switchMap((keyword: string) =>
          this._searchWorkspaces(keyword, this._limit, this._page)
        )
      )
      .subscribe((res: PageableSearchList | undefined) => {
        if (res) {
          this.availableWorkspaces = res;
        }
      });

    this._searchWorkspacesEvent$.emit('');
  }

  ngOnDestroy(): void {
    this._searchWorkspacesEvent$.complete();
  }

  onChangeEvent(keyword: any): void {
    this.availableWorkspaces = null;
    this._page = 0;
    this._dirtyInput = true;
    this._searchWorkspacesEvent$.emit(keyword);
  }

  onClearSearch($event: any): void {
    this._page = 0;
    this._lastSearchKeyword = null;
    this._searchWorkspacesEvent$.emit('');
  }

  loadMoreSearchResults(): void {
    if (this._lastSearchKeyword != null) {
      this._searchWorkspaces(
        this._lastSearchKeyword,
        this._limit,
        ++this._page
      ).subscribe((res: PageableSearchList | undefined) => {
        if (res && this.availableWorkspaces) {
          for (const workspace of res.content) {
            this.availableWorkspaces.content.push(workspace);
          }

          this.availableWorkspaces.hasNext = res.hasNext;
        }
      });
    }
  }

  setDirty(): void {
    this._dirtyInput = true;
  }

  get getSelectedWorkspace(): SearchResultModel | null {
    return this.selectedWorkspace;
  }

  private _searchWorkspaces(
    keyword: string,
    limit: number,
    page: number
  ): Observable<PageableSearchList | undefined> {
    this._lastSearchKeyword = keyword;
    return this.workspaceService.searchForWorkspaces(keyword, limit, page);
  }

  get invalid(): boolean {
    return (
      this._dirtyInput &&
      (!this.selectedWorkspace || !this.selectedWorkspace.workspaceId)
    );
  }

  getWorkspaceTitle(item: SearchResultModel): string {
    return item?.title || '';
  }
}
