export class GlobalConstants {
  public static routes = {
    workspaces: 'workspaces',
    workspace: 'workspaces/:id',
    workspaceEvent: 'workspaces/:workspacesId/events/:id',
    workspaceTab: 'workspaces/:id/:tab',
    workspaceMessages: 'messages',
    workspaceEvents: 'events',
    workspaceArchive: 'library',
    workspaceParticipants: 'participants',
    workspaceConflicts: 'conflicts',
    workspaceTasks: 'tasks',
    chatsHome: 'chats',
    tasksHome: 'tasks',
    taskDetails: 'tasks/details/',
    details: 'details',
    chatsSettings: '/settings',
    signin: 'signin',
    confirmReset: 'confirm-reset',
    register: 'register',
    sendLink: 'send-link',
    resetPasswordExpired: 'reset-password-expired',
    profile: 'profile',
    profileAndSettings: 'profile-and-settings',
    errorsPage: 'errors-page',
    skills: 'skills',
    accountSettings: 'account-settings',
    privacyPolicy: 'privacy-policy',
    termsOfUse: 'terms-of-use',
    calendar: 'calendar',
    newWorkspace: 'new-workspace',
    faq: 'faq',
    paymentCompletion: 'payments/completed'
  };

  public static publicEventRoutes = {
    publicEventBase: 'public-events',
    publicEvent: 'public-events/:workspaceId',
    publicEventEditing: 'public-events/:workspaceId/draft'
  };

  public static adminRoutes = {
    adminPanel: 'admin-panel',
    users: 'users',
    complaints: 'complaints',
    serverErrors: 'server-errors'
  };

  public static i18n = {
    showspace: 'showspace',
    showspaces: 'showspaces',
    workspace: 'workspace',
    workspaces: 'workspaces'
  };
}
