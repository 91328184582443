<div class="add-update-form">
  <div class="closeElement" (click)="onGoBack()">
    <mat-icon svgIcon="back-button" class="pointer"></mat-icon>
  </div>

  <div class="titleBlock">
    <h1 mat-dialog-title class="apg-title">Choose Showspace</h1>
  </div>

  <mat-dialog-content>
    <div class="search" fxLayout="row">
      <i class="material-icons">search</i>
      <input
        type="text"
        class="searchField"
        placeholder="Search for showspace"
        (keyup)="onSearch($event)"
      />
    </div>

    <ng-container *ngIf="showspaces && showspaces.length > 0; else empty">
      <div fxLayout="column" fxLayoutAlign="left stretch">
        <ng-container
          *ngFor="let showspace of showspaces; trackBy: taskTrackByFn"
        >
          <div class="workspace-item" (click)="selectWorkspace(showspace)">
            {{ showspace.title }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </mat-dialog-content>
</div>

<ng-template #empty>
  <app-empty-content
    *ngIf="!loadInProgress; else loading"
    fxFlexFill
    fxLayoutAlign="center center"
  ></app-empty-content>
</ng-template>

<ng-template #loading>
  <app-loading *ngIf="loadInProgress"></app-loading>
</ng-template>
