import { Participant } from './participant';
import { CalendarOptions } from 'datebook';
import { UtilsService } from '../services/utils/utils.service';

const date1 = new Date();
const date0 = new Date();
date0.setDate(date0.getDate() + 1);

export class Workspace {
  creatorId: number | undefined;
  dateEnd: string | undefined = date0.toUTCString();
  dateStart: string | undefined = date1.toUTCString();
  description = '';
  location: string | null | undefined;
  participants: Participant[] = [];
  siteUrl: string | null | undefined;
  title = '';
  workspaceId: number | undefined;
  sample = false;
  bannerUrl: string | null = null;
  channelId!: string;
  createDefaultTasks = false;
  hasPublicPage = false;
  hasSalesEnabled = false;

  static getCalendarConfig(workspace: Workspace): CalendarOptions {
    return {
      title: workspace.title,
      location: workspace.location || '',
      description: workspace.description || '',
      start: UtilsService.toLocalDate(workspace.dateStart),
      end: UtilsService.toLocalDate(workspace.dateEnd)
    };
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.participants) {
      this.participants = input.participants;
    }

    return this;
  }
}
