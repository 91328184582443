import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';
import { Post } from '../../models/post';
import { PostService } from '../../services/post/post.service';
import { FnMentionableSearchParticipant } from '../../directive/mentionable.fn.search.participant';

@Component({
  selector: 'app-post-description',
  templateUrl: './post-description.component.html',
  styleUrls: ['./post-description.component.scss']
})
export class PostDescriptionComponent implements AfterViewInit {
  @Input() post: Post | undefined;
  @Input() fullscreen = false;
  @Input() editing = false;
  @ViewChild('description') description?: ElementRef;
  @ViewChild('descriptionLess') descriptionLess?: ElementRef;
  @Input() callbackBad: (() => void) | undefined;
  @Input() callback: (() => void) | undefined;
  @Input() fnSearchParticipant?: FnMentionableSearchParticipant;
  moreShow = true;
  lessShow = true;

  constructor(public postService: PostService) {}

  editPostReply(text: string, mentionedParticipantsIds: Array<number>): void {
    this.editing = false;
    if (this.post && text) {
      this.post.mentionedUserIds = mentionedParticipantsIds;
      this.postService.editPost(this.post, text).then(() => {
        if (this.callback) {
          this.callback();
        }
      });
    }
  }

  notSave(): void {
    if (this.callbackBad) {
      this.callbackBad();
    } else {
      this.editing = false;
    }
  }

  ngAfterViewInit(): void {
    const h1 = this.description?.nativeElement.getBoundingClientRect().height;
    const h2 =
      this.descriptionLess?.nativeElement.getBoundingClientRect().height;
    if (h1 !== undefined || h2 !== undefined) {
      const more = h1 < h2 && !!this.post?.description?.trim();
      requestAnimationFrame(() => {
        this.setMore(more);
      });
    }
  }

  setMore(value: boolean): void {
    this.moreShow = value;
    this.lessShow = !value;
  }
}
