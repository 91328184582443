export interface Announcement {
  id: number;
  title: string;
  description?: string;
  createTime: string;
  updateTime: string;
  type: AnnouncementType;
}

export interface AnnouncementList {
  content: Announcement[];
}

export enum AnnouncementType {
  MAINTENANCE = 'MAINTENANCE',
  OTHER = 'OTHER'
}
