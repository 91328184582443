import { Injectable } from '@angular/core';
import { ArchiveType } from '../../models/archiveFile';

@Injectable({
  providedIn: 'root'
})
export class FileUtilsService {
  imageExtensions = ['jpg', 'png', 'jpeg', 'tiff'];

  audioExtensions = ['mp3', 'wav', 'aac', 'aiff', 'm4a', 'flac', 'ac3', 'caf'];

  getTypeFor(file: File): ArchiveType {
    const ext = this.extensionOf(file).toLowerCase();
    if (this.imageExtensions.includes(ext)) {
      return ArchiveType.Image;
    } else if (this.audioExtensions.includes(ext)) {
      return ArchiveType.Audio;
    } else {
      return ArchiveType.Document;
    }
  }

  extensionOf(file: File): string {
    const extension = file.name.split('.').pop();
    return extension ?? '';
  }

  fileNameWithoutExt(file: File): string {
    return file.name.replace(/\.[^/.]+$/, '');
  }
}
