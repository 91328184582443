<div
  class="position-fullscreen"
  [style.visibility]="!this.imageLoaded ? 'hidden' : 'visible'"
>
  <span
    class="closeElement icon material-icons icon-close-fullscreen"
    mat-dialog-close
    >close</span
  >

  <div class="full-page-content" #fullscreenElement>
    <div
      class="arrow arrow-previous"
      [ngClass]="isVisibleControls ? 'visible-controls' : ''"
      (click)="previous()"
      (keydown)="keyboardEvent($event)"
    >
      <img src="assets/icons/icon_arrow_left.svg" alt="Previous image" />
    </div>

    <div class="fullscreen-content">
      <div class="header-block">
        <div class="header-one-block-image">
          <div class="img">
            <app-avatar
              [src]="
                this.file.creatorPhotoThumbnailURL || this.file.creatorPhotoURL
              "
              [userIdentity]="this.file.creatorId"
              [size]="'small'"
              [isClickable]="false"
            ></app-avatar>
          </div>
          <div class="info">
            <div class="name">{{ this.file.creatorFullName }}</div>
            <div class="dateCreation">
              {{ this.file.createTime | date: 'MMMM d' }}
            </div>
          </div>
        </div>

        <div class="header-one-block">
          <div
            style="cursor: pointer"
            mat-icon-button
            #matTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_horiz</mat-icon>
          </div>
          <mat-menu #menu="matMenu" class="menu">
            <ng-container>
              <button mat-menu-item (click)="download($event)">
                <span>Download</span>
              </button>
            </ng-container>
            <ng-container>
              <button mat-menu-item (click)="report($event)">
                <span>Report</span>
              </button>
            </ng-container>
            <ng-container *ngIf="libraryManager.canDelete(file)">
              <button mat-menu-item (click)="deleteFile($event)">
                <span>Delete</span>
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </div>

      <div
        class="public-page-toggle"
        *ngIf="libraryManager.canMakePublic"
        fxLayout="row"
        fxLayoutGap="20px"
        fxLayoutAlign="start center"
      >
        <span class="public-page-title"
          >Show this photo on Public Event Page</span
        >
        <mat-slide-toggle
          [checked]="this.file.isPublic"
          (change)="publicPageVisibilityChanged($event)"
        ></mat-slide-toggle>
      </div>

      <div>
        <img
          class="main-image"
          src="{{ this.file.url }}"
          alt=""
          (load)="imageReady()"
          #mainImage
          (tap)="controlsVisibility()"
        />
      </div>
    </div>

    <div
      class="arrow arrow-next"
      [ngClass]="isVisibleControls ? 'visible-controls' : ''"
      (click)="next()"
      (keydown)="keyboardEvent($event)"
    >
      <img src="assets/icons/icon_arrow_right.svg" alt="Next image" />
    </div>
  </div>
</div>
