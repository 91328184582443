<ng-container *ngIf="authService.checkAuth()">
  <div fxFlexFill fxLayout="row" (window:resize)="onResize($event)">
    <mat-drawer-container fxFlex>
      <mat-drawer
        #drawer
        [disableClose]="disableClose"
        [mode]="mode"
        [(opened)]="opened"
        class="sidebar"
      >
        <div fxFlex="240px">
          <app-sidebar *ngIf="!isLoading" [drawer]="drawer"></app-sidebar>
        </div>
      </mat-drawer>
      <div fxFlex fxFlexFill>
        <div fxFlex fxFlexFill fxLayout="column">
          <div fxFlex fxLayout="column">
            <ng-container *ngIf="opacityHeader">
              <div
                fxFlex="100px"
                fxFlex.sm="80px"
                fxFlex.xs="70px"
                fxHide.lt-sm="true"
                class="header header-sticky"
              >
                <app-header *ngIf="!isLoading" [drawer]="drawer"></app-header>
              </div>
              <div class="header header-opacity" fxHide.gt-xs="true">
                <app-header
                  *ngIf="!isLoading"
                  [drawer]="drawer"
                  [opacity]="true"
                ></app-header>
              </div>
            </ng-container>
            <ng-container *ngIf="!opacityHeader">
              <div
                fxFlex="100px"
                fxFlex.sm="80px"
                fxFlex.xs="70px"
                class="header header-sticky"
              >
                <app-header
                  *ngIf="!isLoading"
                  [drawer]="drawer"
                  [opacity]="false"
                ></app-header>
              </div>
            </ng-container>
            <div fxFlex class="router-outlet" [hidden]="isLoading">
              <router-outlet></router-outlet>
            </div>

            <div fxFlex class="loading" *ngIf="isLoading">LOADING ...</div>
          </div>
        </div>
      </div>
    </mat-drawer-container>
  </div>
</ng-container>
