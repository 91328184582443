<ng-container *ngIf="calendarFilterForm">
  <form
    [formGroup]="calendarFilterForm"
    fxLayout="column"
    fxLayoutAlign="center stretch"
  >
    <div fxLayout="row wrap">
      <div fxFlex="20" *ngIf="screenWidth <= 450"></div>
      <div fxFlex class="title">Filters</div>
      <button
        fxFlex="20"
        mat-flat-button
        color="primary"
        class="btn sm"
        *ngIf="screenWidth <= 450"
        [disabled]="calendarFilterForm.invalid"
        (click)="save()"
      >
        <span>Done</span>
      </button>
    </div>

    <div
      class="content-body"
      fxLayout="column"
      fxLayoutGap="11px"
      fxLayoutAlign="space-around stretch"
    >
      <div>
        <label
          ><span class="control-label">Events</span>
          <mat-checkbox
            formControlName="showEvents"
            [class.errorInput]="
              calendarFilterForm.controls['showEvents'].invalid &&
              (calendarFilterForm.controls['showEvents'].dirty ||
                calendarFilterForm.controls['showEvents'].touched)
            "
            (change)="validateShowEvents($event)"
          >
          </mat-checkbox>
        </label>
      </div>

      <hr />

      <div>
        <label
          ><span class="control-label">Tasks</span>
          <mat-checkbox
            formControlName="showTasks"
            [class.errorInput]="
              calendarFilterForm.controls['showTasks'].invalid &&
              (calendarFilterForm.controls['showTasks'].dirty ||
                calendarFilterForm.controls['showTasks'].touched)
            "
            (change)="validateShowTasks($event)"
          >
          </mat-checkbox>
        </label>
      </div>

      <hr />
    </div>

    <ng-container *ngIf="screenWidth > 450">
      <div class="apg-form-group" fxLayout="column" fxLayoutAlign="center end">
        <button
          mat-flat-button
          color="primary"
          class="btn md"
          [disabled]="calendarFilterForm.invalid"
          (click)="save()"
        >
          <span>Done</span>
        </button>
      </div>
    </ng-container>
  </form>
</ng-container>
