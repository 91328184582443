<ng-container *ngIf="conflicts.length === 0">
  <app-loading *ngIf="loadInProgress; else empty"></app-loading>
</ng-container>

<ng-template #empty>
  <app-empty-content
    fxFlexFill
    fxLayoutAlign="center center"
  ></app-empty-content>
</ng-template>

<div fxLayout="column" fxLayoutGap="10px" *ngIf="conflicts">
  <div
    class="conflicts-container"
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="conflicts.length !== 0"
  >
    <div class="row">
      <p-carousel
        #carousel
        [value]="conflicts"
        [showIndicators]="false"
        [numVisible]="2"
        [showNavigators]="showNavigators(carousel)"
        (onPage)="scroll(carousel)"
        [numScroll]="1"
        [responsiveOptions]="responsiveOptions"
      >
        <ng-template let-conflict pTemplate="item">
          <div class="card" [class.selected]="conflict === pickedEvent">
            <app-one-event
              [eventRouteActive]="false"
              [isCompact]="true"
              [workspaceEvent]="conflict"
              (clickedEvent)="onEventPicked($event)"
            >
            </app-one-event>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>

  <div *ngIf="pickedEvent" class="participants-list">
    <app-participants-block
      [attendance]="attendance"
      [workspace]="oneWorkspaceService.workspace"
      [workspaceEvent]="pickedEvent"
      [allowDeleteMenu]="false"
    >
    </app-participants-block>
  </div>
</div>
