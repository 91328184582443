import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { DialogMentionListComponent } from './dialog-mention-list.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [DialogMentionListComponent],
  imports: [CommonModule, MatListModule, FlexLayoutModule, MatButtonModule],
  exports: [DialogMentionListComponent]
})
export class DialogMentionListModule {}
