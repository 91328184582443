import { SharedService } from './services/shared/shared.service';
import {
  Component,
  HostListener,
  OnDestroy,
  AfterViewInit,
  NgZone,
  OnInit
} from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsocketService } from './services/websocket/websocket.service';
import { GlobalConstants } from './common/global-constants';
import { v4 } from 'uuid';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { AnnouncementsService } from './services/announcements/announcements.service';
import { Device } from '@capacitor/device';
import { PushNotificationsService } from './services/push-notifications/push-notifications.service';
import { StorageService } from './services/storage/storage.service';
import { LSKeys } from './services/storage/storage-keys';
import { PlatformService } from './services/platform/platform.service';

// Suppress TS warnings about window.cordova
declare let window: any; // turn off type checking

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private listOfRoutes = [GlobalConstants.routes.errorsPage];
  title = 'webApp';

  constructor(
    public authService: AuthService,
    public announcements: AnnouncementsService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private websocketService: WebsocketService,
    private sharedService: SharedService,
    private ngZone: NgZone,
    private platform: PlatformService,
    private pushNotificationsService: PushNotificationsService,
    private storage: StorageService
  ) {
    this.pushNotificationsService.initialize();
    this.initializeApp().then();
  }

  ngOnInit(): void {
    this.registerSvg();
  }

  async initializeApp(): Promise<void> {
    const app = initializeApp(environment.firebase);
    const analytics = getAnalytics(app);

    if (this.platform.isNativeMobile) {
      setUserProperties(
        analytics,
        { version: environment.appVersion, platform: 'cordova' },
        { global: true }
      );

      await this.platform.ready();
      const deviceId = await Device.getId();

      if (!this.storage.getItem(LSKeys.HWID)) {
        this.storage.setItem(LSKeys.HWID, deviceId.identifier);
      }
    } else {
      setUserProperties(
        analytics,
        { version: environment.appVersion, platform: 'web' },
        { global: true }
      );

      if (!this.storage.getItem(LSKeys.HWID)) {
        this.storage.setItem(LSKeys.HWID, v4());
      }
    }
  }

  ngOnDestroy(): void {
    this.websocketService.disconnect();
  }

  ngAfterViewInit(): void {
    if (this.platform.isNativeMobile) {
      window.cordova.exec(
        (link: any) => {
          if (!link.host || !link.path) {
            return;
          }
          const host = link.host.toLowerCase();
          if (
            host == 'actorspocketguide.com' ||
            host == 'web-dev.actorspocketguide.com'
          ) {
            let url = link.path;
            if (link.queryString) {
              url += '?' + link.queryString;
            }
            this.ngZone.run(() => {
              this.router.navigateByUrl(url);
            });
          }
        },
        () => {},
        'IonicDeeplinkPlugin',
        'onDeepLink',
        []
      );
    }

    this.announcements.checkForAnnouncement();
  }

  public existsOnListOfRoutes(): boolean {
    const url = new URL(this.router.url, environment.url);
    return this.listOfRoutes.includes(url.pathname.substring(1));
  }

  @HostListener('window:beforeunload', ['$event'])
  onCloseWindow($event: any): void {
    this.ngOnDestroy();
  }

  onShowSignin() {
    this.sharedService.startLoading();
  }

  private registerSvg(): void {
    const icons = [
      'unchecked_circle',
      'checked_circle',
      'plus',
      'trash',
      'burger',
      'back-button',
      'share',
      'circle_checked',
      'circle_unchecked',
      'chat',
      'pin'
    ];

    icons.forEach((i) => this.register('assets/icons', i));
  }

  private register(route: string, title: string): void {
    this.matIconRegistry.addSvgIcon(
      title,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${route}/${title}.svg`)
    );
  }
}
