<form class="add-update-form">
  <div class="closeElement" mat-dialog-close>
    <span class="material-icons">close</span>
  </div>

  <button
    class="mat-button mat-warn btn-add-all-participants"
    *ngIf="!allPeoplesAdded && isVisibleListBlock"
    (click)="selectAllParticipants()"
  >
    Add All
  </button>

  <button
    class="mat-button mat-warn btn-add-all-participants"
    *ngIf="allPeoplesAdded && isVisibleListBlock"
    (click)="clearSelectedParticipants()"
  >
    Remove All
  </button>

  <div class="titleBlock content-inputs">
    <h1 class="apg-title">Add Invitees</h1>
  </div>

  <mat-dialog-content>
    <ng-container *ngIf="isVisibleListBlock; else elseDiv">
      <div class="content-inputs">
        <div class="input-with-chip">
          <label class="color-text">To: </label>
          <mat-chip-list #toListElement>
            <mat-chip
              *ngFor="let participant of participants"
              [selectable]="selectable"
              [removable]="true"
              (removed)="remove(participant)"
            >
              {{participant.email}}
              <mat-icon matChipRemove>close</mat-icon>
            </mat-chip>

            <input
              class="input-for-chip emails"
              [matChipInputFor]="toListElement"
              [matChipInputAddOnBlur]="addOnBlur"
              (paste)="onPaste()"
              #inputNewEmail
              [placeholder]="getPlaceholder()"
            />
          </mat-chip-list>
        </div>

        <div class="apg-form-group" *ngIf="!embedded">
          <button
            mat-flat-button
            class="btn upper"
            color="accent"
            [class.spinner]="loadInProgress"
            [disabled]="this.participants.length === 0 || loadInProgress"
            (click)="save()"
          >
            Add
          </button>
        </div>
      </div>

      <app-participants-list
        [availableParticipants]="availableForSelection()"
        [selectedParticipants]="participants"
        (selectParticipant)="onParticipantSelect($event)"
        [subtitleOption]="'email'"
        [allowProfileNavigation]="false"
      ></app-participants-list>

      <div class="apg-form-group" *ngIf="embedded">
        <button
          mat-flat-button
          class="btn upper"
          color="accent"
          [class.spinner]="loadInProgress"
          [disabled]="loadInProgress"
          (click)="save()"
        >
          Create Event
        </button>
      </div>
    </ng-container>

    <ng-template #elseDiv>
      <div class="content-inputs empty-list-text">
        All participants of this showspace have already been invited to the
        event
      </div>

      <div class="apg-form-group" *ngIf="embedded">
        <button
          mat-flat-button
          class="btn upper"
          color="accent"
          [class.spinner]="loadInProgress"
          [disabled]="loadInProgress"
          (click)="save()"
        >
          Create Event
        </button>
      </div>
    </ng-template>
  </mat-dialog-content>
</form>
