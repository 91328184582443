<div class="empty-workspaces-block">
  <img [src]="placeholderImage" alt="" />
  <div #content>
    <ng-content select="[text]"></ng-content>
  </div>

  <p *ngIf="!content.hasChildNodes()">
    {{ text }}
  </p>
</div>
