export class CommonUser {
  constructor(
    public fullName: string = '',
    public photoUrl: string = '',
    public photoThumbnailUrl: string = '',
    public userId: number = 0,
    public email: string = '',
    public identity: string = ''
  ) {}

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
