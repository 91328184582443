import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { Participant } from 'src/app/models/participant';

@Component({
  template: ``
})
export class CommonDialogParticipantsComponent {
  @ViewChild('inputNewEmail') inputNewEmail?: ElementRef<HTMLInputElement>;

  addOnBlur = true;

  @Input() participants: Participant[] = [];
  @Output() participantsChange = new EventEmitter<Participant[]>();

  previouslyInvitedPeople: Participant[] = [];

  constructor() {}

  onPaste(): void {
    setTimeout(() => {
      this.inputNewEmail?.nativeElement.blur();
    }, 50);
  }

  isNotEmptyUserId(p: Participant): boolean {
    return !!p.userId;
  }

  getPlaceholder(): string {
    return this.participants.length > 0 ? '' : 'Enter email';
  }

  availableForSelection(): Participant[] {
    return this.previouslyInvitedPeople;
  }

  get allPeoplesAdded(): boolean {
    return (
      this.participants.length > 0 && this.availableForSelection().length === 0
    );
  }

  remove(item: Participant): void {
    const index = this.participants.indexOf(item);

    if (index >= 0) {
      this.participants.splice(index, 1);
    }
  }

  protected isNotAlreadyInInviteList(p: Participant): boolean {
    for (const participant of this.participants) {
      if (participant.email === p.email) {
        return false;
      }
    }
    return true;
  }

  protected isNotAlreadyInTheList(
    email: string,
    listOfParticipants: any
  ): boolean {
    return !listOfParticipants.find((p: any) => p.email === email);
  }
}
