import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialHintComponent } from './tutorial-hint.component';
import { FlexModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [TutorialHintComponent],
  exports: [TutorialHintComponent],
  imports: [CommonModule, FlexModule, MatRippleModule]
})
export class TutorialHintModule {}
