import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarRoutingModule } from './calendar-routing.module';
import { CalendarComponent } from './calendar/calendar.component';
import { SharedModule } from '../shared/shared.module';
import { CalendarBigComponent } from './calendar-big/calendar-big.component';
import {
  CalendarCommonModule,
  CalendarDayModule,
  CalendarModule as AngularCalendarModule,
  CalendarMonthModule,
  CalendarWeekModule
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CalendarEventsListComponent } from './calendar-events-list/calendar.events.list.component';
import { TaskCardModule } from '../../pages/tasks/task-card/task-card.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OneEventComponent } from 'src/app/pages/workspace/one-workspace-view/events/one-event/one-event.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [
    CalendarComponent,
    CalendarBigComponent,
    CalendarEventsListComponent
  ],
  
  exports: [CalendarBigComponent, CalendarEventsListComponent],
  imports: [
    CommonModule,
    CalendarRoutingModule,
    SharedModule,
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarDayModule,
    CalendarWeekModule,
    AngularCalendarModule,
    MatIconModule,
    MatButtonModule,
    OneEventComponent,
    TaskCardModule,
    MatProgressSpinnerModule
  ]
})
export class CalendarModule {}
