import { Component, Input, OnInit } from '@angular/core';
import { Post, PostComment, PostTopic } from '../../../../../models/post';
import { ProfileService } from '../../../../../services/user/profile/profile.service';
import { PostService } from '../../../../../services/post/post.service';
import { Workspace } from '../../../../../models/workspace';
import { MentionableParticipant } from '../../../../../directive/mentionable.participant';
import { FnMentionableSearchParticipant } from '../../../../../directive/mentionable.fn.search.participant';
import { Observable, Subscriber } from 'rxjs';
import { ExtendedParticipant } from '../../../../../models/participant';
import { UtilsService } from '../../../../../services/utils/utils.service';
import {
  SnackbarService,
  SnackbarType
} from '../../../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-one-workspace-view-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  @Input() postTopic: PostTopic | undefined;
  @Input() workspace: Workspace | undefined;

  pinActive = false;
  editing = false;

  // Todo duplicate
  searchWorkspaceParticipants: FnMentionableSearchParticipant = (
    keyword: string
  ) => {
    return new Observable<Array<ExtendedParticipant>>(
      (subscriber: Subscriber<Array<MentionableParticipant>>) => {
        keyword = keyword.trim();
        const participants: Array<ExtendedParticipant> =
          this.workspace?.participants.map((p) => new ExtendedParticipant(p)) ||
          [];
        const foundParticipants = participants.filter(
          (participant: ExtendedParticipant) => {
            return (
              participant.getId() &&
              participant
                .getVisibleName()
                .toLowerCase()
                .includes(keyword.toLowerCase())
            );
          }
        );

        subscriber.next(foundParticipants);
        subscriber.complete();
      }
    );
  };

  constructor(
    public profileService: ProfileService,
    public snackBar: SnackbarService,
    public postService: PostService,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.postTopic && this.workspace) {
      this.utilsService.highlightMentionedPostTopicParticipants(
        this.postTopic,
        this.workspace.participants.map((p) => new ExtendedParticipant(p))
      );
    }
  }

  editingPostWrapper(post?: Post): void {
    this.editing = true;
    this.postService.updatePostId(post?.postId ?? 0);
  }

  notSave(): void {
    this.editing = false;
  }

  onPostUpdated(): void {
    this.editing = false;
    if (this.postTopic) {
      this.utilsService.highlightMentionedPostParticipants(
        this.postTopic,
        this.workspace?.participants.map((p) => new ExtendedParticipant(p))
      );
    }
  }

  savePostComment(text: string, mentionedParticipantsIds: Array<number>): void {
    if (this.postTopic) {
      this.postService
        .saveNewPostComment(this.postTopic, text, mentionedParticipantsIds)
        .then((comment: PostComment) => {
          if (comment && comment.mentionedUserIds) {
            this.utilsService.highlightMentionedPostParticipants(
              comment,
              this.workspace?.participants.map(
                (p) => new ExtendedParticipant(p)
              )
            );
          }
        });
    }
  }

  getDate(): string {
    return this.postTopic ? Post.getDateCreated(this.postTopic) : '';
  }

  pinClick(): void {
    if (this.postTopic) {
      this.postService.pin(this.postTopic);
    }
  }

  unpinClick(): void {
    if (this.postTopic) {
      this.postService.unpin(this.postTopic);
    }
  }

  onMessageCopied(): void {
    this.snackBar.open('Message copied', SnackbarType.Info, ['snackbar']);
  }
}
