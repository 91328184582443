import { Observable } from 'rxjs';
import { MentionableParticipant } from './mentionable.participant';

export class MentionableListActionsResult {
  afterOpened: Observable<void>;
  afterClosed: Observable<MentionableParticipant>;

  constructor(
    afterOpened: Observable<void>,
    afterClosed: Observable<MentionableParticipant>
  ) {
    this.afterOpened = afterOpened;
    this.afterClosed = afterClosed;
  }
}
