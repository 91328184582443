import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WithoutAuthErrorsComponent } from './pages/errors/without-auth-errors/without-auth-errors.component';
import { AuthGuard } from './guards/auth.guard';
import { GlobalConstants } from './common/global-constants';
import { PrivacyPolicyComponent } from './pages/texts/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/texts/terms-of-use/terms-of-use.component';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  {
    path: GlobalConstants.routes.privacyPolicy,
    component: PrivacyPolicyComponent
  },
  { path: GlobalConstants.routes.termsOfUse, component: TermsOfUseComponent },

  {
    path: '',
    loadChildren: () =>
      import('./modules/landing-layout/landing-layout.module').then(
        (m) => m.LandingLayoutModule
      )
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'new-workspace',
    loadChildren: () =>
      import('./pages/workspace/create-workspace/create-workspace.module').then(
        (m) => m.CreateWorkspaceModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: GlobalConstants.routes.errorsPage,
    component: WithoutAuthErrorsComponent
  },
  {
    path: GlobalConstants.routes.chatsHome,
    loadChildren: () =>
      import('./modules/chats/chats.module').then((m) => m.ChatsModule),
    canActivate: [AuthGuard]
  },
  {
    path: GlobalConstants.routes.tasksHome,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/tasks/tasks.module').then((m) => m.TasksModule)
      }
    ]
  },
  {
    path: GlobalConstants.routes.workspaces,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/workspace/workspace.module').then(
            (m) => m.WorkspaceModule
          )
      }
    ]
  },
  {
    path: GlobalConstants.routes.calendar,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/calendar/calendar.module').then(
            (m) => m.CalendarModule
          )
      }
    ]
  },
  {
    path: GlobalConstants.adminRoutes.adminPanel,
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/admin-panel/admin-panel.module').then(
            (m) => m.AdminPanelModule
          )
      }
    ]
  },
  {
    path: GlobalConstants.publicEventRoutes.publicEventBase,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/public-event/public-event.module').then(
            (m) => m.PublicEventModule
          )
      }
    ]
  },
  {
    path: GlobalConstants.routes.faq,
    loadChildren: () =>
      import('./modules/faq/faq.module').then((m) => m.FaqModule)
  },
  {
    path: GlobalConstants.routes.paymentCompletion,
    loadComponent: () =>
      import(
        './component/payment-completion/payment-completion.component'
      ).then((mod) => mod.PaymentCompletionComponent)
  },
  { path: '**', redirectTo: GlobalConstants.routes.signin } // Todo need page 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
