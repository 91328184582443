import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { SidebarComponent } from './sidebar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { LogoHeaderComponent } from 'src/app/component/logo-header/logo-header.component';
import { DialogTextComponent } from 'src/app/component/dialog/dialog-text/dialog-text.component';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    MatDialogModule,
    MatButtonModule,
    LogoHeaderComponent,
    DialogTextComponent,
    RouterModule,
    CommonModule,
    FlexModule
  ],
  exports: [SidebarComponent]
})
export class SidebarModule {}
