import { DateUtils } from '../../../common/date.utils';

export class DateRange {
  start: Date;
  end: Date;

  constructor(start: Date, end: Date) {
    this.start = start;
    this.end = end;
  }

  equals(o: DateRange): boolean {
    if (!o) {
      return false;
    }

    return (
      DateUtils.equals(this.start, o.start) && DateUtils.equals(this.end, o.end)
    );
  }
}
