import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { CalendarEventsList } from '../../../models/calendar.events.list';
import { CalendarEventType } from '../../../models/calendar.event.type';
import { WorkspaceEventsService } from '../../../services/events/workspace-events.service';
import { Attendance } from '../../../models/participant';
import { WorkspaceEvent } from '../../../models/workspaceEvent';
import { TaskInfo } from '../../../models/task.info';
import { Workspace } from '../../../models/workspace';
import { TaskModel } from '../../../models/task.model';
import { CalendarService } from '../../../services/calendar/calendar.service';
import { DateUtils } from '../../../common/date.utils';
import { CalendarFilter } from '../../../component/dialog/calendar-filter/calendar-filter.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-calendar-events-list',
  templateUrl: './calendar.events.list.component.html',
  styleUrls: ['./calendar.events.list.component.scss']
})
export class CalendarEventsListComponent implements OnInit, OnChanges {
  CalendarEventType = CalendarEventType;
  events: CalendarEventsList | null = null;
  loading = false;

  @Input()
  selectedDate!: Date;

  @Input()
  filter!: CalendarFilter;

  @Output()
  сalendarReload: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private calendarService: CalendarService,
    private eventsService: WorkspaceEventsService
  ) {}

  ngOnInit(): void {
    this._initEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedDate?.currentValue !== changes.selectedDate?.previousValue
    ) {
      this._initEvents();
    } else if (
      !changes.filter?.currentValue.equals(changes.filter?.previousValue)
    ) {
      this._initEvents();
    }
  }

  currentUserStatusFor(event: WorkspaceEvent | TaskInfo): Attendance {
    return this.eventsService.currentUserStatusFor(event as WorkspaceEvent);
  }

  getAsWorkspaceEvent(data: WorkspaceEvent | TaskInfo): WorkspaceEvent {
    return data as WorkspaceEvent;
  }

  createWorkspaceData(data: WorkspaceEvent | TaskInfo): Workspace {
    const event = data as WorkspaceEvent;
    const fakeWorkspace: Workspace = new Workspace();
    fakeWorkspace.workspaceId = event.workspaceId;
    fakeWorkspace.title = event.workspaceTitle || '';
    fakeWorkspace.participants = event.participants;
    return fakeWorkspace;
  }

  createTaskModel(data: WorkspaceEvent | TaskInfo): TaskModel {
    const taskInfo: TaskInfo = data as TaskInfo;
    return taskInfo.toModel();
  }

  reloadData(): void {
    this._initEvents();
  }

  reloadCalendar(): void {
    this.сalendarReload.next(true);
  }

  private _initEvents(): void {
    const startDate: Date = DateUtils.atStartOfDate(this.selectedDate);
    const endDate: Date = DateUtils.atStartOfNextDay(this.selectedDate);
    this.loading = true;

    this.calendarService
      .getCalendarEvents(startDate, endDate, this.filter.getCalendarEventType())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((events: CalendarEventsList) => {
        this.events = events;
      });
  }
}
