<div fxFill fxLayout="column" class="dialog-info-component" fxFlexFill>
  <div fxFlexAlign="end" style="margin-top: 10px; margin-right: 10px">
    <img
      class="icon material-icons close"
      src="assets/dialogs/close_white.svg"
      alt="close"
      mat-dialog-close
    />
  </div>

  <app-right-column fxFlex="90vh" fxFlexFill></app-right-column>
</div>
