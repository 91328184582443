import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Breadcrumb } from '../../models/breadcrumb';
import { GlobalConstants } from '../../common/global-constants';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbs: Breadcrumb[][] = [];

  constructor(public router: Router) {
    router.events.subscribe(() => {
      this.breadcrumbs = [];
      this.router.url
        .split('?')[0]
        .replace('/', ' ')
        .split('/')
        .forEach((item) => {
          this.breadcrumbs.push([new Breadcrumb(item)]);
        });
      if (this.getNameI(0).trim() === GlobalConstants.i18n.workspaces) {
        this.setAlias(GlobalConstants.i18n.showspaces, 0);
      }
    });
  }

  setAlias(name: string, i: number): void {
    if (this.breadcrumbs[i]) {
      const breadcrumb =
        this.breadcrumbs[i][1] !== undefined
          ? this.breadcrumbs[i][1]
          : this.breadcrumbs[i][0];
      breadcrumb.alias = name;
    }
  }

  setUrl(name: string, i: number): void {
    if (this.breadcrumbs[i]) {
      const breadcrumb =
        this.breadcrumbs[i][1] !== undefined
          ? this.breadcrumbs[i][1]
          : this.breadcrumbs[i][0];
      breadcrumb.name = name;
    }
  }

  eraseAliases(): void {
    this.breadcrumbs.forEach((bc) => bc.forEach((i) => (i.alias = '')));
  }

  removeBreadcrumb(i: number): void {
    this.breadcrumbs.splice(i, 1);
  }

  addRouteParam(i: number, name: string, value: string): void {
    if (this.breadcrumbs[i]) {
      const breadcrumb =
        this.breadcrumbs[i][1] !== undefined
          ? this.breadcrumbs[i][1]
          : this.breadcrumbs[i][0];
      breadcrumb.addQueryParam(name, value);
    }
  }

  get isNeedSlash(): boolean {
    return (
      this.breadcrumbs.filter((data) => data[0] && data[0]?.alias !== '')
        .length > 1
    );
  }

  getRouteI(i: number): string {
    return this.breadcrumbs
      .slice(0, i + 1)
      .map((n) => '/' + n[0].name.trim())
      .join('');
  }

  getNameI(i: number): string {
    return this.getRouteData(i, 'alias');
  }

  getRealNameI(i: number): string {
    return this.getRouteData(i, 'name');
  }

  getRouteQueryParams(i: number): any {
    return this.getRouteData(i, 'queryParams');
  }

  private getRouteData(i: number, field: string): any {
    if (this.breadcrumbs[i] === undefined) {
      return '';
    }

    // @ts-ignore
    return this.breadcrumbs[i][1] !== undefined ? this.breadcrumbs[i][1][field] : this.breadcrumbs[i][0][field];
  }
}
