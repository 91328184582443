import { SharedService } from './services/shared/shared.service';
import {
  Component,
  HostListener,
  OnDestroy,
  AfterViewInit,
  NgZone
} from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { WebsocketService } from './services/websocket/websocket.service';
import { GlobalConstants } from './common/global-constants';

import { Platform } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { v4 } from 'uuid';

import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { AnnouncementsService } from './services/announcements/announcements.service';

// Suppress TS warnings about window.cordova
declare let window: any; // turn off type checking

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {
  private listOfRoutes = [GlobalConstants.routes.errorsPage];
  title = 'webApp';

  private paused = false;

  constructor(
    public authService: AuthService,
    private readonly router: Router,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly websocketService: WebsocketService,
    private readonly sharedService: SharedService,
    private ngZone: NgZone,
    private platform: Platform,
    public announcements: AnnouncementsService,
    private device: Device
  ) {
    this.matIconRegistry.addSvgIcon(
      'unchecked_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/unchecked_circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'checked_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/checked_circle.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'plus',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/plus.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'trash',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/avatar/trash.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'burger',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/burger/burger.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'back-button',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/back-button/back.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'share-button',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/share-button/share.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'circle_checked',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/tasks/circle_checked.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'circle_unchecked',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/tasks/circle_unchecked.svg'
      )
    );

    const app = initializeApp(environment.firebase);
    const analytics = getAnalytics(app);

    if (this.platform.is('cordova')) {
      setUserProperties(
        analytics,
        { version: environment.appVersion, platform: 'cordova' },
        { global: true }
      );

      this.platform.ready().then((source) => {
        if (!localStorage.getItem('hwid')) {
          localStorage.setItem('hwid', this.device.uuid);
        }

        OneSignal.setAppId('cc18263e-76af-44f6-b878-47d2bbf093f1');
        OneSignal.setNotificationOpenedHandler((data) => {});
        OneSignal.clearOneSignalNotifications();
        OneSignal.setNotificationWillShowInForegroundHandler((event) => {
          if (this.paused) {
            // Application went to background.
            event.complete(event.getNotification());
          } else {
            // Application is back to foreground.
            event.complete(null);
          }
        });
        OneSignal.setNotificationOpenedHandler((event) => {});

        this.platform.pause.subscribe(this.onPause.bind(this));
        this.platform.resume.subscribe(this.onResume.bind(this));
      });
    } else {
      setUserProperties(
        analytics,
        { version: environment.appVersion, platform: 'web' },
        { global: true }
      );

      if (!localStorage.getItem('hwid')) {
        localStorage.setItem('hwid', v4());
      }
    }
  }

  ngOnDestroy(): void {
    this.websocketService.disconnect();
  }

  ngAfterViewInit(): void {
    if (this.platform.is('cordova')) {
      window.cordova.exec(
        (link: any) => {
          if (!link.host || !link.path) {
            return;
          }
          const host = link.host.toLowerCase();
          if (
            host == 'actorspocketguide.com' ||
            host == 'web-dev.actorspocketguide.com'
          ) {
            let url = link.path;
            if (link.queryString) {
              url += '?' + link.queryString;
            }
            this.ngZone.run(() => {
              this.router.navigateByUrl(url);
            });
          }
        },
        () => {},
        'IonicDeeplinkPlugin',
        'onDeepLink',
        []
      );
    }

    this.announcements.checkForAnnouncement();
  }

  public existsOnListOfRoutes(): boolean {
    const url = new URL(this.router.url, environment.url);
    return this.listOfRoutes.includes(url.pathname.substring(1));
  }

  @HostListener('window:beforeunload', ['$event'])
  onCloseWindow($event: any): void {
    this.ngOnDestroy();
  }

  onShowSignin() {
    this.sharedService.startLoading();
  }

  async onPause() {
    console.log('Application paused.');

    this.paused = true;
  }

  async onResume() {
    console.log('Application resumed.');

    this.paused = false;
  }
}
