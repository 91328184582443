import { MatCardModule } from '@angular/material/card';
import { CommonModule } from '@angular/common';
import { WithoutAuthErrorsComponent } from './without-auth-errors.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [WithoutAuthErrorsComponent],
  imports: [CommonModule, MatCardModule],
  exports: [WithoutAuthErrorsComponent]
})
export class WithoutAuthErrorsModule {}
