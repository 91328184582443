export class Post {
  postId: number | undefined;
  commentId: number | undefined;
  description = '';
  enabled = true;
  email: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  feedType: 'POST' | 'QUESTION' | 'WORKSPACE_COMMENT' = 'WORKSPACE_COMMENT';
  link: string | undefined;
  profileImage = '';
  profileImageThumbnail = '';
  mentionedUserIds: Array<number> | undefined;
  pictures: string | undefined;
  postType: 'TOPIC' | 'COMMENT' | 'REPLY' = 'TOPIC';
  replyId: number | undefined;
  subtopicArea: string | undefined;
  tags: Array<string> | undefined;
  title = '';
  topicAreaId: number | undefined;
  topicId: number | undefined;
  workspaceId = 0;
  createTime: string | undefined;
  children: Array<Post> = [];
  pinned?: boolean;
  dislikeCounter = 0;
  likeCounter = 0;
  userId: number | undefined;
  liked: boolean | undefined;
  disliked: boolean | undefined;

  public static getDateCreated(post: Post): string {
    return post.createTime
      ? new Date(post.createTime).toLocaleDateString()
      : '';
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export class PostTopic extends Post {
  postType: 'TOPIC' | 'COMMENT' | 'REPLY' = 'TOPIC';
  feedType: 'POST' | 'QUESTION' | 'WORKSPACE_COMMENT' = 'WORKSPACE_COMMENT';

  constructor(
    workspaceId: number,
    description: string,
    mentionedUserIds: Array<number> | undefined
  ) {
    super();
    this.workspaceId = workspaceId;
    this.description = description;
    this.mentionedUserIds = mentionedUserIds;
  }
}

export class PostComment extends Post {
  postType: 'TOPIC' | 'COMMENT' | 'REPLY' = 'COMMENT';
  feedType: 'POST' | 'QUESTION' | 'WORKSPACE_COMMENT' = 'WORKSPACE_COMMENT';

  constructor(
    workspaceId: number,
    description: string,
    postId?: number,
    mentionedUserIds?: Array<number>
  ) {
    super();
    this.workspaceId = workspaceId;
    this.description = description;
    this.topicId = postId;
    this.mentionedUserIds = mentionedUserIds;
  }
}

export class PostReply extends Post {
  postType: 'TOPIC' | 'COMMENT' | 'REPLY' = 'REPLY';
  feedType: 'POST' | 'QUESTION' | 'WORKSPACE_COMMENT' = 'WORKSPACE_COMMENT';

  constructor(
    workspaceId: number,
    description: string,
    postId?: number,
    topicId?: number
  ) {
    super();
    this.workspaceId = workspaceId;
    this.description = description;
    this.topicId = topicId;
    this.commentId = postId;
  }
}
