import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../dialog/dialog-confirm/dialog-confirm.component';
import { ProfileService } from '../../../services/user/profile/profile.service';
import { PostService } from '../../../services/post/post.service';
import { FnMentionableSearchParticipant } from '../../../directive/mentionable.fn.search.participant';
import { MentionableDirective } from '../../../directive/mentionable.directive';
import { PlatformUtils } from '../../../common/platform-utils';
import { MentionablePosition } from '../../../directive/mentionable.position';
import { DialogInputWriteComponent } from './dialog-input-write.component';

@Component({
  selector: 'app-input-write-post-and-comment',
  templateUrl: './input-write-post-and-comment.component.html',
  styleUrls: ['./input-write-post-and-comment.component.scss']
})
export class InputWritePostAndCommentComponent implements OnInit, OnDestroy {
  MentionablePosition = MentionablePosition;

  @Input() white = false;
  @Input() fullscreen = false;
  @Input() defaultValue = '';
  @Input() appPlaceholder = '';
  @Input() callback:
    | ((data: string, mentionedParticipantsIds: Array<number>) => void)
    | undefined;
  @Input() callbackBad: (() => void) | undefined;
  @Input() focusAuto = false;
  @Input() sticky = false;
  @Input() avatar = false;
  @Input() id = 0;
  @Input() fnSearchParticipant?: FnMentionableSearchParticipant;
  @ViewChild(MentionableDirective) mentionableDirective?: MentionableDirective;
  @ViewChild('spanInput') spanInput?: ElementRef;

  stickyClick = false;
  sendError = false;
  avatarShow = true;
  scrolling = false;
  idTimeout: NodeJS.Timeout | undefined;
  eventFocusIn?: Event;

  scroll: any;

  constructor(
    public dialog: MatDialog,
    public profileService: ProfileService,
    public postService: PostService
  ) {}

  ngOnInit(): void {
    if (!this.scroll) {
      this.scroll = (ev: Event) => {
        if (ev?.target) {
          const t = ev.target as HTMLElement;
          if (
            t.classList.contains('badge') &&
            t.classList.contains('alert-info')
          ) {
            return;
          }
        }
        if (!this.scrolling && this.sticky) {
          this.stickyClick = false;
          this.avatarShow = true;
          this.spanInput?.nativeElement?.blur();
        }
      };
    }
    window.addEventListener('scroll', this.scroll, true);

    if (this.focusAuto) {
      this.scrolling = true;
      setTimeout(() => {
        this.spanInput?.nativeElement?.focus();
        setTimeout(() => {
          this.scrolling = false;
        }, 500);
      }, 10);
    }
  }

  sent(mentionedParticipantsIds: Array<number>): void {
    this.focusOut();
    if (this.callback && this.defaultValue.trim()) {
      if (this.defaultValue.trim().length > 1500) {
        this.confirmDialog();
      } else {
        this.callback(this.defaultValue.trim(), mentionedParticipantsIds);
        this.defaultValue = '';
      }
    }
  }

  sentSpan($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    const span = this.spanInput?.nativeElement;
    if (this.callback && span && span.innerText.trim() && !this.sendError) {
      if (span.innerText.trim().length > 1500) {
        this.confirmDialog($event);
        this.sendError = true;
      } else {
        this.focusOut();
        const mentionedParticipantsIds: Array<number> =
          this.mentionableDirective?.mentionedParticipantsIds || [];
        this.callback(span.innerText.trim(), mentionedParticipantsIds);
        span.innerText = '';
        this.sendError = false;
      }
    } else {
      this.sendError = true;
      span.click();
    }
  }

  focusOut(): void {
    this.scroll();
    if (
      this.focusAuto &&
      this.callbackBad !== undefined &&
      (this.postService.getPostId() !== this.id ||
        this.postService.getPostId() === 0)
    ) {
      this.callbackBad();
    }
  }

  confirmDialog($event?: Event): void {
    const message = `The comment cannot contain more than 1500 characters. Try cut your comment.`;
    const dialogData = new ConfirmDialogModel(
      message,
      'Ok',
      '',
      'Comment is too long'
    );
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: 'confirm-dialog-container',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      console.log(dialogResult);
      if ($event) {
        this.focusin($event);
      }
    });
  }

  notSent(): void {
    if (this.callbackBad) {
      this.callbackBad();
    }
  }

  focusin($event: Event): void {
    this.postService.updatePostId(this.id);

    if (this.isClientMobileSmallScreen && !this.stickyClick) {
      if (this.fullscreen) {
        $event.stopPropagation();
        this.openDialog();
      } else if (this.sticky) {
        if ($event.target instanceof Element) {
          this.scrolling = true;
          this.eventFocusIn = $event;
          $event.target.scrollIntoView(false);
          if (this.idTimeout) {
            clearTimeout(this.idTimeout);
          }
          this.idTimeout = setTimeout(() => {
            this.scrolling = false;
          }, 100);
        }

        this.stickyClick = true;
        this.avatarShow = false;
        this.spanInput?.nativeElement?.focus();
      }
    }
  }

  inputText(): void {
    if (this.sendError) {
      const span = this.spanInput?.nativeElement;
      this.sendError = !span.innerText.trim();
    }
  }

  getClasses(): string[] {
    const out = [];
    if (this.appPlaceholder === 'post') {
      out.push('post');
    }
    return out;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogInputWriteComponent, {
      minWidth: '100%',
      minHeight: '100%',
      panelClass: 'custom-modalbox',
      data: {
        defaultValue: this.defaultValue,
        fnSearchParticipant: this.fnSearchParticipant
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.defaultValue = result?.description;
      if (result) {
        this.sent(result.mentionedParticipantsIds);
      } else {
        this.notSent();
      }
      this.focusOut();
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scroll, true);
  }

  get isClientMobileSmallScreen(): boolean {
    return PlatformUtils.isClientMobileSmallScreen();
  }
}
