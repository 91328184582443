<ng-container>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div class="calendar" fxLayout="column" fxLayoutAlign="center stretch">
      <app-calendar-big
        [currentDate]="selectedDate"
        [eventsCounts]="eventsCounts"
        [filter]="selectedFilter"
        (calendarPeriodChanged)="onPeriodChange($event)"
        (calendarSelectedDateChanged)="onSelectedDateChange($event)"
        (calendarFilterChanged)="onFilterChange($event)"
      >
      </app-calendar-big>
    </div>
    <div
      class="calendar-events-list"
      fxLayout="row"
      fxLayoutAlign="center none"
      *ngIf="selectedDate"
    >
      <app-calendar-events-list
        fxFlex="0 1 570px"
        fxFlex.lt-sm="90"
        [selectedDate]="selectedDate"
        [filter]="selectedFilter"
        #calendarEventsList
        (onCalendarReload)="onCalendarReload()"
      >
      </app-calendar-events-list>
    </div>
  </div>

  <app-float-button [callback]="onCreateEvent.bind(this)"></app-float-button>
</ng-container>
