import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ProfileService } from '../profile/profile.service';
import {
  EventBusNames,
  EventBusService
} from '../../event-bus/event-bus.service';
import { lastValueFrom } from 'rxjs';
import { ChatParticipant } from 'src/app/models/participant';

@Injectable({
  providedIn: 'root'
})
export class MapUsersForChatService {
  getPaginatedListUsersUrl = environment.url + 'chats/peers';
  mapUsers: Map<string, ChatParticipant> = new Map();
  countPage = 1;

  constructor(
    private httpClient: HttpClient,
    public profileService: ProfileService,
    private eventBusService: EventBusService
  ) {
    if (this.profileService.user) {
      this.updateUsers().then();
    } else {
      this.eventBusService.on(EventBusNames.profile, () => {
        this.updateUsers().then();
      });
    }
  }

  updateUsers(): Promise<any> {
    return this.getCountPagesUsers().then(this.successCount.bind(this));
  }

  successList(data: Array<ChatParticipant>): void {
    this.mapUsers = new Map();
    data.forEach((participant: ChatParticipant) => {
      this.mapUsers.set(participant.identity, participant);
    });
  }

  successCount(data: string): void {
    this.countPage = parseInt(data, 10);
    this.getListUsers().then(this.successList.bind(this));
  }

  get users(): ChatParticipant[] {
    return Array.from(this.mapUsers.values());
  }

  getCountPagesUsers(): Promise<any> {
    return lastValueFrom(
      this.httpClient.get(this.getPaginatedListUsersUrl + '/pages', {
        responseType: 'text'
      })
    );
  }

  getListUsers(): Promise<any> {
    return lastValueFrom(
      this.httpClient.get(this.getPaginatedListUsersUrl, {
        params: new HttpParams()
          .set('page', 0)
          .set('limit', 20 * this.countPage)
      })
    );
  }
}
