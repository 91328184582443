import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-selectable-button',
  templateUrl: './selectable-button.component.html',
  styleUrls: ['./selectable-button.component.scss']
})
export class SelectableButtonComponent implements OnInit {
  @Input() menuItem: MenuItem = { title: '', route: '' };
  @Input() isSelected = false;
  @Output() clicked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}

export interface MenuItem {
  title: string;
  route: string;
}
