import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortUrl'
})
export class ShortUrlPipe implements PipeTransform {
  transform(url: string, args?: any): any {
    if (url) {
      const length = url.length;
      if (length > 30) {
        return url.substring(0, 21) + '...' + url.substring(length - 9, length);
      }
      return url;
    }
    return url;
  }
}
