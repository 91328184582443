<div fxLayout="column" fxLayoutGap="10px">
  <ng-container *ngFor="let group of groups">
    <button
      mat-button
      class="group-button"
      [class.active]="selectedGroupValue === group.value"
      (click)="groupSelected.emit(group)"
    >
      {{ group.name }}
    </button>
  </ng-container>
</div>
