import { EventParticipant } from './participant';
import { CalendarOptions } from 'datebook';
import { UtilsService } from '../services/utils/utils.service';
import * as moment from 'moment/moment';

const date1 = new Date();
const date0 = new Date();
date0.setDate(date0.getDate() + 1);

export enum EventTypes {
  Audition = 'AUDITION',
  Class = 'CLASS',
  Performance = 'PERFORMANCE',
  Rehearsal = 'REHEARSAL',
  Miscellaneous = 'MISCELLANEOUS'
}

export class WorkspaceEvent {
  dateEnd: string | undefined = date0.toISOString();
  dateStart: string | undefined = date1.toISOString();
  workspaceTitle: string | undefined;
  description: string | null | undefined;
  enabled = true;
  eventId = 0;
  link: string | null | undefined;
  location: string | null | undefined;
  name = '';
  participants: EventParticipant[] = [];
  fileLink: string | undefined;
  inviterId: number | undefined;
  workspaceId = 0;
  pictures = '';
  type: EventTypes = EventTypes.Audition;
  mentionedUserIds: Array<number> = [];

  ticketCapacity = 0;
  ticketCost = 0;
  ticketAvailability = 0;
  ticketCurrency = 'usd';
  ticketReserved = 0;

  get isReadyForSale(): boolean {
    return this.ticketCost > 0 && this.ticketCapacity > 0;
  }

  get ticketsAvailable(): number {
    const availability = this.ticketAvailability - this.ticketReserved;
    return availability < 0 ? 0 : availability;
  }

  get isPastEvent(): boolean {
    const date = this.dateEnd ?? this.dateStart;
    return date ? moment.utc(date).local().toDate() < new Date() : false;
  }

  // TODO: Backend should return it in the future...
  getGrossPrice(quantity: number): number {
    const applicationFee = 0.01;
    const price = this.ticketCost;
    const fee = price * applicationFee;
    const total = (price + 0.3 + fee) / (1 - 0.029);
    return (Math.round(total * 100.0) / 100.0) * quantity;
  }

  get isNotStarted(): boolean {
    if (this.dateStart) {
      return new Date().getTime() < new Date(this.dateStart).getTime();
    }
    return false;
  }

  static getCalendarConfig(workspaceEvent: WorkspaceEvent): CalendarOptions {
    return {
      title: workspaceEvent.name,
      location: workspaceEvent.location || '',
      description: workspaceEvent.description || '',
      start: UtilsService.toLocalDate(workspaceEvent.dateStart),
      end: UtilsService.toLocalDate(workspaceEvent.dateEnd)
    };
  }

  constructor(workspaceId: number) {
    this.workspaceId = workspaceId;
  }

  deserialize(input: any): this {
    Object.assign(this, input);
    if (input.participants) {
      this.participants = input.participants;
    }
    return this;
  }
}
