import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private isLoading$ = new BehaviorSubject<boolean>(false);

  public startLoading(): void {
    this.isLoading$.next(true);
  }

  public stopLoading(): void {
    this.isLoading$.next(false);
  }

  get isLoading(): boolean {
    return this.isLoading$.getValue();
  }
}
