<div (window:resize)="onResize($event)" #divElement>
  <ng-container *ngIf="currentWindowWidth > 600; else mobileDesign">
    <div class="popup-header">
      <div class="title">Background photo</div>
      <div class="closeElement" (click)="closePopup()">
        <span class="material-icons">close</span>
      </div>
    </div>

    <div class="popup-content">
      <div class="image-container">
        <app-image-cropper
          [src]="data.src"
          [width]="cropperWidth"
          [height]="290"
          #imageCropper
        ></app-image-cropper>
      </div>

      <div class="options-container">
        <div class="option">
          <span class="title-slider">Zoom</span>
          <mat-slider
            (input)="onZoom($event)"
            #zoomSlider
            min="1"
            max="3"
            step="0.1"
          ></mat-slider>
        </div>

        <!--            <div class="option">-->
        <!--                <span class="title-slider">Straighten</span>-->
        <!--                <mat-slider (change)="changeAngle($event)"></mat-slider>-->
        <!--            </div>-->

        <!--            <div class="option">-->
        <!--                <img class="iconRotate" (click)="rotateImage()" src="assets/workspaces/banner/icon-rotate-image.png" alt="rotate">-->
        <!--            </div>-->
      </div>

      <!--        <img src="{{ this.data.imageSrc }}">-->
    </div>

    <div class="popup-footer">
      <div class="left-buttons">
        <span
          class="button button-delete"
          (click)="deletePhoto()"
          [class.disabled-text]="isDisabledButtons"
        >
          Delete Photo
        </span>
      </div>

      <div class="right-buttons">
        <div
          class="button button-rounded button-change-photo"
          (click)="selectPhoto()"
        >
          <input
            type="file"
            #inputFile
            (change)="handleFileInput($event)"
            accept="image/*"
            style="display: none"
          />
          <div>Change photo</div>
        </div>
        <div
          class="button button-rounded button-apply"
          (click)="apply()"
          [class.disabled]="isDisabledButtons"
        >
          <div>Apply</div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #mobileDesign>
    <div class="mobile-popup-header">
      <div class="closeElement" (click)="closePopup()">
        <span class="material-icons">close</span>
      </div>
      <div class="title">Preview</div>
      <div
        class="action-button"
        (click)="apply()"
        [class.disabled-text]="isDisabledButtons"
      >
        Apply
      </div>
    </div>

    <div class="mobile-popup-content">
      <div class="image-container">
        <app-image-cropper
          [src]="data.src"
          [width]="cropperWidth"
          [height]="290"
          #imageCropper
        ></app-image-cropper>
      </div>

      <div class="options padding-container">
        <div class="option">
          <span class="title-slider">Zoom</span>
          <mat-slider
            (input)="onZoom($event)"
            #zoomSlider
            min="1"
            max="3"
            step="0.1"
          ></mat-slider>
        </div>
      </div>

      <div class="mobile-buttons padding-container">
        <div
          class="mobile-button mobile-button-delete"
          (click)="deletePhoto()"
          [class.disabled]="isDisabledButtons"
        >
          <div>Delete</div>
        </div>

        <div class="mobile-button" (click)="selectPhoto()">
          <input
            type="file"
            #inputFile
            (change)="handleFileInput($event)"
            style="display: none"
          />
          <div>Change</div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
