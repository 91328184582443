import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CalendarFilterComponent } from './calendar-filter.component';

@NgModule({
  declarations: [CalendarFilterComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatCheckboxModule
  ],
  exports: [CalendarFilterComponent]
})
export class CalendarFilterModule {}
