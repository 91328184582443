import { RoleEducationDataSource } from './role-education-datasource';

export class Education implements RoleEducationDataSource {
  date: string;
  educationId: number;
  teacherOrSchool: string;
  training: string;

  constructor(
    training: string,
    teacherOrSchool: string,
    date: string,
    id: number
  ) {
    this.date = date;
    this.teacherOrSchool = teacherOrSchool;
    this.training = training;
    this.educationId = id;
  }

  get firstField(): string {
    return this.training;
  }

  get secondField(): string {
    return this.teacherOrSchool;
  }

  get thirdField(): string {
    return this.date;
  }

  get id(): number {
    return this.educationId;
  }
}
