import { Component } from '@angular/core';
import { AvatarComponent } from './avatar.component';

@Component({
  selector: 'app-avatar-message-group',
  templateUrl: './avatar-message-group.component.html',
  styleUrls: [
    './avatar.component.scss',
    './avatar-message-group.component.scss'
  ]
})
export class AvatarMessageGroupComponent extends AvatarComponent {}
