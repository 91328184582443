<div fxFlexFill fxLayout="column">
  <ng-container *ngIf="isHeaderVisible()">
    <div
      fxFlex="100px"
      fxFlex.sm="80px"
      fxFlex.xs="70px"
      class="notification-app-header"
    >
      <div fxFlexFill fxLayout="row" fxLayoutAlign="stretch">
        <div
          fxFlex="64px"
          fxFlex.lt-sm="50px"
          fxFlexFill
          fxHide.gt-sm="true"
          fxLayoutAlign="left center"
        >
          <mat-icon class="burger" (click)="close()">close</mat-icon>
        </div>

        <div fxFlex fxFlexFill fxLayoutAlign="start center"></div>

        <div
          fxFlex="46px"
          fxFlex.lt-md="38px"
          fxFlexFill
          fxLayoutAlign="end center"
          style="margin-right: 45px"
        >
          <app-avatar
            (click)="onDismiss()"
            [src]="profileService.user?.photoURL"
            [userIdentity]="profileService.user?.userId"
            [customRoute]="userProfileRoute"
          ></app-avatar>
        </div>
        <div fxFlex="20px" fxFlexFill fxLayoutAlign="end center"></div>
      </div>
    </div>
  </ng-container>

  <div>
    <ng-container *ngIf="notificationService.count !== 0">
      <div class="notifications-options">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="menu">
          <button mat-menu-item (click)="markAllAsRead()">
            <span>Mark all as read</span>
          </button>
          <button class="danger" mat-menu-item (click)="deleteAll()">
            <span>Delete all</span>
          </button>
        </mat-menu>
      </div>
    </ng-container>
  </div>

  <div class="notification-header">Notifications</div>

  <ng-container
    *ngIf="
      notificationService.loadInProgress &&
      !notificationService.notifications.length
    "
  >
    <app-loading></app-loading>
  </ng-container>

  <mat-dialog-content
    infinite-scroll
    (scrolled)="onScrollDown()"
    [scrollWindow]="false"
    [infiniteScrollDistance]="modalScrollDistance"
    [infiniteScrollThrottle]="modalScrollThrottle"
  >
    <ng-container *ngIf="notificationService.count === 0">
      <span class="empty-notification-block">Nothing here yet</span>
    </ng-container>
    <ng-container
      *ngFor="let notification of notificationService.notifications"
    >
      <app-notification-card
        [notification]="notification"
        (clicked)="dialogRef.close()"
      ></app-notification-card>
    </ng-container>
  </mat-dialog-content>
</div>
