export class StringUtils {
  public static removeSubstring(
    text: string,
    startIndex: number,
    endIndex: number
  ): string {
    if (endIndex < startIndex) {
      startIndex = endIndex;
    }

    const a = text.substring(0, startIndex);
    const b = text.substring(endIndex);

    return a + b;
  }

  /**
   * String.format implementation which works with positional arguments
   * @param template format template. example: "Message {0} with argument {1} for message {0}"
   * @param args array of replacement values. example: 'The message', 'XYZ'
   */
  public static format(template: string, ...args: string[]): string {
    for (let index = 0; index < args.length; index++) {
      // TODO: replaceAll - standard 2021
      // @ts-ignore
      template = template.replaceAll(`{${index}}`, args[index]);
    }

    return template;
  }

  public static removeHtmlTags(text: string): string {
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }

  static contains(source: string, str: string): boolean {
    return source.indexOf(str) >= 0;
  }
}
