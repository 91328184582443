import { Component, Input } from '@angular/core';
import { Participant } from 'src/app/models/participant';

@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: [
    './participants-list.component.scss',
    '../common-dialog-participants.component.scss',
    '../../add-update..scss',
    '../../../../../pages/workspace/one-workspace-view/participants/participants.component.scss'
  ]
})
export class ParticipantsListComponent {
  @Input() titleText = '';
  @Input() availableParticipants: Participant[] = [];
  @Input() participants: Participant[] = [];
  @Input() participantsMap: Map<string, Participant> = new Map();
  @Input() subtitleOption: 'none' | 'email' | 'title' = 'email';

  /**
   * If true, then user will navigate to participant profile while clicking on avatar
   */
  @Input() allowProfileNavigation = true;

  constructor() {}

  clickRow(participant: Participant): void {
    this.participants.push(participant);
    this.participantsMap.clear();
    this.participantsMap.set(participant.email, participant);
  }

  subtitleText(participant: Participant): string {
    switch (this.subtitleOption) {
      case 'none':
        return '';
      case 'email':
        return participant.email ? participant.email.toLowerCase() : '';
      case 'title':
        return participant.workspaceRole
          ? participant.workspaceRole.toUpperCase()
          : '';
    }
  }
}
