import {
  debounce,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import {
  SearchResultModel,
  WorkspaceService
} from '../../../../services/workspace/workspace.service';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-showspace-search',
  templateUrl: './showspace-search.component.html',
  styleUrls: ['./showspace-search.component.scss']
})
export class ShowspaceSearchComponent implements OnInit, OnDestroy {
  @Output()
  selectedWorkspace = new EventEmitter<SearchResultModel>();

  private searchEvent$: EventEmitter<string> = new EventEmitter<string>();

  private searchPage = 0;
  private searchLimit = 50;
  loadInProgress = false;
  showspaces: Array<SearchResultModel> | undefined = [];

  private searchSubs$: Subscription | undefined;
  private firstTimeCall = true;

  constructor(private readonly showspaceService: WorkspaceService) {}

  ngOnInit(): void {
    this.loadInProgress = true;
    this.searchSubs$ = this.searchEvent$
      .pipe(
        debounce(() => timer(this.firstTimeCall ? 0 : 500)),
        distinctUntilChanged(),
        switchMap((_value) =>
          this.showspaceService
            .searchForWorkspaces(_value, this.searchLimit, this.searchPage)
            .pipe(
              tap(() => (this.firstTimeCall = false)),
              map((_response) => _response?.content),
              filter((_items) => !!_items),
              map((_items) =>
                _items?.map(
                  (_item) =>
                    ({
                      workspaceId: _item.workspaceId ?? 0,
                      title: _item.title ?? ''
                    }) as SearchResultModel
                )
              )
            )
        ),
        finalize(() => (this.loadInProgress = false))
      )
      .subscribe((_showspaces) => {
        this.showspaces = _showspaces;
      });

    // start search of all as soon as component is created
    this.searchEvent$.emit('');
  }

  ngOnDestroy(): void {
    this.searchSubs$?.unsubscribe();
  }

  onGoBack(): void {
    this.selectedWorkspace.emit();
  }

  selectWorkspace(showspace: SearchResultModel): void {
    this.selectedWorkspace.emit(showspace);
  }

  taskTrackByFn(
    index: number,
    showspace: SearchResultModel
  ): number | undefined {
    return showspace.workspaceId;
  }

  onSearch(input: Event) {
    this.loadInProgress = true;
    this.searchEvent$.emit((<HTMLInputElement>input?.target)?.value);
  }
}
