import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ArchiveFile, ArchiveType } from '../../models/archiveFile';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileUtilsService } from './file-utils.service';
import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  archiveUrl = environment.url + 'attachments/';
  archivePagesUrl = environment.url + 'attachments/pages/';
  deleteAttachmentUrl = environment.url + 'attachments/delete';
  uploadAttachmentUrl = environment.url + 'attachments/upload';

  constructor(
    private httpClient: HttpClient,
    private utils: UtilsService,
    private fileUtils: FileUtilsService
  ) {}

  getFiles(
    type: ArchiveType,
    workspaceId: number,
    page: number,
    limit: number,
    isPublic: boolean | null = null
  ): Observable<ArchiveFile[]> {
    let params = { page, limit, workspaceId, type };
    if (isPublic) {
      params = Object.assign(params, { isPublic });
    }
    return this.httpClient
      .get<ArchiveFile[]>(this.archiveUrl, { params })
      .pipe(catchError((err) => this.handleError('get all files', [], err)));
  }

  deleteFiles(ids: number): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(this.deleteAttachmentUrl, { ids: [ids] })
    );
  }

  downloadAttachment(file: ArchiveFile): void {
    if (file.url) {
      this.utils.openLink(file.url);
    }
  }

  makePublic(file: ArchiveFile, isPublic: boolean): Observable<ArchiveFile> {
    return this.httpClient
      .put<ArchiveFile>(this.archiveUrl + file.attachmentId, { isPublic })
      .pipe(
        catchError((err) => this.handleError('make file public', file, err))
      );
  }

  uploadLink(file: ArchiveFile): Observable<ArchiveFile> {
    return this.httpClient
      .post<ArchiveFile>(this.archiveUrl, file)
      .pipe(
        catchError((err) => this.handleError('upload new link', file, err))
      );
  }

  uploadAttachment(file: any, workspaceId: number): any {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('workspaceId', workspaceId.toString());
    formData.append('title', file.name);
    formData.append('type', this.fileUtils.getTypeFor(file));

    return this.httpClient.post(this.uploadAttachmentUrl, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }
}
