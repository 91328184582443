import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BannerDialogData } from '../../pages/workspace/one-workspace-view/banner-dialog/dialog-banner.component';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceBannerService {
  constructor(private http: HttpClient) {}

  uploadBanner(workspaceId: number, data: BannerDialogData): Promise<any> {
    const formData = new FormData();
    const file = this.dataURLtoFile(data.imageSrc, 'name.jpg');
    formData.append('file', file);

    return lastValueFrom(
      this.http.put(
        environment.url + '/workspaces/' + workspaceId + '/banner',
        formData
      )
    );
  }

  deleteBanner(workspaceId: number): Promise<any> {
    return lastValueFrom(
      this.http.delete(
        environment.url + '/workspaces/' + workspaceId + '/banner'
      )
    );
  }

  dataURLtoFile(dataUrl: string, filename: string): File {
    const arr = dataUrl.split(',');
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}

export interface ResponseUploadBanner {
  link: string | undefined;
  previewLink: string | undefined;
}
