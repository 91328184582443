import { TaskPriority } from './taskPriority';
import { TaskAssignee } from './task.assignee';
import { Priority, TaskModel } from './task.model';

export class TaskInfo {
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  workspaceId: number | undefined;
  dueDate: string | undefined;
  priority: TaskPriority = TaskPriority.LOW;
  opened = true;
  assignees: TaskAssignee[] = [];
  creatorId: number | undefined;
  creatorFullName: string | undefined;
  creatorProfileImage: string | undefined;
  workspaceTitle = '';
  canManage = false;

  dateCreated: string | undefined;
  dateUpdated: string | undefined;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  toModel(): TaskModel {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      dueDate: this.dueDate,
      priority: this.getTaskPriorityAsPriority(this.priority),
      showspace: this.workspaceTitle,
      assignees: this.assignees,
      isOpen: this.opened,
      canManage: this.canManage
    };
  }

  getTaskPriorityAsPriority(priority: TaskPriority): Priority {
    switch (priority) {
      case TaskPriority.LOW:
        return Priority.LOW;
      case TaskPriority.MEDIUM:
        return Priority.MEDIUM;
      case TaskPriority.HIGH:
        return Priority.HIGH;
    }
    return Priority.NONE;
  }
}
