<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="mat-typography">
  <app-privacy-policy
    *ngIf="data.type === 'privacy policy'"
  ></app-privacy-policy>

  <app-terms-of-use *ngIf="data.type === 'terms of use'"></app-terms-of-use>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>OK</button>
</mat-dialog-actions>
