import { MenuItemsTitles } from '../../pages/workspace/one-workspace-view/one-workspace-view.component';
import { TutorialItem } from './tutorial-item';
import {
  FnOnNextItem,
  ShowspaceTutorialComponent
} from './showspace-tutorial.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

export class ShowspaceTutorialProcessor {
  private storageKey = 'seenShowspaceTutorial';
  private tutorialComponent?: ShowspaceTutorialComponent;
  private dialogRef?: MatDialogRef<ShowspaceTutorialComponent>;

  afterOpenedObservable?: Observable<void>;
  afterClosedObservable?: Observable<void>;

  constructor(private dialog: MatDialog) {}

  showTutorial(
    menuItems: MenuItemsTitles[],
    isManager: boolean,
    fnOnNextItem: FnOnNextItem
  ): void {
    const dialogRef = this.dialog.open(ShowspaceTutorialComponent, {
      minWidth: '100%',
      minHeight: '100vh',
      panelClass: 'showspace-tutorial-dialog',
      autoFocus: false,
      hasBackdrop: false,
      data: {
        tutorialItems: this.itemsFor(menuItems, isManager),
        fnOnNextItem
      }
    });
    this.dialogRef = dialogRef;
    this.tutorialComponent = dialogRef.componentInstance;
    this.tutorialComponent.setDialogRef(dialogRef);

    this.afterOpenedObservable = dialogRef.afterOpened();
    this.afterClosedObservable = dialogRef.afterClosed();
  }

  closeTutorial(): void {
    this.dialogRef?.close();
  }

  get seenTutorial(): boolean {
    return localStorage.getItem(this.storageKey) === 'true';
  }

  setTutorialAsSeen(): void {
    localStorage.setItem(this.storageKey, 'true');
  }

  private itemsFor(
    menuItems: MenuItemsTitles[],
    isManager: boolean
  ): TutorialItem[] {
    return menuItems.map((item) => {
      return new TutorialItem(
        this.tipTitleFor(item, isManager),
        this.tipDescriptionFor(item, isManager)
      );
    });
  }

  private tipTitleFor(menuItem: MenuItemsTitles, isManager: boolean): string {
    switch (menuItem) {
      case MenuItemsTitles.CONFLICTS:
        return 'Attendance is Key';
      case MenuItemsTitles.EVENTS:
        return 'Check the Schedule';
      case MenuItemsTitles.LIBRARY:
        return isManager
          ? 'Upload Documents, Music, Images, and Links'
          : 'Access Documents, Music, Images, and Links';
      case MenuItemsTitles.MESSAGE_BOARD:
        return 'Post Announcements';
      case MenuItemsTitles.PARTICIPANTS:
        return isManager ? 'Invite Cast & Crew' : 'Meet your Cast & Crew';
      case MenuItemsTitles.TASKS:
        return 'Track Show To-Dos';
    }
  }

  private tipDescriptionFor(
    menuItem: MenuItemsTitles,
    isManager: boolean
  ): string {
    switch (menuItem) {
      case MenuItemsTitles.CONFLICTS:
        return 'Only Show Managers can view Participant Show Conflicts. Assign Show Managers from the participants tab.';
      case MenuItemsTitles.EVENTS:
        return isManager
          ? 'Add your rehearsal and show dates, create event reports, and mark attendance. Receive email reminders and push notifications ahead of events, and for any updates or changes. Participants need to be invited to view events.'
          : 'View your rehearsal and show dates, reports, and mark your attendance. Receive email reminders and push notifications ahead of Events, and for any updates or changes.';
      case MenuItemsTitles.LIBRARY:
        return isManager
          ? 'Upload all of your sheet music, tracks, and rehearsal videos in one place. This helps your cast quickly access everything before, during, and after rehearsals.'
          : 'Find all of your sheet music, tracks, and rehearsal videos in one place. Quickly access everything before, during, and after rehearsals.';
      case MenuItemsTitles.MESSAGE_BOARD:
        return 'Message your entire cast from a single place and pin important messages in the Showspace Feed to keep your cast & crew updated. Feed posts are also sent via email to all participants, ensuring nobody is left out or misses announcements.';
      case MenuItemsTitles.PARTICIPANTS:
        return isManager
          ? 'Update show roles and encourage everyone to add photos. People who have not signed up will display their email. Click on the icon next to their email to send a reminder.'
          : 'Here you can see the cast list, roles, and profiles. Update your profile photo, skills, and previous roles to help introduce yourself.';
      case MenuItemsTitles.TASKS:
        return isManager
          ? 'Stay organized and ensure your cast is prepared by creating and assigning show tasks. Assignees receive reminder emails alerting them to complete the task.'
          : 'Always be prepared by completing assigned show tasks and stay organized by creating your own.';
    }
  }
}
