import { DialogInputWriteModule } from './dialog-input-write.module';
import { AvatarModule } from './../../avatar/avatar.module';
import { MentionableDirectiveModule } from 'src/app/directive/mentionable.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { InputWritePostAndCommentComponent } from 'src/app/component/input/input-write-post-and-comment/input-write-post-and-comment.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [InputWritePostAndCommentComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MentionableDirectiveModule,
    AvatarModule,
    DialogInputWriteModule
  ],
  exports: [InputWritePostAndCommentComponent]
})
export class InputWritePostAndCommentModule {}
