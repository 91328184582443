import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats
} from '@angular-material-components/datetime-picker';
import {
  FormValidatorService,
  greaterThan
} from '../../../../services/form-validators/form-validator.service';
import { EventTypes, WorkspaceEvent } from '../../../../models/workspaceEvent';
import { WorkspaceEventsService } from '../../../../services/events/workspace-events.service';
import * as moment from 'moment/moment';
import { FnMentionableSearchParticipant } from '../../../../directive/mentionable.fn.search.participant';
import { Observable, Subscriber } from 'rxjs';
import { MentionableParticipant } from '../../../../directive/mentionable.participant';
import {
  ExtendedParticipant,
  toExtendedParticipant
} from '../../../../models/participant';
import { Workspace } from '../../../../models/workspace';
import { StringUtils } from '../../../../common/string-utils';
import { MentionableDirective } from '../../../../directive/mentionable.directive';
import { PlatformUtils } from '../../../../common/platform-utils';
import { MentionablePosition } from '../../../../directive/mentionable.position';
import { finalize, first } from 'rxjs/operators';
import { OneWorkspaceService } from 'src/app/services/workspace/one-workspace.service';
import { SearchWorkspaceFieldComponent } from 'src/app/pages/workspace/search-workspace-field/search-workspace-field.component';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';

/**
 * Documentation datetime picker https://www.npmjs.com/package/@angular-material-components/datetime-picker
 * Displaying formats: https://momentjs.com/docs/#/displaying/
 */

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'M/D h:mm a'
  },
  display: {
    dateInput: 'M/D h:mm a',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-dialog-add-update-event',
  templateUrl: './dialog-add-update-event.html',
  styleUrls: [
    '../../../../pages/workspace/workspace.component.scss',
    './dialog-add-update-event.component.scss',
    '../add-update..scss'
  ],
  providers: [
    MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER,
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class DialogAddUpdateEventComponent implements OnInit {
  MentionablePosition = MentionablePosition;

  eventForm: FormGroup = new FormGroup({});

  editMode = false;
  clicked = true;
  allEventTypes: EventTypeOption[] = [];
  formSubmitAttempt = false;
  loadInProgress = false;
  shouldSaveOnSubmit = true;

  @ViewChild(MentionableDirective) mentionableDirective?: MentionableDirective;

  @ViewChild('searchWorkspaceField')
  searchWorkspaceField?: SearchWorkspaceFieldComponent;

  // Todo duplicate
  searchWorkspaceParticipants: FnMentionableSearchParticipant = (
    keyword: string
  ) => {
    return new Observable<Array<MentionableParticipant>>(
      (subscriber: Subscriber<Array<MentionableParticipant>>) => {
        keyword = keyword.trim();
        const participants: Array<ExtendedParticipant> =
          this.data.workspace?.participants.map(toExtendedParticipant) || [];
        const foundParticipants = participants.filter(
          (participant: ExtendedParticipant) => {
            return (
              participant.getId() &&
              participant
                .getVisibleName()
                .toLowerCase()
                .includes(keyword.toLowerCase())
            );
          }
        );

        subscriber.next(foundParticipants);
        subscriber.complete();
      }
    );
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WorkspaceData,
    public eventsService: WorkspaceEventsService,
    public dialogRef: MatDialogRef<DialogAddUpdateEventComponent>,
    private formValidator: FormValidatorService,
    private oneWorkspaceService: OneWorkspaceService
  ) {
    const keys = Object.keys(EventTypes);
    for (const key of keys) {
      // @ts-ignore
      this.allEventTypes.push(new EventTypeOption(key, EventTypes[key]));
    }
    this.shouldSaveOnSubmit = data.shouldSaveOnSubmit ?? true;
  }

  ngOnInit(): void {
    this.eventForm = new FormGroup({
      type: new FormControl(this.data.event.type, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      workspaceId: new FormControl(null, []),
      name: new FormControl(this.data.event.name, [
        Validators.required,
        Validators.maxLength(255)
      ]),
      dateStart: new FormControl(
        moment.utc(this.data.event.dateStart).local(),
        [Validators.required]
      ),
      dateEnd: new FormControl(moment.utc(this.data.event.dateEnd).local(), [
        greaterThan('dateStart')
      ]),
      location: new FormControl(this.data.event.location, [
        Validators.maxLength(255)
      ]),
      link: new FormControl(this.data.event.link, [Validators.maxLength(255)]),
      description: new FormControl(this.data.event.description, [
        Validators.maxLength(1500)
      ])
    });
    this.updateValidators();
    this.editMode = !!this.data.event.eventId || this.data.type === 'update';
  }

  updateValidators(): void {
    this.eventForm?.get('dateStart')?.valueChanges.subscribe(() => {
      this.eventForm.get('dateEnd')?.updateValueAndValidity();
    });
  }

  private updateRequest(): void {
    this.formSubmitAttempt = true;

    if (this.eventForm && this.eventForm.valid) {
      this.loadInProgress = true;

      this.eventsService
        .updateEvent(this.data.event)
        .pipe(
          first(),
          finalize(() => (this.loadInProgress = false))
        )
        .subscribe((event) => this.dialogRef.close(event));
    }
  }

  async save() {
    this.setFormDirty();

    if (!this.data.workspace && !this.editMode) {
      if (!this.searchWorkspaceField?.selectedWorkspace?.workspaceId) {
        this.searchWorkspaceField?.setDirty();
        return;
      }

      const workspaceId =
        this.searchWorkspaceField?.selectedWorkspace?.workspaceId;
      if (workspaceId) {
        const workspace =
          await this.oneWorkspaceService.getWorkspaceById(workspaceId);
        this.data.workspace = workspace;
        this.data.event.workspaceId = workspace.workspaceId ?? 0;
        this._save();
      }
    } else {
      this._save();
    }
  }

  private _save(): void {
    if (this.eventForm && this.eventForm.valid) {
      this.updateModel();

      if (!this.shouldSaveOnSubmit) {
        this.dialogRef.close(this.data.event);
        return;
      }

      this.isInEditMode
        ? this.updateRequest()
        : this.dialogRef.close(this.data.event);
    }
  }

  setFormDirty(): void {
    this.clearInputs();
    this.eventForm?.markAllAsTouched();
  }

  get isInEditMode(): boolean {
    return this.editMode;
  }

  updateModel(): void {
    const event = this.data.event;
    const formValue = this.eventForm.value;
    if (event && formValue) {
      event.type = formValue.type.toUpperCase();
      event.name = formValue.name;
      event.dateStart = formValue.dateStart?.utc().format();
      event.dateEnd = formValue.dateEnd?.utc().format();
      event.location = formValue.location;
      event.link = formValue.link;
      event.description = formValue.description;
      event.description = StringUtils.removeHtmlTags(
        this.data.event.description || ''
      );
      event.mentionedUserIds =
        this.mentionableDirective?.mentionedParticipantsIds || [];
    }
  }

  clearInputs(): void {
    if (this.eventForm) {
      const controls = this.eventForm.controls;
      const formValue = this.eventForm.value;
      controls.type.setValue(this.formValidator.clearInput(formValue.type));
      controls.name.setValue(this.formValidator.clearInput(formValue.name));
      controls.location.setValue(
        this.formValidator.clearInput(formValue.location)
      );
      controls.link.setValue(this.formValidator.clearInput(formValue.link));
      controls.description.setValue(
        this.formValidator.clearInput(formValue.description)
      );
    }
  }

  get isClientMobileSmallScreen(): boolean {
    return PlatformUtils.isClientMobileSmallScreen();
  }
}

export interface WorkspaceData {
  type: 'add' | 'update';
  event: WorkspaceEvent;
  workspace: Workspace;
  shouldSaveOnSubmit: boolean;
}

export class EventTypeOption {
  label: string;
  option: string;

  constructor(label: string, option: string) {
    this.label = label;
    this.option = option;
  }
}
