import { Injectable } from '@angular/core';
import { CalendarEventType } from '../../models/calendar.event.type';
import { Observable } from 'rxjs';
import { CalendarEventsList } from '../../models/calendar.events.list';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CalendarEventsCount } from '../../models/calendar.events.count';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  constructor(private httpClient: HttpClient) {}

  getCalendarEvents(
    startDate: Date,
    endDate: Date,
    type: CalendarEventType
  ): Observable<CalendarEventsList> {
    const startDateStr: string = startDate.toISOString().replace('Z', '');
    const endDateStr: string = endDate.toISOString().replace('Z', '');

    const params = new HttpParams()
      .set('startDate', startDateStr)
      .set('endDate', endDateStr)
      .set('type', type);

    return this.httpClient.get(`${environment.url}calendar`, { params }).pipe(
      map((res: any) => {
        return CalendarEventsList.fromJson(res);
      })
    );
  }

  getCalendarEventsCount(
    startDate: Date,
    endDate: Date,
    type: CalendarEventType
  ): Observable<Array<CalendarEventsCount>> {
    const startDateStr: string = startDate.toISOString().replace('Z', '');
    const endDateStr: string = endDate.toISOString().replace('Z', '');

    const params = new HttpParams()
      .set('startDate', startDateStr)
      .set('endDate', endDateStr)
      .set('type', type);

    const headers = new HttpHeaders().set(
      'timezone',
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    return this.httpClient
      .get(`${environment.url}calendar/statistic`, { headers, params })
      .pipe(
        map((res: any) => {
          return CalendarEventsCount.fromJsonArray(res);
        })
      );
  }
}
