import { KeyboardEventProcessor } from './keyboard.event.processor';
import { MentionableNodeUtils } from '../mentionable.node.utils';
import { CursorUtils } from '../../common/cursor-utils';

export class AndroidKeyboardEventProcessor extends KeyboardEventProcessor {
  onChange(event: any): void {
    if (event.data) {
      if (this.mentionableProcessor.isActivated) {
        if (event.data === '@') {
          // should stop previous mention and start a new one
          this.mentionableProcessor.stopMention(undefined);
          this.mentionableProcessor
            .closeMentionDialogIfOpened()
            .subscribe((res: any) => {
              this.startMention();
            });
        } else if (event.data === ' ') {
          // stop previous mention because space is entered
          this.mentionableProcessor.closeMentionDialogIfOpened();
          this.mentionableProcessor.stopMention(undefined);
        } else if (!event.isComposing && event.data.length === 1) {
          this.onTypeNameChar(event.data);
        } else if (event.isComposing) {
          // Android (Samsung Galaxy) Keyboard tricks
          const mentionNodeContent = CursorUtils.getSelectedNodeContent();

          if (event.data.length > 0 && mentionNodeContent !== '@') {
            // composing event: in Android Chrome input event contains here a full word
            const words: Array<string> = event.data.split('@');
            const name = words[words.length - 1];
            this.onTypeName(name);
          } else if (
            event.data.length === 1 &&
            mentionNodeContent === '@' &&
            event.inputType === 'insertCompositionText' &&
            event.composed
          ) {
            // trick for Android Samsung Galaxy keyboard when deleting first name char
            this.onTypeName(''); // displaying all list
          }
        }
      } else {
        // not activated yet
        if (event.data === '@') {
          this.startMention();
        }
      }
    } else if (
      event.data == null &&
      event.composed &&
      this.mentionableProcessor.isActivated &&
      (event.isComposing || event.inputType === 'deleteContentBackward')
    ) {
      const mentionNodeContent = CursorUtils.getSelectedNodeContent();

      // trick for Android, see https://discuss.prosemirror.net/t/contenteditable-on-android-is-the-absolute-worst/3810 to understand the problem
      // case when removing the first char after @ (for ex. in text '@g' removing letter 'g' on Samsung)
      if (mentionNodeContent === '@') {
        this.onTypeName(''); // displaying all list
      }
    }
  }

  // in Android Chrome input event contains a full word
  protected onTypeName(name: string): void {
    this.participantNameTyped = name;
    this.mentionableProcessor.filterParticipantsList(this.participantNameTyped);
  }

  protected onReplaceTextSymbol(event: any): void {
    const selection: Selection | null = window.getSelection();
    if (selection?.focusNode?.parentNode) {
      const parentNode: any = selection.focusNode.parentNode;
      if (MentionableNodeUtils.isMentionedNode(parentNode)) {
        // user tried to enter symbol inside mention tag - just ignore/lock input
        event.preventDefault();

        if (event.keyCode === 229 && event.composed) {
          // Trick for Samsung - event.preventDefault() is not working in such devices
          // Futhermore there is key is inserted at that moment
          if (selection.focusNode.nodeValue) {
            setTimeout(() => {
              // just removing mention <span> and move its text child node in the parent position
              parentNode.parentNode.replaceChild(
                selection.focusNode,
                parentNode
              );
            }, 0);
          }
        }
      }
    }
  }
}
