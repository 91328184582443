import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Workspace } from '../../models/workspace';
import { ProfileService } from '../user/profile/profile.service';
import { Participant } from '../../models/participant';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WorkspaceLoadStatus } from '../../pages/workspace/workspace.component';

export type SearchResultModel = {
  title: string | undefined;
  workspaceId?: number;
};

export class PageableSearchList {
  hasNext = false;
  content: Array<SearchResultModel> = [];

  constructor(hasNext: boolean, content: Array<SearchResultModel>) {
    this.hasNext = hasNext;
    this.content = content;
  }
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  /**
   * /workspaces Get paginated list of workspaces
   * ANY authenticated role access
   */
  private urlWorkspaceSearch = environment.url + 'workspaces/search';
  private urlWorkspacesSave = environment.url + 'workspaces'; // post
  private urlWorkspacesUpdate = environment.url + 'workspaces/'; // put /workspaces/{id}  {id} workspaceId
  private urlWorkspacesDelete = environment.url + 'workspaces/'; // delete /workspaces/{id}  {id} workspaceId

  private urlGetWorkspaces = environment.url + 'workspaces';
  private urlGetArchivedWorkspaces = environment.url + 'workspaces/archive';

  constructor(
    private httpClient: HttpClient,
    public profileService: ProfileService,
    public dialog: MatDialog
  ) {}

  canCreateWorkspace(): boolean {
    return true;
  }

  canLeaveWorkspace(workspace: Workspace): boolean {
    return !this.isCreator(workspace) && this.isParticipant(workspace);
  }

  canManageWorkspace(participants: Participant[]): boolean {
    return this.profileService.isAdminOrManager(participants);
  }

  canDeleteWorkspace(workspace: Workspace): boolean {
    return this.profileService.isAdmin() || this.isCreator(workspace);
  }

  isCreator(workspace: Workspace): boolean {
    const userId = this.profileService.user?.userId;
    if (userId) {
      return userId === workspace.creatorId;
    } else {
      return false;
    }
  }

  isParticipant(workspace: Workspace): boolean {
    return (
      workspace.participants?.some(
        (participant: Participant) =>
          participant.userId === this.profileService.user?.userId
      ) || false
    );
  }

  getWorkspaces(
    page: number,
    limit: number,
    status: WorkspaceLoadStatus
  ): Observable<Workspace[]> {
    const category = this.profileService.isAdmin()
      ? Category.all
      : Category.participating;
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('category', category)
      .set('page', page.toString());
    return this.httpClient
      .get<Workspace[]>(this.getUrlWorkspacesForStatus(status), { params })
      .pipe(
        map((response) => {
          const workspaces: Array<Workspace> = response.map((workspace) =>
            new Workspace().deserialize(workspace)
          );
          return workspaces;
        })
      );
  }

  searchForWorkspaces(
    search: string,
    limit: number,
    page: number
  ): Observable<PageableSearchList | undefined> {
    const params = new HttpParams()
      .set('limit', limit.toString())
      .set('page', page.toString())
      .set('keyword', search);
    return this.httpClient.get<PageableSearchList>(
      this.getUrlWorkspaceSearch(),
      { params }
    );
  }

  async deleteWorkspace(workspace: Workspace): Promise<any> {
    return await lastValueFrom(
      this.httpClient.delete(this.urlWorkspacesDelete + workspace.workspaceId)
    );
  }

  saveWorkspaces(workspace: Workspace): Observable<Workspace> {
    return this.httpClient
      .post<Workspace>(this.urlWorkspacesSave, workspace)
      .pipe(
        map((response) => {
          return new Workspace().deserialize(response);
        }),
        catchError((err) =>
          this.handleError('create workspace', workspace, err)
        )
      );
  }

  private handleError<T>(
    title: string,
    payload: T,
    error: Error
  ): Observable<T> {
    console.error('There was an error with ', title, payload, error);
    return of(payload);
  }

  publishShowspaceEventPage(
    workspace: Workspace,
    isPublished: boolean,
    salesEnabled: boolean
  ): Promise<Workspace> {
    workspace.hasSalesEnabled = salesEnabled;
    workspace.hasPublicPage = isPublished;
    return this.updateWorkspaces(workspace);
  }

  updateWorkspaces(workspace: Workspace): Promise<Workspace> {
    return lastValueFrom(
      this.httpClient
        .put(this.urlWorkspacesUpdate + workspace.workspaceId, workspace)
        .pipe(map((response) => new Workspace().deserialize(response)))
    );
  }

  assignAsManagers(workspaceId: number, ids: number[]): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(
        environment.url + 'workspaces/' + workspaceId + '/managers/assign',
        {
          ids
        }
      )
    );
  }

  revokeManagers(workspaceId: number, ids: number[]): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(
        environment.url + 'workspaces/' + workspaceId + '/managers/revoke',
        {
          ids
        }
      )
    );
  }

  private getUrlWorkspacesForStatus(status: WorkspaceLoadStatus): string {
    switch (status) {
      case 'active':
        return this.urlGetWorkspaces;
      case 'archived':
        return this.urlGetArchivedWorkspaces;
    }
  }

  private getUrlWorkspaceSearch(): string {
    return this.urlWorkspaceSearch;
  }
}

enum Category {
  all = 'ALL',
  participating = 'PARTICIPATING'
}
