export class WebSocketHeaders {
  userId: string;
  apiVersion: string;
  client: string;
  sessionId: string;

  constructor(
    userId: string,
    apiVersion: string,
    client: string,
    sessionId: string
  ) {
    this.userId = userId;
    this.apiVersion = apiVersion;
    this.client = client;
    this.sessionId = sessionId;
  }
}
