<div fxFill fxLayout="column">
  <div fxFlex="220px">
    <app-logo-header [color]="'#343D79'"></app-logo-header>
  </div>
  <div fxFlex>
    <div>
      <button
        mat-button
        class="sidebar-button"
        [routerLink]="['/' + routes.workspaces]"
        routerLinkActive="active"
        (click)="close()"
      >
        <img
          src="../../../../assets/sidebar/workspace.svg"
          class="blue"
          alt="Workspaces"
        /><img
          src="../../../../assets/sidebar/workspace_a.svg"
          class="white"
          alt="Workspaces"
        />{{ i18n.showspaces }}
      </button>
    </div>
    <div>
      <button
        mat-button
        class="sidebar-button"
        [routerLink]="[
          '/' + routes.chatsHome,
          twilioService.getCurrentChannel()?.channel?.sid || ''
        ]"
        routerLinkActive="active"
        (click)="close()"
      >
        <img
          src="../../../../assets/sidebar/chat.svg"
          class="blue"
          alt="Chats"
        /><img
          src="../../../../assets/sidebar/chat_a.svg"
          class="white"
          alt="Chats"
        />Chats
      </button>
    </div>
    <div>
      <button
        mat-button
        class="sidebar-button"
        [routerLink]="['/' + routes.tasksHome]"
        routerLinkActive="active"
        (click)="close()"
      >
        <img
          src="../../../../assets/sidebar/tasks.svg"
          class="blue"
          alt="Tasks"
        /><img
          src="../../../../assets/sidebar/tasks_a.svg"
          class="white"
          alt="Tasks"
        />Tasks
      </button>
    </div>
    <div>
      <button
        mat-button
        class="sidebar-button"
        [routerLink]="['/' + routes.calendar]"
        routerLinkActive="active"
        (click)="close()"
      >
        <img
          src="../../../../assets/sidebar/calendar.svg"
          class="blue"
          alt="Calendar"
        /><img
          src="../../../../assets/sidebar/calendar_a.svg"
          class="white"
          alt="Calendar"
        />Calendar
      </button>
    </div>
    <ng-container *ngIf="profile.isAdmin()">
      <div class="multiLevelMenu">
        <div class="mainButton">
          <div>
            <button
              mat-button
              class="sidebar-button"
              (click)="this.isVisibleAdminButtons = !this.isVisibleAdminButtons"
            >
              <img
                src="../../../../assets/sidebar/calendar.svg"
                class="blue"
                alt="Calendar"
              />
              <img
                src="../../../../assets/sidebar/calendar_a.svg"
                class="white"
                alt="Calendar"
              />
              Admin panel
            </button>
          </div>
        </div>
        <div class="items" *ngIf="this.isVisibleAdminButtons">
          <div>
            <button
              mat-button
              class="sidebar-button"
              [routerLink]="[this.adminLink(adminRoutes.users)]"
              routerLinkActive="active"
              (click)="close()"
            >
              <img
                src="../../../../assets/sidebar/calendar.svg"
                class="blue"
                alt="Calendar"
              />
              <img
                src="../../../../assets/sidebar/calendar_a.svg"
                class="white"
                alt="Calendar"
              />
              Users
            </button>
          </div>

          <div>
            <button
              mat-button
              class="sidebar-button"
              [routerLink]="[this.adminLink(adminRoutes.complaints)]"
              routerLinkActive="active"
              (click)="close()"
            >
              <img
                src="../../../../assets/sidebar/calendar.svg"
                class="blue"
                alt="Calendar"
              />
              <img
                src="../../../../assets/sidebar/calendar_a.svg"
                class="white"
                alt="Calendar"
              />
              Complaints
            </button>
          </div>

          <div>
            <button
              mat-button
              class="sidebar-button"
              [routerLink]="[this.adminLink(adminRoutes.serverErrors)]"
              routerLinkActive="active"
              (click)="close()"
            >
              <img
                src="../../../../assets/sidebar/calendar.svg"
                class="blue"
                alt="Calendar"
              />
              <img
                src="../../../../assets/sidebar/calendar_a.svg"
                class="white"
                alt="Calendar"
              />
              Server Errors
            </button>
          </div>
        </div>
      </div>
    </ng-container>
    <div>
      <button mat-button class="sidebar-button" (click)="logout()">
        Logout
      </button>
    </div>
  </div>
  <div fxFlex="none">
    <div>
      <button
        mat-button
        class="sidebar-button"
        [routerLink]="routes.faq"
        routerLinkActive="active"
        (click)="close()"
      >
        <img src="assets/sidebar/3.svg" class="blue" alt="FAQ" />
        <img src="assets/sidebar/3_a.svg" class="white" alt="FAQ" />
        FAQ
      </button>
    </div>
    <div>
      <button mat-button class="sidebar-button" (click)="openUserTerms($event)">
        <img src="assets/sidebar/3.svg" class="blue" alt="Terms of Use" />
        <img src="assets/sidebar/3_a.svg" class="white" alt="Terms of Use" />
        Terms of Use
      </button>
    </div>
    <div>
      <button
        mat-button
        class="sidebar-button"
        (click)="openPrivacyPolicy($event)"
      >
        <img src="assets/sidebar/4.svg" class="blue" alt="Privacy Policy" /><img
          src="assets/sidebar/4_a.svg"
          class="white"
          alt="Privacy Policy"
        />Privacy Policy
      </button>
    </div>
  </div>
  <div fxFlex="none" class="sidebar-link" fxLayoutAlign="center">
    <a mat-button href="https://www.actorspocketguide.com"
      >www.actorspocketguide.com</a
    >
  </div>
  <div fxFlex="none" class="version-app" fxLayoutAlign="center">
    <span>{{ version }}</span>
  </div>
</div>
