import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortUrlPipe } from '../../pipes/short-url.pipe';
import { AppDateSimplePipe } from '../../common/pipe/app.date.simple.pipe';
import { SortParticipantsPipe } from './sort-participants.pipe';

@NgModule({
  declarations: [ShortUrlPipe, AppDateSimplePipe],
  imports: [CommonModule, SortParticipantsPipe],
  exports: [ShortUrlPipe, AppDateSimplePipe, SortParticipantsPipe]
})
export class PipesModule {}
