<ng-container *ngIf="workspaceForm">
  <form [formGroup]="workspaceForm" class="add-update-form">
    <div *ngIf="needsCloseButton" class="closeElement" mat-dialog-close>
      <span class="material-icons">close</span>
    </div>

    <div
      class="titleBlock"
      [class.showOnSmallOnly]="showTitleOnSmallDeviceOnly"
    >
      <ng-container *ngIf="type === 'add'">
        <h1 class="apg-title">Create {{ i18n.showspace }}</h1>
      </ng-container>
      <ng-container *ngIf="type === 'update'">
        <h1 class="apg-title">Edit {{ i18n.showspace }}</h1>
      </ng-container>
    </div>

    <!--    1-->

    <div class="apg-form-group">
      <label
        ><span style="text-transform: capitalize">{{ i18n.showspace }}</span>
        title
        <input
          type="text"
          formControlName="title"
          maxlength="255"
          [class]="workspaceForm.controls['title'].invalid &&
                   (workspaceForm.controls['title'].dirty ||  workspaceForm.controls['title'].touched)
                   ? 'errorInput' : ''"
        />
      </label>

      <ng-container
        *ngIf="workspaceForm.controls['title'].invalid && workspaceForm.controls['title'].touched"
      >
        <div class="error" *ngIf="hasRequiredError(workspaceForm.get('title'))">
          {{ i18n.showspace }} title is required
        </div>
        <div
          class="error"
          *ngIf="workspaceForm.get('title')?.hasError('maxlength')"
        >
          Max length
          {{workspaceForm.get('title')?.errors?.maxlength?.requiredLength}}
          symbols
        </div>
      </ng-container>
    </div>

    <!--    2-->

    <div class="apg-row">
      <div class="apg-form-group apg-col-6">
        <label
          >Starts
          <input
            type="text"
            [ngxMatDatetimePicker]="picker1"
            placeholder="Choose a date"
            formControlName="dateStart"
            (keydown)="$event.preventDefault()"
            readonly="readonly"
            [class]="workspaceForm.controls['dateStart'].invalid &&
                       (workspaceForm.controls['dateStart'].dirty || workspaceForm.controls['dateStart'].touched)
                            ? 'errorInput' : ''"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(picker1)"
            [disableRipple]="true"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker1
            [enableMeridian]="true"
          ></ngx-mat-datetime-picker>
        </label>

        <ng-container
          *ngIf="workspaceForm.controls['dateStart'].invalid &&
            (workspaceForm.controls['dateStart'].dirty || workspaceForm.controls['dateStart']?.touched)"
        >
          <div
            class="error"
            *ngIf="workspaceForm.controls['dateStart']?.errors?.required"
          >
            Should not be empty
          </div>
        </ng-container>
      </div>

      <div class="apg-form-group apg-col-6">
        <label
          >Ends
          <input
            type="text"
            [ngxMatDatetimePicker]="picker2"
            placeholder="Date Time"
            formControlName="dateEnd"
            readonly="readonly"
            (keydown)="$event.preventDefault()"
            [class]="workspaceForm.controls['dateEnd'].invalid &&
                       (workspaceForm.controls['dateEnd'].dirty || (workspaceForm.controls['dateEnd'].touched || workspaceForm.controls['dateStart'].touched ))
                            ? 'errorInput' : ''"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="$any(picker2)"
            [disableRipple]="true"
          ></mat-datepicker-toggle>
          <ngx-mat-datetime-picker
            #picker2
            [enableMeridian]="true"
          ></ngx-mat-datetime-picker>
        </label>

        <ng-container
          *ngIf="workspaceForm.controls['dateEnd'].invalid &&
            (workspaceForm.controls['dateEnd'].dirty ||  (workspaceForm.controls['dateEnd'].touched || workspaceForm.controls['dateStart'].touched ))"
        >
          <div
            class="error"
            *ngIf="workspaceForm.controls['dateEnd'].errors?.required"
          >
            Should not be empty
          </div>
          <div
            class="error"
            *ngIf="workspaceForm.controls['dateEnd'].errors?.lessThenStart"
          >
            Cannot be less than start date
          </div>
        </ng-container>
      </div>
    </div>

    <!--    3-->
    <div class="apg-form-group">
      <label
        >Location
        <input
          type="text"
          formControlName="location"
          [class]="workspaceForm.controls['location'].invalid &&
                   (workspaceForm.controls['location'].dirty ||  workspaceForm.controls['location'].touched)
                            ? 'errorInput' : ''"
        />
      </label>

      <ng-container
        *ngIf="workspaceForm.controls['location'].invalid &&
        (workspaceForm.controls['location'].dirty ||  workspaceForm.controls['location'].touched)"
      >
        <div
          class="error"
          *ngIf="workspaceForm.controls['location'].errors?.maxlength"
        >
          Max length 255 symbols
        </div>
      </ng-container>
    </div>

    <!--    4-->
    <div class="apg-form-group">
      <label
        >Link
        <input
          type="text"
          formControlName="siteUrl"
          [class]="workspaceForm.controls['siteUrl'].invalid &&
                   (workspaceForm.controls['siteUrl'].dirty ||  workspaceForm.controls['siteUrl'].touched)
                        ? 'errorInput' : ''"
        />
      </label>

      <ng-container
        *ngIf="workspaceForm.controls['siteUrl'].invalid &&
        (workspaceForm.controls['siteUrl'].dirty ||  workspaceForm.controls['siteUrl'].touched)"
      >
        <div
          class="error"
          *ngIf="workspaceForm.controls['siteUrl'].errors?.maxlength"
        >
          Max length 255 symbols
        </div>
      </ng-container>
    </div>

    <!--    5-->
    <div class="apg-form-group">
      <label
        >Description
        <textarea
          formControlName="description"
          [class]="workspaceForm.controls['description'].invalid &&
                      (workspaceForm.controls['description'].dirty ||  workspaceForm.controls['description'].touched)
                        ? 'errorInput' : ''"
        ></textarea>
      </label>

      <ng-container
        *ngIf="workspaceForm.controls['description'].invalid &&
        (workspaceForm.controls['description'].dirty ||  workspaceForm.controls['description'].touched)"
      >
        <div
          class="error"
          *ngIf="hasRequiredError(workspaceForm.get('description'))"
        >
          {{ i18n.showspace }} description is required
        </div>
        <div
          class="error"
          *ngIf="workspaceForm.controls['description'].errors?.maxlength"
        >
          Max length 1500 symbols
        </div>
      </ng-container>
    </div>

    <!--    5-->
    <div
      fxLayout="column"
      fxLayoutGap="10px"
      style="margin-top: 20px"
      *ngIf="type === 'add'"
    >
      <div fxLayoutAlign="space-between center">
        <label>Add default tasks</label>
        <mat-slide-toggle
          [checked]="workspace.createDefaultTasks"
          formControlName="defaultTasks"
        ></mat-slide-toggle>
      </div>
      <span class="default-task-hint">
        Common show tasks are automatically created sign show contract, be off
        book, sell tickets, etc.
      </span>
    </div>

    <div class="apg-form-group">
      <button
        *ngIf="showSaveButton"
        class="apg-button red-button apg-button-margin-top"
        (click)="saveOrUpdate()"
        [class.spinner]="loadInProgress"
        [disabled]="(!clicked && workspaceForm.invalid) || loadInProgress"
      >
        {{ type === 'add' ? 'Add' : 'Save' }}
      </button>
    </div>
  </form>
</ng-container>
