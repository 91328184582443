import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from 'src/app/services/notifications/notifications.service';
import { ProfileService } from 'src/app/services/user/profile/profile.service';
import { GlobalConstants } from '../../../../../common/global-constants';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  page = 0;
  modalScrollDistance = 4;
  modalScrollThrottle = 50;

  userProfileRoute = GlobalConstants.routes.profile;

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    public notificationService: NotificationsService,
    public profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.notificationService.setNotification();
    this.notificationService.getNotifications().then();
  }

  onConfirm(): void {
    this.dialogRef.close();
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  markAllAsRead(): void {
    this.notificationService.readAllNotification().then();
  }

  deleteAll(): void {
    this.notificationService.deleteAllNotification().then();
  }

  close(): void {
    this.dialogRef.close();
  }

  isHeaderVisible(): boolean {
    return window.innerWidth < 500;
  }

  onScrollDown(): void {
    this.notificationService.getPageCount().then((count) => {
      if (this.page >= count) {
        return;
      }
      this.notificationService.setPage(++this.page);
      this.notificationService.getNotifications().then();
    });
  }
}
