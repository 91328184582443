import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ShareComponent } from './share.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ShareComponent],
  imports: [CommonModule, MatIconModule],
  exports: [ShareComponent]
})
export class ShareModule {}
