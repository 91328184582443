import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { lastValueFrom, Observable } from 'rxjs';
import { WorkspaceInvite } from '../../models/workspaceInvite';
import { Participant } from '../../models/participant';

@Injectable({
  providedIn: 'root'
})
export class ParticipantsService {
  private urlUsersInvitedByMe: string =
    environment.url + 'users/invited/by/me/short/info';

  private baseParticipantsUrl(workspaceId: number): string {
    return environment.url + 'workspaces/' + workspaceId + '/participants';
  }

  private removeParticipantFromWorkspace(workspaceId: number): string {
    return this.baseParticipantsUrl(workspaceId) + '/delete';
  }

  private reinviteToWorkspace(workspaceId: number): string {
    return environment.url + 'workspaces/' + workspaceId + '/reinvite';
  }

  constructor(private httpClient: HttpClient) {}

  invite(data: AddParticipantsData, workspaceId: number): Observable<any> {
    return this.httpClient.post(this.baseParticipantsUrl(workspaceId), data);
  }

  leave(data: LeaveParticipantsData, workspaceId: number): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(this.removeParticipantFromWorkspace(workspaceId), {
        emails: data.emails
      })
    );
  }

  resendInvite(invite: WorkspaceInvite, workspaceId: number): Promise<any> {
    return lastValueFrom(
      this.httpClient.post(this.reinviteToWorkspace(workspaceId), invite)
    );
  }

  userInvitedByMe(): Promise<any> {
    return lastValueFrom(this.httpClient.get(this.urlUsersInvitedByMe));
  }

  updateUserRole(data: UpdateUserRoleData, workspaceId: number): Promise<any> {
    return lastValueFrom(
      this.httpClient.put(this.baseParticipantsUrl(workspaceId), {
        participants: data.participants
      })
    );
  }

  updateShowCast(
    cast: Pick<Participant, 'email' | 'isCastMember'>[],
    workspaceId: number
  ): Observable<Participant[]> {
    return this.httpClient.put<Participant[]>(
      this.baseParticipantsUrl(workspaceId),
      { participants: cast }
    );
  }

  searchForParticipantsForWorkspace(
    workspaceId: number,
    limit: number,
    page: number
  ): Observable<Participant[]> {
    const params = new HttpParams().set('limit', limit).set('page', page);

    return this.httpClient.get<Participant[]>(
      this.baseParticipantsUrl(workspaceId),
      {
        params
      }
    );
  }
}

export interface UpdateUserRoleData {
  participants: Pick<Participant, 'email' | 'workspaceRole'>[];
}

export interface AddParticipantsData {
  participants: Pick<Participant, 'email' | 'workspaceRole'>[];
}

export interface LeaveParticipantsData {
  emails: string[];
}
