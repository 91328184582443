import { TaskLoadStatus } from '../services/task/task.service';
import { TaskModel } from './task.model';

export class TaskCollection {
  status: TaskLoadStatus;
  tasks: Array<TaskModel>;
  hasNext: boolean;

  constructor(status: TaskLoadStatus) {
    this.status = status;
    this.tasks = [];
    this.hasNext = true;
  }

  cleanUp(isCleanUp: boolean): void {
    this.hasNext = isCleanUp ? true : this.hasNext;
  }
}
