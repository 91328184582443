<ng-container *ngIf="form">
  <form [formGroup]="form" class="add-update-form">
    <div class="closeElement" mat-dialog-close>
      <span class="material-icons">close</span>
    </div>

    <div class="dots" *ngIf="this.reportData.workspaceEventReportId">
      <button
        class="more"
        mat-icon-button
        [matMenuTriggerFor]="menu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_horiz</mat-icon>
      </button>

      <mat-menu #menu="matMenu" class="menu">
        <button mat-menu-item (click)="downloadPdf()">
          <span>Download pdf</span>
        </button>
        <!--                <button mat-menu-item (click)="dialogShare()"><span>Share</span></button>-->
        <button
          *ngIf="isCanEdit"
          mat-menu-item
          (click)="dialogDelete()"
          class="danger"
        >
          <span>Delete</span>
        </button>
      </mat-menu>
    </div>

    <div class="titleBlock">
      <h1 class="apg-title">
        <ng-container *ngIf="data.type === this.allEventTypes.Rehearsal"
          >Rehearsal report</ng-container
        >
        <ng-container *ngIf="data.type === this.allEventTypes.Class"
          >Class report</ng-container
        >
        <ng-container *ngIf="data.type === this.allEventTypes.Miscellaneous"
          >Report</ng-container
        >
        <ng-container *ngIf="data.type === this.allEventTypes.Performance"
          >Performance report</ng-container
        >
        <ng-container *ngIf="data.type === this.allEventTypes.Audition"
          >Audition report</ng-container
        >
      </h1>
    </div>
    <mat-dialog-content>
      <div class="published" *ngIf="this.reportData.publishDate !== null">
        Updated {{ this.reportData.updateTime | date: 'MM/dd/YYYY h:mm a' }}
      </div>

      <ng-container
        *ngIf="
          data.type === this.allEventTypes.Rehearsal ||
          this.data.type === this.allEventTypes.Performance
        "
      >
        <ng-container *ngFor="let field of rehearsalPerformanceFields">
          <app-row-textarea
            [name]="field.key"
            [form]="form"
            [label]="field.title"
            (fieldChanged)="onFieldChanged($event)"
            [readonly]="isReadOnly"
          >
          </app-row-textarea>
        </ng-container>
      </ng-container>

      <ng-container
        *ngIf="
          data.type === this.allEventTypes.Audition ||
          data.type === this.allEventTypes.Class ||
          data.type === this.allEventTypes.Miscellaneous
        "
      >
        <ng-container *ngFor="let field of classFields">
          <app-row-textarea
            [name]="field.key"
            [form]="form"
            [label]="field.title"
            (fieldChanged)="onFieldChanged($event)"
            [readonly]="isReadOnly"
          >
          </app-row-textarea>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isCanEdit && !this.reportData.published">
        <div class="apg-form-group">
          <div class="reportNotice">
            Participants don't see the report until you tap Publish
          </div>
        </div>

        <div class="apg-form-group">
          <button
            class="apg-button red-button apg-button-margin-top"
            (click)="publish()"
            [disabled]="form.invalid"
          >
            Publish
          </button>
        </div>
      </ng-container>
    </mat-dialog-content>
  </form>
</ng-container>
