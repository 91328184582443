import { MatMenuModule } from '@angular/material/menu';
import { AvatarModule } from 'src/app/component/avatar/avatar.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { FullscreenImageComponent } from './fullscreen-image.component';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [FullscreenImageComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule,
    MatIconModule,
    AvatarModule,
    MatMenuModule,
    MatSlideToggleModule
  ],
  exports: [FullscreenImageComponent]
})
export class FullScreenImageModule {}
