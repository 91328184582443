<ng-container *ngIf="participants && workspaceEvent && workspace">
  <ng-container *ngIf="participants.length === 0">
    <app-empty-content
      fxFlexFill
      fxLayoutAlign="center center"
    ></app-empty-content>
  </ng-container>

  <ng-container *ngIf="participants.length > 0">
    <div
      fxLayout="column"
      class="workspace-card participant-list"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollThrottle]="throttle"
      [infiniteScrollContainer]="elem"
      [fromRoot]="true"
      (scrolled)="onScrollDown()"
    >
      <div
        class="sticky-dots"
        *ngIf="profileUser.isAdminOrManager(workspace.participants)"
      >
        <div class="dots">
          <button
            class="more"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>

          <mat-menu #menu="matMenu" class="menu">
            <ng-container *ngIf="attendance === allAttendanceTypes.Rejected">
              <ng-container *ngIf="workspaceEvent.isNotStarted">
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toReason()"
                >
                  <span>Reason</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toUndecided()"
                >
                  <span>Move to Undecided</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toAttending()"
                >
                  <span>Move to Attending</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="allowDeleteMenu"
                  [disabled]="selectedParticipants.size === 0"
                  (click)="delete()"
                >
                  <span>Delete</span>
                </button>
              </ng-container>

              <ng-container *ngIf="!workspaceEvent.isNotStarted">
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toReason()"
                >
                  <span>Reason</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toAttending()"
                >
                  <span>Confirm Attending</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="allowDeleteMenu"
                  [disabled]="selectedParticipants.size === 0"
                  (click)="delete()"
                >
                  <span>Delete</span>
                </button>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="attendance === allAttendanceTypes.Unmarked">
              <ng-container *ngIf="workspaceEvent.isNotStarted">
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toAttending()"
                >
                  <span>Move to Attending</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toRejected()"
                >
                  <span>Move to Rejected</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="allowDeleteMenu"
                  [disabled]="selectedParticipants.size === 0"
                  (click)="delete()"
                >
                  <span>Delete</span>
                </button>
              </ng-container>

              <ng-container *ngIf="!workspaceEvent.isNotStarted">
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toAttending()"
                >
                  <span>Confirm Attending</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toRejected()"
                >
                  <span>Absent</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="allowDeleteMenu"
                  [disabled]="selectedParticipants.size === 0"
                  (click)="delete()"
                >
                  <span>Delete</span>
                </button>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="attendance === allAttendanceTypes.Attending">
              <ng-container *ngIf="workspaceEvent.isNotStarted">
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toUndecided()"
                >
                  <span>Move to Undecided</span>
                </button>
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toRejected()"
                >
                  <span>Move to Rejected</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="allowDeleteMenu"
                  [disabled]="selectedParticipants.size === 0"
                  (click)="delete()"
                >
                  <span>Delete</span>
                </button>
              </ng-container>

              <ng-container *ngIf="!workspaceEvent.isNotStarted">
                <button
                  mat-menu-item
                  [disabled]="selectedParticipants.size === 0"
                  (click)="toRejected()"
                >
                  <span>Absent</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="allowDeleteMenu"
                  [disabled]="selectedParticipants.size === 0"
                  (click)="delete()"
                >
                  <span>Delete</span>
                </button>
              </ng-container>
            </ng-container>
          </mat-menu>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="10px" style="width: 100%">
        <ng-container
          *ngFor="
            let participant of participants | appSortParticipants: workspace
          "
        >
          <app-one-participant
            [participant]="participant"
            [canSelectItem]="canEditParticipants"
            (click)="clickRow(participant)"
            [isSelected]="selectedParticipants.has(participant)"
          >
            <!-- TODO: <button
              class="participant-button"
              mat-icon-button
              (click)="clickRow(participant)"
            >
              <img [src]="'assets/icons/plus-white.svg'" alt="" />
            </button> -->
          </app-one-participant>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="workspaceEventService.canManageEvent(workspace.participants)"
  >
    <app-float-button [callback]="openDialog.bind(this)"></app-float-button>
  </ng-container>
</ng-container>
