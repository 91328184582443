// Credit to Liam (Stack Overflow)
// https://stackoverflow.com/a/41034697/3480193
export class CursorUtils {
  static getCurrentCursorPosition(parentElement: any): number {
    const selection = window.getSelection();
    let charCount = -1;
    let node: any;

    if (selection?.focusNode) {
      if (CursorUtils._isChildOf(selection.focusNode, parentElement)) {
        node = selection.focusNode;
        charCount = selection.focusOffset;

        while (node) {
          if (node === parentElement) {
            break;
          }

          if (node.previousSibling) {
            node = node.previousSibling;
            charCount += node.textContent.length;
          } else {
            node = node.parentNode;
            if (node === null) {
              break;
            }
          }
        }
      }
    }

    return charCount;
  }

  static getCurrentCursorPositionInHTML(parentElement: any): number {
    const selection = window.getSelection();
    let charCount = -1;
    let node: any;

    if (selection?.focusNode) {
      if (CursorUtils._isChildOf(selection.focusNode, parentElement)) {
        node = selection.focusNode;
        charCount = selection.focusOffset;

        while (node) {
          if (node === parentElement) {
            break;
          }

          if (node.previousSibling) {
            node = node.previousSibling;
            charCount += node.outerHTML?.length || node.textContent.length;
          } else {
            node = node.parentNode;
            if (node === null) {
              break;
            }
          }
        }
      }
    }

    return charCount;
  }

  static setCurrentCursorPosition(pos: number, element: any): void {
    if (pos >= 0) {
      const selection = window.getSelection();

      const range = CursorUtils._createRange(element, { count: pos }, null);

      if (range) {
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  }

  static getSelectionCaretPosition(): DOMRect | undefined {
    const rangesCount = window.getSelection()?.rangeCount || 0;

    if (rangesCount === 0) {
      return undefined;
    }

    return window.getSelection()?.getRangeAt(0)?.getBoundingClientRect();
  }

  public static getSelectedNodeContent(): string {
    const selection: Selection | null = window.getSelection();
    let mentionNodeContent = '';

    if (selection?.focusNode?.nodeValue) {
      mentionNodeContent =
        selection?.focusNode?.nodeValue[selection?.focusOffset - 1];
    } else {
      console.warn('Selected node is not mentioned node');
    }

    return mentionNodeContent;
  }

  static _createRange(node: any, chars: any, range: Range | null): Range {
    if (!range) {
      range = document.createRange();
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count);
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length;
        } else {
          range.setEnd(node, chars.count);
          chars.count = 0;
        }
      } else {
        for (const childNode of node.childNodes) {
          range = CursorUtils._createRange(childNode, chars, range);
          if (chars.count === 0) {
            break;
          }
        }
      }
    }

    return range;
  }

  static _isChildOf(node: any, parentElement: any): boolean {
    while (node !== null) {
      if (node === parentElement) {
        return true;
      }
      node = node.parentNode;
    }

    return false;
  }
}
