<div
  class="workspace-block"
  routerLink="/{{ routes.workspaces }}/{{ workspace.workspaceId }}/{{
    routes.workspaceMessages
  }}"
>
  <div class="more-options">
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="menu">
      <ng-container *ngIf="workspaceService.canDeleteWorkspace(workspace)">
        <button mat-menu-item (click)="onDeleteWorkspace()">
          <span>Delete</span>
        </button>
      </ng-container>
      <ng-container
        *ngIf="
          workspaceService.isCreator(workspace) ||
          workspaceService.canManageWorkspace(workspace.participants)
        "
      >
        <button mat-menu-item (click)="onEditWorkspace()">
          <span>Edit</span>
        </button>
      </ng-container>
      <ng-container>
        <button mat-menu-item (click)="onReportWorkspace()">
          <span>Report</span>
        </button>
      </ng-container>
      <ng-container *ngIf="workspaceService.canLeaveWorkspace(workspace)">
        <button mat-menu-item (click)="onLeaveWorkspace()">
          <span>Leave</span>
        </button>
      </ng-container>
      <button mat-menu-item [matMenuTriggerFor]="calendar">
        <span>Add to Calendar</span>
      </button>
    </mat-menu>
    <mat-menu #calendar="matMenu" class="menu">
      <button
        mat-menu-item
        *ngIf="utils.isMacOrIOS()"
        (click)="addToICalendar()"
      >
        iCalendar
      </button>
      <button mat-menu-item (click)="addToGoogle()">Google calendar</button>
    </mat-menu>
  </div>
  <div class="workspace-title">{{ this.workspace.title }}</div>
  <div class="workspace-date">
    {{ workspace.dateStart | date: 'MMMM, d' }} -
    {{ workspace.dateEnd | date: 'MMMM, d' }}
  </div>
</div>
