import { DisclaimerModule } from './../disclaimer/disclaimer.module';
import { TermsOfUseComponent } from './terms-of-use.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [TermsOfUseComponent],
  imports: [DisclaimerModule],
  exports: [TermsOfUseComponent]
})
export class TermsOfUseModule {}
