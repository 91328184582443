import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { EventTypes, WorkspaceEvent } from '../../../../models/workspaceEvent';
import { EventReport } from '../../../../models/EventReport';
import { EventReportService } from '../../../../services/event-report/event-report.service';
import {
  EventBusNames,
  EventBusService
} from '../../../../services/event-bus/event-bus.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../dialog-confirm/dialog-confirm.component';
import { atLeastOneFieldFilled } from '../../../../services/form-validators/form-validator.service';
import { UtilsService } from '../../../../services/utils/utils.service';

@Component({
  selector: 'app-dialog-event-report',
  templateUrl: './dialog-event-report.component.html',
  styleUrls: ['./dialog-event-report.component.scss', '../add-update..scss']
})
export class DialogEventReportComponent implements OnInit {
  public allEventTypes = EventTypes;

  private validatorOrOpts: ValidatorFn[] = [Validators.maxLength(512)];

  public form: FormGroup | null = null;
  clicked = false;

  rehearsalPerformanceFields = [
    { title: 'Scheduled Activities', key: 'scheduledActivities' },
    { title: 'Schedule', key: 'schedule' },
    { title: 'General Notes', key: 'generalNotes' },
    { title: 'Set', key: 'set' },
    { title: 'Lighting', key: 'lightning' },
    { title: 'Costume/hair', key: 'costumeHair' },
    { title: 'Props', key: 'props' },
    { title: 'Production Management', key: 'productionManagement' }
  ];

  classFields = [
    { title: 'Schedule', key: 'schedule' },
    { title: 'Notes', key: 'notes' }
  ];

  public blocker = new BlockerFields();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EventReportData,
    private eventReportService: EventReportService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private eventBusService: EventBusService,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<DialogEventReportComponent>
  ) {}

  ngOnInit(): void {
    if (
      this.data.type === this.allEventTypes.Class ||
      this.data.type === this.allEventTypes.Audition ||
      this.data.type === this.allEventTypes.Miscellaneous
    ) {
      this.form = new FormGroup({
        schedule: new FormControl(
          this.reportData.schedule,
          this.validatorOrOpts
        ),
        notes: new FormControl(this.reportData.notes, this.validatorOrOpts)
      });
    } else if (
      this.data.type === this.allEventTypes.Rehearsal ||
      this.data.type === this.allEventTypes.Performance
    ) {
      this.form = new FormGroup({
        scheduledActivities: new FormControl(
          this.reportData.scheduledActivities,
          this.validatorOrOpts
        ),
        schedule: new FormControl(
          this.reportData.schedule,
          this.validatorOrOpts
        ),
        generalNotes: new FormControl(
          this.reportData.generalNotes,
          this.validatorOrOpts
        ),
        set: new FormControl(this.reportData.set, this.validatorOrOpts),
        lightning: new FormControl(
          this.reportData.lightning,
          this.validatorOrOpts
        ),
        costumeHair: new FormControl(
          this.reportData.costumeHair,
          this.validatorOrOpts
        ),
        props: new FormControl(this.reportData.props, this.validatorOrOpts),
        sound: new FormControl(this.reportData.sound, this.validatorOrOpts),
        productionManagement: new FormControl(
          this.reportData.productionManagement,
          this.validatorOrOpts
        )
      });
    }
    this.form?.setValidators(atLeastOneFieldFilled());
  }

  onFieldChanged(spinnerName: string): void {
    this.blocker.block();

    console.log(this.reportData);
    if (this.form && this.form.valid) {
      let isDelete = true;
      for (const prop in this.form?.value) {
        if (
          this.form?.value.hasOwnProperty(prop) &&
          this.reportData.hasOwnProperty(prop)
        ) {
          const formValue = this.form.value[prop]
            ? this.form.value[prop].trim()
            : null;
          // @ts-ignore
          this.data.eventReport[prop] = formValue ? formValue : null;

          if (isDelete && formValue) {
            isDelete = false;
          }
        }
      }

      if (isDelete) {
        this.deleteReport().then(
          () => {
            const title = this.data.eventReport.title;
            this.data.eventReport = new EventReport();
            this.data.eventReport.title = title;
            this.hideCustomSpinner(spinnerName);
          },
          () => {
            this.hideCustomSpinner(spinnerName);
          }
        );
      } else {
        this.data.eventReport.type = this.data.type;

        this.saveReport().then(
          (response) => {
            this.data.eventReport = new EventReport().deserialize(response);

            this.hideCustomSpinner(spinnerName);
          },
          () => {
            this.hideCustomSpinner(spinnerName);
          }
        );
      }
    } else {
      this.hideCustomSpinner(spinnerName);
    }
  }

  hideCustomSpinner(spinnerName: string): void {
    this.spinner.hide(spinnerName).then();
    this.blocker.unBlock();
  }

  get reportData(): EventReport {
    return this.data.eventReport;
  }

  get isCanEdit(): boolean {
    return this.data.isCanEdit;
  }

  get isReadOnly(): boolean {
    return !this.isCanEdit || this.blocker.isBlocked();
  }

  publish(): void {
    this.reportData.published = true;
    this.reportData.publishDate = new Date();
    this.saveReport().then();
  }

  saveReport(): Promise<any> {
    return this.eventReportService.saveReport(
      this.data.workspaceEvent.workspaceId,
      this.data.workspaceEvent.eventId,
      this.data.eventReport
    );
  }

  deleteReport(): Promise<any> {
    return this.eventReportService.deleteReport(
      this.data.workspaceEvent.workspaceId,
      this.data.workspaceEvent.eventId
    );
  }

  dialogDelete(): void {
    const message = `Are you sure you want to delete this report?`;
    const dialogData = new ConfirmDialogModel(message, 'Delete', 'Discard', '');
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: 'confirm-dialog-container',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.spinner.show().then();
        this.deleteReport().then(
          this.deletedReport.bind(this),
          this.deletedReport.bind(this)
        );
      }
    });
  }

  /**
   * Now pdf on blacklist
   * https://bugs.chromium.org/p/chromium/issues/detail?id=1006055
   * Chrome 93.0.4570.0 now supports sharing PDF files with Web Share.
   */
  dialogShare(): void {
    this.spinner.show().then();
    this.eventReportService
      .downloadPdf(
        this.data.workspaceEvent.workspaceId,
        this.data.workspaceEvent.eventId
      )
      .then(async (blob: any) => {
        this.spinner.hide().then();

        const file = new File([blob], 'report.pdf', { type: blob.type });
        const files = [file];

        // @ts-ignore
        if (navigator.canShare && navigator.canShare({ files })) {
          navigator
            .share({
              // @ts-ignore
              files
            })
            .then(() => console.log('Share was successful.'))
            .catch((error) => console.log('Sharing failed', error));
        } else {
          console.log(`Your system doesn't support sharing files.`);
        }
      });
  }

  downloadPdf(): void {
    this.spinner.show().then();
    this.eventReportService
      .downloadPdf(
        this.data.workspaceEvent.workspaceId,
        this.data.workspaceEvent.eventId
      )
      .then((res: any) => {
        this.spinner.hide().then();

        if (res.url) {
          this.utils.openLink(res.url);
        }
      });
  }

  deletedReport(): void {
    this.spinner.hide().then();
    this.dialogRef.close();
    this.eventBusService.emit({ name: EventBusNames.deletedReport });
  }
}

export interface EventReportData {
  type: EventTypes;
  eventReport: EventReport;
  isCanEdit: boolean;
  workspaceEvent: WorkspaceEvent;
}

export class BlockerFields {
  private blocked = false;

  isBlocked(): boolean {
    return this.blocked;
  }

  block(): void {
    this.blocked = true;
  }

  unBlock(): void {
    this.blocked = false;
  }
}
