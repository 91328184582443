import { TaskService } from 'src/app/services/task/task.service';
import { UserReportingService } from 'src/app/services/user/user-reporting/user-reporting.service';
import { Priority, TaskModel } from 'src/app/models/task.model';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  skip
} from 'rxjs/operators';
import { ConfirmDialogModel } from 'src/app/component/dialog/dialog-confirm/dialog-confirm.component';
import { GlobalConstants } from '../../../common/global-constants';
import { TaskCompletionService } from '../../../services/task/task-completion.service';
import { BehaviorSubject } from 'rxjs';
import { Workspace } from '../../../models/workspace';
import { UiHelperService } from '../../../services/utils/ui-helper.service';
import {
  SnackbarService,
  SnackbarType
} from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnInit, OnDestroy {
  @Input() taskModel!: TaskModel;
  @Input() workspace!: Workspace | undefined;
  @Input() displayStatus = false;
  @Output() loadTasksEvent = new EventEmitter<void>();
  @Output() taskChanged = new EventEmitter<TaskModel>();

  taskDetailsRoute = '/' + GlobalConstants.routes.taskDetails;
  isCheckmarkChecked = new BehaviorSubject(false);

  constructor(
    private readonly dialog: MatDialog,
    private readonly taskService: TaskService,
    private readonly taskCompletionService: TaskCompletionService,
    private snackBar: SnackbarService,
    private reportingService: UserReportingService,
    private uiHelperService: UiHelperService
  ) {}

  ngOnInit(): void {
    this.subscribeToCheckmarkChanges();
    if (this.workspace) {
      this.taskDetailsRoute =
        '/tasks/' +
        this.workspace?.workspaceId +
        '/' +
        GlobalConstants.routes.details;
    }
  }

  ngOnDestroy(): void {
    this.isCheckmarkChecked.complete();
  }

  private subscribeToCheckmarkChanges(): void {
    const defaultValue = this.taskCompletionService.isCheckmarkChecked(
      this.taskModel
    );
    this.isCheckmarkChecked = new BehaviorSubject(defaultValue);
    this.isCheckmarkChecked
      .pipe(debounceTime(500), distinctUntilChanged(), skip(1))
      .subscribe((value) => {
        this.changeTaskStatus();
      });
  }

  async onEditTask(): Promise<void> {
    const { TaskCreateModule } = await import(
      '../task-create/task-create-module'
    );

    const dialogRef = this.dialog.open(TaskCreateModule.getComponent(), {
      data: this.taskModel,
      panelClass: 'task-dialog'
    });

    dialogRef.afterClosed().subscribe((shouldReload) => {
      if (shouldReload) {
        this.loadTasksEvent.emit();
      }
    });
  }

  async onDeleteTask(): Promise<void> {
    const { DialogConfirmModule } = await import(
      '../../../component/dialog/dialog-confirm/dialog-confirm.module'
    );

    const message = 'Are you sure you want to delete this task ?';
    const dialogData = new ConfirmDialogModel(message, 'Delete', 'Cancel', '');
    const dialogRef = this.dialog.open(DialogConfirmModule.getComponent(), {
      panelClass: 'confirm-dialog-container',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.taskService
          .deleteTask(this.taskModel)
          .pipe(first())
          .subscribe(() => this.loadTasksEvent.emit());
      }
    });
  }

  onReportTask(): void {
    this.uiHelperService
      .showFreeFormDialog('Report task', 'Please specify report reason')
      .then((result) => {
        if (result) {
          this.reportingService
            .reportTask(this.taskModel.id!, undefined, result.description)
            .subscribe({
              next: () => {
                this.snackBar.open('Task has been reported.');
              },
              error: () => {
                this.snackBar.open(
                  'Failed to send complaint! Please try again.',
                  SnackbarType.Error
                );
              }
            });
        }
      });
  }

  public get priorityClass(): string {
    if (!this.taskModel || !this.taskModel.priority) {
      return '';
    }

    return Priority[this.taskModel.priority].toLocaleLowerCase();
  }

  // If user is not a manager of the task and task is closed - user don't need to see the checkmark
  get shouldHideCheckmark(): boolean {
    return !this.taskModel.isOpen && !this.taskModel.canManage;
  }

  onCheckmarkClick(event: MouseEvent): void {
    event.stopPropagation();
    this.isCheckmarkChecked.next(!this.isCheckmarkChecked.value);
  }

  changeTaskStatus(): void {
    this.taskCompletionService
      .handleTaskCheckmarkClick(this.taskModel, this.isCheckmarkChecked.value)
      .subscribe({
        next: (updatedTask) => {
          this.resetCheckmarkState();
          this.taskChanged.emit(updatedTask);
          this.taskModel = updatedTask;
        },
        error: (err: string) => {
          this.resetCheckmarkState();
          this.taskCompletionService.showErrorSnackbar(err);
        }
      });
  }

  private resetCheckmarkState(): void {
    this.isCheckmarkChecked.complete();
    this.subscribeToCheckmarkChanges();
  }
}
