<div fxLayout fxFlexFill *ngIf="authService.checkAuth()">
  <ng-container
    infinite-scroll
    [infiniteScrollDistance]="scrollDistance"
    [infiniteScrollThrottle]="throttle"
    [infiniteScrollContainer]="elem"
    [fromRoot]="true"
    (scrolled)="onScrollDown(currentTabStatus)"
  >
    <div class="all-workspaces-parent">
      <ng-container>
        <mat-tab-group
          [animationDuration]="0"
          class="profile-tabs"
          selectedIndex="0"
          (selectedTabChange)="onTabChange($event)"
        >
          <mat-tab label="Active">
            <ng-container
              *ngIf="this.activeWorkspaces.workspaces.length > 0; else empty"
              [ngTemplateOutlet]="workspacesList"
              [ngTemplateOutletContext]="{
                workspaces: this.activeWorkspaces.workspaces
              }"
            ></ng-container>
          </mat-tab>
          <mat-tab label="Archived">
            <ng-container
              *ngIf="this.archivedWorkspaces.workspaces.length > 0; else empty"
              [ngTemplateOutlet]="workspacesList"
              [ngTemplateOutletContext]="{
                workspaces: this.archivedWorkspaces.workspaces
              }"
            ></ng-container>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #workspacesList let-workspaces="workspaces">
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    style="margin-top: 30px; margin-inline: 8px"
  >
    <div
      class="workspace-wrap"
      fxFlex="0 1 855px"
      fxFlex.lt-sm="0 1 100%"
      fxLayout="row wrap"
      fxLayout.lt-sm="column"
    >
      <app-new-workspace-card
        fxFlex="0 1 50%"
        fxFlex.lt-sm="100%"
        (newWorkspaceCreated)="onCreatedWorkspace($event)"
        *ngIf="currentTabStatus === 'active'"
      ></app-new-workspace-card>

      <ng-container *ngFor="let workspace of workspaces">
        <app-one-workspace
          fxFlex="0 1 50%"
          fxFlex.lt-sm="100%"
          (deletedWorkspace)="onDeletedWorkspace($event)"
          (updatedWorkspace)="onUpdatedWorkspace($event)"
          [workspace]="workspace"
        ></app-one-workspace>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #empty>
  <ng-container *ngIf="!loadInProgress; else loading">
    <app-empty-content
      *ngIf="
        !workspaceService.canCreateWorkspace() ||
          currentTabStatus === 'archived';
        else elseAddWorkspace
      "
      fxFlexFill
      fxLayoutAlign="center center"
    >
    </app-empty-content>

    <ng-template #elseAddWorkspace>
      <div fxFlexFill fxLayoutAlign="center center">
        <app-new-workspace-card
          fxFlex="0 1 420px"
          fxFlex.lt-sm="100%"
          *ngIf="currentTabStatus === 'active'"
          (newWorkspaceCreated)="onCreatedWorkspace($event)"
        ></app-new-workspace-card>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #loading>
  <app-loading></app-loading>
</ng-template>
