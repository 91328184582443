import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-without-auth-errors',
  templateUrl: './without-auth-errors.component.html',
  styleUrls: ['./without-auth-errors.component.scss']
})
export class WithoutAuthErrorsComponent {
  public static route = 'errors-page';

  public message = 'Something went wrong...';

  constructor(
    public authService: AuthService,
    private aRoute: ActivatedRoute
  ) {
    this.aRoute.queryParams.subscribe((queryParam: any) => {
      this.message = queryParam.message ? queryParam.message : '';
    });
  }
}
