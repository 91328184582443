import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { LibraryService } from '../../../services/archive/library.service';
import { ArchiveFile } from '../../../models/archiveFile';
import { Workspace } from '../../../models/workspace';
import { LibraryManager } from '../../../services/utils/library-manager/library-manager';
import { MediaLibraryManager } from '../../../services/utils/library-manager/media-library-manager';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-fullscreen-image',
  templateUrl: './fullscreen-image.component.html',
  styleUrls: ['./fullscreen-image.component.scss'],
  providers: [
    {
      provide: LibraryManager,
      useClass: MediaLibraryManager
    }
  ]
})
export class FullscreenImageComponent implements OnInit, OnDestroy {
  private keyboardCodeArrowLeft = 'ArrowLeft';
  private keyboardCodeArrowRight = 'ArrowRight';

  private defaultTouch = { x: 0, y: 0, time: 0 };

  private timeControlVisibility = 5000;
  public isVisibleControls = false;

  public imageLoaded = false;
  @ViewChild('fullscreenElement') fullscreenElement: ElementRef | undefined;
  @ViewChild('mainImage') mainImage: ElementRef | undefined;
  @ViewChild('matTrigger') matMenuTrigger?: MatMenuTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataFullscreenImage,
    private spinner: NgxSpinnerService,
    public libraryManager: LibraryManager,
    public archiveService: LibraryService,
    private dialogRef: MatDialogRef<FullscreenImageComponent>,
    private renderer: Renderer2
  ) {
    this.libraryManager.workspace = data.workspace;

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.imageLoaded &&
        !this.fullscreenElement?.nativeElement.contains(e.target)
      ) {
        this.dialogRef.close();
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === this.keyboardCodeArrowLeft) {
      this.previous();
    } else if (event.key === this.keyboardCodeArrowRight) {
      this.next();
    }
  }

  @HostListener('touchstart', ['$event'])
  // @HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event: any): void {
    const touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      const deltaX = touch.pageX - this.defaultTouch.x;
      // const deltaY = touch.pageY - this.defaultTouch.y;
      const deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulate a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 500) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > 60) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.doSwipeRight();
          } else {
            this.doSwipeLeft();
          }
        }

        // if (Math.abs(deltaY) > 60) {
        //     // delta y is at least 60 pixels
        //     if (deltaY > 0) {
        //         this.doSwipeDown(event);
        //     } else {
        //         this.doSwipeUp(event);
        //     }
        // }
      }
    }
  }

  doSwipeLeft(): void {
    this.previous();
  }

  doSwipeRight(): void {
    this.next();
  }

  ngOnDestroy(): void {
    this.imageLoaded = false;
  }

  ngOnInit(): void {
    this.spinner.show().then();
  }

  imageReady(): void {
    this.imageLoaded = true;
    this.spinner.hide().then();
  }

  download(event: Event): void {
    event.stopPropagation();

    this.archiveService.downloadAttachment(this.file);
  }

  report(event: Event): void {
    event.stopPropagation();

    this.dialogRef.close({ postAction: 'report', file: this.file });
  }

  deleteFile(event: Event): void {
    event.stopPropagation();

    this.dialogRef.close({ postAction: 'delete', file: this.file });
  }

  publicPageVisibilityChanged(event: MatSlideToggleChange): void {
    this.archiveService
      .makePublic(this.file, event.checked)
      .subscribe((file) => {
        this.file.isPublic = file.isPublic;
      });
  }

  get file(): ArchiveFile {
    return this.data.file;
  }

  currentFileIndex(): number {
    return this.allFiles.findIndex((file: ArchiveFile) => {
      return file.attachmentId === this.file.attachmentId;
    });
  }

  get lastFileIndex(): number {
    return this.allFiles.length - 1;
  }

  showOtherFile(index: number): void {
    this.dialogRef.close({
      postAction: 'otherImage',
      file: this.allFiles[index]
    });
  }

  next(): void {
    let index = this.currentFileIndex();
    if (index === this.lastFileIndex) {
      index = 0;
    } else {
      index += 1;
    }

    this.showOtherFile(index);
  }

  previous(): void {
    let index = this.currentFileIndex();
    if (index > 0) {
      index -= 1;
    } else {
      index = this.lastFileIndex;
    }

    this.showOtherFile(index);
  }

  keyboardEvent(event: any): void {
    console.log(event);
  }

  controlsVisibility(): void {
    this.isVisibleControls = true;
    setTimeout(() => {
      this.isVisibleControls = false;
    }, this.timeControlVisibility);
  }

  get allFiles(): ArchiveFile[] {
    return this.data.allFiles;
  }
}

interface DataFullscreenImage {
  file: ArchiveFile;
  workspace: Workspace;
  allFiles: ArchiveFile[];
}
