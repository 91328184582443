import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Participant } from 'src/app/models/participant';

@Component({
  selector: 'app-assignee-search',
  templateUrl: './assignee-search.component.html',
  styleUrls: ['./assignee-search.component.scss']
})
export class AssigneeSearchComponent {
  @Input()
  workspaceId!: number;

  @Output()
  selectedAssignees = new EventEmitter<Partial<Participant>[]>();

  @Input()
  @Optional()
  assigneeList: Partial<Participant>[] = [];

  @Input()
  @Optional()
  showBackButton = true;

  @Input()
  @Optional()
  showSaveButton = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: any) {
    if (this.data.workspaceId && data.assigneesList) {
      this.workspaceId = data.workspaceId;
      this.assigneeList = data.assigneesList;
      this.showBackButton = data.showBackButton;
      this.showSaveButton = data.showSaveButton;
    }
  }

  onSelectAssignees(participants: Participant[]): void {
    this.selectedAssignees.emit(participants);
  }

  get selectedParticipants(): Participant[] {
    return this.assigneeList as Participant[];
  }
}
