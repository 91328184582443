import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ShareModule } from './../../../component/share/share.module';
import { AvatarModule } from './../../../component/avatar/avatar.module';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NgModule } from '@angular/core';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NotificationIconComponent } from './notification/notification-icon/notification-icon.component';
import { NotificationDialogComponent } from './notification/notification-dialog/notification-dialog.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    FlexLayoutModule,
    AvatarModule,
    MatButtonModule,
    NotificationIconComponent,
    NotificationDialogComponent,
    ShareModule,
    MatMenuModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}
