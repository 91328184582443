<form class="add-update-form task">
  <div *ngIf="showBackButton" class="closeElement">
    <mat-icon svgIcon="back-button" (click)="onSelectParticipants()"></mat-icon>
  </div>

  <div *ngIf="!showBackButton" class="closeElement" mat-dialog-close>
    <span class="material-icons">close</span>
  </div>

  <div class="titleBlock content-inputs">
    <h1 class="apg-title">
      {{ title }}

      <button
        class="mat-button btn-add-all-participants"
        *ngIf="!areAllParticipantsSelected"
        (click)="selectAllParticipants()"
      >
        Add All
      </button>

      <button
        class="mat-button btn-add-all-participants"
        *ngIf="areAllParticipantsSelected"
        (click)="clearSelectedParticipants()"
      >
        Remove All
      </button>
    </h1>
  </div>

  <mat-dialog-content class="participants-search-block">
    <div class="content-inputs">
      <div class="input-with-chip">
        <label class="color-text">To: </label>
        <mat-chip-list #toListElement>
          <mat-chip
            *ngFor="
              let participant of selectedParticipants;
              trackBy: participantsTrackByFn
            "
            (removed)="remove(participant)"
          >
            {{ participant.name + ' ' + participant.surname }}
            <mat-icon matChipRemove>close</mat-icon>
          </mat-chip>

          <input
            #searchInput
            class="input-for-chip emails"
            [matChipInputFor]="toListElement"
            (input)="onSearch($event)"
            #inputNewEmail
            placeholder="Search ..."
          />
        </mat-chip-list>
      </div>
    </div>

    <ng-container *ngIf="loadInProgress && !foundParticipants?.length">
      <app-loading></app-loading>
    </ng-container>

    <ng-container
      *ngIf="foundParticipants?.length"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      [infiniteScrollContainer]="'mat-dialog-container'"
      (scrolled)="onScrollDown()"
      [fromRoot]="true"
    >
      <!-- a must to have 2, first is for mobile, second for desktop -->
      <div
        class="participants-container"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [infiniteScrollContainer]="'.task-dialog'"
        (scrolled)="onScrollDown()"
        [fromRoot]="true"
      >
        <ng-container
          *ngFor="let participant of foundParticipants | sortBy: 'fullName'"
        >
          <div class="oneParticipant">
            <div class="img">
              <app-avatar
                [src]="participant.photoThumbnailUrl || participant.photoUrl"
                [userIdentity]="participant.userId"
                [isClickable]="false"
              ></app-avatar>
            </div>
            <div class="info">
              <div
                class="name"
                *ngIf="
                  participant.name?.trim() || participant.surname?.trim();
                  else showEmail
                "
              >
                {{ (participant.name + ' ' + participant.surname).trim() }}
              </div>

              <ng-template #showEmail>
                <span>{{ participant.email }}</span>
              </ng-template>

              <div class="second-row">
                {{
                  participant.workspaceRole
                    ? participant.workspaceRole
                    : 'No Role'
                }}
              </div>
            </div>

            <div
              class="participant-button"
              (click)="onSelectParticipant(participant)"
            >
              <span class="material-icons">add_circle</span>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="apg-form-group" *ngIf="showSaveButton">
      <button
        class="apg-button red-button apg-button-margin-top"
        (click)="selectParticipants.emit(this.selectedParticipants)"
      >
        Save
      </button>
    </div>
  </mat-dialog-content>
</form>
