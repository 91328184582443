import { ArchiveFile, ArchiveType } from './archiveFile';

export interface LinkMetadata {
  description?: string;
  image?: string;
  siteName?: string;
  title?: string;
  type?: string;
  url?: string;
  baseUrl?: string;
  videoUrl?: string;
}

export function toArchiveFile(
  link: LinkMetadata,
  workspaceId: number
): ArchiveFile {
  return {
    title: link.title,
    previewImageUrl: link.image,
    url: link.url,
    type: ArchiveType.Link,
    workspaceId,
    createTime: '',
    fileSize: 0,
    isPublic: false
  };
}
