import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogAddUpdateWorkspaceComponent } from './dialog-add-update-workspace.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [DialogAddUpdateWorkspaceComponent],
  exports: [DialogAddUpdateWorkspaceComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FlexModule,
    MatSlideToggleModule
  ]
})
export class DialogAddUpdateWorkspaceModule {}
