import { CalendarEventType } from './calendar.event.type';
import { CalendarEvent } from './calendar.event';

export class CalendarEventsList {
  type: CalendarEventType;
  data: Array<CalendarEvent>;

  constructor(type: CalendarEventType, data: Array<CalendarEvent>) {
    this.type = type;
    this.data = data;
  }

  static fromJson(json: any): CalendarEventsList {
    const type: CalendarEventType =
      CalendarEventType[json.type as keyof typeof CalendarEventType];
    const data: Array<CalendarEvent> = CalendarEvent.fromJsonArray(json.data);
    return new CalendarEventsList(type, data);
  }
}
