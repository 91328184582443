import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Attendance, EventParticipant } from '../../../models/participant';
import { WorkspaceEvent } from '../../../models/workspaceEvent';
import { MatDialog } from '@angular/material/dialog';
import { DialogAddEventParticipantsComponent } from '../../../component/dialog/add-update/participants/event/dialog-add-event-participants.component';
import { WorkspaceEventsService } from '../../../services/events/workspace-events.service';
import {
  EventBusService,
  EventBusNames
} from '../../../services/event-bus/event-bus.service';
import { ProfileService } from '../../../services/user/profile/profile.service';
import { AttendanceObject } from '../../../models/AttendanceObject';
import { Workspace } from '../../../models/workspace';
import { UtilsService } from '../../../services/utils/utils.service';
import { ParticipantItem } from '../../tasks/task-details/one-participant/participant.model';

@Component({
  selector: 'app-participants-block',
  templateUrl: './participants-block.component.html',
  styleUrls: [
    './participants-block.component.scss',
    '../../workspace/one-workspace-view/participants/participants.component.scss'
  ]
})
export class ParticipantsBlockComponent implements OnInit, OnChanges {
  @Input() attendance: Attendance = Attendance.Unmarked;
  @Input() workspaceEvent: WorkspaceEvent | undefined;
  @Input() workspace: Workspace | undefined;
  @Input() allowDeleteMenu = true;

  public participants: EventParticipant[] = [];
  public allAttendanceTypes = Attendance;

  selectedParticipants: Set<EventParticipant> = new Set();

  page = 1;
  throttle = 50;
  scrollDistance = 4;
  limit = 10;
  elem = 'mat-drawer-content';
  canEditParticipants = false;
  isTabletOrMobile = false;

  constructor(
    private dialog: MatDialog,
    public workspaceEventService: WorkspaceEventsService,
    private eventBusService: EventBusService,
    public profileUser: ProfileService,
    private utils: UtilsService
  ) {}

  ngOnInit(): void {
    this.getParticipants();
    if (this.workspace?.participants) {
      this.canEditParticipants = this.profileUser.isAdminOrManager(
        this.workspace.participants
      );
    }
    this.isTabletOrMobile = this.utils.isTabletOrMobile();
  }

  ngOnChanges(): void {
    this.getParticipants();
    this.undoSelection();
  }

  getParticipants(): void {
    this.participants =
      this.workspaceEvent?.participants
        .filter((p: EventParticipant) => {
          return p.attendance === this.attendance;
        })
        .splice(0, this.page * this.limit) || [];
  }

  private undoSelection(): void {
    this.selectedParticipants.clear();
  }

  clickRow(participant: EventParticipant): void {
    if (this.selectedParticipants.has(participant)) {
      this.selectedParticipants.delete(participant);
    } else {
      this.selectedParticipants.add(participant);
    }
  }

  openDialog(): void {
    const workspaceId = this.workspaceEvent?.workspaceId;
    if (this.workspaceEvent && workspaceId) {
      const dialogRef = this.dialog.open(DialogAddEventParticipantsComponent, {
        panelClass: ['popup-dialog', 'popup-dialog-participants'],
        data: {
          event: this.workspaceEvent,
          participants: this.workspaceEvent.participants,
          embedded: false
        }
      });

      dialogRef
        .afterClosed()
        .subscribe((participants: Array<ParticipantItem>) => {
          if (participants) {
            this.eventBusService.emit({ name: EventBusNames.workspaceEvent });
          }
        });
    }
  }

  delete(): void {
    if (this.workspaceEvent) {
      this.workspaceEventService
        .deleteUserFromEvent(
          this.workspaceEvent.workspaceId,
          this.workspaceEvent.eventId,
          [...this.selectedParticipants]
        )
        .then(() => {
          this.eventBusService.emit({ name: EventBusNames.workspaceEvent });
          this.undoSelection();
        });
    }
  }

  updateAttendances(attendance: Attendance, reason: string): void {
    if (this.workspaceEvent) {
      const attendances = [];

      for (const participant of [...this.selectedParticipants]) {
        attendances.push(
          new AttendanceObject(
            attendance,
            participant.userId,
            this.workspaceEvent.eventId,
            this.workspaceEventService.getActualFromEvent(this.workspaceEvent),
            reason
          )
        );
      }

      this.workspaceEventService
        .updateAttendances(this.workspaceEvent?.workspaceId, attendances)
        .then(() => {
          this.eventBusService.emit({ name: EventBusNames.workspaceEvent });
          this.undoSelection();
        });
    }
  }

  toAttending(): void {
    this.updateAttendances(Attendance.Attending, '');
  }

  toUndecided(): void {
    this.updateAttendances(Attendance.Unmarked, '');
  }

  toRejected(): void {
    this.updateAttendances(Attendance.Rejected, '');
  }

  toReason(): void {
    if (this.workspaceEvent) {
      const p = [...this.selectedParticipants][0];

      this.rejectAction(p.reason || '');
    }
  }

  rejectAction(reason: string): void {
    const dialogRef = this.workspaceEventService.showRejectReasonDialog(reason);

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult?.description || dialogResult.required === false) {
        this.updateAttendances(Attendance.Rejected, dialogResult.description);
      }
    });
  }

  onScrollDown(): void {
    this.page++;
    this.getParticipants();
  }
}
