<ng-container *ngIf="eventForm">
  <form [formGroup]="eventForm" class="add-update-form">
    <div class="closeElement" mat-dialog-close>
      <span class="material-icons">close</span>
    </div>

    <div class="titleBlock">
      <h1 class="apg-title">{{isInEditMode ? 'Edit Event' : 'Add Event'}}</h1>
    </div>
    <mat-dialog-content>
      <!--    1-->

      <div class="apg-form-group">
        <label>Event type</label>

        <mat-select
          [panelClass]="'apg-mat-select'"
          formControlName="type"
          [class.errorInput]="eventForm.controls['type'].invalid && (eventForm.controls['type'].dirty || eventForm.controls['type'].touched)"
        >
          <mat-option
            *ngFor="let eventTypeOption of allEventTypes"
            [value]="eventTypeOption.option"
          >
            {{ eventTypeOption.option | titlecase}}
          </mat-option>
        </mat-select>

        <ng-container
          *ngIf="eventForm.controls['type'].invalid && eventForm.controls['type'].touched"
        >
          <div
            class="error"
            *ngIf="eventForm.get('type')?.hasError('required')"
          >
            Event type is required
          </div>
          <div
            class="error"
            *ngIf="eventForm.get('type')?.hasError('maxlength')"
          >
            Max length
            {{eventForm.get('type')?.errors?.maxlength?.requiredLength}} symbols
          </div>
        </ng-container>
      </div>

      <div class="apg-form-group" *ngIf="!data.workspace && !editMode">
        <label
          >Workspace
          <app-search-workspace-field
            #searchWorkspaceField
          ></app-search-workspace-field>
        </label>
      </div>

      <div class="apg-form-group">
        <label
          >Event title
          <input
            type="text"
            formControlName="name"
            maxlength="255"
            [class]="eventForm.controls['name'].invalid &&
                   (eventForm.controls['name'].dirty ||  eventForm.controls['name'].touched)
                   ? 'errorInput' : ''"
          />
        </label>

        <ng-container
          *ngIf="eventForm.controls['name'].invalid && eventForm.controls['name'].touched"
        >
          <div
            class="error"
            *ngIf="eventForm.get('name')?.hasError('required')"
          >
            Event title is required
          </div>
          <div
            class="error"
            *ngIf="eventForm.get('name')?.hasError('maxlength')"
          >
            Max length
            {{eventForm.get('name')?.errors?.maxlength?.requiredLength}} symbols
          </div>
        </ng-container>
      </div>

      <!--    2-->

      <div class="apg-row">
        <div class="apg-form-group apg-col-6">
          <label
            >Starts
            <input
              type="text"
              [ngxMatDatetimePicker]="picker1"
              placeholder="Choose a date"
              formControlName="dateStart"
              (dateInput)="picker1.close()"
              (keydown)="$event.preventDefault()"
              readonly="readonly"
              [class]="eventForm.controls['dateStart'].invalid &&
                       (eventForm.controls['dateStart'].dirty || eventForm.controls['dateStart'].touched)
                            ? 'errorInput' : ''"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(picker1)"
              [disableRipple]="true"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              #picker1
              [enableMeridian]="true"
            ></ngx-mat-datetime-picker>
          </label>

          <ng-container
            *ngIf="eventForm.controls['dateStart'].invalid &&
            (eventForm.controls['dateStart'].dirty || eventForm.controls['dateStart']?.touched)"
          >
            <div
              class="error"
              *ngIf="eventForm.controls['dateStart']?.errors?.required"
            >
              Should not be empty
            </div>
          </ng-container>
        </div>

        <div class="apg-form-group apg-col-6">
          <label
            >Ends
            <input
              type="text"
              [ngxMatDatetimePicker]="picker2"
              placeholder="Date Time"
              formControlName="dateEnd"
              (dateInput)="picker2.close()"
              readonly="readonly"
              (keydown)="$event.preventDefault()"
              [class]="eventForm.controls['dateEnd'].invalid &&
                       (eventForm.controls['dateEnd'].dirty ||  (eventForm.controls['dateEnd'].touched || eventForm.controls['dateStart'].touched ))
                            ? 'errorInput' : ''"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(picker2)"
              [disableRipple]="true"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              #picker2
              [enableMeridian]="true"
            ></ngx-mat-datetime-picker>
          </label>

          <ng-container
            *ngIf="eventForm.controls['dateEnd'].invalid &&
            (eventForm.controls['dateEnd'].dirty || (eventForm.controls['dateEnd'].touched || eventForm.controls['dateStart'].touched ))"
          >
            <div
              class="error"
              *ngIf="eventForm.controls['dateEnd']?.errors?.required"
            >
              Should not be empty
            </div>
            <div
              class="error"
              *ngIf="eventForm.controls['dateEnd']?.errors?.lessThenStart"
            >
              Cannot be less than start date
            </div>
          </ng-container>
        </div>
      </div>

      <!--    3-->
      <div class="apg-form-group">
        <label
          >Location
          <input
            type="text"
            formControlName="location"
            [class]="eventForm.controls['location'].invalid &&
                   (eventForm.controls['location'].dirty ||  eventForm.controls['location'].touched)
                            ? 'errorInput' : ''"
          />
        </label>

        <ng-container
          *ngIf="eventForm.controls['location'].invalid &&
        (eventForm.controls['location'].dirty ||  eventForm.controls['location']?.touched)"
        >
          <div
            class="error"
            *ngIf="eventForm.controls['location']?.errors?.maxlength"
          >
            Max length 255 symbols
          </div>
        </ng-container>
      </div>

      <!--    4-->
      <div class="apg-form-group">
        <label
          >Link
          <input
            type="text"
            formControlName="link"
            [class]="eventForm.controls['link'].invalid &&
                   (eventForm.controls['link'].dirty ||  eventForm.controls['link'].touched)
                        ? 'errorInput' : ''"
          />
        </label>

        <ng-container
          *ngIf="eventForm.controls['link'].invalid &&
        (eventForm.controls['link'].dirty ||  eventForm.controls['link'].touched)"
        >
          <div
            class="error"
            *ngIf="eventForm.controls['link'].errors?.maxlength"
          >
            Max length 255 symbols
          </div>
        </ng-container>
      </div>

      <!--    5-->
      <div class="apg-form-group">
        <label for="add-update-form-control-description">Description</label>
        <div
          appClearFormatOnPaste
          contenteditable="true"
          formControlName="description"
          id="add-update-form-control-description"
          [class.errorInput]="eventForm.controls['description'].invalid &&
                            (eventForm.controls['description'].dirty || eventForm.controls['description'].touched)"
          appMentionable
          [fnSearchParticipant]="searchWorkspaceParticipants"
          [drawPosition]="isClientMobileSmallScreen ? MentionablePosition.BOTTOM : MentionablePosition.ABOVE"
        ></div>

        <ng-container
          *ngIf="eventForm.controls['description'].invalid &&
        (eventForm.controls['description'].dirty ||  eventForm.controls['description'].touched)"
        >
          <div
            class="error"
            *ngIf="eventForm.controls['description'].errors?.maxlength"
          >
            Max length 1500 symbols
          </div>
        </ng-container>
      </div>

      <div class="apg-form-group">
        <button
          mat-flat-button
          color="accent"
          class="btn upper"
          [class.spinner]="loadInProgress"
          [disabled]="(formSubmitAttempt && eventForm.invalid) || loadInProgress"
          (click)="save()"
        >
          <span *ngIf="!isInEditMode">Next</span>
          <span *ngIf="isInEditMode">Save</span>
        </button>
      </div>
    </mat-dialog-content>
  </form>
</ng-container>
