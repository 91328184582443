import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogInfoComponent {
  constructor() {}
}
