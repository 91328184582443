import { ArchiveFile, ArchiveType } from '../../../models/archiveFile';
import { Workspace } from '../../../models/workspace';
import {
  ConfirmDialogModel,
  DialogConfirmComponent
} from '../../../component/dialog/dialog-confirm/dialog-confirm.component';
import { BreadcrumbService } from '../../breadcrumb/breadcrumb.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProfileService } from '../../user/profile/profile.service';
import { OneWorkspaceService } from '../../workspace/one-workspace.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LibraryService } from '../../archive/library.service';
import { FileUploadComponent } from '../../../component/dialog/file-upload/file-upload.component';
import { UtilsService } from '../utils.service';
import { Injectable } from '@angular/core';
import { UserReportingService } from 'src/app/services/user/user-reporting/user-reporting.service';
import { UiHelperService } from '../../../services/utils/ui-helper.service';
import { SnackbarService, SnackbarType } from '../../snackbar/snackbar.service';
import { finalize } from 'rxjs';

/**
 * Manager scope - prototype, not singleton
 * Recreated for each library component
 */

@Injectable()
export class LibraryManager {
  workspace: Workspace | undefined;
  workspaceId = 0;
  files: ArchiveFile[] = [];
  page = 0;
  limit = 20;
  loadInProgress = false;

  get type(): ArchiveType {
    return ArchiveType.Document;
  }

  private uploader: MatDialogRef<FileUploadComponent> | undefined;

  constructor(
    private profile: ProfileService,
    private oneWorkspaceService: OneWorkspaceService,
    public dialog: MatDialog,
    private archiveService: LibraryService,
    private snackbar: SnackbarService,
    private utils: UtilsService,
    private breadcrumbService: BreadcrumbService,
    private reportingService: UserReportingService,
    private uiHelperService: UiHelperService
  ) {}

  loadFiles(cleanUp: boolean = true): void {
    this.loadInProgress = true;
    this.archiveService
      .getFiles(this.type, this.workspaceId, this.page, this.limit)
      .pipe(finalize(() => (this.loadInProgress = false)))
      .subscribe((files) => {
        if (cleanUp) {
          this.files = [];
        }
        if (files) {
          this.files.push(...files);
        }
      });
  }

  nextPage(): void {
    this.page++;
    this.loadFiles(false);
  }

  reloadFiles(): void {
    this.page = 0;
    this.loadFiles();
  }

  refreshData(route: ActivatedRoute): void {
    route.params.subscribe((params) => {
      if (params.id) {
        this.workspaceId = params.id;
        this.reloadFiles();
        this.updateBreadcrumbsAlias(
          this.breadcrumbService,
          this.workspaceId
        ).then((workspace: Workspace) => {
          this.workspace = workspace;
        });
      }
    });
  }

  uploadFileDialog(): void {
    if (!this.workspaceId || this.uploader) {
      return;
    }

    this.uploader = this.dialog.open(FileUploadComponent, {
      panelClass: 'file-upload-dialog',
      data: {
        workspaceId: this.workspaceId
      }
    });

    this.uploader.afterClosed().subscribe(() => {
      this.uploader = undefined;
      this.reloadFiles();
    });
  }

  documentIsLastInSection(index: number): boolean {
    if (this.files[index + 1]) {
      return this.shouldDisplayDate(index + 1);
    } else {
      return true;
    }
  }

  shouldDisplayDate(index: number): boolean {
    if (index > 0) {
      const previousFileMonth = new Date(
        this.files[index - 1].createTime
      ).getMonth();
      const currentFileMonth = new Date(
        this.files[index].createTime
      ).getMonth();
      return previousFileMonth !== currentFileMonth;
    }
    return false;
  }

  canDelete(file: ArchiveFile): boolean {
    if (
      this.workspace &&
      this.workspace.sample &&
      this.profile.isParticipant(this.workspace.participants)
    ) {
      return false;
    }
    if (this.profile.isAdminOrManager(this.workspace?.participants ?? [])) {
      return true;
    }
    return file.creatorId === this.profile.user?.userId;
  }

  canMakePublic(): boolean {
    return this.profile.isManager(this.workspace?.participants ?? []);
  }

  get canUpload(): boolean {
    return this.workspace !== undefined && !this.workspace.sample;
  }

  confirmDelete(file: ArchiveFile): void {
    const message = `Are you sure you want to delete this file?`;
    const dialogData = new ConfirmDialogModel(message, 'Delete', 'Discard', '');
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      panelClass: 'confirm-dialog-container',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult && file.attachmentId) {
        this.archiveService.deleteFiles(file.attachmentId).then(() => {
          this.reloadFiles();
        });
      }
    });
  }

  async updateBreadcrumbsAlias(
    breadcrumbsService: BreadcrumbService,
    workspaceId: number | undefined
  ): Promise<any> {
    breadcrumbsService.setAlias('', 1);
    if (workspaceId) {
      const workspace =
        await this.oneWorkspaceService.getWorkspaceById(workspaceId);
      breadcrumbsService.setAlias(workspace.title || '', 1);
      return new Promise((resolve) =>
        resolve(this.oneWorkspaceService.workspace)
      );
    } else {
      return new Promise((resolve) => resolve('Workspace id is undefined'));
    }
  }

  openUrl(file: ArchiveFile): void {
    if (file.url && this.utils.isValidUrl(file.url)) {
      this.utils.openLink(file.url);
    } else {
      this.snackbar.open(
        'Oops! We could not open this link, probably it is not valid',
        SnackbarType.Error
      );
    }
  }

  showContextMenu(
    $event: MouseEvent | any,
    file: ArchiveFile,
    spanTrigger: HTMLSpanElement,
    matMenuTrigger: MatMenuTrigger
  ): void {
    spanTrigger.style.left = $event.pageX + 5 + 'px';
    spanTrigger.style.top = $event.pageY + 5 + 'px';
    matMenuTrigger.openMenu();
    $event.preventDefault();
  }

  reportFile(file: ArchiveFile, title: string = 'Report file'): void {
    this.uiHelperService
      .showFreeFormDialog(title, 'Please specify report reason')
      .then((result) => {
        if (result) {
          this.reportingService
            .reportAttachment(
              file.attachmentId!,
              file.creatorId,
              result.description
            )
            .subscribe({
              next: () => this.snackbar.open('Library item has been reported.'),
              error: () =>
                this.snackbar.open(
                  'Failed to send complaint! Please try again.',
                  SnackbarType.Error
                )
            });
        }
      });
  }

  reportLink(file: ArchiveFile): void {
    this.reportFile(file, 'Report link');
  }
}
