import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteWatcherService {
  private _previousRoute?: RouteDetails;
  private _currentRoute?: RouteDetails;

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        if (events && events.length > 1) {
          this._previousRoute = new RouteDetails(
            events[0].urlAfterRedirects,
            events[0].state?.root?.children[0]?.routeConfig?.path,
            events[0].state?.root?.children[0]?.params
          );

          this._currentRoute = new RouteDetails(
            events[1].urlAfterRedirects,
            events[1].state?.root?.children[0]?.routeConfig?.path,
            events[1].state?.root?.children[0]?.params
          );
        }
      });
  }

  get previousRoute(): RouteDetails | undefined {
    return this._previousRoute;
  }

  get currentRoute(): RouteDetails | undefined {
    return this._currentRoute;
  }
}

export class RouteDetails {
  url: string;
  routePath: string | undefined;
  routeParams: any;

  constructor(url: string, routePath: string | undefined, routeParams: any) {
    this.url = url;
    this.routePath = routePath;
    this.routeParams = routeParams;
  }
}
