import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkspaceService } from '../../../../services/workspace/workspace.service';
import { Workspace } from '../../../../models/workspace';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats
} from '@angular-material-components/datetime-picker';
import {
  FormValidatorService,
  greaterThan,
  noWhitespaceValidator
} from '../../../../services/form-validators/form-validator.service';
import * as moment from 'moment/moment';
import { GlobalConstants } from '../../../../common/global-constants';

/**
 * Documentation datetime picker https://www.npmjs.com/package/@angular-material-components/datetime-picker
 * Displaying formats: https://momentjs.com/docs/#/displaying/
 */
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'M/D h:mm a'
  },
  display: {
    dateInput: 'M/D h:mm a',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-dialog-add-update-workspace',
  templateUrl: './dialog-add-update-workspace.html',
  styleUrls: [
    '../../../../pages/workspace/workspace.component.scss',
    './dialog-add-update-workspace.component.scss',
    '../add-update..scss'
  ],
  providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }]
})
export class DialogAddUpdateWorkspaceComponent implements OnInit {
  loadInProgress = false;
  @Input() type: 'add' | 'update' = 'add';
  @Input() workspace!: Workspace;
  @Input() needsCloseButton = true;

  @Input() showTitleOnSmallDeviceOnly = false;

  @Input()
  @Optional()
  showSaveButton = true;
  workspaceForm: FormGroup = new FormGroup({});

  clicked = true;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: WorkspaceData,
    @Optional()
    public dialogRef: MatDialogRef<DialogAddUpdateWorkspaceComponent>,
    private formValidator: FormValidatorService,
    public workspaceService: WorkspaceService
  ) {
    if (this.data) {
      this.workspace = data.workspace;
      this.type = data.type;
      this.showSaveButton = data.showSaveButton;
      this.needsCloseButton = true;
    }
  }

  public i18n = GlobalConstants.i18n;

  ngOnInit(): void {
    this.workspaceForm = new FormGroup({
      title: new FormControl(this.workspace.title, [
        noWhitespaceValidator(),
        Validators.required,
        Validators.maxLength(255)
      ]),
      dateStart: new FormControl(moment.utc(this.workspace.dateStart).local(), [
        Validators.required
      ]),
      dateEnd: new FormControl(moment.utc(this.workspace.dateEnd).local(), [
        Validators.required,
        greaterThan('dateStart')
      ]),
      location: new FormControl(this.workspace.location, [
        Validators.maxLength(255)
      ]),
      siteUrl: new FormControl(this.workspace.siteUrl, [
        Validators.maxLength(255)
      ]),
      description: new FormControl(this.workspace.description, [
        noWhitespaceValidator(),
        Validators.required,
        Validators.maxLength(1500)
      ]),
      defaultTasks: new FormControl(this.workspace.createDefaultTasks)
    });
    this.updateValidators();
  }

  updateValidators(): void {
    this.workspaceForm?.get('dateStart')?.valueChanges.subscribe(() => {
      this.workspaceForm?.get('dateEnd')?.updateValueAndValidity();
    });
  }

  error(data: any): void {
    this.loadInProgress = false;
    console.log(data);
  }

  success(data: Workspace): void {
    console.log(data);
    this.dialogRef.close(data);
  }

  saveOrUpdate(): void {
    this.setFormDirty();
    if (this.workspaceForm && this.workspaceForm.valid) {
      this.updateModel();
      this.loadInProgress = true;
      if (this.type === 'add') {
        this.workspaceService.saveWorkspaces(this.workspace).subscribe({
          next: this.success,
          error: this.error
        });
      } else if (this.type === 'update') {
        this.workspaceService
          .updateWorkspaces(this.workspace)
          .then(this.success.bind(this), this.error.bind(this));
      }
    } else {
      this.clicked = false;
    }
  }

  setFormDirty(): void {
    this.clearInputs();
    this.workspaceForm?.markAllAsTouched();
  }

  hasRequiredError(form: AbstractControl | null): boolean {
    if (form) {
      return form.hasError('required') || form.hasError('whitespace');
    }
    return false;
  }

  updateModel(): void {
    if (this.workspaceForm) {
      this.workspace.title = this.workspaceForm.value.title;
      this.workspace.dateStart = this.workspaceForm.value.dateStart
        .utc()
        .format();
      this.workspace.dateEnd = this.workspaceForm.value.dateEnd.utc().format();
      this.workspace.location = this.workspaceForm.value.location;
      this.workspace.siteUrl = this.workspaceForm.value.siteUrl;
      this.workspace.description = this.workspaceForm.value.description;
      this.workspace.createDefaultTasks = this.workspaceForm.value.defaultTasks;
    }
  }

  clearInputs(): void {
    if (this.workspaceForm) {
      this.workspaceForm.controls.title.setValue(
        this.formValidator.clearInput(this.workspaceForm.value.title)
      );
      this.workspaceForm.controls.location.setValue(
        this.formValidator.clearInput(this.workspaceForm.value.location)
      );
      this.workspaceForm.controls.siteUrl.setValue(
        this.formValidator.clearInput(this.workspaceForm.value.siteUrl)
      );
      this.workspaceForm.controls.description.setValue(
        this.formValidator.clearInput(this.workspaceForm.value.description)
      );
    }
  }
}

export interface WorkspaceData {
  type: 'add' | 'update';
  workspace: Workspace;
  showSaveButton: boolean;
}
