import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [NotificationComponent],
  imports: [CommonModule, MatIconModule, MatBadgeModule],
  exports: [NotificationComponent]
})
export class NotificationModule {}
