import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  @Input() filePath: string | undefined;
  text = '';

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    if (this.filePath) {
      this.http
        .get(this.filePath, { responseType: 'text' })
        .subscribe((text) => (this.text = text));
    }
  }
}
