import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowspaceTutorialComponent } from './showspace-tutorial.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { TutorialHintModule } from '../tutorial-hint/tutorial-hint.module';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [ShowspaceTutorialComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    TutorialHintModule,
    MatRippleModule
  ],
  exports: [ShowspaceTutorialComponent]
})
export class ShowspaceTutorialModule {}
