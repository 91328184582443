import { SharedModule } from 'src/app/modules/shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogAddLinkComponent } from './dialog-add-link.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [DialogAddLinkComponent],
  imports: [SharedModule, MatDialogModule],
  exports: [DialogAddLinkComponent]
})
export class DialogAddLinkModule {}
