<div class="new-calendar-event-dialog">
  <div class="titleBlock">
    <h1 class="dialog-message">What do you want to create?</h1>
  </div>
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
      <button
        mat-flat-button
        class="btn upper"
        color="primary"
        [ngClass.lt-md]="'md'"
        (click)="openCreateEventDialog()"
      >
        EVENT
      </button>
      <button
        mat-flat-button
        class="btn upper"
        color="accent"
        [ngClass.lt-md]="'md'"
        (click)="openCreateTaskDialog()"
      >
        TASK
      </button>
    </div>
  </mat-dialog-content>
</div>
