import { LoadingModule } from './../../component/loading/loading.module';
import { LogoHeaderModule } from 'src/app/component/logo-header/logo-header.module';
import { AvatarModule } from './../../component/avatar/avatar.module';
import { ShareModule } from './../../component/share/share.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ParticipantsListComponent } from '../../component/dialog/add-update/participants/participants-list/participants-list.component';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { FloatButtonModule } from 'src/app/component/float-button/float-button.module';
import { EmptyContentModule } from 'src/app/component/empty-content/empty-content.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogInfoComponent } from '../../dialog/dialog-info/dialog-info.component';
import { CustomSnackbarComponent } from '../../pages/custom-snackbar/custom-snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { RightColumn2Component } from '../landing-layout/pages/first-page-layout/right-column/right-column2.component';
import { SortByPipe } from '../../common/pipe/sort-by.pipe';
import { LoadingComponent } from '../../component/loading/loading.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SelectableButtonDirective } from '../../directive/selectable-button.directive';
import { MentionableDirectiveModule } from '../../directive/mentionable.directive';
import { ParticipantsComponent } from '../../pages/workspace/one-workspace-view/participants/participants.component';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SearchWorkspaceFieldComponent } from '../../pages/workspace/search-workspace-field/search-workspace-field.component';
import { OneParticipantComponent } from '../../pages/tasks/task-details/one-participant/one-participant.component';
import { PipesModule } from '../pipes/pipes.module';
import { GroupSelectComponent } from "../../pages/workspace/one-workspace-view/participants/group-select/group-select.component";

@NgModule({
  declarations: [
    ParticipantsListComponent,
    ParticipantsComponent,
    DialogInfoComponent,
    CustomSnackbarComponent,
    RightColumn2Component,
    SortByPipe,
    SelectableButtonDirective,
    SearchWorkspaceFieldComponent,
    OneParticipantComponent
  ],
  imports: [
    NgxSpinnerModule,
    CommonModule,
    FlexLayoutModule,
    EmptyContentModule,
    RouterModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatMenuModule,
    FormsModule,
    PipesModule,
    LoadingModule,
    FloatButtonModule,
    ShareModule,
    AvatarModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
        }
    }),
    MatIconModule,
    MatDialogModule,
    LogoHeaderModule,
    GroupSelectComponent
],
  exports: [
    CommonModule,
    AvatarModule,
    ShareModule,
    FlexLayoutModule,
    EmptyContentModule,
    FloatButtonModule,
    ParticipantsListComponent,
    ParticipantsComponent,
    MatAutocompleteModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatMenuModule,
    FormsModule,
    TranslateModule,
    PipesModule,
    CustomSnackbarComponent,
    RightColumn2Component,
    SortByPipe,
    LoadingModule,
    LoadingComponent,
    SelectableButtonDirective,
    MentionableDirectiveModule,
    LogoHeaderModule,
    SearchWorkspaceFieldComponent,
    OneParticipantComponent
  ]
})
export class SharedModule {}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
