import { LogoHeaderComponent } from './logo-header.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExtendedModule } from '@angular/flex-layout';

@NgModule({
  declarations: [LogoHeaderComponent],
  imports: [CommonModule, RouterModule, ExtendedModule],
  exports: [LogoHeaderComponent]
})
export class LogoHeaderModule {}
